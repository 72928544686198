
import { ChatBubbleOvalLeftIcon, HandThumbUpIcon } from "@heroicons/react/24/outline";
import { BsInstagram } from "react-icons/bs";
import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import classes from "../../Screens/custom.module.css";
import { ChatBubbleOvalLeftEllipsisIcon, HeartIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

const RecentPost = (props) => {

  const [socialMediaType , setSocialMediaType] = useState("")
  const [postId , setPostId] = useState("");

  const PostIcon = (type) => {
    if (type === "facebook") {
     
      return (
        <div className="bg-fb rounded-full flex w-8 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaFacebookF size={15} color={"white"} />
        </div>
      );
    }
    if (type === "instagram") {
      // setSocialMediaType("facebook")
      // setPostId(props.data.InstagramPostId.split("_")[1])
      return (
        <div className={classes.instagram}>
          {" "}
          <BsInstagram size={15} color={"#fff"} />
        </div>
      );
    }
    if (type === "twitter") {
      return (
        <div className="bg-twitter rounded-full flex w-8 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaTwitter size={15} color={"#fff"} />
        </div>
      );
    }
    if (type === "youtube") {
      return (
        <div className="bg-red-500 rounded-full flex w-8 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaYoutube size={15} color={"#fff"} />
        </div>
      );
    }
    if (type?.includes("linkedin")) {
      return (
        <div className="bg-linkedIn rounded-full flex w-8 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaLinkedinIn size={15} color={"#fff"} />
        </div>
      );
    }
  };
  const dateFormat = (date) => {
    const dateObj = new Date(date);
    const day = dateObj?.getDate();
    const month = dateObj?.getMonth();
    const year = dateObj?.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + (month + 1) + "-" + year;
  };
  const time24to12Converter = (date) => {
        // let currentTime = new Date(date).getTime();
    // let dateObj = new Date(currentTime + 5.5 * 60 * 60 * 1000);
    const dateObj = new Date(date);
    var t = dateObj.getHours() + ":" + dateObj.getMinutes();
    let time = t.split(":");
    let temp = " AM";
    if (parseInt(time[0]) > 11) {
      time[0] = time[0] - 12 < 1 ? 12 : (time[0] -= 12);
      temp = " PM";
    } else {
      time[0] = time[0] < 1 ? 12 : time[0];
    }
    return time.join(":") + temp;
  };
  const Discription = (socialName) => {
    if (socialName === "facebook") {
      return props.data.FBDescription || props.data.Description;
    }
    if (socialName === "instagram") {
      return props.data.InstaDescription || props.data.Description;
    }
    if (socialName === "twitter") {
      return props.data.TwitterDescription || props.data.Description;
    }
    if (socialName.includes("linkedin")) {
      return props.data.LinkedinDescription || props.data.Description;
    }
  };
  const LikesAndComments = (socialName) => {
    if (socialName === "facebook") {
      return (
        <div className="flex m-2">
        {
          <>
            {" "}
            <HandThumbUpIcon className="h-6 w-6 text-gray-500 mr-2" /> <span className="mr-2">{props.data.FBPostReactionByTypeTotal||0}</span>
          </>
        }
        {
          <>
            {" "}
            <ChatBubbleOvalLeftIcon  className="h-6 w-6  mr-2" /> <span className="mr-2"></span>
          </>
        }
      </div>);
    }
    if (socialName === "instagram") {
      return (
        <div className="flex m-2">
          {
            <>
              {" "}
              <HandThumbUpIcon className="h-6 w-6 mr-2" /> <span className="mr-2">{props.data.IGLikeCount||0}</span>
            </>
          }
          {
            <>
              {" "}
              <ChatBubbleOvalLeftIcon  className="h-6 w-6 mr-2" /> <span className="mr-2">{props.data.IGCommentCount||0}</span>
            </>
          }
        </div>
      );
    }
    if (socialName === "twitter") {
      return (
        <div className="flex m-2">
        {
          <>
            {" "}
            <HandThumbUpIcon className="h-6 w-6 mr-2" /> <span className="mr-2"></span>
          </>
        }
        {
          <>
            {" "}
            <ChatBubbleOvalLeftIcon  className="h-6 w-6 mr-2" /> <span className="mr-2"></span>
          </>
        }
      </div>);
    }
    if (socialName.includes("linkedin")) {
      return (
        <div className="flex m-2">
        {
          <>
            {" "}
            <HandThumbUpIcon className="h-6 w-6 mr-2" /> <span className="mr-2">{props.data.LILikeCount||0}</span>
          </>
        }
        {
          <>
            {" "}
            <ChatBubbleOvalLeftIcon  className="h-6 w-6 mr-2" /> <span className="mr-2">{props.data.LICommentsCount||0}</span>
          </>
        }
      </div>
      );
    }
  };

  const SendPostId = (socialName) => {
    if (socialName === "facebook") {
      
      props.GetAnalyticsData(socialName, props.data.FacebookPostId.split("_").length>1 ? props.data.FacebookPostId.split("_")[1] : props.data.FacebookPostId,props.data) 
      
    }
    if (socialName === "instagram") {
      props.GetAnalyticsData(socialName, props.data.InstagramPostId.split("_").length>1 ? props.data.InstagramPostId.split("_")[1] : props.data.InstagramPostId,props.data) 
    }
    
  };
  
  // return (
  //   <div className="group relative shadow rounded-md w-72 sm:w-52 mr-3 mb-5">
  //     <div className="min-h-80  w-full overflow-hidden rounded-md   lg:aspect-none ">
  //       <div class="flex p-4 justify-between">
  //         <div class="flex items-center space-x-2">
  //           {PostIcon(props.data?.Platform)}
  //           <div>
  //             <h2 class="text-gray-800 font-semibold w-36 truncate">
  //               {props.data.PageName}
  //             </h2>
  //             <div className="text-xs text-gray-400">
  //               {dateFormat(props.data?.PostLiveDate)}{" "}
  //               <span>{time24to12Converter(props.data.PostLiveDate)}</span>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="pl-2 pb-4 flex h-60 items-center pr-2">
  //         {props.data.ImageUrl ? (
  //           props.data.ImageUrl.toLowerCase().includes("video") ||
  //           props.data.ImageUrl.includes("mp4") ||
  //           props.data.ImageUrl.includes("gif") ||
  //           props.data.ImageUrl.includes("mkv") ? (
  //             <video
  //               className="   object-cover object-center m-auto hover:object-contain flex justify-center "
  //               controls
  //             >
  //               <source src={props.data.ImageUrl?.split(",")[0]} />
  //             </video>
  //           ) : (
  //             <img
  //               src={props.data.ImageUrl?.split(",")[0]}
  //               alt={"post_image"}
  //               className="   object-cover object-center m-auto hover:object-contain flex justify-center "
  //             />
  //           )
  //         ) : (
  //           <img
  //             src={"/assets/onepost-logo-gray.png"}
  //             alt={"post_image"}
  //             className="object-cover object-center hover:object-contain flex justify-center "
  //           />
  //         )}
  //       </div>
  //       <div className="text-gray-500">{LikesAndComments(props.data.Platform)}</div>
  //       <div className="pl-2 font-semibold text-gray-900 pb-4 w-42 mt-2 truncate">
  //         {Discription(props.data.Platform)}
  //       </div>
  //     </div>
  //   </div>
  // );
  return (


    <div  className="group relative shadow rounded-md w-72 sm:w-52 mr-3 mb-5">
      <div className="min-h-80  w-full overflow-hidden rounded-md   lg:aspect-none ">
      <div class="flex p-4 justify-between">
  <div class="flex items-center space-x-2">
   {PostIcon(props.data?.SocialMediaName)}
   <div><h2 class="text-gray-800 font-semibold w-36 truncate">{props.data.PageName}</h2>
    <div className="text-xs text-gray-400">{dateFormat(props.data?.UpdatedAt)}     <span>{time24to12Converter(props.data.UpdatedAt)}</span></div>
    </div> 
  </div>
</div>
<div className="pl-2 pb-4 flex h-60 items-center pr-2">
       {props.data.ImageUrl?
       props.data.ImageUrl.toLowerCase().includes("video")||props.data.ImageUrl.includes("mp4")||props.data.ImageUrl.includes("MP4")||props.data.ImageUrl.includes("gif")||props.data.ImageUrl.includes("mkv")? <video  className="   object-cover object-center m-auto hover:object-contain flex justify-center " controls>
          <source src={props.data.ImageUrl?.split(",")[0]} />
        </video>: <img
          src={props.data.ImageUrl?.split(",")[0]}
          alt={"post_image"}
          className="   object-cover object-center m-auto hover:object-contain flex justify-center "
        />:<img
        src={"/assets/onepost-logo-gray.png"}
        alt={"post_image"}
        className="object-cover object-center hover:object-contain flex justify-center "
      />}
   
        </div>
        <div className="pl-2 font-semibold text-gray-600 pb-2 w-42 mt-2 truncate px-2">{Discription(props.data.SocialMediaName)}</div>
        {props.data.SocialMediaName == "facebook" || props.data.SocialMediaName == "instagram" ?<div className="flex justify-end px-2 pb-2 text-xs font-medium cursor-pointer text-indigo-500" onClick={()=>{SendPostId(props.data.SocialMediaName)}}>
          view more
        </div>:<></>}
      </div>

    </div>
)
};
export default RecentPost;
