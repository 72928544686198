import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HiOutlineLogout } from 'react-icons/hi'
import Logout from '../Logout';
import { AiOutlineLogout } from 'react-icons/ai';
import { IoMdLogOut } from 'react-icons/io';

const SocialPageModal = (props) => {
  const [modal, setModal] = useState(false);
    const cancelButtonRef = useRef(null)
  return (
    <Transition.Root show={props.modal} as={Fragment}>
    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setShowModal} > 
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 lg:w-[1200px] lg:mx-auto">
              <div className="bg-white p-4 ">
                <div className="">
                
                  <div className=" text-center sm:text-left">
                    <Dialog.Title as="h3" className="text-lg text-gray-900">
                    {modal==="Logout" && (
          <Logout modal={modal} setModal={setModal} />
        )}
            <div className='w-full flex justify-between items-center pb-3' id={props.id}>        <h1 className="text-lg font-semibold text-gray-900" >
                    Connect a new channel
                        </h1> 
                        <div
                              onClick={() => setModal("Logout")}
                              className={
                                " px-4 py-1.5 flex items-center gap-1  bg-gray-100 cursor-pointer text-sm sm:text-base font-semibold text-gray-700 hover:bg-gray-300"
                              }
                            >
                              Logout <IoMdLogOut className='h-4 w-4 text-gray-900'  />
                            </div>
                        {/* <AiOutlineClose className='cursor-pointer' onClick={()=>props.setModal(false)} /> */}
                         </div>
                        <div className='w-full flex justify-between'>      <div className=" text-left pb-2 sm:pb-0  text-base">
                        To allow SAS One access to your social accounts, click
                        on connect and follow the steps
                        </div></div>
                  {props.children}
                    </Dialog.Title>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  )
}

export default SocialPageModal