import React, { useEffect, useState } from "react";
import Calender from "../../Components/TailwindCompnents/Calender";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import TailwindModal from "../../Components/TailwindCompnents/TailwindModal";
import CalendarMonthView from "../../Components/TailwindCompnents/CalendarMonthView";
import Cookies from "js-cookie";
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
const ScheduledPostsNew = (props) => {
  const { pathname } = useLocation();
  const [newPostedData, setNewPostedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const loginToken = Cookies.get("UserToken");
  const [status, setStatus] = useState(
    pathname.includes("live") ? "Live" : "Scheduled"
  );
  const [modal, setModal] = useState("");
  const [pages, setPages] = useState([]);
  const [postId, setPostId] = useState("");

  const [modalShow, setModalShow] = useState(false);
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var dt = new Date(firstDay);
  const [monthNum, setMonthNum] = useState(dt);

  const [succesMessage, setSuccesMessage] = useState("");
  const navigate = useNavigate();
  const checkLoginType = Cookies.get("LoginType")
  const contextValue = Cookies.get("UserType")
  const ProductName = Cookies.get("ProductName");


  useEffect(() => {
    var date = props.device === "Mobile" ? currentDate() : currentMonth();
    console.log(currentMonth(), "iii");
    schedulePosts(date);
    AllPages();
  }, [startDate, monthNum]);
  const currentDate = () => {
    const name = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const DAY = 60 * 60 * 24 * 1000;
    const today = new Date(startDate).getDay();

    var arr = [];

    for (let i = today; i >= 0; i--) {
      const date = new Date(startDate - DAY * i);

      arr.push({
        day: name[date.getDay()],
        date: date,
      });
      // arr1.push(date.getDate());
    }
    for (let i = 1; i < 7 - today; i++) {
      const date = new Date(startDate + DAY * i);

      arr.push({
        day: name[date.getDay()],
        date: date,
      });
    }
    let day = arr[0].date.getDate();
    let month = arr[0].date.getMonth();
    let year = arr[0].date.getFullYear();

    let format2 =
      year +
      "-" +
      (month + 1 < 10 ? "0" + (month + 1) : month + 1) +
      "-" +
      (day < 10 ? "0" + day : day);
    return format2;
  };

  const currentMonth = () => {
    var date = new Date(monthNum);
    const curMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    let day = curMonth.getDate();
    let month = curMonth.getMonth();
    let year = curMonth.getFullYear();

    let format2 =
      year +
      "-" +
      (month + 1 < 10 ? "0" + (month + 1) : month + 1) +
      "-" +
      (day < 10 ? "0" + day : day);
    return format2;
  };
  const schedulePosts = (date) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: loginToken,
      StartDate: date,
      Duration: props.device === "Mobile" ? "Weekly" : "Monthly",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/onepost/GetWeeklyScheduledPosts",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setNewPostedData(result.scheduled);
        } else if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType === "Email" ? LogOut() : SignOutFunction()
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const AllPages = (date) => {
    // setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: loginToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/social/GetUserPageNames",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setPages(result.scheduled);
        } else if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType === "Email" ? LogOut() : SignOutFunction()
        }
      })
      .catch((error) => console.log("error", error));
    // .finally(()=>setLoading(false))
  };
  const removePost = (id) => {
    setModalShow(false);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: loginToken,
      PostID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/social/DeletePost",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setModal("saved");
          setSuccesMessage("Your post is now deleted!");
          schedulePosts(date);
        } else if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType === "Email" ? LogOut() : SignOutFunction()
        } else {
          setModal("saved");
          setSuccesMessage(" Oops, A problem has occurred. Please try again ");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const LogOut = () => {
    Cookies.remove("LoggedIn", { domain: '.sasone.in' });
    Cookies.remove("UserToken", { domain: '.sasone.in' });
    Cookies.remove("FirstName", { domain: '.sasone.in' });
    Cookies.remove("LastName", { domain: '.sasone.in' });
    Cookies.remove("Mobile", { domain: '.sasone.in' });
    Cookies.remove("userName", { domain: '.sasone.in' });
    Cookies.remove("Email", { domain: '.sasone.in' });
    Cookies.remove("UserId", { domain: '.sasone.in' });
    Cookies.remove("LoginType", { domain: '.sasone.in' });
    Cookies.remove("ProfilePicture", { domain: '.sasone.in' });
    Cookies.remove("UserType", { domain: '.sasone.in' });
    Cookies.remove("SignupUser", { domain: '.sasone.in' });

    navigate("/login");
  };
  const SignOutFunction = () => {
    Cookies.remove("LoggedIn", { domain: '.sasone.in' });
    Cookies.remove("UserToken", { domain: '.sasone.in' });
    Cookies.remove("FirstName", { domain: '.sasone.in' });
    Cookies.remove("LastName", { domain: '.sasone.in' });
    Cookies.remove("Mobile", { domain: '.sasone.in' });
    Cookies.remove("userName", { domain: '.sasone.in' });
    Cookies.remove("Email", { domain: '.sasone.in' });
    Cookies.remove("UserId", { domain: '.sasone.in' });
    Cookies.remove("LoginType", { domain: '.sasone.in' });
    Cookies.remove("ProfilePicture", { domain: '.sasone.in' });
    Cookies.remove("UserType", { domain: '.sasone.in' });
    Cookies.remove("SignupUser", { domain: '.sasone.in' });

    // Cookies.remove("LoggedIn");
    // Cookies.remove("UserToken");
    // Cookies.remove("FirstName");
    // Cookies.remove("LastName");
    // Cookies.remove("Mobile");
    // Cookies.remove("userName");
    // Cookies.remove("Email");
    // Cookies.remove("UserId");
    // Cookies.remove("LoginType");
    // Cookies.remove("ProfilePicture");
    Cookies.set("googleLogin", "googleLogin", { domain: '.sasone.in' })
    window.open("https://www.sasone.in/sasone-login", "_self")
  }
  const BreadCrumb = [{ name: `${status} Posts`, href: "", current: true }];
  var date = currentDate();
  return (
    <DashboardComp BreadCrumb={BreadCrumb} setStatus={setStatus}>
      {modal === "saved" && (
        <TailwindModal
          modalCase={"confirmation"}
          Text={succesMessage}
          setModal={setModal}
          isCancel={false}
          modal={modal}
        />
      )}
      {modal === "remove" && (
        <TailwindModal
          modalCase={"ConfirmMessage"}
          Heading={"Delete Post"}
          Text={"Are you sure you want to delete this post?"}
          setModal={setModal}
          RemoveText={"Delete"}
          onClick={() => removePost(postId)}
          modal={modal}
        />
      )}
      <div className=" overflow-hidden mb-5 sm:mx-16 mx-6 rounded-lg bg-white lg:p-4 shadow">
        <div
          className={
            localStorage.getItem("trialexpire") === "yes"
              ? "p-6 pointer-events-none opacity-50"
              : "p-6"
          }
        >
          <h1 className="text-xl font-semibold text-gray-900">
            Your {status} Posts
          </h1>
          <div className="text-gray-400">
            All your {status} posts across social media published by {ProductName?ProductName:"ONE POST"}
          </div>

          {props.device === "Mobile" ? (
            <Calender
              pages={pages}
              setPostId={setPostId}
              setModal={setModal}
              setStatus={setStatus}
              status={status}
              modalShow={modalShow}
              setModalShow={setModalShow}
              Loading={loading}
              setStartDate={setStartDate}
              startDate={startDate}
              schedulePosts={schedulePosts}
              newPostedData={newPostedData}
              device={props.device}
              contextValue={contextValue}
            />
          ) : (
            <CalendarMonthView
              pages={pages}
              monthNum={monthNum}
              setMonthNum={setMonthNum}
              setPostId={setPostId}
              setModal={setModal}
              setStatus={setStatus}
              status={status}
              modalShow={modalShow}
              setModalShow={setModalShow}
              Loading={loading}
              setStartDate={setStartDate}
              startDate={startDate}
              schedulePosts={schedulePosts}
              newPostedData={newPostedData}
              device={props.device}
              contextValue={contextValue}
            />
          )}
        </div>
      </div>{" "}
    </DashboardComp>
  );
};
export default ScheduledPostsNew;
