import { useRef } from "react";

const Badges = (props) => {
  const videoRef = useRef(null);

  function handleVideoLoad() {
    const video = videoRef.current;
    props.setVideoInfo([
      ...props.videoInfo,{videoWidth:video.videoWidth,videoHeight:video.videoHeight}
    ])
    console.log(`Video resolution: ${video.videoWidth}x${video.videoHeight}`);
  }
  return (
    <>
      <span className="inline-flex items-center h-15 w-15 mr-2 mt-2  py-0.5 pl-2.5 pr-1 text-sm font-medium text-indigo-700">
        <div className=" border-2  border-indigo-500 rounded-md">
          {props.item?.type?.includes("video") ? (
          <div className="h-16 w-16">  <video ref={videoRef} onLoadedData={handleVideoLoad}
              // width="250" height="250"
              controls
              // autoPlay
            >
              <source
                src={URL.createObjectURL(props.item)}
                type={props.item.type}
                className=" object-cover  rounded-md"
              />
            </video></div>
          ) : (
           <div className="h-16 w-16"> <img
              src={URL.createObjectURL(props.item)}
              alt=""
              className=" object-cover w-full  rounded-md"
            /></div>
          )}
        </div>
        <button  onClick={props.onClick}
          type="button"
          className="ml-0.5 -ml-2.5 -mt-16 bg-red-500 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-white hover:bg-indigo-400 hover:text-white focus:bg-indigo-500 focus:text-white focus:outline-none"
        >
          <svg
           
            className="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      </span>
    </>
  );
};
export default Badges;
