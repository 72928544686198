import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationMessage from "../../Components/TailwindCompnents/ConfirmationMessage";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import GMBCard from "../../Components/TailwindCompnents/GMBCard";
import Chats from "../../Components/TailwindCompnents/GMBComp/Chats";
import Posts from "../../Components/TailwindCompnents/GMBComp/Posts";
import Reviews from "../../Components/TailwindCompnents/GMBComp/Reviews";
import GMBPageList from "../../Components/TailwindCompnents/GMBPageList";
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
const GoogleMyBussiness = () => {
  const [showGMB, setShowGMB] = useState(true);
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const [locationIdLoading, setLocationIdLoading] = useState(false);
  const [locationId, setLocationId] = useState("");
  const [pageId, setPageId] = useState("");
  const [accounts, setAccounts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [post, setPost] = useState([]);

  const loginToken = Cookies.get("UserToken");
  const [successMsg, setSuccessMsg] = useState("");
  const navigate = useNavigate();
  const checkLoginType=Cookies.get("LoginType")
  const contextValue = Cookies.get("UserType")
  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: loginToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/GetGMBaccountsByUserId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType==="Email"?LogOut():SignOutFunction()
        } else if (result.status_code === 200 && result.status === "Success") {
          setAccounts(result.data);
          getLocation(result.data[0].PageId);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const getLocation = (id) => {
    setPageId(id);
    setLocationId("");
    setLocationIdLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      UserToken: loginToken,
      PageId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/GetGMBlocationsByAccountId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
         checkLoginType==="Email"?LogOut():SignOutFunction()
        } else if (result.status_code === 200 && result.status === "Success") {
          setLocations(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLocationIdLoading(false));
  };
  const getReviews = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      UserToken: loginToken,
      PageId: pageId,
      LocationId: locationId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/GetGMBReviewsByLocationId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType==="Email"?LogOut():SignOutFunction()
        } else if (result.status_code === 200 && result.status === "Success") {
          setReviews(result.data);
          setShowGMB(false);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const getPost = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      UserToken: loginToken,
      PageId: pageId,
      LocationId: locationId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/GetGMBPostsByLocationId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType==="Email"?LogOut():SignOutFunction()
        } else if (result.status_code === 200 && result.status === "Success") {
          setPost(result.data);
          setShowGMB(false);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const replyReview = (reviewId) => {
    setCommentLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      UserToken: loginToken,
      PageId: pageId,
      LocationId: locationId,
      ReviewId: reviewId,
      Reply: comment,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/ReplyGMBReviewsByReviewId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType==="Email"?LogOut():SignOutFunction()
        } else if (result.status_code === 200 && result.status === "Success") {
          setSuccessMsg(
            "Thank you for your time. We appreciate your valuable feedback. "
          );
          setComment("");
          getReviews();
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setCommentLoading(false));
  };
  const LogOut = () => {
    Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
    Cookies.remove("UserToken", {  domain:'.sasone.in' });
    Cookies.remove("FirstName", {  domain:'.sasone.in' });
    Cookies.remove("LastName", {  domain:'.sasone.in' });
    Cookies.remove("Mobile", {  domain:'.sasone.in' });
    Cookies.remove("userName", {  domain:'.sasone.in' });
    Cookies.remove("Email", {  domain:'.sasone.in' });
    Cookies.remove("UserId", {  domain:'.sasone.in' });
    Cookies.remove("LoginType", {  domain:'.sasone.in' });
    Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });

    navigate("/login");
  };
  const SignOutFunction=()=>{
    Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
    Cookies.remove("UserToken", {  domain:'.sasone.in' });
    Cookies.remove("FirstName", {  domain:'.sasone.in' });
    Cookies.remove("LastName", {  domain:'.sasone.in' });
    Cookies.remove("Mobile", {  domain:'.sasone.in' });
    Cookies.remove("userName", {  domain:'.sasone.in' });
    Cookies.remove("Email", {  domain:'.sasone.in' });
    Cookies.remove("UserId", {  domain:'.sasone.in' });
    Cookies.remove("LoginType", {  domain:'.sasone.in' });
    Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });

    // Cookies.remove("LoggedIn");
    // Cookies.remove("UserToken");
    // Cookies.remove("FirstName");
    // Cookies.remove("LastName");
    // Cookies.remove("Mobile");
    // Cookies.remove("userName");
    // Cookies.remove("Email");
    // Cookies.remove("UserId");
    // Cookies.remove("LoginType");
    // Cookies.remove("ProfilePicture");
    Cookies.set("googleLogin", "googleLogin", {  domain:'.sasone.in' })
    window.open("https://www.sasone.in/sasone-login","_self")
  }
  return (
    <div>
      {showGMB && (
        <GMBPageList
          setModal={setShowGMB}
          modal={showGMB}
          accounts={accounts}
          locations={locations}
          loading={loading}
          setShowModal={setModal}
          locationIdLoading={locationIdLoading}
          getLocation={getLocation}
          locationId={locationId}
          setLocationId={setLocationId}
          setPageId={setPageId}
          onClick={() => {
            getReviews();
            getPost();
          }}
          contextValue={contextValue}
        />
      )}
      {successMsg ===
        "Thank you for your time. We appreciate your valuable feedback. " && (
        <ConfirmationMessage
          Alert={"success"}
          onClick={() => setSuccessMsg(false)}
          success={""}
          contextValue={contextValue}
          text={successMsg}
        />
      )}
      <DashboardComp>
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          {" "}
          <div className="overflow-hidden mb-5 rounded-lg bg-white shadow">
            <div
              className={
                localStorage.getItem("trialexpire") === "yes"
                  ? "p-6 pointer-events-none opacity-50"
                  : "p-6"
              }
            >
              {" "}
              <h1 className="text-xl font-semibold text-gray-900">
                Google My Business
              </h1>
              <div className="text-gray-400">
                To allow SAS One access to your social accounts, click on
                connect and follow the steps
              </div>
              <div className="grid lg:grid-cols-3 lg:gap-2 gap-y-4 mt-4">
                {reviews.length ? (
                  <Reviews
                    onReplySubmit={replyReview}
                    data={reviews}
                    comment={comment}
                    setIsAccount={setShowGMB}
                    setComment={setComment}
       
                    commentLoading={commentLoading}
                  />
                ) : (
                  <GMBCard
                  headingText={"No Reviews"}  text={"Ask your customers to review your business on Google."}
                  setIsAccount={setShowGMB}
                    heading={"Reviews"}
                  />
                )}
                {/* <Chats /> */}
                <GMBCard
                headingText={"Coming Soon"}  text={"Keep up with the messages from your customers on Google."}
                  setIsAccount={setShowGMB}
                    heading={"Chats"}
                  />
                {post.length ? (
                  <Posts data={post}    setIsAccount={setShowGMB}   pageId={pageId?pageId:""} locationId={locationId?locationId:""}/>
                ) : (
                  <GMBCard
                  headingText={"No Posts"}  text={"Share the latest updates about your Business."}
                  pageId={pageId?pageId:""} locationId={locationId?locationId:""}
                  isPost={true}

                  setIsAccount={setShowGMB}
                    heading={"Posts"}
                  />
                )}

                {/* <GMBCard onClick={()=>setIsAccount(true)} heading={"Chats"} />
          <GMBCard onClick={()=>setIsAccount(true)} heading={"Posts"} /> */}
              </div>
            </div>
          </div>
        </div>
      </DashboardComp>
    </div>
  );
};

export default GoogleMyBussiness;
