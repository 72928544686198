import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { IoAnalytics} from 'react-icons/io5'
import { HiOutlineDocumentDuplicate} from 'react-icons/hi'
import { SiGoogleanalytics} from 'react-icons/si'
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
import Cookies from 'js-cookie';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AnalyticsCard=(props)=> {

  const contextValue = Cookies.get("UserType")
  const stats = [
    { id: 1, name: 'Posts', stat: props.data.total_post_count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), icon: HiOutlineDocumentDuplicate, change: '122', changeType: 'increase' },
    { id: 2, name: 'Impression', stat: props.data.total_impressions?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), icon: SiGoogleanalytics, change: '5.4%', changeType: 'increase' },
    { id: 3, name: 'Reach', stat: props.data.total_reach?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), icon: IoAnalytics, change: '3.2%', changeType: 'decrease' },
  ]
  return (
    <div>
  <h1 className="text-xl font-semibold text-gray-900">
         Performance
          </h1>
          <div className="text-gray-400 text-base">Get insights on your social posts performance in real time</div>
      <dl className="mt-5 grid grid-cols-3 gap-4 sm:gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-0 pt-5 pb-12 sm:shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className={`sm:absolute flex justify-center rounded-md bg-${contextValue}-600 p-3 mb-2`}>
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-1 sm:ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
            </dt>
            <dd className="ml-1 sm:ml-16 flex items-baseline pb-0 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
            
              <div className="absolute inset-x-0 bottom-0 sm:bg-gray-50 px-1 py-4 sm:px-6">
                <div className="text-sm">
                  <a href="/analytics-new" className={`font-medium text-${contextValue}-600 hover:text-${contextValue}-500`}>
                    {' '}
                    View all<span className="sr-only"> {item.name} stats</span>
                  </a>
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
export default AnalyticsCard