import React, { useEffect, useState } from "react";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import { FcGoogle } from "react-icons/fc";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
const GMBTesting = () => {
  const onSuccess = (res) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${res.accessToken}`);
    myHeaders.append("Accept", `application/json`);
    var raw = "";
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${res.accessToken}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        loactions(res.accessToken, result.id);
      })
      .catch((error) => console.log(error, "abc"));
  };

  const loactions = (res, id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${res}`);
    myHeaders.append("Accept", `application/json`);
    var raw = JSON.stringify({
        readMask: "user.displayName"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

    fetch(
      `https://mybusinessbusinessinformation.googleapis.com/v1/locations/110256401911158996551`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log(error, "abc"));
  };

  const onFailure = (res) => {
    console.log("error", res);
  };

  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId:
        "834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com",
      plugin_name: "chat",
    });
  });

  return (
    <div>
      <DashboardComp>
        <GoogleLogin
          clientId={
            "834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com"
          }
        //   clientId={
        //     "274543696084-900qn4u2l50tff1d3ocee9el2mp136k4.apps.googleusercontent.com"
        //   }
          buttonText="Login"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={false}
          render={(renderProps) => (
            <div
              className="m-auto mt-6 flex justify-center items-center bg-blue-500/100 py-1 w-48 rounded hover:bg-blue-400/100 cursor-pointer"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <div className="bg-white p-1 rounded">
                {" "}
                <FcGoogle size="23" />
              </div>
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="px-1 text-base font-medium text-white"
              >
                Sign in with Google
              </button>
            </div>
          )}
        />
      </DashboardComp>
    </div>
  );
};

export default GMBTesting;
