import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AiOutlineSchedule } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom';
import Input from './Input';
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
import Cookies from 'js-cookie';
const TailwindModal = (props) => {
  const cancelButtonRef = useRef(null)
  const navigate = useNavigate();
  const contextValue = Cookies.get("UserType")
  const modalType = (type) => {
    switch (type) {
      case "SchedulePost":
        return (
          <Transition.Root show={props.modal === "schedule" ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${contextValue}-100 sm:mx-0 sm:h-10 sm:w-10`}>
                            <AiOutlineSchedule className={`h-6 w-6 text-${contextValue}-600`} aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">

                              Schedule a  post
                            </Dialog.Title>
                            <div className="mt-1">
                              <p className="text-sm text-gray-500">
                                Quickly create a social post and share it across multiple platforms.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='flex w-full my-4 pb-4 justify-between px-4 sm:px-6'>
                        <div className='w-3/5 mr-2  '>
                          <Input
                            type={"input with label"}
                            inputType={"date"}
                            min={props.min}
                            // placeholder={"The content will be posted on Facebook"}
                            onChange={(e) => { props.setDate(e.target.value) }}
                            value={props.date}
                          />
                          <div className={"text-red-500 text-sm"}>{props.error.date}</div>
                        </div>
                        <div className='w-3/5'>
                          <Input
                            type={"input with label"}
                            inputType={"time"}
                            // placeholder={"The content will be posted on Facebook"}
                            onChange={(e) => { props.setTime(e.target.value) }}
                            value={props.time}
                          />
                          <div className={"text-red-500 text-sm"}>{props.error.time}</div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-${contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={props.onClick}
                        >
                          Schedule
                        </button>
                        <button
                          type="button"
                          className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={() => props.setModal("")}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )
      case "confirmation":
        return (
          <Transition.Root show={(props.modal === "save" || props.modal === "saved") ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex  sm:items-start">
                          <div className={`mx-auto items-center flex h-12 w-12 flex-shrink-0  justify-center rounded-full bg-${contextValue}-100 sm:mx-0 sm:h-10 sm:w-10`}>
                            <AiOutlineSchedule className={`h-6 w-6 text-${contextValue}-600`} aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-lg mt-2 font-medium leading-6 text-gray-900">

                              {props.Text}
                            </Dialog.Title>
                            {/* <div className="mt-2">
                            <p className="text-sm text-gray-500">
                            Quickly create a social post and share it across multiple platforms.
                            </p>
                          </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-${contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={() => props.onClick || props.setModal("")}
                        >
                          Ok
                        </button>
                        {props.isCancel !== false && <button
                          type="button"
                          className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={() => props.setModal("")}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )
      case "ConfirmMessage":
        return (
          <Transition.Root show={(props.modal === "remove") ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white mx-4  pb-4 sm:pb-4">
                        <div className="">
                          <div className=" py-6 mx-4 border-b ">
                            <div className='text-2xl font-bold '>{props.Heading}</div>
                          </div>
                          <div className="py-6 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">

                              {props.Text}
                            </Dialog.Title>

                          </div>
                        </div>
                      </div>

                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-${contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={props.onClick}
                        >
                          {props.RemoveText || "Remove"}
                        </button>
                        <button
                          type="button"
                          className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={() => props.setModal("")}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )
      case "Logout":
        return (
          <Transition.Root show={(props.modal === "Logout") ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white mx-4  pb-4 sm:pb-4">
                        <div className="">
                          <div className=" py-6 mx-4 border-b ">
                            <div className='text-2xl font-bold '>Logout</div>
                          </div>
                          <div className="py-6 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">

                              {props.Text}
                            </Dialog.Title>

                          </div>
                        </div>
                      </div>

                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 z-50">
                        <button
                          type="button"
                          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-${contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={props.onClick}
                        >
                          Logout
                        </button>
                        <button
                          type="button"
                          className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={() => props.setModal("")}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )
      case "resolution":
        return (
          <Transition.Root show={(props.modal === "resolution") ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white mx-4 ">
                        <div className="">
                          {/* <div className=" py-6 mx-4 border-b ">
                                    <div className='text-base leading-6'>    {props.Text}</div>
                                    </div> */}
                          <div className="py-6 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-base ">
                              <div className='font-semibold' >     {props.Text}</div>

                              {props.SubText}
                            </Dialog.Title>

                          </div>
                        </div>
                      </div>

                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={props.onClick}
                        >
                          Continue
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          onClick={() => props.setModal("")}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )
      case "linkAccount":
        return (
          <Transition.Root show={props.modal === "save" ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className={`mx-auto items-center flex h-12 w-12 flex-shrink-0  justify-center rounded-full bg-${contextValue}-100 sm:mx-0 sm:h-10 sm:w-10`}>
                            <AiOutlineSchedule className={`h-6 w-6 text-${contextValue}-600`} aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">

                              {props.Text}
                            </Dialog.Title>

                          </div>
                        </div>
                      </div>

                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-${contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={props.onClick}
                        >
                          Ok
                        </button>

                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )
      case "CreatePost":
        return (
          <Transition.Root show={props.modal === "CreatePost" ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="{`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${contextValue}-100 sm:mx-0 sm:h-10 sm:w-10`}">
                            <AiOutlineSchedule className={`h-6 w-6 text-${contextValue}-600`} aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-lg mt-2 font-medium leading-6 text-gray-900">

                              Status of your post
                            </Dialog.Title>
                            {/* <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                Quickly create a social post and share it across multiple platforms.
                                </p>
                              </div> */}
                          </div>
                        </div>
                      </div>
                      <div className={"  px-4 sm:px-6"}>
                        {props.createPostData.facebook?.length ? (
                          <div>
                            Facebook :
                            {props.createPostData.facebook.map((item, index) => {
                              if (item.status === "Sucess") {
                                return (
                                  <div className={"text-gray-500"}>
                                    {item.pageName} : Post is live on {item.pageName}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className={"text-red-500"}>
                                    {item.pageName} :
                                    <span> Failed to post on {item.pageName}</span>{" "}
                                    <br />
                                    Reason : {item.message}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                        {props.createPostData.gmb?.length ? (
                          <div>
                            Google My Bussiness :
                            {props.createPostData.gmb.map((item, index) => {
                              if (item.status === "Success") {
                                return (
                                  <div className={"text-gray-500"}>
                                    {item.pageName} : Post is live on {item.pageName}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className={"text-red-500"}>
                                    {item.pageName} :
                                    <span> Failed to post on {item.pageName}</span>{" "}
                                    <br />
                                    Reason : {item.message}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                        {props.createPostData.instagram?.length ? (
                          <div>
                            Instagram :
                            {props.createPostData.instagram.map((item, index) => {
                              if (item.status === "Sucess") {
                                return (
                                  <div className={"text-gray-500"}>
                                    {item.pageName} : Post is live on {item.pageName}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className={"text-red-500"}>
                                    {item.pageName} :
                                    <span> Failed to post on {item.pageName}</span>{" "}
                                    <br />
                                    Reason : {item.message === "The aspect ratio is not supported." ? "The Image/Video Aspect Ratio is not Supported ." : item.message}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                        {props.createPostData.twitter?.length ? (
                          <div>
                            Twitter :
                            {props.createPostData.twitter.map((item, index) => {
                              if (item.status === "Sucess") {
                                return (
                                  <div className={"text-gray-500"}>
                                    {item.pageName} : Post is live on {item.pageName}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className={"text-red-500"}>
                                    {item.pageName} :
                                    <span> Failed to post on {item.pageName}</span>{" "}
                                    <br />
                                    Reason : {item.message}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                        {props.createPostData.linkedin?.length ? (
                          <div>
                            LinkedIn :
                            {props.createPostData.linkedin.map((item, index) => {
                              if (item.status === "Sucess") {
                                return (
                                  <div className={"text-gray-500"}>
                                    {item.pageName} : Post is live on {item.pageName}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className={"text-red-500"}>
                                    {item.pageName} :
                                    <span> Failed to post on {item.pageName}</span>{" "}
                                    <br />
                                    Reason : {item.message}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                        {props.createPostData.youtube?.length ? (
                          <div>
                            Youtube :
                            {props.createPostData.youtube.map((item, index) => {
                              if (item.status === "Sucess") {
                                return (
                                  <div className={"text-gray-500"}>
                                    {item.pageName} : Post is live on {item.pageName}
                                  </div>
                                );
                              } else {
                                return (
                                  <div className={"text-red-500"}>
                                    {item.pageName} :
                                    <span> Failed to post on {item.pageName}</span>{" "}
                                    <br />
                                    Reason : {item.message}
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* <div >Instagram : {props?.createPostData.linkedin[0].status==="Sucess"?<span className={"classes.successtext"}>Post is live on instagram</span>:
                            <span className={"text-red-500"}>Failed to post on instagram <br />Reason : {props.createPostData?.linkedin[0].message}</span>}</div>
                            <div >LinkedIn : {props?.createPostData.instagram[0].status==="Sucess"?<span className={"classes.successtext"}>Post is live on linkedin</span>:
                            <span className={"text-red-500"}>Failed to post on linkedin <br />Reason : {props.createPostData?.instagram[0].message}</span>}</div> */}
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-${contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={() => props.setModal("")}
                        >
                          Ok
                        </button>
                        <button
                          type="button"
                          className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={() => props.setModal("")}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )
      case "UpdatePost":
        return (
          <Transition.Root show={props.modal === "UpdatePost" ? true : false} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="{`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${contextValue}-100 sm:mx-0 sm:h-10 sm:w-10`}">
                            <AiOutlineSchedule className={`h-6 w-6 text-${contextValue}-600`} aria-hidden="true" />
                          </div>
                          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <Dialog.Title as="h3" className="text-lg mt-2 font-medium leading-6 text-gray-900">

                              Your post have been updated successfully!
                            </Dialog.Title>
                            {/* <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                Quickly create a social post and share it across multiple platforms.
                                </p>
                              </div> */}
                          </div>
                        </div>
                      </div>

                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="button"
                          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-${contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={() => props.setModal("")}
                        >
                          Ok
                        </button>
                        <button
                          type="button"
                          className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={() => props.setModal("")}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )

      default:
        console.log("Wrong Input");
    }
  };
  return modalType(props.modalCase);
};

export default TailwindModal;
