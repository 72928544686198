import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";
import Cookies from "js-cookie";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
// import Image from "next/image";

const PricingSection = (props) => {
  const [selectTier, setSelectTier] = useState("");
  const [currentTier, setCurrentTier] = useState("");
  const [LoggedIn, setLoggedIn] = useState();
  const [open, setOpen] = useState(false);

  const [activeButton, setActiveButton] = useState("Monthly");

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  useEffect(() => {
    if (Cookies.get("LoggedIn")) {
      setLoggedIn(Cookies.get("LoggedIn"));
    }
  }, []);

  // console.log(open, "open");
  // console.log(selectTier);

  const Section = (e) => {
    if (e) {
      if (e.target.value === "STANDARD") {
        setCurrentTier("STANDARD");
        setSelectTier("STANDARD");
      } else if (e.target.value === "PREMIUM") {
        setCurrentTier("PREMIUM");
        setSelectTier("PREMIUM");
      } else if (e.target.value === "GOLD") {
        setCurrentTier("GOLD");
        setSelectTier("GOLD");
      }
    }
  };

  const tiers = [
    {
      name: "STANDARD",
      id: "tier-basic",
      href: "https://www.sasone.in/payment-gatway/razorpay-gateway?subscribeCode=OP1&userToken=",
      priceMonthly: "₹3,526",
      originalprice: "₹4,788",
      discount: "36%",
      description: "annually-₹3,526",
      mostPopular: false,
      key: "",
    },
    {
      name: "PREMIUM",
      id: "tier-essential",
      href: "https://www.sasone.in/payment-gatway/razorpay-gateway?subscribeCode=OP2&userToken=",
      priceMonthly: "₹4,999",
      originalprice: "₹7,188",
      discount: "44%",
      description: "annually-₹4,999",
      mostPopular: true,
      key: "Most Popular",
    },
    {
      name: "GOLD",
      id: "tier-premium",
      href: "https://www.sasone.in/payment-gatway/razorpay-gateway?subscribeCode=OP3&userToken=",
      priceMonthly: "₹14,999",
      originalprice: "₹19,188",
      discount: "28%",
      description: "annually-₹14,999",
      mostPopular: false,
      key: "",
    },
  ];

  const monthlytiers = [
    {
      name: "STANDARD",
      id: "tier-basic",
      href: "https://www.sasone.in/payment-gatway/razorpay-gateway?subscribeCode=OPMbasic&userToken=",
      priceMonthly: "₹399",
      description: "monthly-₹399",
      mostPopular: false,
      key: "",
    },
    {
      name: "PREMIUM",
      id: "tier-essential",
      href: "https://www.sasone.in/payment-gatway/razorpay-gateway?subscribeCode=OPMPremium&userToken=",
      priceMonthly: "₹599",
      description: "monthly-₹599",
      mostPopular: true,
      key: "Most Popular",
    },
    {
      name: "GOLD",
      id: "tier-premium",
      href: "https://www.sasone.in/payment-gatway/razorpay-gateway?subscribeCode=OPMGold&userToken=",
      priceMonthly: "₹1599",
      description: "monthly-₹1599",
      mostPopular: false,
      key: "",
    },
  ];

  const sections = [
   
    {
        name: "Features",
        features: [
        { name: " Brands", tiers: { STANDARD: "2", PREMIUM: "5", GOLD: "5" } },
        {
        name: "Post Scheduling (Set time slots throughout each week for your content to be realeased)",
        tiers: { STANDARD: true, PREMIUM: true, GOLD: true },
        },
        {
        name: "Calendar View (view your live content across all channels by week or month)",
        tiers: { STANDARD: true, PREMIUM: true, GOLD: true },
        },
        {
        name: "Activity Timeline (Live & Scheduled Posts - Today, This Week, This Month)",
        tiers: { STANDARD: true, PREMIUM: true, GOLD: true },
        },
        {
        name: "AI Creative Calendar (Get automatic posts on your Social Accounts on all major festivals)",
        tiers: { STANDARD: false, PREMIUM: true, GOLD: true },
        },
      ],
    },
    
   
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="bg-zinc-50 rounded-md mb-4 mt-4 py-6 sm:py-6">
      <div className="mx-auto max-w-7xl px-4 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          {/* <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Pricing</h2> */}
          <h2 className="mt-2   font-medium tracking-tight text-gray-900  text-xl lg:text-3xl">
            {props.pricingMainHeading}
          </h2>
        </div>
        <p className={`mx-auto mt-2 max-w-full text-center text-base   sm:leading-6 text-${props.contextValue}-600`}>
          {props.pricingSubHeading}
        </p>
        {/* ---------------------buttons */}

        <div className={`flex border mx-auto  border-${props.contextValue}-600 shadow-md shadow-${props.contextValue}-600 rounded-full p-1 max-w-fit justify-center mt-10`}>
          <div
            onClick={() => handleClick("Monthly")}
            className={
              activeButton === "Monthly"
                ? `active text-white bg-${props.contextValue}-600 font-medium rounded-full border-${props.contextValue}-600 px-4 py-1  border cursor-pointer`
                : "px-4 py-1   text-black rounded-lg font-medium cursor-pointer"
            }
          >
            Monthly
          </div>
          <div
            onClick={() => handleClick("Yearly")}
            className={
              activeButton === "Yearly"
                ? `active text-white bg-${props.contextValue}-600 font-medium rounded-full border-${props.contextValue}-600 border  px-4 py-1 cursor-pointer`
                : "px-4  py-1   text-black rounded-lg font-medium cursor-pointer"
            }
          >
            Annually
          </div>
        </div>

        {/* xs to lg */}
        <div className="mx-auto mt-8 max-w-md space-y-8 sm:mt-16 lg:hidden">
          {activeButton === "Monthly" ? (
            <>
              {monthlytiers.map((tier, idx) => (
                <section
                  key={idx}
                  className={`border p-4 rounded-xl bg-white  ring-1 ring-inset ring-${props.contextValue}-600`}
                >
                  <h3
                    id={tier.id}
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    <div className="flex flex-row-reverse justify-between">
                      <div>
                        {idx === 1 ? (
                          <div className="flex     bg-red-100 my-auto border-red-200 border  w-fit   text-red-500 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md  ">
                            <div >
                              Most Popular
                            </div>
                            <span>
                              <img src={"/assets/fire.png"} height={18} width={20} />
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>{tier.name}</div>
                    </div>
                  </h3>
                  <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
                    <span className="text-4xl font-bold">
                      {tier.priceMonthly}
                    </span>
                    <span className="text-sm font-semibold">/month</span>
                  </p>

                  {LoggedIn ? (
                    <a
                      href={tier.href}
                      onChange={(e) => Section(e)}
                      onClick={() => {
                        setCurrentTier(tier);
                        setSelectTier(tier);
                      }}
                      className={classNames(
                        tier.mostPopular
                          ? `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`
                          : `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`,
                        `mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${props.contextValue}-600`
                      )}
                    >
                      Buy plan
                    </a>
                  ) : (
                    <a
                      onClick={props.onClick}
                      className={classNames(
                        tier.mostPopular
                          ? `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`
                          : `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`,
                        `mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${props.contextValue}-600`
                      )}
                    >
                      Buy plan
                    </a>
                  )}

                  <ul
                    role="list"
                    className="mt-4 space-y-4 text-sm leading-6 text-gray-900"
                  >
                    <div
                      className="flex justify-center pb-3 font-semibold text-gray-900"
                      onClick={() => {
                        open === idx ? setOpen(null) : setOpen(idx);
                      }}
                    >
                      {open === idx ? " Hide Features" : "Show Features"}
                      <ChevronDownIcon
                        className={classNames(
                          open === idx ? "-rotate-180" : "rotate-0",
                          `h-5 ml-1 my-auto text-${props.contextValue}-600 transform`
                        )}
                        aria-hidden="true"
                      />
                    </div>
                    {open === idx && (
                      <div>
                        {sections.map((section) => (
                          <li key={section.name}>
                            <ul role="list" className="space-y-4">
                              {section.features.map((feature) =>
                                feature.tiers[tier.name] ? (
                                  <li
                                    key={feature.name}
                                    className="flex p-1 gap-x-3"
                                  >
                                    <span className="bg-green-600 my-auto  max-w-fit rounded-full ">
                                      <CheckIcon
                                        className="h-5 w-5 p-1 flex-none text-white"
                                        aria-hidden="true"
                                      />
                                    </span>

                                    <span className="text-start">
                                      {feature.name}{" "}
                                      {typeof feature.tiers[tier.name] ===
                                        "string" ? (
                                        <span className="text-sm leading-6 text-gray-900">
                                          ({feature.tiers[tier.name]})
                                        </span>
                                      ) : null}
                                    </span>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                        ))}
                      </div>
                    )}
                  </ul>
                </section>
              ))}
            </>
          ) : (
            <>
              {tiers.map((tier, idx) => (
                <section
                  key={idx}
                  className={`border p-4 rounded-xl bg-white  ring-1 ring-inset ring-${props.contextValue}-600`}
                >
                  <h3
                    id={tier.id}
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    <div className="flex flex-row-reverse justify-between">
                      <div>
                        {idx === 1 ? (
                          <div className="flex pt-1 bg-red-100 my-auto border-red-200 border  w-fit  text-red-500 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md  ">
                            <div className="" >
                              Most Popular
                            </div>
                            <span>
                              <img src={"/assets/fire.png"} height={18} width={20} />
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>{tier.name}</div>
                    </div>
                  </h3>
                  <p className="mt-2   items-baseline gap-x-1 text-gray-900">
                    <div className="flex items-baseline gap-x-1  text-green-600">
                      <span className="text-xl line-1  line-through decoration-red-400 font-bold">
                        {tier.originalprice}
                      </span>
                      <span className="text-xs font-semibold leading-6">
                        /year
                      </span>
                    </div>
                    <div className="flex">
                      <div className="text-4xl my-auto font-bold">
                        {tier.priceMonthly}
                      </div>
                      <span className="text-sm mt-5 ml-1 font-semibold">/Year</span>
                    </div>
                    <div className=" text-sm pt-1 font-medium text-green-600">
                      <span className="text-xl font-bold text-green-600">{tier.discount}</span> Off On Our Regular Price
                    </div>
                  </p>

                  {LoggedIn ? (
                    <a
                      href={tier.href}
                      onChange={(e) => Section(e)}
                      onClick={() => {
                        setCurrentTier(tier);
                        setSelectTier(tier);
                      }}
                      className={classNames(
                        tier.mostPopular
                          ? `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`
                          : `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`,
                        `mt-4 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${props.contextValue}-600`
                      )}
                    >
                      Buy plan
                    </a>
                  ) : (
                    <a
                      onClick={props.onClick}
                      className={classNames(
                        tier.mostPopular
                          ? `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`
                          : `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`,
                        `mt-4 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${props.contextValue}-600`
                      )}
                    >
                      Buy plan
                    </a>
                  )}

                  <ul
                    role="list"
                    className="mt-4 space-y-4 text-sm leading-6 text-gray-900"
                  >
                    <div
                      className="flex justify-center pb-3 font-semibold text-gray-900"
                      onClick={() => {
                        open === idx ? setOpen(null) : setOpen(idx);
                      }}
                    >
                      {open === idx ? " Hide Features" : "Show Features"}
                      <ChevronDownIcon
                        className={classNames(
                          open === idx ? "-rotate-180" : "rotate-0",
                          `h-5 ml-1 my-auto w-6 text-${props.contextValue}-600 transform`
                        )}
                        aria-hidden="true"
                      />
                    </div>
                    {open === idx && (
                      <div>
                        {sections.map((section) => (
                          <li key={section.name}>
                            <ul role="list" className="space-y-4">
                              {section.features.map((feature) =>
                                feature.tiers[tier.name] ? (
                                  <li
                                    key={feature.name}
                                    className="flex p-1 gap-x-3"
                                  >
                                    <span className="bg-green-600 my-auto  max-w-fit rounded-full ">
                                      <CheckIcon
                                        className="h-5 w-5 p-1 flex-none text-white"
                                        aria-hidden="true"
                                      />
                                    </span>
                                    <span>
                                      {feature.name}{" "}
                                      {typeof feature.tiers[tier.name] ===
                                        "string" ? (
                                        <span className="text-sm leading-6 text-gray-900">
                                          ({feature.tiers[tier.name]})
                                        </span>
                                      ) : null}
                                    </span>
                                  </li>
                                ) : null
                              )}
                            </ul>
                          </li>
                        ))}
                      </div>
                    )}
                  </ul>
                </section>
              ))}
            </>
          )}
        </div>

        {/* lg+ */}
        <div className="isolate mt-12 hidden lg:block">
          <div className="relative -mx-8">
            {/* ----------------css for default box------------- */}
            <div className="absolute inset-y-0 inset-x-4 -z-10 flex">
              <div
                className="flex w-1/4  px-4"
                aria-hidden="true"
                style={{
                  marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                    }%`,
                }}
              >
                <div className={`w-full  rounded-t-xl border-x border-t border-gray-900/10 bg-${props.contextValue}-50`} />
              </div>
            </div>
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
                <col className="w-1/4" />
              </colgroup>
              {/* -------------- names of package-------- */}
              <thead>
                <tr>
                  <td />

                  {tiers.map((tier, id) => (
                    <th
                      key={id}
                      scope="col"
                      className="px-6   pt-6 xl:px-8 xl:pt-8"
                    >
                      <div className="text-sm  font-semibold leading-7 text-gray-900">
                        {id === 1 ? (
                          <div className="flex pt-1  bg-red-100 my-auto border-red-200 border  w-fit mt-[-15px] text-red-500 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-md  ">
                            <div >
                              Most Popular
                            </div>
                            <span>
                              <img src={"/assets/fire.png"} height={18} width={20} />
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {tier.name}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              {/* --------------buttons options for subscriptions starts here  */}
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>

                  {activeButton === "Monthly" ? (
                    <>
                      {monthlytiers.map((tier) => (
                        <td key={tier.id} className="px-6  pt-2 xl:px-8">

                          <div className="flex items-baseline gap-x-1 text-gray-900">
                            <span className="text-4xl   font-bold">
                              {tier.priceMonthly}
                            </span>
                            <span className="text-sm   font-semibold leading-6">
                              /month
                            </span>
                          </div>

                          {LoggedIn ? (
                            <a
                              href={tier.href}
                              onChange={(e) => Section(e)}
                              onClick={() => {
                                setCurrentTier(tier);
                                setSelectTier(tier);
                              }}
                              className={classNames(
                                tier.mostPopular
                                  ? `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`
                                  : `text-white bg-gray-900 cursor-pointer ring-1 ring-inset   hover:ring-${props.contextValue}-300`,
                                `mt-8 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${props.contextValue}-600`
                              )}
                            >
                              Buy plan
                            </a>
                          ) : (
                            <a
                              onClick={props.onClick}
                              className={classNames(
                                tier.mostPopular
                                  ? `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`
                                  : `text-white bg-gray-900 cursor-pointer ring-1 ring-inset   hover:ring-${props.contextValue}-300`,
                                `mt-8 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${props.contextValue}-600`
                              )}
                            >
                              Buy plan
                            </a>
                          )}
                        </td>
                      ))}
                    </>
                  ) : (
                    <>

                      {tiers.map((tier) => (
                        <td key={tier.id} className="px-6  pt-2 xl:px-8">
                          <div className="flex items-baseline gap-x-1  text-green-600">
                            <span className="text-xl line-through decoration-red-400 font-semibold">
                              {tier.originalprice}
                            </span>
                            <span className="text-xs font-semibold leading-6">
                              /year
                            </span>
                          </div>
                          <div className="flex items-baseline gap-x-1 text-gray-900">
                            <span className="text-4xl font-bold">
                              {tier.priceMonthly}
                            </span>
                            <span className="text-sm font-semibold leading-6">
                              /year
                            </span>
                          </div>
                          <div className="mt-2 text-xs font-medium text-green-600">
                            <span className="text-xl font-bold text-green-600">{tier.discount}</span> Off On Our Regular Price
                          </div>

                          {LoggedIn ? (
                            <a
                              href={tier.href}
                              onChange={(e) => Section(e)}
                              onClick={() => {
                                setCurrentTier(tier);
                                setSelectTier(tier);
                              }}
                              className={classNames(
                                tier.mostPopular
                                  ? `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`
                                  : `text-white bg-gray-900 cursor-pointer ring-1 ring-inset   hover:ring-${props.contextValue}-300`,
                                `mt-4 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${props.contextValue}-600`
                              )}
                            >
                              Buy plan
                            </a>
                          ) : (
                            <a
                              onClick={props.onClick}
                              className={classNames(
                                tier.mostPopular
                                  ? `bg-${props.contextValue}-600 cursor-pointer text-white hover:bg-${props.contextValue}-500`
                                  : `text-white bg-gray-900 cursor-pointer ring-1 ring-inset   hover:ring-${props.contextValue}-300`,
                                `mt-4 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${props.contextValue}-600`
                              )}
                            >
                              Buy plan
                            </a>
                          )}
                        </td>
                      ))}{" "}
                    </>
                  )}
                  {/* --------------buttons */}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    {/* ----------------------heading of types---------------- */}
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        className={classNames(
                          sectionIdx === 0 ? "pt-2" : "pt-2",
                          "pb-4 text-sm font-bold leading-6 text-black"
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-blue-900/10" />
                      </th>
                    </tr>

                    {section.features.map((feature) => (
                      //   -------------------------------------------  box types------------------------------------
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          className="py-3 text-sm font-normal leading-6 text-black"
                        >
                          {feature.name}
                          <div className="absolute inset-x-8 mt-2 h-px bg-gray-800/5" />
                        </th>

                        {/* -------------------------------------------  box checks  ------------------------------------------- */}

                        {tiers.map((tier) => (
                          <td key={tier.id} className="py-3 px-6 xl:px-8">
                            {typeof feature.tiers[tier.name] === "string" ? (
                              <div className="text-center text-sm leading-6 text-gray-900">
                                {feature.tiers[tier.name]}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <div className="bg-green-600 mx-auto max-w-fit rounded-full ">
                                    <CheckIcon
                                      className="mx-auto p-1 h-5 w-5 text-white"
                                      aria-hidden="true"
                                    />
                                  </div>
                                ) : (
                                  <div className="bg-red-600 mx-auto max-w-fit rounded-full">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-5 text-white h-5 p-1"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </div>
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.name] === true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
