import { MapIcon } from "@heroicons/react/20/solid";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import PostCard from "./PostCard";

const Posts = (props) => {
  return (
    <div className=" shadow ">
      <div className="flex items-center border-b justify-between gap-2 p-1">
        <div className="flex gap-2">
          <img src="./assets/gmbLogo.png" alt="gmb-logo" className="h-7 w-7" />
          <div className="font-semibold">
            Posts{" "}
            <span className="text-sm text-gray-400 font-normal">
              Google My Bussiness
            </span>
          </div>
        </div>
        <BsThreeDotsVertical
          className="cursor-pointer"
          onClick={() => props.setIsAccount(true)}
        />
      </div>
      <div className=" ">
        <div className=" h-[520px] overflow-scroll">
          <a
            href={"/gmb-create-post/" + props.pageId + "/" + props.locationId}
            className="flex justify-end p-2 text-gray-500 cursor-pointer pb-1"
          >
            <FiEdit className={(props.locationId&&props.pageId)?"h-5 w-5 hover:text-gray-700":"h-5 w-5 opacity-0 hover:text-gray-700"} />
          </a>
          {props.data &&
            props.data.map((item, index) => {
              return <PostCard data={item} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default Posts;
