import { MapIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useState } from "react";
import { BiMap, BiPencil, BiShare, BiStar } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReplyCommentBox from "./ReplyCommentBox";

const Reviews = (props) => {
  const [isReply, setIsReply] = useState(false);
  var start = [0, 1, 2, 3, 4];

  const rating = (rcount) => {
    if (rcount === "FIVE") {
      return (
        <div className="mx-1 flex items-center">
          {" "}
          5.0
          {start.map((rating) => (
            <BiStar
              key={rating}
              className={"text-yellow-400  h-4 w-4 flex-shrink-0"}
              aria-hidden="true"
            />
          ))}
        </div>
      );
    }
    if (rcount === "FOUR") {
      return (
        <div className="mx-1 flex items-center">
          {" "}
          4.0
          {start.slice(0, 4).map((rating) => (
            <BiStar
              key={rating}
              className={"text-yellow-400  h-4 w-4 flex-shrink-0"}
              aria-hidden="true"
            />
          ))}
        </div>
      );
    }
    if (rcount === "THREE") {
      return (
        <div className="mx-1 flex items-center">
          {" "}
          3.0
          {start.slice(0, 3).map((rating) => (
            <BiStar
              key={rating}
              className={"text-yellow-400  h-4 w-4 flex-shrink-0"}
              aria-hidden="true"
            />
          ))}
        </div>
      );
    }
    if (rcount === "TWO") {
      return (
        <div className="mx-1 flex items-center">
          {" "}
          2.0
          {start.slice(0, 2).map((rating) => (
            <BiStar
              key={rating}
              className={"text-yellow-400  h-4 w-4 flex-shrink-0"}
              aria-hidden="true"
            />
          ))}
        </div>
      );
    }
    if (rcount === "ONE") {
      return (
        <div className="mx-1 flex items-center">
          {" "}
          1.0
          {start.slice(0, 1).map((rating) => (
            <BiStar
              key={rating}
              className={"text-yellow-400  h-4 w-4 flex-shrink-0"}
              aria-hidden="true"
            />
          ))}
        </div>
      );
    }
  };
  return (
    <div className=" shadow ">
      <div className='flex items-center border-b justify-between gap-2 p-1'>
           <div className='flex gap-2'>
           <img src='./assets/gmbLogo.png' alt='gmb-logo' className='h-7 w-7'/>
            <div className='font-semibold'>Reviews <span className='text-sm text-gray-400 font-normal'>Google My Bussiness</span></div>
            </div>
            <BsThreeDotsVertical className='cursor-pointer' onClick={()=>props.setIsAccount(true)} />
        </div>
      <div className=" ">
        <div className=" h-[520px]">
          {/* <div className="border-b p-2">
            <div className="flex  items-center ">
              <BiMap className="h-4 w-4 " />{" "}
              <span className="font-semibold ml-2 text-base">
                None at this Time
              </span>
            </div>
            <div className="text-sm ml-6 text-gray-400 -mt-0.5">
              1 East 8 eve
            </div>
            <div className="text-sm ml-6 flex text-gray-400 -mt-0.5">
              {" "}
              5.0{" "}
              <div className="mx-1 flex items-center">
                {" "}
                {start.map((rating) => (
                  <BiStar
                    key={rating}
                    className={"text-yellow-400  h-4 w-4 flex-shrink-0"}
                    aria-hidden="true"
                  />
                ))}
              </div>{" "}
              (17)
            </div>

            <div></div>
          </div> */}
          {props.data &&
            props.data.map((item, index) => {
              return (
                <>
                  <div className="border-b p-2">
                    <div className="flex  ">
                      <img
                        src={item.reviewer.profilePhotoUrl}
                        className="h-8 w-8 "
                      />{" "}
                      <span className="font-semibold ml-2 text-base">
                        {item.reviewer.displayName}
                      </span>
                    </div>
                    {/* <div className="text-sm ml-9 text-gray-400 -mt-2">1 East 8 eve</div> */}
                    <div className="text-sm ml-9 flex text-gray-400 ">
                      {" "}
                      {rating(item.starRating)}{" "}
                    </div>
                    <div className="text-sm mx-9 text-gray-400 ">
                      {item?.comment}
                    </div>
                    <div
                      onClick={() => setIsReply(!isReply)}
                      className="flex text-blue-400 cursor-pointer w-fit gap-1 px-2 py-1 rounded ml-9 mt-1 items-center border"
                    >
                      {" "}
                      <BiShare />
                      <div>Reply</div>
                    </div>
                    {isReply && (
                      <div className="mx-9 mt-2">
                        <ReplyCommentBox
                          comment={props.comment}
                          setComment={props.setComment}
                        />
                        <div className="flex justify-end mt-2">
                         { props.commentLoading?
                         <div
                            onClick={() => props.onReplySubmit(item.reviewId)}
                            className="flex    bg-blue-500 text-white cursor-pointer w-fit gap-1 px-2 py-1 rounded  mt-1 items-center border"
                          >
                            {" "}
                            <svg
                              aria-hidden="true"
                              className="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <div>Submit</div>
                          </div>:
                          <div
                            onClick={() => props.onReplySubmit(item.reviewId)}
                            className={props.comment?"flex  bg-blue-500 text-white cursor-pointer w-fit gap-1 px-2 py-1 rounded  mt-1 items-center border":"flex opacity-50 pointer-events-none bg-blue-500 text-white cursor-pointer w-fit gap-1 px-2 py-1 rounded  mt-1 items-center border"}
                          >
                            {" "}
                           
                            <div>Submit</div>
                          </div>}
                        </div>
                      </div>
                    )}
                    <div></div>
                  </div>{" "}
                  <div className="text-sm ml-11 text-gray-400 ">
                    {item.reviewReply?.comment}
                  </div>
                </>
              );
            })}
          {/* <div className=" p-2">
            <div className="flex  ">
              <img src="./assets/download.png" className="h-8 w-8 " />{" "}
              <span className="font-semibold ml-2 text-base">Ryan</span>
            </div>
            <div className="text-sm ml-9 text-gray-400 -mt-2">1 East 8 eve</div>
            <div className="text-sm ml-9 flex text-gray-400 ">
              {" "}
              5.0{" "}
              <div className="mx-1 flex items-center">
                {" "}
                {start.map((rating) => (
                  <BiStar
                    key={rating}
                    className={"text-yellow-400  h-4 w-4 flex-shrink-0"}
                    aria-hidden="true"
                  />
                ))}
              </div>{" "}
              (17)
            </div>
            <div className="text-sm ml-9 text-gray-400 ">
              Lorem ipsum sit amet Lorem ipsum sit amet
            </div>

            <div>

            </div>
          </div> */}
          {/* <div className=" p-2">
            <div className="flex  ">
              <img src="./assets/download.png" className="h-8 w-8 " />{" "}
              <span className="font-semibold ml-2 gap-1 flex text-base">
                (Owner) <BiPencil className="mt-1 h-4 w-4 text-gray-400 " />{" "}
                <RiDeleteBin6Line className=" h-4 w-4 text-gray-400 mt-1" />
              </span>
            </div>
            <div className="text-sm ml-9 text-gray-400 -mt-2">1 East 8 eve</div>

            <div className="text-sm ml-9 text-gray-400 ">
              Lorem ipsum sit amet Lorem ipsum sit amet
            </div>

            <div></div>
          </div>
          <div className=" p-2">
            <div className="flex  ">
              <img src="./assets/download.png" className="h-8 w-8 " />{" "}
              <span className="font-semibold ml-2 text-base">Ryan</span>
            </div>
            <div className="text-sm ml-9 text-gray-400 -mt-2">1 East 8 eve</div>
            <div className="text-sm ml-9 flex text-gray-400 ">
              {" "}
              5.0{" "}
              <div className="mx-1 flex items-center">
                {" "}
                {start.map((rating) => (
                  <BiStar
                    key={rating}
                    className={"text-yellow-400  h-4 w-4 flex-shrink-0"}
                    aria-hidden="true"
                  />
                ))}
              </div>{" "}
              (17)
            </div>
            <div className="text-sm ml-9 text-gray-400 ">
              Lorem ipsum sit amet Lorem ipsum sit amet
            </div>

            <div></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
