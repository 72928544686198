import { Fragment, useEffect, useRef, useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { TailSpin } from "react-loader-spinner";
import { Menu, Transition } from "@headlessui/react";
import { BsThreeDots, BsInstagram, BsYoutube } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineSchedule } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import classes from '../../Screens/custom.module.css'
import Modal from "../Modal";
import Carousel from "../CreatePost/Carousel";
import FBCard from "../CreatePost/FBCard";
import InstagramCard from "../CreatePost/InstagramCard";
import LinkedinCard from "../CreatePost/LinkedinCard";
import TwitterCard from "../CreatePost/TwitterCard";
import LoadingComp from "./LoadingComp";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Calender = (props) => {
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const [scheduleData, setScheduleData] = useState([]);
  const [scheduleFullData, setScheduleFullData] = useState([]);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState("Pages");
  const [socialAccount, setSocialAccount] = useState("All");
  const weekDuration = 7 * 60 * 60 * 24 * 1000;
const navigate=useNavigate()
  const FindDateIndex = (date) => {
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var d = new Date(date);
    var dayName = days[d.getDay()];
    switch (dayName) {
      case "Sun":
        return "relative mt-px flex sm:col-start-1";
      case "Mon":
        return "relative mt-px flex sm:col-start-2";
      case "Tue":
        return "relative mt-px flex sm:col-start-3";
      case "Wed":
        return "relative mt-px flex sm:col-start-4";
      case "Thu":
        return "relative mt-px flex sm:col-start-5";
      case "Fri":
        return "relative mt-px flex sm:col-start-6";
      case "Sat":
        return "relative mt-px flex sm:col-start-7";
      default:
        console.log("Invalid case");
    }
  };

  const IconTextSelected = (value) => {
    if (value === "instagram") {
      return (
        <>
          <BsInstagram size={15} color={"#fff"} />
        </>
      );
    }
    if (value === "facebook") {
      return (
        <>
          <FaFacebookF size={15} color={"white"} />
        </>
      );
    }
    if (value === "linkedin" || value === "linkedinorg") {
      return (
        <>
          <FaLinkedinIn size={15} color={"#fff"} />
        </>
      );
    }
    if (value === "twitter") {
      return (
        <>
          <FaTwitter size={15} color={"#fff"} />
        </>
      );
    }
    if (value === "youtube") {
      return (
        <>
          <FaYoutube size={15} color={"#fff"} />
        </>
      );
    }
  };
  const time24to12Converter = (t) => {
    let time = t?.split(':');
    let temp = ' AM';
    if (parseInt(time?.length && time[0]) > 11) {
      time[0] = (time[0] - 12 < 1) ? 12 : time[0] -= 12
      temp = " PM";
    }
    else {
      time[0] = time[0] < 1 ? 12 : time[0]
    }
    return time?.join(":") + temp;
  };

  const TextColor = (value) => {
    if (value === "instagram") {
      return classes.schedulInsta;
    }
    if (value === "facebook") {
      return "bg-fb rounded-full flex items-center p-1";
    }
    if (value === "linkedin" || value === "linkedinorg") {
      return "bg-linkedIn rounded-full flex items-center p-1";
    }
    if (value === "twitter") {
      return "bg-twitter rounded-full flex items-center p-1";
    }
    if (value === "youtube") {
      return "bg-red-500 rounded-full flex items-center p-1";
    }
  };

  const FindTimeIndex = (curTime) => {
    var timeIndex = parseInt(curTime.slice(0, 2));
    var timeZone = curTime.slice(-2);
    // if (timeZone === "AM") {
    switch (timeIndex) {
      case 0:
        return 2;
      case 1:
        return 14;
      case 2:
        return 26;
      case 3:
        return 38;
      case 4:
        return 50;
      case 5:
        return 62;
      case 6:
        return 74;
      case 7:
        return 86;
      case 8:
        return 98;
      case 9:
        return 110;
      case 10:
        return 122;
      case 11:
        return 134;
      case 12:
        return 146;
      case 13:
        return 158;
      case 14:
        return 170;
      case 15:
        return 182;
      case 16:
        return 194;
      case 17:
        return 206;
      case 18:
        return 218;
      case 19:
        return 230;
      case 20:
        return 242;
      case 21:
        return 254;
      case 22:
        return 266;
      case 23:
        return 278;
      default:
        console.log("Invalid case");
    }
  };
  const weekData = (type) => {
    const name = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const now = Date.now();
    const DAY = 60 * 60 * 24 * 1000;
    const today = new Date(props.startDate).getDay();
    var arr = [];

    for (let i = today; i >= 0; i--) {
      const date = new Date(props.startDate - DAY * i);
      arr.push({
        day: name[date.getDay()],
        date: date.getDate(),
      });
      // arr1.push(date.getDate());
    }
    for (let i = 1; i < 7 - today; i++) {
      const date = new Date(props.startDate + DAY * i);
      arr.push({
        day: name[date.getDay()],
        date: date.getDate(),
      });
    }
    if (type === "Mobile") {
      return (
        <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 hidden">
          {arr.map((item) => {
            return (
              <button
                type="button"
                className="flex flex-col items-center pt-2 pb-3"
              >
                {item.day.slice(0, 1)}{" "}
                <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">
                  {item.date}
                </span>
              </button>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="-mr-px  grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 grid">
          <div className="col-end-1  w-14" ><div className="sm:hidden block flex items-center justify-center h-12 font-bold text-base text-orange " >{currentMonth()}</div></div>
          {arr.map((item) => {
            return (
              <div className="flex items-center justify-center py-3">
                <span>
                  {item.day}{" "}
                  <span className="items-center justify-center font-semibold text-gray-900">
                    {" "}
                    {item.date}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
      );
    }
  };
  const currentMonth = () => {
    const date1 = new Date(props.startDate);
    const month = date1.toLocaleString('default', { month: 'short' });
    return month
  }
  const dateWithTime = (date) => {

    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dateObj = new Date(date);
    const day = dateObj?.getDate();
    const monthNameLong = dateObj?.toLocaleString("en-US", { month: "short" });
    const dayNameLong = days[dateObj.getDay()];
    const year = dateObj?.getFullYear();
    return (
      dayNameLong + " , " + (day <= 9 ? "0" : "") + day + " " + monthNameLong
    );
  };

  const socialAccounts = () => {
    if (socialAccount === "All") {
      return "All Posts"
    }
    if (socialAccount === "facebook") {
      return "Facebook Posts"
    }
    if (socialAccount === "instagram") {
      return "Instagram Posts"
    }
    if (socialAccount === "linkedin") {
      return "LinkedIn Posts"
    }
    if (socialAccount === "twitter") {
      return "Twitter Posts"
    }
    if (socialAccount === "youtube") {
      return "Youtube Posts"
    }
  }
  const pageName = (pagename, toPost) => {
    if (toPost?.includes('twitter')) {
      return pagename.twitter
    }
    if (toPost?.includes('instagram')) {
      return pagename.instagram
    }
    if (toPost === 'linkedin') {
      return pagename.linkedin
    }
    if (toPost?.includes('linkedinorg')) {
      return pagename.linkedinorg
    }
    if (toPost?.includes('facebook')) {
      return pagename.facebook
    }
  }
  const filteredData = (data) => {

    var filterData = []
    if (socialAccount === "facebook") {
      filterData = data?.filter((item) => item.ToPost?.includes(socialAccount))?.filter((post) => page === "Pages" ? post.Posts?.findIndex((item) => item.PageName == page) === -1 : post.Posts?.findIndex((item) => item.PageName == page) !== -1)?.filter((val) => val.PostStatus === props.status)
    }
    else if (socialAccount === "instagram") {
      filterData = data?.filter((item) => item.ToPost?.includes(socialAccount))?.filter((post) => page === "Pages" ? post.Posts?.findIndex((item) => item.PageName == page) === -1 : post.Posts?.findIndex((item) => item.PageName == page) !== -1)?.filter((val) => val.PostStatus === props.status)
    }
    else if (socialAccount === "twitter") {
      filterData = data?.filter((item) => item.ToPost?.includes(socialAccount))?.filter((post) => page === "Pages" ? post.Posts?.findIndex((item) => item.PageName == page) === -1 : post.Posts?.findIndex((item) => item.PageName == page) !== -1)?.filter((val) => val.PostStatus === props.status)
    }
    else if (socialAccount === "linkedin") {
      filterData = data?.filter((item) => item.ToPost?.includes(socialAccount))?.filter((post) => page === "Pages" ? post.Posts?.findIndex((item) => item.PageName == page) === -1 : post.Posts?.findIndex((item) => item.PageName == page) !== -1)?.filter((val) => val.PostStatus === props.status)
    }
    else if (socialAccount === "youtube") {
      filterData = data?.filter((item) => item.ToPost?.includes(socialAccount))?.filter((post) => page === "Pages" ? post.Posts?.findIndex((item) => item.PageName == page) === -1 : post.Posts?.findIndex((item) => item.PageName == page) !== -1)?.filter((val) => val.PostStatus === props.status)
    }
    else if (socialAccount === "All" && page !== "Pages") {
      filterData = data?.filter((post) => post.Posts?.findIndex((item) => item.PageName == page) !== -1)?.filter((val) => val.PostStatus === props.status)
    }
    else if (socialAccount === "All" && page === "Pages") {
      filterData = data?.filter((val) => val.PostStatus === props.status)
    }
    return filterData
  }
  const Description = (post, toPost) => {
    if (toPost?.includes('twitter')) {
      return post.TwitterDescription || post.Description
    }
    if (toPost?.includes('instagram')) {
      return post.InstaDescription || post.Description
    }
    if (toPost?.includes('facebook')) {
      return post.FBDescription || post.Description
    }
    if (toPost?.includes('linkedin')) {
      return post.LinkedinDescription || post.Description
    }
    if (toPost?.includes('youtube')) {
      return post.LinkedinDescription || post.Description
    }
  }
  return (
    <div className="flex h-full flex-col">
      <header className=" flex-none items-center hidden sm:flex  justify-between border-b border-gray-200 py-4 ">
        <div className="flex">
          <div className="  md:flex md:items-center  ">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                <div className="hidden sm:block ">{socialAccounts()}</div>
                <FaFilter className="block sm:hidden" />
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1 ml-5">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => { setSocialAccount("All"); setPage("Pages") }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          All Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => { setSocialAccount("facebook"); setPage("Pages") }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Facebook Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => { setSocialAccount("instagram"); setPage("Pages") }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Instagram Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => { setSocialAccount("linkedin"); setPage("Pages") }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          LinkedIn Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => { setSocialAccount("twitter"); setPage("Pages") }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Twitter Posts
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className=" md:ml-4 md:flex md:items-center ml-4">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                {page}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {(socialAccount === "All" ? props.pages : props.pages?.filter((val) => val.SocialMediaName?.includes(socialAccount)))?.map((item, index) => {
                      return (
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() => setPage(item.PageName)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm cursor-pointer"
                              )}
                            >
                              {item.PageName}
                            </div>
                          )}
                        </Menu.Item>
                      )
                    })}

                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className=" md:ml-4 md:flex md:items-center ml-4">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                {props.status === "Live" ? "Live Posts" : "Scheduled Posts"}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => props.setStatus("Scheduled")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Scheduled Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => props.setStatus("Live")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Live Posts
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex items-center ">
          <div className="flex items-center rounded-md shadow-sm md:items-stretch">
            <button
              onClick={() => {
                props.setStartDate(props.startDate - weekDuration);
              }}
              type="button"
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-2 pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous month</span>
              <MdOutlineKeyboardArrowLeft
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>

            <button
              type="button"
              className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              {currentMonth()}
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              onClick={() => props.setStartDate(props.startDate + weekDuration)}
              type="button"
              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-2 pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <MdOutlineKeyboardArrowRight
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </header>
      <header className="flex  flex-col  sm:hidden   border-b border-gray-200 py-4 ">
        <div className="flex">
          <div className="  md:flex   ">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                <div className="">{socialAccounts()}</div>
                {/* <FaFilter className="block sm:hidden" /> */}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1 ml-5">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("All")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          All Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("facebook")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Facebook Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("instagram")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Instagram Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("linkedin")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          LinkedIn Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("twitter")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Twitter Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("youtube")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Youtube Posts
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="   ml-4">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                {page}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {(socialAccount === "All" ? props.pages : props.pages?.filter((val) => val.SocialMediaName?.includes(socialAccount)))?.map((item, index) => {
                      return (
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() => setPage(item.PageName)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm cursor-pointer"
                              )}
                            >
                              {item.PageName}
                            </div>
                          )}
                        </Menu.Item>
                      )
                    })}

                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

        </div>
        <div className="flex mt-2 items-center ">
          <div className=" ">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                {props.status === "Live" ? "Live Posts" : "Schedule Posts"}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => props.setStatus("Scheduled")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Scheduled Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => props.setStatus("Live")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Live Posts
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="flex items-center rounded-md ml-4 shadow-sm md:items-stretch">
            <button
              onClick={() => {
                props.setStartDate(props.startDate - weekDuration);
              }}
              type="button"
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-2 pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous month</span>
              <MdOutlineKeyboardArrowLeft
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>

            <button
              type="button"
              className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              {currentMonth()}
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              onClick={() => props.setStartDate(props.startDate + weekDuration)}
              type="button"
              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-2 pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <MdOutlineKeyboardArrowRight
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </header>
      {props.Loading ? (
        <LoadingComp />
      ) : <div
        ref={container}
        className="isolate flex flex-auto flex-col overflow-auto  bg-white"
      >
        <div
          style={{ width: "270%" }}
          className="flex with-full flex-none flex-col sm:max-w-none md:max-w-full"
        >
          <div
            ref={containerNav}
            className="sticky top-0 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
          >
            {weekData("Mobile")}
            {weekData()}
          </div>
          <div className="flex flex-auto">
            <div className="sticky left-0 w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{ gridTemplateRows: "repeat(48, minmax(3.5rem, 1fr))" }}
              >
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    1AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    2AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    3AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    4AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    5AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    9AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    11AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    1PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    2PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    3PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    4PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    5PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    9PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0  -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    11PM
                  </div>
                </div>
                <div />
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1  grid-cols-7 grid-rows-1 divide-x divide-gray-100 grid grid-cols-7">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full sm:w-8" />
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-7 sm:pr-8"
                style={{
                  gridTemplateRows: "1.75rem repeat(288, minmax(0, 1fr)) auto",
                }}
              >
                {props.newPostedData?.map((item, key) => {
                  return (
                    <>
                      <li
                        className={FindDateIndex(item.date)}
                        style={{
                          gridRow: `${FindTimeIndex(item.title)}/span 12`,
                        }}
                      >
                        {filteredData(item.content)?.length >= 1 ? (
                          <div

                            className={props.status === "Live" ? "group absolute inset-1 flex  pt-1  flex-col overflow-y-auto rounded-lg bg-green-50  text-xs leading-5 " : "group absolute inset-1 flex  pt-1  flex-col overflow-y-auto rounded-lg bg-blue-50  text-xs leading-5 "}
                          >
                            {filteredData(item.content)
                              // ?.filter((val) => val.PostStatus === status)
                              ?.slice(0, 1)
                              ?.map((post, idx) => {
                                return (
                                  <div
                                    id={idx}
                                    className="  mt-1 p-1 rounded cursor-pointer px-1 mx-1 "
                                    onClick={() => {
                                      setScheduleData(post);
                                      props.setModalShow(true);
                                    }}
                                  ><div className="flex justify-between">
                                      <div className="flex truncate">
                                        {" "}
                                        <div
                                          className={TextColor(
                                            post.Posts[0]?.SocialMediaName
                                          )}
                                        >
                                          {IconTextSelected(
                                            post.Posts[0]?.SocialMediaName
                                          )}
                                        </div>
                                        <div className="font-semibold truncate  text-gray-700 ml-1 ">
                                          {post.Posts[0]?.PageName}
                                          {/* {post.PageName?.split(",")[0]} */}
                                        </div>
                                      </div>
                                      <div className={`text-${props.contextValue}-500 hover:text-${props.contextValue}-800 font-bold w-8 text-right`}>{post.Posts?.length > 1 ? " +" + (post.Posts?.length - 1) : ""}</div>
                                    </div>
                                    <p className=" font-semibold truncate text-gray-700 ">
                                      {Description(post, post.Posts[0]?.SocialMediaName)}
                                      {/* {post.Description ||
                                        post.FBDescription ||
                                        post.InstaDescription ||
                                        post.LinkedinDescription ||
                                        post.TwitterDescription} */}
                                    </p>
                                    <p className="text-gray-700 flex truncate  group-hover:text-blue-700 ">
                                      <div className="block sm:hidden mr-1">
                                        {dateWithTime(post.ScheduleDate)}
                                      </div>
                                      {post.ScheduleTime && <div>
                                        {time24to12Converter(post.ScheduleTime)}
                                      </div>}
                                    </p>

                                  </div>

                                );
                              })}
                            {filteredData(item.content)?.length > 1 && (
                              <div
                                className="text-right mr-1  cursor-pointer text-slate-600"
                                onClick={() => {
                                  setScheduleFullData(filteredData(item.content));
                                  // setCardScheduleData(item.c)
                                  setModal(true);
                                }}
                              >
                                <div className=" flex justify-end">  <div className="border px-1 bg-green-100 rounded" >{"View All"} </div> </div>
                                {/* <div className=" flex justify-end"> + <div className="border px-1 bg-green-200 rounded-full" >{ filteredData(item.content)?.length-1} </div> </div> */}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </li>
                    </>
                  );
                })}
                {modal && (
                  <Modal
                    modalCase={"Post Details"}
                    Text={scheduleFullData}
                    setShowModal={setModal}
                    iscancel={false}
                    status={props.status}
                    setScheduleData={setScheduleData}
                    setModalShow={props.setModalShow}
                    onIconClick={() => setModal("")}
                    onOk={() => setModal("")}
                  />
                )}
                <Modal
                  setOpen={props.setModalShow}
                  open={props.modalShow}

                  data={scheduleData}
                  onClose={() => {
                    props.setModalShow(false);
                  }}
                  modalCase={"rightSideMOdal"}
                >
                  <div className=" ">
                    <div className="p-4 flex items-center border border-gray-200 justify-between">
                      <div className="flex items-center">
                        <AiOutlineSchedule
                          size={40}
                          className="border mr-2 rounded-full p-2"
                        />{" "}
                        <div><div>{props.status} Post</div>
                          <div className="text-gray-700  flex  text-sm text-slate-500 group-hover:text-blue-700 min-w-fit">
                            <div className="block  mr-1">
                              {dateWithTime(scheduleData.ScheduleDate)}
                            </div>
                            {scheduleData.ScheduleTime && <div>{time24to12Converter(scheduleData.ScheduleTime)}</div>}
                          </div></div>
                      </div>
                      <IoCloseSharp
                        size={40}
                        onClick={() => {
                          props.setModalShow(false);
                        }}
                        className="border cursor-pointer rounded-full p-2"
                      />
                    </div>
                    <div className="px-4 py-3 flex items-center  align-center justify-between ">
                      {/* <button onClick={()=>navigate('/create-post/'+scheduleData.SocialId)} className="px-5 rounded-full font-medium bg-slate-200 hover:bg-slate-300 mr-4 py-1 border">
                        Edit
                      </button> */}

                      <div className="flex gap-4">
                        {scheduleData.ToPost?.includes("linkedin") && <div className="bg-linkedIn rounded-full flex w-4 h-4  my-1  justify-center mr-1   items-center p-1">
                          {" "}
                          <FaLinkedinIn size={8} color={"#fff"} />
                        </div>}
                        {scheduleData.ToPost?.includes("twitter") && <div className="bg-twitter rounded-full flex w-4 h-4  my-1  justify-center mr-1   items-center p-1">
                          {" "}
                          <FaTwitter size={8} color={"#fff"} />
                        </div>}
                        {scheduleData.ToPost?.includes("instagram") && <div className={classes.instagramSchedule}>
                          {" "}
                          <BsInstagram size={8} color={"#fff"} />
                        </div>}
                        {scheduleData.ToPost?.includes("facebook") && <div className="bg-fb rounded-full flex w-4 h-4 my-1  justify-center mr-1  items-center p-1 ">
                          {" "}
                          <FaFacebookF size={8} color={"white"} />
                        </div>}
                        {scheduleData.ToPost?.includes("youtube") && <div className="bg-youtube rounded-full flex w-5 h-4 my-1  justify-center mr-1  items-center p-1 ">
                      {" "}
                      <BsYoutube size={8} color={"white"} />
                    </div>}
                      </div>
                      {scheduleData.PostStatus === "Scheduled" ? (
                        <div>
                        <button onClick={() => navigate('/update-schedule-post/' + scheduleData.SocialId)} className="border-none font-medium py-1 border-transparent pr-2">
                          <PencilSquareIcon className="h-7 w-7 border-transparent" />
                        </button>
                        <button
                          onClick={() => {
                            props.setPostId(scheduleData.SocialId);
                            props.setModal("remove");
                          }}
                          className="rounded-full font-medium  py-1"
                        >
                          <TrashIcon className="h-7 w-7 text-red-500"/>
                        </button>
  
                      </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="mt-2">
                      <Carousel infinite timer={2000} stopOnManual>
                        {scheduleData.Posts?.filter((item)=>item.SocialMediaName != "gmb")?.map((item) => {
                          if (item.SocialMediaName === "facebook") {
                            return (
                              <FBCard
                                text={"Facebook preview"}
                                cardCase={"schedulePost"}
                                selectedFiles={scheduleData.ImageUrl}
                                pageId={scheduleData.PageId.facebook}
                                description={scheduleData.Description}
                                FbDescription={scheduleData.FBDescription}
                                userName={item.PageName}
                                ProfileImage={item.ProfileImage}
                              />)
                          }

                          if (item.SocialMediaName === "instagram") {
                            return (<InstagramCard
                              text={"Instagram preview"}
                              cardCase={"schedulePost"}
                              selectedFiles={scheduleData.ImageUrl}
                              description={scheduleData.Description}
                              FbDescription={scheduleData.InstaDescription}
                              userName={item.PageName}
                              ProfileImage={item.ProfileImage}
                            />)
                          }

                          if (item.SocialMediaName.includes("linkedin")) {
                            return (
                              <LinkedinCard
                                text={"Linkedin preview"}
                                cardCase={"schedulePost"}
                                selectedFiles={scheduleData.ImageUrl}
                                description={scheduleData.Description}
                                FbDescription={scheduleData.LinkedinDescription}
                                userName={item.PageName}
                                ProfileImage={item.ProfileImage}
                              />)
                          }
                          if (item.SocialMediaName === "twitter") {
                            return (
                              <TwitterCard
                                text={"Twitter preview"}
                                cardCase={"schedulePost"}
                                selectedFiles={scheduleData.ImageUrl}
                                description={scheduleData.Description}
                                FbDescription={scheduleData.TwitterDescription}
                                userName={item.PageName}
                                ProfileImage={item.ProfileImage}
                              />)
                          }
                          if (item.SocialMediaName === "youtube") {
                            return (
                              <TwitterCard
                                text={"Youtube preview"}
                                cardCase={"schedulePost"}
                                selectedFiles={scheduleData.ImageUrl}
                                description={scheduleData.Description}
                                FbDescription={scheduleData.TwitterDescription}
                                userName={item.PageName}
                                ProfileImage={item.ProfileImage}
                              />)
                          }


                        })}
                      </Carousel>
                    </div>
                  </div>
                </Modal>
              </ol>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default Calender;
