import React, { useEffect, useState } from "react";
import ProgressBar from "../../../Components/UserRegistrationComponent/ProgressBar";
import classes from "./FinalStep.module.css";
import { useNavigate, useParams } from "react-router-dom";
import OPInput from "../../../Components/OPInput";
import ActionButton from "../../../Components/ActionButton";
import Modal from "../../../Components/Modal";

const FinalStep = (props) => {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("Myself only");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [modal, setModal] = useState("");
  const [error, setError] = useState({});
  const [checkBackLink, setCheckBackLink] = useState(null);
  const loginToken = localStorage.getItem("UserToken");
  useEffect(() => {
    GetUserDetails();
  }, []);
 const companySizeData = [
    {
        id:1,
        name : "Myself only"
    },
    {
        id:2,
        name : "2-10 employees"
    },
    {
        id:3,
        name : "11-50 employees"
    },
    {
        id:4,
        name : "51-200 employees"
    },
    {
        id:5,
        name : "201-500 employees"
    },
    {
        id:6,
        name : "501-1,000 employees"
    },
    {
        id:7,
        name : "1,001-5,000 employees"
    },
    {
        id:8,
        name : "5,001-10,000 employees"
    },
    {
        id:8,
        name : "10,000+ employees"
    }

]
const {trialId}=useParams()
  const postData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify( checkBackLink?{
      UserToken: loginToken,
      TrialFormId: trialId,
      CompanyName: companyName,
      NumberOfEmployee: employeeNumber,
      ProductName: "onePost",
    }:{
      UserToken: loginToken,
      TrialFormId: trialId,
      CompanyName: companyName,
      NumberOfEmployee: employeeNumber,
      Password: password,
      ProductName: "onePost",
    });
 
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/Ai/AddCompanyDetials",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status === "Success" &&
          result.status_code === 200
        ) {
          localStorage.setItem("LoggedIn", true);
          navigate("/")
        }
        else{
          setModal("apiFailed")
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const GetUserDetails=()=>{
    var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "UserToken": loginToken
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://promote.onecorp.co.in/api/v1/Ai/GetUserInfo", requestOptions)
  .then(response => response.json())
  .then(result =>{
    if (result.status_code === 200 && result.status === "Success") {
      setCompanyName(result.data.CompanyName)
      setCheckBackLink(result.data.NumberOfEmployee !== null)

     ( result.data.NumberOfEmployee&&setEmployeeNumber(result.data.NumberOfEmployee))
    }
  })
  .catch(error => console.log('error', error));
  }
  const formValidation = () => {
    const regex=/[a-zA-Z]/;
    let errors = {};
    let isValid = true;
    if (!companyName || companyName === ""|| !companyName.match(regex)) {
      errors.companyName = "Please enter Company Name";
      isValid = false;
    }
    if (!employeeNumber || employeeNumber === "") {
      errors.employeeNumber = "Please enter Employee Number";
      isValid = false;
    }

if(!checkBackLink){
  if ( !password || password === "") {
    errors.password = "Please enter password";
    isValid = false;
  }
  if (  password?.length<6 && password?.length>0) {
    errors.password = "Password must be of 6 characters atleast";
    isValid = false;
}
}
    setError(errors);

    return isValid;
  };

  return (
    <div className={classes.mainContainer}>
          {modal === "apiFailed" && (
        <Modal
        modalCase={"confirmation"}
        Text={"There is some issue , please contact support!"}
        setShowModal={setModal}
        iscancel={false}
        onClose={() => setModal("")}
        onOk={() => setModal("")}
      />
      )}
      <div className={classes.logoContainer}>
        <img
          src={"/assets/One_post.svg"}
          width={props.device === "Mobile" ? "50%" : "15%"}
        />
      </div>
      <div className={classes.inputSectionContainer}>
        <div className={classes.headImage}>
          <img
            src={"/assets/UserRegistrationImages/Finalstep.svg"}
            className="m-auto"
            width={"35px"}
          />
          <div className={classes.headingTextFirst}>
            Final step for your free trial
          </div>
          <div className={classes.headingTextThird}>Step 3 of 3</div>
          <div className={classes.progressBar}>
            <ProgressBar screen={"lastScreen"} />{" "}
          </div>
          <div className={classes.inputSectionFlex}>
            <div className={classes.inputContainer}>
              <OPInput
                input={"SingleLineInputSecType"}
                type={"text"}
                label={"Company Name"}
                placeholder={"Company Name"}
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                onInput={() => {
                  error.companyName = "";
                }}
              />
              <div className={classes.errors}> {error.companyName}</div>
            </div>
            <div className={classes.inputContainer}>
              {/* <OPInput
                input={"SingleLineInputSecType"}
                type={"text"}
                label={"Number of Employee"}
                placeholder={"Number of Employee"}
                onChange={(e) => {
                  setEmployeeNumber(e.target.value);
                }}
                value={employeeNumber}
                onInput={() => {
                  error.employeeNumber = "";
                }}
              /> */}
                     <OPInput
                input={"DropDown"}
                type={"text"}
                label={"Number of Employee"}
                placeholder={"Number of Employee"}
                onChange={(e) => {
                  setEmployeeNumber(e.target.value);
                }}
                optionData={companySizeData}
                value={employeeNumber}
                onInput={() => {
                  error.employeeNumber = "";
                }}
              />
              <div className={classes.errors}> {error.employeeNumber}</div>
            </div>
          </div>
         {!checkBackLink&& <div className={classes.inputContainerSection}>
            <OPInput
              input={"passwordLoginType"}
              type={"text"}
              label={"Create Password"}
              placeholder={"Create Password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onInput={() => {
                error.password = "";
              }}
            />
            <div className={classes.errors}> {error.password}</div>
          </div>}
          <div className={classes.btnNext}>
            <ActionButton
              text={"Next"}
              btnType={"circular"}
              loading={loading}
              onClick={() => {
                formValidation()
                  ? postData()
                  : console.log("something went wrong");
              }}
            />
          </div>

          <div className={classes.sasOneText}>SAS ONE</div>
        </div>
      </div>
    </div>
  );
};

export default FinalStep;
