import React from 'react'
import { BiMap } from 'react-icons/bi'

const PostCard = (props) => {
  const PostDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj?.getDate();
    const month = dateObj?.getMonth();
    const year = dateObj?.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + (month + 1) + "-" + year;
  };
  return (
    <div className="flex flex-col  rounded-md m-2 shadow ">
    <div className="flex-shrink-0">
    {props.data.media&& <>  <div className={"h-48 top-0 w-full my -2 relative"}>
   {props.data.media[0].mediaFormat==="PHOTO"?<img
                src={props.data.media[0].googleUrl}
                fill
                alt="article image"
            className='m-auto'
              />
            :  <video
            // width="250" height="250"
            controls
            // autoPlay
          >
            <source
              src={props.data.media[0].googleUrl}
              className='m-auto'
            />
          </video>}
      </div>
      
      </>}
      {/* <div className='absolute'>edit</div> */}
    </div>

      <div >
    
  
        <div className=' p-2'>

<div className=' ml-1 text-sm'>{props.data.summary}</div>
<div className='text-gray-400 ml-1 text-sm'>{PostDate(props.data.createTime)}</div>
</div>
        </div>
      </div>
 
  )
}

export default PostCard