import React from "react";
import classes from "./InstagramCard.module.css";
import { HiDotsHorizontal, HiUserCircle } from "react-icons/hi";
import { IoChatbubbleOutline } from "react-icons/io5";
import { FiSend } from "react-icons/fi";
import { MdFavoriteBorder } from "react-icons/md";
import { GrFavorite } from "react-icons/gr";
const InstagramCard = (props) => {
  const cardType = (type) => {
    switch (type) {
      case "schedulePost":
        return (
          <>
            <div className={classes.heading}>{props.text}</div>
            <div className={classes.postContainer}>
              <div className={classes.postHeader}>
                <div className={classes.postHeaderUsername}>
                  {props.ProfileImage ? (
                    <img
                      src={props.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div className={classes.userTitle}>{props.userName}</div>
                </div>
                <HiDotsHorizontal />
              </div>
              <div className={classes.postImg}>
                {props.selectedFiles ?
                 (
                  props.selectedFiles?.split(",")[0]?.includes("video")|| props.selectedFiles?.split(",")[0]?.includes("mp4") || props.selectedFiles?.split(",")[0]?.includes("gif")|| props.selectedFiles?.split(",")[0]?.includes("MP4")   ? (
                    <video
                      // width="250" height="250"
                      width="100%"
                      height="100%"
                      controls
                      // autoPlay
                      className={classes.postImginner}
                    >
                      <source
                        src={props.selectedFiles.split(",")[0]}
                        type={props.selectedFiles.type}
                        className={classes.postImginner}
                      />
                    </video>
                  ) : (
                    <img
                      src={props.selectedFiles.split(",")[0]}
                      width="100%"
                      height="100%"
                      className={classes.postImginner}
                    />
                  )
                ) : (
                  <img
                    src={"/assets/onepost-logo-gray.png"}
                    width="100%"
                    height="100%"
                    className={classes.postImginner}
                  />
                )}
              </div>
              <div className={classes.line}></div>
              <div className="flex">
                <GrFavorite className={classes.favIcon} />
                <IoChatbubbleOutline className={classes.favIcon} />
                <FiSend className={classes.favIcon} />
              </div>
              <div className={classes.userName}>
                {props.userName}{" "}
                <span className={" whitespace-normal font-normal text-sm "}>
                  {props.FbDescription
                    ? props.FbDescription
                    : props.description}
                </span>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className={classes.heading}>{props.text}</div>
            <div className={classes.postContainer}>
              <div className={classes.postHeader}>
                <div className={classes.postHeaderUsername}>
                  {props.ProfileImage ? (
                    <img
                      src={props.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  {/*   {props.credentials.map((item) => {
                    return <div>{item.SocialMediaName==='instagram'&&item.SocailUserName}</div>;
                  })} */}
                  <div className={classes.userTitle}>{props.userName}</div>
                </div>
                <HiDotsHorizontal />
              </div>
              <div className={classes.postImg}>
                {props.selectedFiles.length ? (
                  props.selectedFiles[0].type.includes("video") ? (
                    <video
                      // width="250" height="250"
                      width="100%"
                      height="100%"
                      controls
                      // autoPlay
                      className={classes.postImginner}
                    >
                      <source
                        src={URL.createObjectURL(props.selectedFiles[0])}
                        type={props.selectedFiles.type}
                        className={classes.postImginner}
                      />
                    </video>
                  ) : (
                    <img
                      src={URL.createObjectURL(props.selectedFiles[0])}
                      width="100%"
                      height="100%"
                      className={classes.postImginner}
                    />
                  )
                ) : (
                  <img
                    src={"../assets/onepost-logo-gray.png"}
                    width="100%"
                    height="100%"
                    className={classes.postImginner}
                  />
                )}
              </div>

              <div className={classes.line}></div>
              <div className="flex">
                <GrFavorite className={classes.favIcon} />
                <IoChatbubbleOutline className={classes.favIcon} />
                <FiSend className={classes.favIcon} />
              </div>
              {/*  <div className={classes.likesContainer}>
                <MdFavoriteBorder className={classes.favIconSmall} /> 0 likes
              </div> */}

              <div className={classes.userName}>
                {props.userName}{" "}
                <span className={" whitespace-normal font-normal text-sm "}>
                  {props.instaDescription
                    ? props.instaDescription
                    : props.description}
                </span>
              </div>
            </div>
          </>
        );
    }
  };
  return cardType(props.cardCase);
};

export default InstagramCard;
