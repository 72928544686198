
import { Fragment, useState, useEffect } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { BsInstagram } from "react-icons/bs";
import { AiFillSchedule } from "react-icons/ai";
import { IoCreateSharp } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";

import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import Logout from "../Logout";
import BreadCrumb from "./BreadCrumb";
import { BiSupport } from "react-icons/bi";
import { MdOutlineContactPhone } from "react-icons/md";
import Cookies from "js-cookie";
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
import CreativeDelieveryModal from "../CreativeDelieveryModal";
import TailwindModal from "./TailwindModal";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardComp = (props) => {
  const [modal, setModal] = useState(false);
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [loginToken, setLoginToken] = useState("")
  const [profile, setProfile] = useState("")
  const [email, setEmail] = useState("")
  const trialexpire = localStorage.getItem('trialexpire')
  const contextValue = Cookies.get("UserType")
  const CompanyLogo = Cookies.get("CompanyLogo")
  const CompanyName = Cookies.get("CompanyName")
  const [creativeModal ,setCreativeModal] = useState(false)
  const [creativeRequestDescription,setCreativeRequestDescription] = useState("")
  const [showSuccess,setShowSuccess]= useState("")
  const [succesMessage, setSuccesMessage] = useState("");
  const DomainName = window.location.hostname;
  useEffect(() => {
    setLoginToken(Cookies.get("UserToken"))
    setProfile(Cookies.get("ProfilePicture"))
    setEmail(Cookies.get("Email"))
    setLastName(Cookies.get("LastName"))
    setFirstName(Cookies.get("FirstName"))
  }, [Cookies.get("FirstName")])
  const navigation = [
    // { name: "Social Feed", href: "/social-feed", current: true },
    { name: "Analytics", href: "/analytics-new", current: false,id:"id3" },
    { name: "Google My Bussiness", href: "/google-my-bussiness", current: false,id:"id4" },
    // { name: "Twitter Testing", href: "/twitterposting", current: false },

    // { name: "Help & Support", href: `https://www.sasone.in/contactus?loginToken=${loginToken}`, current: false },
  ];
  const SubmitCreativeDelieveryRequest = () => {
   
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "CreativeDescription" : creativeRequestDescription,
      "UserEmail" :Cookies.get("Email"), 
      "UserID" : Cookies.get("UserId")
   });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.sasone.in/apiadmin/v2/onepost/SaveCreativeRequest", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "Success") {
          setShowSuccess("save")
          setCreativeModal(false)
         
          setSuccesMessage("Congratulation,your request have been save successfully!!")
        }
        else if (result.status_code === 300 && result.status === "fail") {
           alert("Fail")
        }
      })
      .catch(error => console.log('error', error));
  }

  return (
    <>
      <div className="min-h-full">
      {showSuccess==="save"&& <TailwindModal
          modalCase={"confirmation"}
          Text={succesMessage}
          setModal={setShowSuccess}
          isCancel={false}
          modal={showSuccess}
        />}
        {creativeModal && <CreativeDelieveryModal open={creativeModal}
        setOpen={setCreativeModal}
        onClose={() => {
          setCreativeModal(false);
        }}

        value={creativeRequestDescription}
        onChange={(e) => setCreativeRequestDescription(e.target.value)}
        onClick={SubmitCreativeDelieveryRequest}
        />}
        {modal === "Logout" && (
          <Logout modal={modal} setModal={setModal} />
        )}
        <Popover as="header" className={`bg-${contextValue}-500 pb-24`}>
          {({ open }) => (
            <>
              <div className="mx-auto max-w-3xl px-4 pt-5 sm:pt-0 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="relative flex items-center justify-center py-5 lg:justify-between">
                  <div className="absolute left-0 flex-shrink-0 lg:static">
                    <a href="/">
                      <span className="sr-only">Your Company</span>
                      {contextValue === "indigo" ? <img
                        className="h-6 sm:h-10 w-auto"
                        src={"/assets/Logo/One_post_white.svg"}
                        alt="Your Company"
                      /> : <div className="flex items-center">
                        <img
                          className="h-6 sm:h-10 w-auto"
                          src={CompanyLogo}
                          alt={CompanyName}
                        />
                        {/* <span className="ml-2">{CompanyName}</span> */}
                      </div>}
                    </a>

                  </div>

                  <div className="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
                    <button
                      type="button"
                      className={`flex-shrink-0 rounded-full p-1 text-${contextValue}-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white`}
                    >
                      <span className="sr-only">View notifications</span>
                      {/* <BellIcon className="h-6 w-6" aria-hidden="true" /> */}
                    </button>

                    <Menu as="div" className="relative ml-4 flex-shrink-0" id={props.id6}>
                      <div>
                        <Menu.Button className="flex rounded-full bg-white text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                          <span className="sr-only">Open user menu</span>
                          {(!profile || profile === "undefined") ? <span className="bg-gray-500 h-9 w-9 rounded-full font-medium leading-none text-white flex items-center justify-center">{firstName?.charAt(0)?.toUpperCase() +
                            " " +
                            lastName?.charAt(0)?.toUpperCase()}</span> : <div>
                            <img
                              className="inline-block h-9 w-9 rounded-full"
                              src={profile}
                              alt=""
                            />
                          </div>}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute -right-2 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {(DomainName === "localhost" || DomainName === "onepost.sasone.in") &&
                            <>
                              <Menu.Item>
                                <a target={"_blank"}
                                  href={`https://www.sasone.in/dashboard/profile`}
                                  className={classNames(
                                    "block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  )}
                                >
                                  Profile
                                </a>
                              </Menu.Item>
                              <Menu.Item>
                                <a target={"_blank"}
                                  href={"https://www.sasone.in/dashboard/subscriptions"}
                                  className={classNames(
                                    "block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  )}
                                >
                                  Subscriptions
                                </a>
                              </Menu.Item>
                              <Menu.Item>
                                <a target={"_blank"}
                                  href={`https://www.sasone.in/dashboard/transaction`}
                                  className={classNames(
                                    "block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 "
                                  )}
                                >
                                  Transactions
                                </a>
                              </Menu.Item>
                              <Menu.Item>
                                <a target={"_blank"}
                                  href={`https://www.sasone.in/contactus`}
                                  className={classNames(
                                    "block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100 "
                                  )}
                                >
                                  Contact
                                </a>
                              </Menu.Item></>}
                          <Menu.Item>
                            <div
                              onClick={() => setModal("Logout")}
                              className={classNames(
                                "block px-4 cursor-pointer py-2 text-sm text-gray-700 hover:bg-gray-100"
                              )}
                            >
                              Logout
                            </div>
                          </Menu.Item>


                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  {/* <div className="min-w-0 flex-1 px-12 lg:hidden">
                    <div className="mx-auto w-full max-w-xs">
                      <label htmlFor="desktop-search" className="sr-only">
                        Search
                      </label>
                      <div className="relative text-white focus-within:text-gray-600">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <MagnifyingGlassIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="desktop-search"
                          className="block w-full rounded-md border border-transparent bg-white bg-opacity-20 py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-white focus:border-transparent focus:bg-opacity-100 focus:placeholder-gray-500 focus:outline-none focus:ring-0 sm:text-sm"
                          placeholder="Search"
                          type="search"
                          name="search"
                        />
                      </div>
                    </div>
                  </div> */}

                  <div className="absolute right-0 flex-shrink-0 lg:hidden">
                    <Popover.Button className={`inline-flex items-center justify-center rounded-md bg-transparent p-2 text-${contextValue}-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white`}>
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Popover.Button>
                  </div>

                </div>
                <div className="hidden border-t border-white border-opacity-20 py-5 lg:block">
                  <div className={trialexpire === "yes" ? "flex items-center justify-between pointer-events-none opacity-50 " : "flex items-center justify-between"}>
                    <div className="flex">
                      <a

                        href={"/"}
                        className={classNames(
                          // item.current ? "text-white" : "text-indigo-100",
                          "text-sm font-medium text-white rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
                        )}
                      // aria-current={item.current ? "page" : undefined}
                      >
                        {"Dashboard"}
                      </a>
                      <Popover.Group as="nav" className="flex space-x-10" id={props.id1}>
                        <Popover className="relative">
                          {({ open }) => (
                            <>
                              <Popover.Button
                                className={classNames(
                                  open ? `text-white` : `text-white`,
                                  "text-sm font-medium flex rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10 "
                                )}
                              >
                                <span>Create Post</span>
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? "text-white" : "text-white",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              </Popover.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0" >
                                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8" >
                                      <a
                                        href={"/create-post-new"}
                                        rel="canonical"
                                        className="-m-3 block rounded-md p-3 hover:bg-gray-50"
                                      >
                                        <p className={`text-base font-medium text-${contextValue}-500 flex items-center`}>

                                          <IoCreateSharp size={20} className=" mr-2 " />  Post Now
                                        </p>
                                      </a>
                                      <a
                                        href={"/create-post-new"}
                                        rel="canonical"
                                        className="-m-3 block rounded-md p-3 hover:bg-gray-50"
                                      >
                                        <p className={`text-base text-${contextValue}-500 font-medium flex items-center `}>

                                          <AiFillSchedule size={20} className=" mr-2 " />  Schedule a Post
                                        </p>
                                      </a>


                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      </Popover.Group>
                      <Popover.Group as="nav" className="flex space-x-10" id={props.id2}>
                        <Popover className="relative">
                          {({ open }) => (
                            <>
                              <Popover.Button
                                className={classNames(
                                  open ? "text-white" : "text-white",
                                  "text-sm font-medium flex rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10 "
                                )}
                              >
                                <span>My Posts</span>
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? "text-white" : "text-white",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              </Popover.Button>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                      <a
                                        href={"/live-post-new"}
                                        rel="canonical"
                                        className="-m-3 block rounded-md p-3 hover:bg-gray-50"
                                      >
                                        <p className={`text-base text-${contextValue}-500 font-medium flex items-center`}>

                                          <IoIosCheckmarkCircle size={20} className=" mr-2 " />  Live posts
                                        </p>
                                      </a>
                                      <a
                                        href={"/schedule-post-new"}
                                        rel="canonical"
                                        className="-m-3 block rounded-md p-3 hover:bg-gray-50"
                                      >
                                        <p className={`text-base text-${contextValue}-500 font-medium flex items-center`}>

                                          <AiFillSchedule size={20} className=" mr-2 " />  Scheduled Post
                                        </p>
                                      </a>


                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      </Popover.Group>
                      <div className="col-span-2">
                        <nav className="flex space-x-4" id={props.id3}>
                         
                            <a
                              key={"Analytics"}
                              href={"/analytics-new"}
                              target={"Analytics" === "Help & Support" ? "_blank" : "_self"}
                              className={classNames(
                                false ? "text-white" : "text-white",
                                "text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
                              )}
                              aria-current={false ? "page" : undefined}
                              
                            >
                              {"Analytics"}
                            </a>
                         
                        </nav>
                      </div>
                      <div className="col-span-2">
                        <nav className="flex space-x-4" id={props.id4}>
                         
                            <a
                              key={"Google My Bussiness"}
                              href={"/google-my-bussiness"}
                              target={"Google My Bussiness" === "Help & Support" ? "_blank" : "_self"}
                              className={classNames(
                                false ? "text-white" : "text-white",
                                "text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
                              )}
                              aria-current={false ? "page" : undefined}
                              
                            >
                              {"Google My Business"}
                            </a>
                         
                        </nav>
                      </div>
                      {/* <div className="col-span-2">
                        <nav className="flex space-x-4">
                          
                            <div
                              
                              onClick={()=>setCreativeModal(true)}
                              className={classNames(
                               
                                "text-sm font-medium rounded-md  text-white cursor-pointer bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
                              )}
                           
                            >
                             Creative Request
                            </div>
                         
                        </nav>
                      </div> */}
                      {/* <div className="col-span-2">
                        <nav className="flex space-x-4">
                          
                            <a
                              
                              href="/creativerequestlist"
                              className={classNames(
                               
                                "text-sm font-medium rounded-md  text-white cursor-pointer bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
                              )}
                           
                            >
                             Creative Requests List 
                            </a>
                         
                        </nav>
                      </div> */}
                      {/* <div className="col-span-2">
                        <nav className="flex space-x-4">
                          
                            <a
                              
                              href="/creativedelivereylist"
                              className={classNames(
                               
                                "text-sm font-medium rounded-md  text-white cursor-pointer bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10"
                              )}
                           
                            >
                             Creative Delivery List 
                            </a>
                         
                        </nav>
                      </div> */}
                      <Popover.Group as="nav" className="flex space-x-10" id={props.id5}>
                        <Popover className="relative">
                          {({ open }) => (
                            <>
                              <Popover.Button
                                className={classNames(
                                  open ? "text-white" : "text-white",
                                  "text-sm font-medium flex rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10 "
                                )}
                              >
                                <span>Contact Us</span>
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? "text-white" : "text-white",
                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                  )}
                                  aria-hidden="true"
                                />
                              </Popover.Button>
                               
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                    <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                      {(DomainName === "localhost" || DomainName === "onepost.sasone.in") && <a
                                        // href={`https://www.sasone.in/contactus?loginToken=${loginToken}?product=One Post`}
                                        href={`https://www.sasone.in/contactus`}
                                        rel="canonical"
                                        target={"_blank"}
                                        className="-m-3 block rounded-md p-3 hover:bg-gray-50"
                                      >
                                        <p className={`text-base text-${contextValue}-500 font-medium flex items-center`}>

                                          <BiSupport size={20} className=" mr-2 " />   Help & Support
                                        </p>
                                      </a>}
                                      <a
                                        href={`/contact-support`}
                                        rel="canonical"
                                        className="-m-3 block rounded-md p-3 hover:bg-gray-50"
                                      >
                                        <p className={`text-base text-${contextValue}-500 font-medium flex items-center`}>

                                          <MdOutlineContactPhone size={20} className=" mr-2 mt-0.5 " />  Support Ticket
                                        </p>
                                      </a>


                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                        
                      </Popover.Group>
                    </div>
                    
                    <div>
                      <div className="hidden md:flex md:flex-1 md:items-center md:justify-between"></div>
                      {/* <div className="mx-auto w-full max-w-md">
                        <label htmlFor="mobile-search" className="sr-only">
                          Search
                        </label>
                        <div className="relative text-white focus-within:text-gray-600">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            id="mobile-search"
                            className="block w-full rounded-md border border-transparent bg-white bg-opacity-20 py-2 pl-10 pr-3 leading-5 text-gray-900 placeholder-white focus:border-transparent focus:bg-opacity-100 focus:placeholder-gray-500 focus:outline-none focus:ring-0 sm:text-sm"
                            placeholder="Search"
                            type="search"
                            name="search"
                          />
                        </div>
                      </div> */}

                    </div>
                  </div>
                  <div className="mx-2 pt-3"> <BreadCrumb pages={props.BreadCrumb} /></div>
                </div>
              </div>
              <div className="mx-6 pb-3 pt-3 lg:hidden"> <BreadCrumb pages={props.BreadCrumb} /></div>
              <Transition.Root as={Fragment}>
                <div className="lg:hidden">
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Overlay className="fixed inset-0 z-20 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      className="absolute inset-x-0 top-0 z-30  mx-auto w-full max-w-3xl origin-top transform p-2 transition"
                    >
                      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="pt-3 pb-2">
                          <div className={"flex items-center justify-between px-4"}>
                            <div>
                              {contextValue === "indigo" ? <img
                                className="h-8 w-auto"
                                src={ "/assets/One_Post_Logo.svg"}
                                alt="Your Company"
                              />: <img
                              className="h-6 sm:h-10 w-auto"
                              src={CompanyLogo}
                              alt={CompanyName}
                            />}
                            </div>
                            <div className="-mr-2">
                              <Popover.Button className={`inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-${contextValue}-500`}>
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </Popover.Button>
                            </div>
                          </div>
                          <div className={trialexpire === "yes" ? "mt-3 space-y-1 px-2 pointer-events-none opacity-40 " : "mt-3 space-y-1 px-2"}>
                            <a
                              href="/"
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Dashboard
                            </a>
                            <Menu as="div" className="relative  text-left">
                              <div>
                                <Menu.Button className="inline-flex w-full  text-base  text-gray-900 hover:bg-gray-100 hover:text-gray-800 px-3 py-2 rounded-md  font-medium">
                                  Create Post
                                  <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className=" right-0 mt-2  z-10  origin-top-right rounded-md bg-white shadow-lg text-Black bg-White ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href={
                                            "/create-post-new"
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-Red-100"
                                              : "text-Red-200",
                                            "block px-4 py-2 text-sm"
                                          )}
                                        >
                                          Post Now
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href={
                                            "/create-post-new"
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-Red-100"
                                              : "text-Red-200",
                                            "block px-4 py-2 text-sm"
                                          )}
                                        >
                                          Schedule a Post
                                        </a>
                                      )}
                                    </Menu.Item>


                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                            <Menu as="div" className="relative  text-left">
                              <div>
                                <Menu.Button className="inline-flex w-full  text-base  text-gray-900 hover:bg-gray-100 hover:text-gray-800 px-3 py-2 rounded-md  font-medium">
                                  My Posts
                                  <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className=" right-0 mt-2  z-10  origin-top-right rounded-md bg-white shadow-lg text-Black bg-White ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href={
                                            "/live-post-new"
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-Red-100"
                                              : "text-Red-200",
                                            "block px-4 py-2 text-sm"
                                          )}
                                        >
                                          Live Post
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href={
                                            "/schedule-post-new"
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-Red-100"
                                              : "text-Red-200",
                                            "block px-4 py-2 text-sm"
                                          )}
                                        >
                                          Scheduled Post
                                        </a>
                                      )}
                                    </Menu.Item>


                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                            <a
                              href="/analytics-new"
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                              
                            >
                              Analytics
                            </a>
                            <a
                              href="/google-my-bussiness"
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                              
                            >
                              Google My Business
                            </a>
                            {/* <a
                              href="/twitterposting"
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                             Twitter Testing
                            </a> */}
                            <Menu as="div" className="relative  text-left">
                              <div>
                                <Menu.Button className="inline-flex w-full  text-base  text-gray-900 hover:bg-gray-100 hover:text-gray-800 px-3 py-2 rounded-md  font-medium">
                                  Contact Us
                                  <ChevronDownIcon
                                    className="-mr-1 ml-2 h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className=" right-0 mt-2  z-10  origin-top-right rounded-md bg-white shadow-lg text-Black bg-White ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    {(DomainName === "localhost" || DomainName === "onepost.sasone.in") && <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href={
                                            `https://www.sasone.in/contactus?product=One Post`
                                          } target="_blank"
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-Red-100"
                                              : "text-Red-200",
                                            "block px-4 py-2 text-sm"
                                          )}
                                        >
                                          Help & Support
                                        </a>
                                      )}
                                    </Menu.Item>}
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href={
                                            "/contact-support"
                                          }
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-Red-100"
                                              : "text-Red-200",
                                            "block px-4 py-2 text-sm"
                                          )}
                                        >
                                          Support Ticket
                                        </a>
                                      )}
                                    </Menu.Item>


                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          {(DomainName === "localhost"||DomainName === "onepost.sasone.in")&&  <a
                              href={`https://www.sasone.in/contactus?loginToken=${loginToken}?product=One Post`}
                              target={"_blank"}
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Help & Support
                            </a>}
                          </div>
                        </div>

                        <div className="pt-4 pb-2"  >
                          <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                              {(!profile || profile === "undefined") ? <span className="bg-gray-500 h-9 w-9 rounded-full font-medium leading-none text-white flex items-center justify-center">{firstName?.charAt(0)?.toUpperCase() +
                                " " +
                                lastName?.charAt(0)?.toUpperCase()}</span> :
                                <div>
                                  <img
                                    className="inline-block h-9 w-9 rounded-full"
                                    src={profile}
                                    alt=""
                                  />
                                </div>
                              }
                            </div>
                            <div className="ml-3 min-w-0 flex-1">
                              <div className="truncate text-base font-medium text-gray-800">
                                {firstName + " " + lastName}
                              </div>
                              <div className="truncate text-sm font-medium text-gray-500">
                                {email}
                              </div>
                            </div>
                            <button
                              type="button"
                              className={`ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2`}
                            >
                              <span className="sr-only">
                                View notifications
                              </span>
                              {/* <BellIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              /> */}
                            </button>
                          </div>
                          <div className="mt-3 space-y-1 px-2">
                            {(DomainName === "localhost"||DomainName === "onepost.sasone.in")&&<><a target={"_blank"}
                              href={`https://www.sasone.in/dashboard/profile?loginToken=${loginToken}`}
                              className={classNames(
                                "block px-4 py-2 cursor-pointer text-sm text-gray-700"
                              )}
                            >
                              Profile
                            </a>
                            <a target={"_blank"}
                              href={`https://www.sasone.in/dashboard/profile?loginToken=${loginToken}`}
                              className={classNames(
                                "block px-4 py-2 cursor-pointer text-sm text-gray-700"
                              )}
                            >
                              Manage Account
                            </a>
                            <a target={"_blank"}
                              href={`https://www.sasone.in/dashboard/home?loginToken=${loginToken}`}
                              className={classNames(
                                "block px-4 py-2 cursor-pointer text-sm text-gray-700"
                              )}
                            >
                              Products you may like
                            </a></>}

                            <div
                              onClick={() => setModal("Logout")}
                              className={classNames(
                                "block px-4 py-2 cursor-pointer text-sm text-gray-700"
                              )}
                             
                            >
                              Logout
                            </div>
                          </div>
                        </div>
                      </div>

                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
            </>
          )}
        </Popover>

        <main className="-mt-28 pt-5 pb-8">

          {props.children}
        </main>
        <footer>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 pb-10 sm:pb-0">
            <div className="border-t border-gray-200 py-8 text-center text-sm text-gray-500 sm:text-left">
              <span className="block sm:inline">
                V.1.2 &copy; {new Date().getFullYear()}{" "}SAS ONE PRIVATE LIMITED.
              </span>{" "}
              <span className="block sm:inline">All rights reserved.</span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
export default DashboardComp;
