import { type } from "@testing-library/user-event/dist/type";
import Cookies from "js-cookie";
import { HiUserCircle } from "react-icons/hi";





const DetailedAnalyticsCard = (props) => {
  const contextValue = Cookies.get("UserType")
  const stats = [
    { name: 'Total Posts Count', stat: '71' },
    { name: 'Total Reach', stat: '580' },
    { name: 'Total impression', stat: '24' },
    { name: 'Total pages', stat: '2100' },
  ]

  if (props.type == "facebook" || props.type == "instagram") {
    return (
      <div className=" mb-4">
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Page</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_pages}</dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Posts</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_post_count}</dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Reach</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_reach}</dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Impressions</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_impressions}</dd>
          </div>

        </dl>



        {props.Pagedata.length>0&&props.Pagedata?.map((item) => {

          return (
            <div className="mt-5">

              <div className='flex items-center'>{item.page_image ? <img className='rounded-full border h-12 w-12 mr-2' src={item.page_image} /> : <HiUserCircle className='rounded-full h-12 w-12 mr-2' />} <div className=" py-5 text-gray-800 text-lg font-semibold">{item.user_name}</div></div>
              <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4">
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Post Count</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.post_count}</dd>
                </div>
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Reach Count</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.reach}</dd>
                </div>
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Impression Count</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.impression}</dd>
                </div>


              </dl>
            </div>

          )

        })}
      </div>

    )
  } else if (props.type == "linkedin") {
    return (
      <div className=" mb-4">
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Page</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_pages}</dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Posts</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_post_count}</dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Clicks</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_clicks}</dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Views</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_views}</dd>
          </div>

        </dl>
        {props.Pagedata.length>0&&props.Pagedata?.map((item) => {

          return (
            <div className="mt-5">

              <div className='flex items-center'>{item.page_image ? <img className='rounded-full border h-12 w-12 mr-2' src={item.page_image} /> : <HiUserCircle className='rounded-full h-12 w-12 mr-2' />} <div className=" py-5 text-gray-800 text-lg font-semibold">{item.user_name}</div></div>
              <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4">
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Post Count</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.post_count}</dd>
                </div>
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Clicks Count</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.clicks}</dd>
                </div>
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Views Count</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.views}</dd>
                </div>


              </dl>
            </div>

          )

        })}
      </div>
    )
  } else if (props.type == "twitter") {
    return (
      <div className=" mb-4">
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Page</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_pages}</dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Impressions</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_impressions}</dd>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">Total Reach</dt>
            <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{props.data.total_reach}</dd>
          </div>

        </dl>
        {props.Pagedata.length>0? props.Pagedata?.map((item) => {

          return (
            <div className="mt-5">

              <div className='flex items-center'>{item.page_image ? <img className='rounded-full border h-12 w-12 mr-2' src={item.page_image} /> : <HiUserCircle className='rounded-full h-12 w-12 mr-2' />} <div className=" py-5 text-gray-800 text-lg font-semibold">{item.user_name}</div></div>
              <dl className="grid grid-cols-1 gap-5 sm:grid-cols-4">
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Post Count</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.post_count}</dd>
                </div>
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Clicks Count</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.clicks}</dd>
                </div>
                <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                  <dt className="truncate text-sm font-medium text-gray-500">Views Count</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">{item.views}</dd>
                </div>


              </dl>
            </div>

          )

        }) : <div className="text-base font-semibold mt-5"></div>}
      </div>
    )
  } else if (props.type == "youtube") {
    return (<div className="text-base font-semibold my-5">No Data Found !!!</div>)
  }
}

export default DetailedAnalyticsCard;