import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SelectCredentials from "../../Components/SelectCredentials";
import Input from "../../Components/TailwindCompnents/Input";
import DummySocialCard from "../../Components/CreatePost/DummySocialCard";
import Carousel from "../../Components/CreatePost/Carousel";
import FBCard from "../../Components/CreatePost/FBCard";
import LinkedinCard from "../../Components/CreatePost/LinkedinCard";
import InstagramCard from "../../Components/CreatePost/InstagramCard";
import TwitterCard from "../../Components/CreatePost/TwitterCard";
import Loader from "../../Components/Loader";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import Badges from "../../Components/TailwindCompnents/Badges";
import Button from "../../Components/TailwindCompnents/Button";
import Tabs from "../../Components/TailwindCompnents/Tabs";
import TailwindModal from "../../Components/TailwindCompnents/TailwindModal";
import LoadingComp from "../../Components/TailwindCompnents/LoadingComp";
import GMBPageList from "../../Components/TailwindCompnents/GMBPageList";
import YoutubeModal from "../../Components/TailwindCompnents/YoutubeModal";
import YoutubeCard from "../../Components/CreatePost/YoutubeCard";
import Cookies from "js-cookie";
import copy from "copy-to-clipboard";
import axios from "axios";
import { MinusSmallIcon } from "@heroicons/react/20/solid";
import { PhoneXMarkIcon, XCircleIcon } from "@heroicons/react/24/outline";
import ConfirmationMessage from "../../Components/TailwindCompnents/ConfirmationMessage";
import YoutubeEditModal from "../../Components/TailwindCompnents/YoutubeEditModal";

const EditSchedulePost = () => {
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [fbContent, setFbContent] = useState("");
  const [instaContent, setInstaContent] = useState("");
  const [linkedInContent, setLinkedInContent] = useState("");
  const [twitterContent, setTwitterContent] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [contentType, setContentType] = useState("common");
  const [checked, setChecked] = useState([]);
  const [pagesId, setPagesId] = useState([]);
  const [open, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const [error, setError] = useState({});
  const [isFb, setIsFb] = useState(false);
  const [isLkdContent, setIsLkdContent] = useState(false);
  const [isTwitter, setIsTwitter] = useState(false);
  const [isYoutube, setIsYoutube] = useState(false);
  const [youtubeContent, setYoutubeContent] = useState("");
  const [isInstaContent, setIsInstaContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [isReel, setIsReel] = useState("no");
  const [createPostData, setCreatePostData] = useState([]);
  const [modal, setModal] = useState("");
  const [credentials, setCredentials] = useState([]);
  const [succesMessage, setSuccesMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesGet, setSelectedFilesGet] = useState([]);

  const [dragActive, setDragActive] = useState(false);
  const [overContent, setOverContent] = useState(false);
  const [showGMB, setShowGMB] = useState(false);
  const [showYoutube, setShowYoutube] = useState(false);
  const [title, setTitle] = useState("");
  const [visibility, setVisibility] = useState("Public");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [ytSocial, setYtSocial] = useState("");
  const [ytPagesId, setYTPagesId] = useState("");
  const [postId, setPostId] = useState("");
  const [minimumDate, setMinimumDate] = useState("");
  const [shortenLink, setShortenLink] = useState("")
  const [inputLink, setInputLink] = useState("")

  const [copied, setCopied] = useState(false);
  const [shortenLoading, setShortenLoading] = useState(false)
  const [longUrl, setLongUrl] = useState();
  const [shortUrl, setShortUrl] = useState();
const [toPost,setToPost]=useState("")
  const [videoInfo, setVideoInfo] = useState([]);
const [ytErrorShow,setYtErrorShow]=useState(false)
  const loginToken = Cookies.get("UserToken");
  const contextValue = Cookies.get("UserType")

  const { id } = useParams();
  

  useEffect(() => {
    GetCreadentials();
    GetSchedulePost();
    if(checked?.includes("youtube")){
      setYtErrorShow(true)
    }
    console.log(checked.includes("youtube"));
  }, [checked.includes("youtube")]);
  const reelData = [
    { id: "Yes", title: "Yes" },
    { id: "No", title: "No" },
  ];
  const navigate = useNavigate();

  const GetSchedulePost = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: loginToken,
      SocialId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.sasone.in/apiadmin/v1/onepost/GetPost", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&
          result.message === "Post Found Successfully"
        ) {
          setDescription(result.data.Description);
          setFbContent(result.data.FBDescription);
          setInstaContent(result.data.InstaDescription);
          setLinkedInContent(result.data.LinkedinDescription);
          setTwitterContent(result.data.TwitterDescription);
          setPagesId(result.data.FacebookPageId.split(","));
          setChecked(result.data.ToPost.split(","));
          setSelectedFilesGet(result.data.ImageUrl.split(","));
          setPostId(result.data.SocialId);
          setIsReel(result.data.IsReel)
          
          // setSelectedId([

          //     {
          //       id: pageId,
          //       value: value,
          //     },
          //   ])
        }
      })
      .catch((error) => console.log("error", error));
  };

  const BitLy = () => {
    setShortenLoading(true)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer a3672beec3406d6a181455e6cac2e300863de74e"
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Host", "api-ssl.bitly.com");

    var raw = JSON.stringify({
      long_url: longUrl,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api-ssl.bitly.com/v4/shorten", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setShortUrl(result.link);
      })
      .catch((error) => console.log("error", error))
      .finally(()=>{setShortenLoading(false)});
  };

  const instaSelected =
    selectedId.findIndex((item) => item.value == "instagram") !== -1 ||
    checked.includes("instagram");
  const twitterSelected =
    selectedId.findIndex((item) => item.value == "twitter") !== -1;
  const selectedVideo =
    selectedFiles?.findIndex((item) => item.type?.includes("video")) !== -1;
    
  const fbstaSelected =
    selectedId.findIndex((item) => item.value == "facebook") !== -1;
  const ytSelected =
    selectedId.findIndex((item) => item.value == "youtube") !== -1||
    checked.includes("youtube");
  const linkedinstaSelected =
    selectedId.findIndex((item) => item.value == "linkedin") !== -1 ||
    selectedId.findIndex((item) => item.value == "linkedinorg") !== -1;
 
  const LogOut = () => {
    Cookies.remove("LoggedIn", { domain: ".sasone.in" });
    Cookies.remove("UserToken", { domain: ".sasone.in" });
    Cookies.remove("FirstName", { domain: ".sasone.in" });
    Cookies.remove("LastName", { domain: ".sasone.in" });
    Cookies.remove("Mobile", { domain: ".sasone.in" });
    Cookies.remove("userName", { domain: ".sasone.in" });
    Cookies.remove("Email", { domain: ".sasone.in" });
    Cookies.remove("UserId", { domain: ".sasone.in" });
    Cookies.remove("LoginType", { domain: ".sasone.in" });
    Cookies.remove("ProfilePicture", { domain: ".sasone.in" });
    Cookies.remove("UserType", { domain: ".sasone.in" });
    Cookies.remove("SignupUser", { domain: ".sasone.in" });

    navigate("/login");
  };
  useEffect(() => {
    selectedTwitter();
  }, [pagesId, overContent]);
  // useEffect(() => {
  //   if (ytSelected) {
  //     selectedYoutube();
  //     setShowYoutube(true);
  //   }
  // }, [ytSelected]);

  const GetCreadentials = () => {
    setLoading("getPost");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: loginToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/social/GetCredentials",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCredentials(result.data);
        }
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(" "));
  };

  const selectedTwitter = () => {
    let errors = {};
    if (twitterSelected && overContent) {
      errors.twitterDescription = "Twitter accepts 279 characters only";
    }
    if (twitterSelected) {
      errors.checked =
        "Please Note : You can only post text content on Twitter";
    } else {
      errors.checked = "";
    }
    setError(errors);
    return error;
  };
  const selectedYoutube = () => {
    let errors = {};
    if ((ytSelected) && selectedFiles.length > 0) {

      selectedFiles.map((item, index) => {
        if (!item.name.match(/\.(flv|wmv|mp4|mov|gif)$/)) {
          errors.imageUrl = "Please Note : You can only post videos on Youtube";
        }
      });
    }
    if (ytSelected) {
      errors.checked = "Your Youtube account is currently under review.";
    } else {
      errors.checked = "";
    }
    setError(errors);
    return error;
  };
  const formValidation = () => {
    let errors = {};
    let isValid = true;
    if (!checked.length) {
      errors.checked = "Please select social account";
      isValid = false;
    }

    if (
      !selectedFiles.length &&
      ((fbstaSelected && fbContent === "") ||
        (instaSelected && instaContent === "") ||
        (twitterSelected && twitterContent === "") ||
        (linkedinstaSelected && linkedInContent === "")) &&
      description === ""
    ) {
      errors.description = "Please enter content";
      isValid = false;
    }
    if (
        !selectedFilesGet.length &&
        ((fbstaSelected && fbContent === "") ||
          (instaSelected && instaContent === "") ||
          (twitterSelected && twitterContent === "") ||
          (linkedinstaSelected && linkedInContent === "")) &&
        description === ""
      ) {
        errors.description = "Please enter content";
        isValid = false;
      }

    // if (isReel === "Yes" && selectedFiles.length < 1&&selectedFilesGet.length<1) {
    //   errors.imageUrl = "Please select video";
    //   isValid = false;
    // } 
    // else if (
    //   !description &&
    //   !instaContent &&
    //   !linkedInContent &&
    //   !twitterContent &&
    //   !fbContent &&
    //   (!selectedFiles || selectedFiles.length < 1)&&
    //   (!selectedFilesGet || selectedFilesGet.length < 1)
    // ) {
    //   errors.imageUrl = "Please select image/video";
    //   isValid = false;
    // }
      else if ((!selectedFilesGet || selectedFilesGet.length<1)&&(!selectedFiles||selectedFiles.length<1) ) {
      errors.imageUrl = "Please select image/video";
      isValid = false;
     }

    if (isReel === "Yes" && selectedFiles.length > 0) {
      selectedFiles.map((item, index) => {
        if (!item.name.toLowerCase().match(/\.(flv|wmv|mp4|mov|gif)$/)) {
          errors.imageUrl = "Invalid video";
          isValid = false;
        }
      });
    }
    

    if (selectedFiles.length > 0) {
      selectedFiles.map((item, index) => {
        if (
          !item.name
            ?.toLowerCase()
            ?.match(
              /\.(jpg|jpeg|webp|png|gif|mpeg-2|avchd|avi|webm|mkv|swf|f4v|flv|swf|wmv|mp4|mov)$/
            )
        ) {
          errors.imageUrl = "Invalid image/video";
          isValid = false;
        }
      });
    }
    

    if (modal === "schedule" && (!time || time === "")) {
      errors.time = "Please enter  time for post";
      isValid = false;
    }

    if (
      currentDate?.getMonth() === InputDate?.getMonth() &&
      currentDate?.getDate() === InputDate?.getDate() &&
      time < dt1 &&
      modal === "schedule"
    ) {
      errors.time = "Please schedule a post a hour from now";
      isValid = false;
    }
    // if (
    //   currentDate?.getMonth() === InputDate?.getMonth() &&
    //   currentDate?.getDate() === InputDate?.getDate() &&
    //   time < updatedTIme.toLocaleTimeString().replace(/(.*)\D\d+/, '$1') &&
    //   modal === "schedule"
    // ) {
    //   errors.time = "Please schedule a post a hour from now!!!";
    //   isValid = false;
    // }
    if (modal === "schedule" && (!date || date === "")) {
      errors.date = "Please enter date  for post";
      isValid = false;
    }
    if (instaSelected && videoInfo.length > 0) {
      videoInfo.map((item, index) => {
        if (item.videoWidth > 1920) {
          isValid = false;
          setModal("resolution");
          errors.resolution = `The selected video  resolution is ${item.videoWidth}x${item.videoHeight}. Instagram allows max width resolution 1920px. This video will not post on Instagram Reels. Please select video Within the instagram allowed limit.`;
        }
      });
    }
    setError(errors);
    return isValid;
  };
  const post = (socials, pageId) => {
    setLoading("schedulePost");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    var formdata = new FormData();
    formdata.append("Description", description);
    formdata.append("Reel", isReel === "Yes" ? 1 : 0);

    formdata.append("PagesId", pageId);
    formdata.append("Socials", socials.toString());
    // formdata.append("YTDescription", youtubeContent);
    for (let i = 0; i < selectedFiles.length; i++) {
      if (typeof selectedFiles[i] !== "string") {
        formdata.append("Images", selectedFiles[i]);
      }
    }
    // for (let i = 0; i < selectedFiles.length; i++) {
    //     formdata.append("Videos", selectedFiles[i]);
    // }
    formdata.append("TwitterDescription", twitterContent.slice(0, 279));
    formdata.append("UserToken", loginToken);
    formdata.append("InstaDescription", instaContent);
    formdata.append("LinkedinDescription", linkedInContent);
    formdata.append("FBDescription", fbContent);
    formdata.append("PostID", postId);
    formdata.append("ImageUrl", selectedFilesGet);
    formdata.append("DateTime ",date+" "+time);
    // formdata.append("Date", date);

    // formdata.append("Title", title);
    // formdata.append("Visibility", visibility);
    // formdata.append("Tags", tags);
    // formdata.append("Category", category);
    // formdata.append("Reel", isReel === "Yes" ? 1 : 0);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/UpdatePost",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status === "Success" &&
          result.status_code === 200 &&
          result.message?.includes("Post Updated Successfully")
        ) {
          setCreatePostData(result.data);
          setModal("save");
          setSuccesMessage(
            `You have successfully scheduled a post for ${dateConverter(date)} at ${time24to12Converter(time)}`
          );
          setTime("");
          setDescription("");
          setChecked([]);
          setSelectedFiles([]);
          setSelected(false);
          setFbContent("");
          setTwitterContent("")
          setInstaContent("");
          setLinkedInContent("");
          setPagesId([]);
          setImageUrl("");
          GetSchedulePost()
          setTimeout(()=>{
navigate("/schedule-post-new")
          },1000)
        } else if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          LogOut();
        } else {
          setModal("save");
          setSuccesMessage(
            "Oops, A problem has occurred while posting. Please try after sometime"
          );
        }
      })
      .catch((error) => {
        setModal("save");
        setSuccesMessage(
          "Oops, A problem has occurred while posting. Please try after sometime"
        );
      })
      .finally(() => setLoading(""));
  };

  const dateConverter = (d) => {
    let date = d.split("-");
    return [date[2], date[1], date[0]].join("-");
  };
  const time24to12Converter = (t) => {
    let time = t.split(":");
    let temp = " AM";
    console.log(time[0]);
    if (parseInt(time[0]) > 11) {
      time[0] = (time[0] - 12 < 1) ? 12 : time[0] -= 12
      temp = " PM";
    }
    else {
      time[0] = time[0] < 1 ? 12 : time[0]
    }
    return time.join(":") + temp;
  };

  const RemoveImage = (img) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: loginToken,
      PostId: postId,
      ImageUrl: img.toString(),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/UpdateScheduledPostImage",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        alert("success")
        GetSchedulePost()
      })
      .catch((error) => console.log("error", error));
  };

  const loadingHandler = () => {
    if (loading === "getPost") {
      return <LoadingComp />;
    }
    if (loading === "save") {
      return (
        <Loader
          textOne={"Getting ready to post"}
          textTwo={
            fbstaSelected
              ? "Logging on to facebook"
              : linkedinstaSelected
              ? "Logging on to linkedin"
              : instaSelected
              ? "Logging on to instagram"
              : ""
          }
          textThree={
            linkedinstaSelected
              ? "Logging on to linkedin"
              : fbstaSelected
              ? "Logging on to facebook"
              : instaSelected
              ? "Logging on to instagram"
              : ""
          }
          textFour={
            instaSelected
              ? "Logging on to instagram"
              : fbstaSelected
              ? "Logging on to facebook"
              : linkedinstaSelected
              ? "Logging on to linkedin"
              : ""
          }
          textFive={
            fbstaSelected
              ? "Posting on facebook"
              : linkedinstaSelected
              ? "Posting on linkedin"
              : instaSelected
              ? "Posting on instagram"
              : ""
          }
          textSix={
            linkedinstaSelected
              ? "Posting on linkedin"
              : fbstaSelected
              ? "Posting on facebook"
              : instaSelected
              ? "Posting on instagram"
              : ""
          }
          textSeven={
            instaSelected
              ? "Posting on instagram"
              : fbstaSelected
              ? "Posting on facebook"
              : linkedinstaSelected
              ? "Posting on linkedin"
              : ""
          }
          textEight={"Finishing up, validating posts"}
        />
      );
    }
    if (loading === "schedulePost") {
      return (
        <Loader
          textOne={"Scheduling post for later"}
          textTwo={"Scheduling post for later"}
          textThree={"Scheduling post for later"}
          textFour={"Scheduling post for later"}
          textFive={"Scheduling post for later"}
          textSix={"Scheduling post for later"}
          textSeven={"Scheduling post for later"}
        />
      );
    }
    if (loading === "Reels") {
      return (
        <Loader
          textOne={"Logging on to instagram"}
          textTwo={"Logging on to instagram"}
          textThree={"Logging on to instagram"}
          textFour={"Posting on instagram"}
          textFive={"Posting on instagram"}
          textSix={"Posting on instagram"}
          textSeven={"Posting on instagram"}
          textEight={"Posting on instagram"}
        />
      );
    } else {
      return false;
    }
  };
  const handleImageChange = (e) => {
    error.description = "";
    error.imageUrl = "";
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) => file);
      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
    }
  };
  const deleteFile = (e) => {
    const s = selectedFiles.filter((item, index) => index !== e);
    const res = videoInfo.filter((item, index) => index !== e);
    error.resolution = "";
    setVideoInfo(res);
    setSelectedFiles(s);
  };
  const descriptionHandler = (e) => {
    error.description = "";
    error.imageUrl = "";
    setDescription(e.target.value);
    if (!isInstaContent) {
      setInstaContent(e.target.value);
    }
    if (!isFb) {
      setFbContent(e.target.value);
    }
    if (!isLkdContent) {
      setLinkedInContent(e.target.value);
    }
    if (!isTwitter) {
      setTwitterContent(e.target.value);
      setOverContent(e.target.value?.length > 279 ? true : false);
    }
    if (!isYoutube) {
      setYoutubeContent(e.target.value);
    }
  };
  const datettt = new Date();
  useEffect(() => {
    let fakeDate = `${datettt.getFullYear()}-${datettt.getMonth() + 1
      }-${datettt.getDate()}`;
    let fakeArray = fakeDate.split("-");
    if (fakeArray[1].length === 1) {
      fakeArray[1] = `0${fakeArray[1]}`;
    }
    if (fakeArray[2].length === 1) {
      fakeArray[2] = `0${fakeArray[2]}`;
    }
    let freshArray = fakeArray.join("-");
    setMinimumDate(freshArray);
  }, []);

  var currentDate = new Date();
  let dateMDY = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${
    currentDate.getDate().toString().length === 1
      ? "0" + currentDate.getDate()
      : currentDate.getDate()
  }`;
  var InputDate = new Date(date);
  currentDate.setHours(currentDate.getHours());
  var dt2 = currentDate.toString().slice(16, 21);
  currentDate.setHours(currentDate.getHours() + 1);
  var dt1 = currentDate.toString().slice(16, 21);

  const pages = [{ name: "Update Schedule Post", href: "", current: true }];
  const addEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    error.description = "";
    error.imageUrl = "";
    setDescription(description + emoji);
    if (!isInstaContent) {
      setInstaContent(description + emoji);
    }
    if (!isFb) {
      setFbContent(description + emoji);
    }
    if (!isLkdContent) {
      setLinkedInContent(description + emoji);
    }
    if (!isTwitter) {
      setTwitterContent(description + emoji);
    }
    if (!isYoutube) {
      setYoutubeContent(description + emoji);
    }
  };
  const addFbEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setFbContent(fbContent + emoji);
  };
  const addInstaEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setInstaContent(instaContent + emoji);
  };
  const addLinkedInEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setLinkedInContent(linkedInContent + emoji);
  };
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    error.description = "";
    error.imageUrl = "";
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const filesArray = Array.from(e.dataTransfer.files).map((file) => file);
      setSelectedFiles((prevImages) => prevImages.concat(filesArray));
    }
  };

  const addTwitterEmoji = (e) => {
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setTwitterContent(twitterContent + emoji);
  };

  const resolutionErrorHandler = () => {
    if (linkedinstaSelected || twitterSelected || fbstaSelected) {
      var filterSocial = checked.filter((item) => item !== "instagram");
      var filterSelected = selectedId.filter(
        (item) => item.value !== "instagram"
      );
      var filterId = filterSelected.map((item) =>
        pagesId.filter((val) => val === item.id)
      );

      setChecked(filterSocial);
      setSelectedId(filterSelected);

      post(filterSocial, filterId);
    }
  };

  const videoRef = useRef(null);

  function handleVideoLoad() {
    const video = videoRef.current;
    setVideoInfo([
      ...videoInfo,
      { videoWidth: video.videoWidth, videoHeight: video.videoHeight },
    ]);
    console.log(`Video resolution: ${video.videoWidth}x${video.videoHeight}`);
  }

  return (
    <DashboardComp BreadCrumb={pages}>
      {modal === "save" && (
        <TailwindModal
          modalCase={"confirmation"}
          Text={succesMessage}
          setModal={setModal}
          isCancel={false}
          modal={modal}
        />
      )}
      {modal === "resolution" && (
        <TailwindModal
          modalCase={"resolution"}
          Text={error.resolution}
          setModal={setModal}
          SubText={
            linkedinstaSelected || twitterSelected || fbstaSelected
              ? "Do you want to Continue to post on rest of the platform without posting on instagram."
              : ""
          }
          onClick={() => {
            setModal("");
            resolutionErrorHandler();
          }}
          modal={modal}
        />
      )}
      {modal === "schedule" && (
        <TailwindModal
          modalCase={"SchedulePost"}
          modal={modal}
          setTime={setTime}
          error={error}
          setDate={setDate}
          date={date}
          time={time}
          min={minimumDate}
          setModal={setModal}
          onClick={(e) => {
            e.preventDefault();
                              formValidation()
                                ? post(checked, pagesId)
                                : console.log("something went wrong");
          }}
        />
      )}
      {showGMB && <GMBPageList setModal={setShowGMB} modal={showGMB} />}
      {ytErrorShow && (
        <YoutubeEditModal
          Alert={"success"}
          onClick={() => setYtErrorShow(false)}
          success={"Youtube Posts cannot be Edited."}
          
          contextValue={contextValue}
        />
      )}
      {/* {showYoutube  && (
        <YoutubeModal
        setYoutubeContent={setYoutubeContent}
        youtubeContent={youtubeContent}
        setTitle={setTitle}
        ytSelected={ytSelected}
        setCategory={setCategory}
        category={category}
        setVisibility={setVisibility}
        visibility={visibility}
        tags={tags}
        setTags={setTags}
        title={title}
          setModal={setShowYoutube}
          modal={showYoutube}
        />
      )} */}
      {modal === "UpdatePost" && (
        <TailwindModal
          modalCase={"UpdatePost"}
          createPostData={createPostData}
          setModal={setModal}
          modal={modal}
        />
      )}
      <div
        className={
          "sm:hidden flex justify-center w-full mt-4 fixed bottom-0 z-10 bg-white py-4"
        }
      >
        <>
          <Button
            type={"blueFilled"}
            onClick={(e) => {
              e.preventDefault();
              formValidation()
                ? post(checked, pagesId)
                : console.log("something went wrong");
            }}
            // onClick={()=>CreateYoutubePost()}
            loading={loading === "save" ? true : false}
            buttonText={"Update now"}
          />
        </>
      </div>

      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
        <h1 className="sr-only">Page title</h1>

        <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          <div className="grid grid-cols-1 gap-4 lg:col-span-6">
            <section aria-labelledby="section-1-title">
              <h2 className="sr-only" id="section-1-title">
                Section title
              </h2>
              <div className="mb-5 rounded-lg bg-white shadow">
                <div
                  className={
                    localStorage.getItem("trialexpire") === "yes"
                      ? "p-6 pointer-events-none opacity-50"
                      : "p-6"
                  }
                >
                  {" "}
                  <h1 className="text-xl font-semibold text-gray-900">
                    Edit Schedule Post
                  </h1>
                  <div className="text-gray-400">
                    Quickly create a social post and publish it to the multiple
                    social platforms within minutes
                  </div>
                  {loadingHandler() ? (
                    loadingHandler()
                  ) : (
                    <>
                      <div className={"text-gray-900 mt-5 font-bold"}>
                        Publish to
                      </div>
                      <SelectCredentials
                        setModal={setModal}
                        credentials={credentials}
                        setChecked={setChecked}
                        checked={checked}
                        setIsOpen={setIsOpen}
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        selected={selected}
                        setSelected={setSelected}
                        ytPagesId={ytPagesId}
                        ytSocial={ytSocial}
                        setYTPagesId={setYTPagesId}
                        setYtSocial={setYtSocial}
                        ytSelected={ytSelected}
                        // setShowGMB={setShowGMB}
                        // showGMB={showGMB}
                        twitterSelected={error.checked}
                        setPagesId={setPagesId}
                        pagesId={pagesId}
                       
                      />

                      <div className={"text-red-500 text-sm"}>
                        {error.checked}
                      </div>
                      <div className={"text-gray-900 mt-5 pb-2 font-bold"}>Shorten Link</div>
                      <input
                      className="border border-gray-300 w-full py-2 px-3 text-sm outline-none"
                      value={longUrl}
                      onChange={(e) =>{ setLongUrl(e.target.value);setShortUrl("")}}
                    />

                      {!shortUrl ? <div className="my-3 flex justify-center">
                      <Button
                        type={"blueFilled"}
                        onClick={() => BitLy()}
                       
                        loading={shortenLoading ? true : false}
                        buttonText={"Short link"}
                      />
                    </div> : <div className="text-sm text-center my-3">{shortUrl} {!copied?<span className="text-red-500 cursor-pointer" onClick={() => {
                      copy(
                        shortUrl); setCopied(true); setTimeout(() => {
                          setCopied(false);
                        }, 1500);
                    }}> Copy</span>:<span className="text-sm text-red-500">Copied!!</span>}</div>}

                      {copied && <div className="text-sm">Copied!!</div>}

                      <div className={"text-gray-900 mt-5 font-bold"}>
                        Description
                      </div>
                      <Tabs
                        contentType={contentType}
                        checked={checked}
                        selectedId={selectedId}
                        setContentType={setContentType}
                      />
                      {contentType === "common" && (
                        <>
                          {" "}
                          <Input
                            type={"textarea"}
                            inputType={"text"}
                            placeholder={
                              "The content will be posted on all social platforms"
                            }
                            addEmoji={addEmoji}
                            onChange={(e) => descriptionHandler(e)}
                            value={description}
                          />
                          {
                            <div className="text-right w-full flex justify-between">
                              <div className={"text-red-500  text-sm"}>
                                {" "}
                                {error.twitterDescription || error.description}
                              </div>{" "}
                              {description?.length}
                            </div>
                          }
                        </>
                      )}
                      {contentType === "Facebook preview" && (
                        <>
                          {" "}
                          <Input
                            type={"textarea"}
                            inputType={"text"}
                            placeholder={
                              "The content will be posted on Facebook"
                            }
                            addEmoji={addFbEmoji}
                            onChange={(e) => {
                              setFbContent(e.target.value);
                              setIsFb(true);
                              error.description = "";
                              error.imageUrl = "";
                            }}
                            value={fbContent}
                          />
                          {
                            <div className="text-right w-full flex justify-between">
                              <div className={"text-red-500  text-sm"}>
                                {" "}
                                {error.description}
                              </div>{" "}
                              {fbContent.length || description?.length}
                            </div>
                          }
                        </>
                      )}
                      {contentType === "Linkedin preview" && (
                        <>
                          {" "}
                          <Input
                            type={"textarea"}
                            inputType={"text"}
                            addEmoji={addLinkedInEmoji}
                            placeholder={
                              "The content will be posted on Linkedin"
                            }
                            onChange={(e) => {
                              setLinkedInContent(e.target.value);
                              setIsLkdContent(true);
                              error.description = "";
                              error.imageUrl = "";
                            }}
                            value={linkedInContent}
                          />
                          {
                            <div className="text-right w-full flex justify-between">
                              <div className={"text-red-500  text-sm"}>
                                {" "}
                                {error.description}
                              </div>{" "}
                              {linkedInContent.length || description?.length}
                            </div>
                          }
                        </>
                      )}
                      {contentType === "Twitter preview" && (
                        <>
                          {" "}
                          <Input
                            type={"textarea"}
                            inputType={"text"}
                            addEmoji={addTwitterEmoji}
                            placeholder={
                              "The content will be posted on Twitter"
                            }
                            onChange={(e) => {
                              {
                                setOverContent(
                                  e.target.value?.length > 279 ? true : false
                                );
                                setTwitterContent(e.target.value);
                              }
                              setIsTwitter(true);
                              error.description = "";
                              error.imageUrl = "";
                            }}
                            value={twitterContent}
                          />
                          {
                            <div className="text-right w-full flex justify-between">
                              <div className={"text-red-500  text-sm"}>
                                {" "}
                                {error.twitterDescription || error.description}
                              </div>{" "}
                              {twitterContent.length || description?.length}
                            </div>
                          }
                        </>
                      )}
                      {contentType === "Youtube preview" && (
                        <>
                          {" "}
                          <Input
                            type={"textarea"}
                            inputType={"text"}
                            placeholder={
                              "The content will be posted on Youtube"
                            }
                            onChange={(e) => {
                              setYoutubeContent(e.target.value);
                              setIsYoutube(true);
                             
                              error.description = "";
                              error.imageUrl = "";
                            }}
                            value={youtubeContent}
                          />{" "}
                          {
                            <div className="text-right w-full flex justify-between">
                              <div className={"text-red-500  text-sm"}>
                                {" "}
                                {error.description}
                              </div>{" "}
                              {youtubeContent.length || description?.length}
                            </div>
                          }
                        </>
                      )}
                      {contentType === "Instagram preview" && (
                        <>
                          {" "}
                          <Input
                            type={"textarea"}
                            addEmoji={addInstaEmoji}
                            inputType={"text"}
                            placeholder={
                              "The content will be posted on Instagram"
                            }
                            onChange={(e) => {
                              setInstaContent(e.target.value);
                              setIsInstaContent(true);
                              error.description = "";
                              error.imageUrl = "";
                            }}
                            value={instaContent}
                          />{" "}
                          {
                            <div className="text-right w-full flex justify-between">
                              <div className={"text-red-500  text-sm"}>
                                {" "}
                                {error.description}
                              </div>{" "}
                              {instaContent.length || description?.length}
                            </div>
                          }
                        </>
                      )}

                      <div className={"text-gray-900 mt-5 font-bold"}>
                        Media
                      </div>
                      <Input
                        type="file"
                        multiple={true}
                        dragActive={dragActive}
                        handleDrop={handleDrop}
                        handleDrag={handleDrag}
                        onChange={handleImageChange}
                        value={selectedFiles}
                        // placeholder={selectedFiles}
                      />
                      <div className="flex gap-3 mt-4">
                        {selectedFilesGet?.map((item, index) => {
                          return (
                            <div>
                              {
                              typeof item === "string" ? (
                                <>
                                  <div className="relative mt-2">
                                    <div
                                      onClick={() => {
                                        const imagearr = selectedFilesGet.filter(
                                          (img, imgidx) => index !== imgidx
                                        );
                                        setSelectedFilesGet(imagearr);
                                      }}
                                    >
                                      <svg
                                        className="h-5 w-5 border bg-red-500  border-red-500 rounded-[50%] p-1 absolute left-[90%] bottom-[90%]"
                                        stroke="white"
                                        fill="none"
                                        viewBox="0 0 8 8"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeWidth="1.5"
                                          d="M1 1l6 6m0-6L1 7"
                                        />
                                      </svg>
                                    </div>
                                   {!item.includes("mp4","flv","wmv","mov","gif")? <img
                                      src={item}
                                      className="h-20 w-20 border rounded-md"
                                    />:
                                    <video className="h-20 w-20 border rounded-md" controls>
                                    <source
                                        src={item}
                                        
                                        className=" object-cover  rounded-md"
                                      />
                                      </video>}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="relative mt-2">
                                    {" "}
                                    <div
                                      onClick={() => {
                                        const imagearr = selectedFiles.filter(
                                          (img, imgidx) => index !== imgidx
                                        );
                                        setSelectedFiles(imagearr);
                                      }}
                                    >
                                    <svg
                                        className="h-5 w-5 border bg-red-500  border-red-500 rounded-[50%] p-1 absolute left-[90%] bottom-[90%]"
                                        stroke="white"
                                        fill="none"
                                        viewBox="0 0 8 8"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeWidth="1.5"
                                          d="M1 1l6 6m0-6L1 7"
                                        />
                                      </svg>
                                      </div>
                                    <img
                                      src={URL.createObjectURL(item)}
                                      className=" h-20 w-20 border rounded-md"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          );
                        })}
                        {selectedFiles?.map((item, index) => {
                          return (
                            <div>
                              {item.type?.includes("video") ? (
                                <div className="relative mt-2">
                                  {" "}
                                  <div
                                    onClick={() => {
                                      const imagearr = selectedFiles.filter(
                                        (img, imgidx) => index !== imgidx
                                      );
                                      setSelectedFiles(imagearr);
                                    }}
                                    className=""
                                  >
                                     <svg
                                        className="h-5 w-5 border bg-red-500  border-red-500 rounded-[50%] p-1 absolute left-[90%] bottom-[90%]"
                                        stroke="white"
                                        fill="none"
                                        viewBox="0 0 8 8"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeWidth="1.5"
                                          d="M1 1l6 6m0-6L1 7"
                                        />
                                      </svg>
                                    </div>
                                  
                                    {" "}
                                    <video
                                      ref={videoRef}
                                      onLoadedData={handleVideoLoad}
                                      // width="250" height="250"
                                      className="h-20 w-20 border rounded-md"
                                      controls
                                      // autoPlay
                                    >
                                      <source
                                        src={item}
                                        type="video/mp4"
                                        className=" object-cover  rounded-md"
                                      />
                                    </video>
                                  </div>
                               
                              ) : typeof item === "string" ? (
                                <>
                                  <div className="relative mt-2">
                                    <div
                                      onClick={() => {
                                        const imagearr = selectedFiles.filter(
                                          (img, imgidx) => index !== imgidx
                                        );
                                        RemoveImage(imagearr);
                                      }}
                                    >
                                      <svg
                                        className="h-5 w-5 border bg-red-500  border-red-500 rounded-[50%] p-1 absolute left-[90%] bottom-[90%]"
                                        stroke="white"
                                        fill="none"
                                        viewBox="0 0 8 8"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeWidth="1.5"
                                          d="M1 1l6 6m0-6L1 7"
                                        />
                                      </svg>
                                    </div>
                                    <img
                                      src={item}
                                      className="h-20 w-20 border rounded-md"
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="relative mt-2">
                                    {" "}
                                    <div
                                      onClick={() => {
                                        const imagearr = selectedFiles.filter(
                                          (img, imgidx) => index !== imgidx
                                        );
                                        setSelectedFiles(imagearr);
                                      }}
                                    >
                                    <svg
                                        className="h-5 w-5 border bg-red-500  border-red-500 rounded-[50%] p-1 absolute left-[90%] bottom-[90%]"
                                        stroke="white"
                                        fill="none"
                                        viewBox="0 0 8 8"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeWidth="1.5"
                                          d="M1 1l6 6m0-6L1 7"
                                        />
                                      </svg>
                                      </div>
                                    <img
                                      src={URL.createObjectURL(item)}
                                      className=" h-20 w-20 border rounded-md"
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {/* <div className={"classes.flexBox"}>
                                            {selectedFiles.map((item, index) => {
                                                return (
                                                    <Badges
                                                        setVideoInfo={setVideoInfo}
                                                        item={item}
                                                        videoInfo={videoInfo}
                                                        onClick={() => deleteFile(index)}
                                                    />
                                                );
                                            })}
                                        </div> */}
                      <div className={"text-red-500 text-sm"}>
                        {" "}
                        {error.imageUrl}
                      </div>
                      {(selectedVideo && instaSelected)? (
                        <>
                          {" "}
                          <div className={"text-gray-900 mt-3 font-bold"}>
                            Post on Instagram reels{" "}
                          </div>
                          <Input
                            type={"radio group"}
                            value={isReel}
                            setState={setIsReel}
                            data={reelData}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    {/* {ytErrorShow&&<div className="text-sm font-semibold text-red-500 py-3">You can not edit you youtube post!!</div>} */}
                      <div className={"hidden sm:flex justify-end mt-4"}>
                        <>
                          <Button
                            type={"blueFilled"}
                            onClick={(e) => {
                              setModal(
                                formValidation() ? "schedule" : ""
                              );
                            }}
                            // onClick={()=>CreateYoutubePost()}
                            loading={loading === "save" ? true : false}
                            buttonText={"Update now"}
                          />
                        </>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
          </div>

          {/* {/ Right column /} */}
          {/* <div className="grid grid-cols-1 gap-4">
                        <section aria-labelledby="section-2-title">
                            <h2 className="sr-only" id="section-2-title">
                                Section title
                            </h2>
                            <div className="mb-5 rounded-lg bg-white shadow">
                                <div className="p-6 ">
                                    <div>
                                        <Tabs
                                            tab={"preview"}
                                            contentType={contentType}
                                            checked={checked}
                                            selectedId={selectedId}
                                            setContentType={setContentType}
                                        />

                                        <div className={"bg-indigo-50 rounded-md sm:p-10 py-10 "}>
                                            {checked.length === 0 ? (
                                                <DummySocialCard />
                                            ) : (
                                                <Carousel infinite timer={2000} stopOnManual prevPage={"createPost"}>
                                                    {credentials?.map((item, index) => {

                                                        if (item.SocialMediaName === "facebook" && (pagesId?.toString()?.includes(item.PageId))) {
                                                            return (
                                                                <FBCard
                                                                    text={"Facebook preview"}
                                                                    imageUrl={imageUrl}
                                                                    cardPosition={contentType}
                                                                    selectedFiles={selectedFiles}
                                                                    description={description}
                                                                    pageId={item.PageId}
                                                                    FbDescription={fbContent}
                                                                    userName={item.PageName}
                                                                    ProfileImage={item.ProfileImage}
                                                                    credentials={credentials}
                                                                />
                                                            );
                                                        }
                                                        if (
                                                            (item.SocialMediaName === "linkedin" ||
                                                                item.SocialMediaName === "linkedinorg") && (pagesId?.toString()?.includes(item.PageId))
                                                        ) {
                                                            return (
                                                                <LinkedinCard
                                                                    text={"Linkedin preview"}
                                                                    imageUrl={imageUrl}
                                                                    description={description}
                                                                    selectedFiles={selectedFiles}
                                                                    cardPosition={contentType}
                                                                    ProfileImage={item.ProfileImage}
                                                                    LinkedinDescription={linkedInContent}
                                                                    userName={item.PageName}
                                                                    credentials={credentials}
                                                                />
                                                            );
                                                        }

                                                        if (item.SocialMediaName === "instagram" && (pagesId?.toString()?.includes(item.PageId))) {
                                                            return (
                                                                <InstagramCard
                                                                    text={"Instagram preview"}
                                                                    imageUrl={imageUrl}
                                                                    description={description}
                                                                    selectedFiles={selectedFiles}
                                                                    cardPosition={contentType}
                                                                    ProfileImage={item.ProfileImage}
                                                                    instaDescription={instaContent}
                                                                    userName={item.PageName}
                                                                    credentials={credentials}
                                                                />
                                                            );
                                                        }
                                                        if (item.SocialMediaName === "twitter" && (pagesId?.toString()?.includes(item.PageId))) {
                                                            return (
                                                                <TwitterCard
                                                                    text={"Twitter preview"}
                                                                    imageUrl={imageUrl}
                                                                    cardCase={"createPost"}
                                                                    description={description}
                                                                    selectedFiles={selectedFiles}
                                                                    cardPosition={contentType}
                                                                    ProfileImage={item.ProfileImage}
                                                                    instaDescription={twitterContent}
                                                                    userName={item.PageName}
                                                                    credentials={credentials}
                                                                />
                                                            );
                                                        }
                                                        if (item.SocialMediaName === "youtube" && (pagesId?.toString()?.includes(item.PageId))) {
                                                            return (
                                                                <YoutubeCard
                                                                    text={"Youtube preview"}
                                                                    imageUrl={imageUrl}
                                                                    description={description}
                                                                    selectedFiles={selectedFiles}
                                                                    cardPosition={contentType}
                                                                    ProfileImage={item.ProfileImage}
                                                                    youtubeContent={youtubeContent}
                                                                    userName={item.PageName}
                                                                    credentials={credentials}
                                                                />
                                                            );
                                                        }
                                                    })}
                                                </Carousel>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div> */}
        </div>
      </div>
    </DashboardComp>
  );
};

export default EditSchedulePost;
