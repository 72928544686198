import React,{useState} from 'react';

import classes from './LoginWithOtpScreen.module.css';
import { useLocation, useNavigate } from "react-router-dom";
import OPInput from '../../../Components/OPInput';
import ActionButton from '../../../Components/ActionButton';
import { MdMarkEmailRead } from 'react-icons/md';
import Modal from "../../../Components/Modal";
const LoginWithOtpEmailScreen = (props) => {
    const [loading, setLoading] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState("");

    const [modal, setModal] = useState("");
    const [error, setError] = useState({});
    const [email, setEmail] = useState("");
    const navigate = useNavigate()
    const {pathname} =useLocation()
    const formValidation = () => {
        let errors = {};
        let isValid = true;
        if (!email || email === "") {
          errors.email = "Please enter email address";
          isValid = false;
        }
        else if (!emailRegex.test(email)) {
          isValid = false;
          errors.email = "Please enter valid email address";
        }
  
        setError(errors);
        return isValid;
      };
      const emailRegex =
      /^(([^<>()/.,;:\s@"]+(\.[^<>()/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const resentOTP =  () => {
        setInvalidEmail("")
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({
          Email: email,
        });
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
    
         fetch(
          "https://promote.onecorp.co.in/api/v1/Ai/SASResendOtp",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (
              result.status === "SUCCESS" &&
              result.message === "OTP sent successfully"
            ) {
                localStorage.setItem("Email",email)
                navigate(pathname==="/verify-email-otp"?"/verify-email":"/login-with-otp")
            }
            if(result.status === "fail" &&result.Remark==="Email Invalid"){
                setInvalidEmail("Please enter valid email")
            }
            else{
              setModal("apiFailed")
            }
          })
          .catch((error) => console.log("error", error))
          .finally(() => setLoading(false));
      };
    return (
        <div className={classes.mainContainer}>
                  {modal === "apiFailed" && (
        <Modal
        modalCase={"confirmation"}
        Text={"There is some issue , please contact support!"}
        setShowModal={setModal}
        iscancel={false}
        onClose={() => setModal("")}
        onOk={() => setModal("")}
      />
      )}
            <div className={classes.logoContainer}>
            <img src={"/assets/One_post.svg"} width={props.device === "Mobile" ? "50%" : "15%"} />
            </div>
            <div className={classes.inputSectionContainer}>
                <div className={classes.headImage}>
                { pathname==="/verify-email-otp"?<MdMarkEmailRead size={40} className="m-auto"  color={"#ef5122"}/> : <img className="m-auto" src={"/assets/UserRegistrationImages/Login.svg"} width={"35px"} />}
                    <div className={classes.headingTextFirst}>{pathname==="/verify-email-otp"?"Verify email":"Login with OTP"}</div>

              <div className={classes.inputContainer}>
                        <OPInput
                             input={"SingleLineInputSecType"}
                            type={"email"}
                            label={"Email"}
                            placeholder={"Enter your email"}
                            onInput={() => {
                                error.email = "";setInvalidEmail("")
                              }}
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                        />
                                     <div className={classes.errors}> {error.email}<span>{invalidEmail}</span></div>
                        <div className={classes.btnNext}><ActionButton text={"Next"} btnType={"circular"} loading={loading} onClick={()=>formValidation()?resentOTP():console.log("something went wrong")}/></div>
                    </div>
               
                    <div className={classes.sasOneText}>SAS ONE</div>
                </div>


            </div>

        </div>
    )
}

export default LoginWithOtpEmailScreen