// import Cookies from 'js-cookie'
// import React, { useState, useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
// import Carousel from '../../Components/CreatePost/Carousel'
// import DummySocialCard from '../../Components/CreatePost/DummySocialCard'
// import TwitterCard from '../../Components/CreatePost/TwitterCard'
// import SelectCredentials from '../../Components/SelectCredentials'
// import DashboardComp from '../../Components/TailwindCompnents/DashoardComp'

// const MediaTestingTwitter = () => {
//   const [imageUrl, setImageUrl] = useState("");
//   const [description, setDescription] = useState("");
//   const [fbContent, setFbContent] = useState("");
//   const [instaContent, setInstaContent] = useState("");
//   const [linkedInContent, setLinkedInContent] = useState("");
//   const [twitterContent, setTwitterContent] = useState("");
//   const [time, setTime] = useState("");
//   const [date, setDate] = useState("");
//   const [contentType, setContentType] = useState("common");
//   const [checked, setChecked] = useState([]);
//   const [pagesId, setPagesId] = useState([]);
//   const [open, setIsOpen] = useState(false);
//   const [selected, setSelected] = useState(false);
//   const [error, setError] = useState({});
//   const [isFb, setIsFb] = useState(false);
//   const [isLkdContent, setIsLkdContent] = useState(false);
//   const [isTwitter, setIsTwitter] = useState(false);
//   const [isYoutube, setIsYoutube] = useState(false);
//   const [youtubeContent, setYoutubeContent] = useState("");
//   const [isInstaContent, setIsInstaContent] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [selectedId, setSelectedId] = useState([]);
//   const [isReel, setIsReel] = useState("no");
//   const [createPostData, setCreatePostData] = useState([]);
//   const [modal, setModal] = useState("");
//   const [credentials, setCredentials] = useState([]);
//   const [succesMessage, setSuccesMessage] = useState("");
//   const [selectedFiles, setSelectedFiles] = useState([]);
//   const [dragActive, setDragActive] = useState(false);
//   const [overContent, setOverContent] = useState(false);
//   const [showGMB, setShowGMB] = useState(false);
//   const [showYoutube, setShowYoutube] = useState(false);
//   const [title, setTitle] = useState("");
//   const [visibility, setVisibility] = useState("Public");
//   const [category, setCategory] = useState("");
//   const [tags, setTags] = useState("");
//   const [ytSocial, setYtSocial] = useState("");
//   const [ytPagesId, setYTPagesId] = useState("");


//   const loginToken = Cookies.get("UserToken");

//   // const loggedIn = Cookies.get("LoggedIn")
//   const navigate = useNavigate()

//   useEffect(() => {
//     // !loggedIn && navigate("/login")
//     GetCreadentials()
//   }, [])

//   const GetCreadentials = () => {
//     setLoading("getPost");
//     var myHeaders = new Headers();
//     myHeaders.append(
//       "Authorization",
//       "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
//     );
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       UserToken: loginToken,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };

//     fetch(
//       "https://promote.onecorp.co.in/api/v1/social/GetCredentials",
//       requestOptions
//     )
//       .then((response) => response.json())
//       .then((result) => {
//         if (result.status_code === 200 && result.status === "Success") {
//           setCredentials(result.data);
//           // CredentialsAvailable(result.data);
//         }
//         if (
//           result.message ===
//           "Invalid User Token, The token may have expired, You may need to re-login"
//         ) {
//           LogOut();
//         }
//       })
//       .catch((error) => console.log("error", error))
//       .finally(() => setLoading(" "));
//   };

//   const LogOut = () => {
//     Cookies.remove("LoggedIn", { domain: '.sasone.in' });
//     Cookies.remove("UserToken", { domain: '.sasone.in' });
//     Cookies.remove("FirstName", { domain: '.sasone.in' });
//     Cookies.remove("LastName", { domain: '.sasone.in' });
//     Cookies.remove("Mobile", { domain: '.sasone.in' });
//     Cookies.remove("userName", { domain: '.sasone.in' });
//     Cookies.remove("Email", { domain: '.sasone.in' });
//     Cookies.remove("UserId", { domain: '.sasone.in' });
//     Cookies.remove("LoginType", { domain: '.sasone.in' });
//     Cookies.remove("ProfilePicture", { domain: '.sasone.in' });
//     navigate("/login");
//   };

//   const pages = [

//     { name: 'Twitter Testing', href: '', current: true },

//   ]
//   const instaSelected =
//     selectedId.findIndex((item) => item.value == "instagram") !== -1;
//   const twitterSelected =
//     selectedId.findIndex((item) => item.value == "twitter") !== -1;
//   const selectedVideo =
//     selectedFiles?.findIndex((item) => item.type?.includes("video")) !== -1;
//   const fbstaSelected =
//     selectedId.findIndex((item) => item.value == "facebook") !== -1;
//   const ytSelected =
//     selectedId.findIndex((item) => item.value == "youtube") !== -1;
//   const linkedinstaSelected =
//     selectedId.findIndex((item) => item.value == "linkedin") !== -1 ||
//     selectedId.findIndex((item) => item.value == "linkedinorg") !== -1;
//   console.log(pagesId);
//   return (
//     <DashboardComp BreadCrumb={pages} >  <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
//       <div className='text-xl font-semibold text-white'>MediaTestingTwitter</div>
//       <div class="w-1/2  pt-4 bg-white shadow mt-6">



//         <div className={"text-gray-900 px-8 py-2 font-bold text-lg"}>
//           Publish to
//         </div>
//         <SelectCredentials
//           setModal={setModal}
//           credentials={credentials}
//           setChecked={setChecked}
//           checked={checked}
//           setIsOpen={setIsOpen}
//           selectedId={selectedId}
//           setSelectedId={setSelectedId}
//           selected={selected}
//           setSelected={setSelected}
//           ytPagesId={ytPagesId}
//           ytSocial={ytSocial}
//           setYTPagesId={setYTPagesId}
//           setYtSocial={setYtSocial}
//           ytSelected={ytSelected}
//           // setShowGMB={setShowGMB}
//           // showGMB={showGMB}
//           twitterSelected={error.checked}
//           setPagesId={setPagesId}
//           pagesId={pagesId}
//         />
//          </div>
//         <div className='flex justify-between mt-8'>
//           <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-1/2">
//             <div class="mb-4">
//               <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
//                 Description
//               </label>
//               <textarea rows="4" cols="50" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="description" />
//             </div>
//             <div class="mb-6">
//               <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
//                 Add Image
//               </label>
//               <input class="shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="file" placeholder="******************" />

//             </div>
//             <div class="flex items-center justify-between">
//               <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
//                 Submit
//               </button>

//             </div>
//           </form>
//           {/* <div className={"bg-indigo-50 rounded-md sm:p-10 py-10 "}>
//           {checked.length === 0 ? (
//             <DummySocialCard />
//           ) : (
//             <Carousel infinite timer={2000} stopOnManual prevPage={"createPost"}>
//               {credentials.map((item, index) => {





//                 if (item.SocialMediaName === "twitter" && (pagesId?.toString()?.includes(item.PageId))) {
//                   return (
//                     <TwitterCard
//                       text={"Twitter preview"}
//                       imageUrl={imageUrl}
//                       cardCase={"createPost"}
//                       description={description}
//                       selectedFiles={selectedFiles}
//                       cardPosition={contentType}
//                       ProfileImage={item.ProfileImage}
//                       instaDescription={twitterContent}
//                       userName={item.PageName}
//                       credentials={credentials}
//                     />
//                   );
//                 }

//               })}
//             </Carousel>
//           )}

//        </div> */}


//       </div>
//     </div> </DashboardComp>
//   )
// }

// export default MediaTestingTwitter

import Cookies from 'js-cookie'
import React, { useState } from 'react'
import DashboardComp from '../../Components/TailwindCompnents/DashoardComp'
import TwitterTestingModal from '../../Components/TailwindCompnents/TwitterTestingModal'

const MediaTestingTwitter = () => {
  const [resourceownerKey, setResourceownerKey] = useState("")
  const [resourceownerSecret, setResourceownerSecret] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [modal, setModal] = useState(false);
  const [code,setCode] = useState("")
  const [showMsg,setShowMsg] = useState(false)
  const [error,setError] = useState("")
  const loginToken =Cookies.get("UserToken");

  const CheckAuthTwitter = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://api.sasone.in/apiadmin/test/onepost/GetTwitterOauthUrl", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "success") {
          setResourceownerKey(result.data.ResourceOwnerKey)
          setResourceownerSecret(result.data.ResourceOwnerSecret)
          setModal(true)
          window.open(result.data.authURL, "_blank")
        }
      })
      .catch(error => console.log('error', error));
  }

  const FinalTwitterSubmit = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "Code": code,
      "ResourceOwnerKey": resourceownerKey,
      "ResourceOwnerSecret": resourceownerSecret,
      "UserToken": loginToken
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.sasone.in/apiadmin/test/onepost/GetTwitterOauth", requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status_code===200&&result.status==="Success"){
          setModal(false)
        }
       else if(result.status_code===300&&result.status==="fail"){
          setError(result.Reason)
          setShowMsg(true)
        }
      })
      .catch(error => console.log('error', error));
  }

  const pages = [

    { name: 'Twitter Testing', href: '', current: true },

  ]
  return (
    <DashboardComp BreadCrumb={pages} >  <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      {modal && <TwitterTestingModal


        setModal={setModal}

        modal={modal}
        value={code}
        onChange={(e)=>setCode(e.target.value)}
        onSubmit={FinalTwitterSubmit}
        error={"The Code has been Expired"}
        onClick={()=>setModal(false)}
      />}
      <div className='text-xl font-semibold text-white'>Media Testing Twitter</div>
      <div onClick={() => CheckAuthTwitter()} className='border w-[20%] text-center px-4 mt-5 rounded py-1 text-white hover:bg-indigo-500 hover:border-indigo-500 cursor-pointer'>Twitter Connect</div>
    </div>
    </DashboardComp>
  )
}

export default MediaTestingTwitter