import React, { useState } from "react";
import { FaRegThumbsUp, FaRegCommentAlt } from "react-icons/fa";
import { HiUserCircle } from "react-icons/hi";
import { RiShareForwardLine } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";
import classes from "./FBCard.module.css";
const FBCard = (props) => {
  const[viewMore,setViewMore]=useState("false")
  const cardType = (type) => {

    const imagePath = () => {
      if (props.data.ImageUrl?.includes(",")) {
        let path = props.data.ImageUrl.split(",");
        return path[0];
      } else {
        return props.data.ImageUrl;
      }
    };
    const dateHandler = (date) => {
      const dateObj = new Date(date);
      const day = dateObj.getDate();
      const monthNameLong = dateObj.toLocaleString("en-US", { month: "long" });
      const year = dateObj.getFullYear();
      const hour = dateObj.getHours();
      const month = dateObj.getMonth();
      return (
        (day <= 9 ? "0" : "") +
        day +
        " " +
        monthNameLong +
        " " +
        "at" +
        " " +
        (hour <= 9 ? "0" : "") +
        hour +
        ":" +
        (month <= 9 ? "0" : "") +
        month
      );
    };
    switch (type) {
      case "fbTypeFirst":
        return (
          <>
            <div className={classes.cardBox}>
              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.data.ProfileImage ? (
                    <img
                      src={props.data.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div>
                    <div className={classes.userTitle}>
                      {props.data?.PageName}
                    </div>
                    <div className={classes.date}>
                      {" "}
                      {dateHandler(props?.data.UpdatedAt)}
                    </div>
                  </div>
                </div>
                <HiDotsHorizontal size={24} color={"gray"} />
              </div>
              <p className={classes.caption}>
                {(props.data.FBDescription
                  ? props.data.FBDescription
                  : props.data.description
                )?.slice(0, 32)}
                {(props.data.FBDescription
                  ? props.data.FBDescription
                  : props.data.description
                )?.length > 32 && <>...</>}
              </p>

              <div>
                {imagePath()?.includes("video") ||
                imagePath()?.includes("mp4") ? (
                  <video controls className={classes.postImgFirst}>
                    <source src={imagePath()} />
                  </video>
                ) : (
                  <img
                    src={
                      imagePath()
                        ? imagePath()
                        : "./assets/onepost-logo-gray.png"
                    }
                    className={classes.postImgFirst}
                  />
                )}
              </div>

              <div className={classes.likesCountBox}></div>
              <div className={classes.line}></div>
              <div className={classes.bottomTextBox}>
                <div className={classes.bottomIconBox}>
                  <FaRegThumbsUp size={16} className={classes.iconBottom} />
                  <div>Like</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <FaRegCommentAlt size={15} className={classes.iconBottom} />
                  <div>comment</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <RiShareForwardLine
                    size={20}
                    className={classes.iconBottom}
                  />
                  <div>share</div>
                </div>
              </div>
            </div>
          </>
        );
      case "socialFeed":
        return (
          <>
            <a
              className={classes.cardBoxFeed}
              href={props.val.permalink_url}
              target={"_blank"}
            >
              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.profilePicture ? (
                    <img
                      src={props.profilePicture}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div>
                    {/* {props.credentials.map((item) => {
                        return (
                          <div className={classes.userTitle}>{item.SocialMediaName==='facebook'&&item.SocailUserName}</div>
                        );
                      })} */}
                    <div className={classes.userTitle}>{props.pageName}</div>
                  </div>
                </div>
                <HiDotsHorizontal size={24} color={"gray"} />
              </div>
              <p className={classes.caption}>
                {props.val.message?.slice(0, 32)}
                {props.val.message?.length > 32 && <>...</>}
              </p>

              <div>
                {props.val.media_url?.includes("video") ? (
                  <video controls>
                    <source
                      className={classes.postImgFirst}
                      src={props.val.full_picture}
                    />
                  </video>
                ) : (
                  <img
                    src={props.val.full_picture}
                    alt=""
                    className={classes.postImgFirst}
                  />
                )}
              </div>

              <div className={classes.likesCountBox}></div>
              <div className={classes.line}></div>
              <div className={classes.bottomTextBox}>
                <div className={classes.bottomIconBox}>
                  <FaRegThumbsUp size={16} className={classes.iconBottom} />
                  <div>{props.val.likes}</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <FaRegCommentAlt size={15} className={classes.iconBottom} />
                  <div>{props.val.comment}</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <RiShareForwardLine
                    size={20}
                    className={classes.iconBottom}
                  />
                  <div>N/A</div>
                </div>
              </div>
              <div className={classes.reach}>
                <div>
                  Reach {props.val.reach}{" "}
                  <span className={classes.impression}>
                    Impression {props.val.impression}
                  </span>
                </div>
              </div>
            </a>
          </>
        );
      case "schedulePost":
        return (
          <>
            <div className={classes.heading}>{props.text}</div>
            <div className={classes.cardBox}>
              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.ProfileImage ? (
                    <img
                      src={props.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div>
                    <div className={classes.userTitle}>{props.userName}</div>
                  </div>
                </div>
                <HiDotsHorizontal size={22} color={"gray"} />
              </div>
              <p onClick={()=>setViewMore(!viewMore)} className={viewMore?" whitespace-normal m-[10px]  text-sm ":" whitespace-normal m-[10px] text-sm "}>
                { props.FbDescription || props.description
                 }
              </p>

              <div className={classes.postImg}>
                {props.selectedFiles ? (
                  props.selectedFiles?.split(",")[0]?.includes("mp4")||  props.selectedFiles?.split(",")[0]?.includes("video")||  props.selectedFiles?.split(",")[0]?.includes("gif")  || props.selectedFiles?.split(",")[0]?.includes("MP4")? (
                    <video
                      // width="250" height="250"
                      width="70%"
                      height="auto"
                      controls
                      // autoPlay
                      className={classes.postImginner}
                    >
                      <source
                        src={props.selectedFiles.split(",")[0]}
                        type={props.selectedFiles.type}
                        className={classes.postImginner}
                      />
                    </video>
                  ) : (
                    <img
                      src={props.selectedFiles.split(",")[0]}
                      width="100%"
                      height="100%"
                      className={classes.postImginner}
                    />
                  )
                ) : (
                  <img
                    src={"../assets/onepost-logo-gray.png"}
                    width="100%"
                    height="100%"
                    className={classes.postImginner}
                  />
                )}
              </div>
              <div className={classes.likesCountBox}></div>
              <div className={classes.line}></div>
              <div className={classes.bottomTextBox}>
                <div className={classes.bottomIconBox}>
                  <FaRegThumbsUp size={16} className={classes.iconBottom} />
                  <div>Like</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <FaRegCommentAlt size={15} className={classes.iconBottom} />
                  <div>comment</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <RiShareForwardLine
                    size={20}
                    className={classes.iconBottom}
                  />
                  <div>share</div>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className={classes.heading}>{props.text}</div>
            <div className={classes.cardBox}>
              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.ProfileImage ? ( 
                    <img
                      src={props.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div>
                    {/* {props.credentials.map((item) => {
                      return (
                        <div className={classes.userTitle}>{item.SocialMediaName==='facebook'&&item.SocailUserName}</div>
                      );
                    })} */}
                    <div className={classes.userTitle}>{props.userName}</div>
                  </div>
                </div>
                <HiDotsHorizontal size={22} color={"gray"} />
              </div>
              <p  onClick={()=>setViewMore(!viewMore)} className={  (props.FbDescription|| props.description).length>236?" whitespace-normal m-[10px] text-sm cursor-pointer" : viewMore?"text-sm whitespace-normal m-[10px]":" whitespace-normal m-[10px]"}>
                {
                   props.FbDescription
                  || props.description
                }
              </p>

          
              <div className={classes.postImg}>
                {props.selectedFiles.length ? (
                  props.selectedFiles[0].type?.includes("video") ? (
                    <video
                      // width="250" height="250"
                      width="100%"
                      height="100%"
                      controls
                      // autoPlay
                      className={classes.postImginner}
                    >
                      <source
                        src={URL.createObjectURL(props.selectedFiles[0])}
                        type={props.selectedFiles.type}
                        className={classes.postImginner}
                      />
                    </video>
                  ) : (
                    <img
                      src={URL.createObjectURL(props.selectedFiles[0])}
                      width="100%"
                      height="100%"
                      className={classes.postImginner}
                    />
                  )
                ) : (
                  <img
                    src={"../assets/onepost-logo-gray.png"}
                    width="100%"
                    height="100%"
                    className={classes.postImginner}
                  />
                )}
              </div>
              <div className={classes.likesCountBox}>
                {/* <div className={classes.iconLikesBox}>
                  <FaRegThumbsUp color="#1877F2" className={classes.like} />
                  <AiOutlineHeart color={"red"} className={classes.heart} />
                  100
                </div>
                <div>42 comments</div> */}
              </div>
              <div className={classes.line}></div>
              <div className={classes.bottomTextBox}>
                <div className={classes.bottomIconBox}>
                  <FaRegThumbsUp size={16} className={classes.iconBottom} />
                  <div>Like</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <FaRegCommentAlt size={15} className={classes.iconBottom} />
                  <div>comment</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <RiShareForwardLine
                    size={20}
                    className={classes.iconBottom}
                  />
                  <div>share</div>
                </div>
              </div>
            </div>
          </>
        );
    }
  };
  return cardType(props.cardCase);
};

export default FBCard;
