import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";
import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import TailwindModal from "../../Components/TailwindCompnents/TailwindModal";
import SocialCard from "../../Components/TailwindCompnents/SocialCard";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import LoadingComp from "../../Components/TailwindCompnents/LoadingComp";
import ConfirmationMessage from "../../Components/TailwindCompnents/ConfirmationMessage";
import Cookies from "js-cookie";
const LinkedAccountsNew = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [succesMessage, setSuccesMessage] = useState("");
  const loginToken = Cookies.get("UserToken");
  const DomainName = window.location.hostname;
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const checkLoginType=Cookies.get("LoginType")
  const contextValue = Cookies.get("UserType")
  useEffect(() => {
    if (params.code) {
      saveCredential();
      console.log(params.code);
    }
  }, [pathname]);
  const LogOut = () => {
    Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
    Cookies.remove("UserToken", {  domain:'.sasone.in' });
    Cookies.remove("FirstName", {  domain:'.sasone.in' });
    Cookies.remove("LastName", {  domain:'.sasone.in' });
    Cookies.remove("Mobile", {  domain:'.sasone.in' });
    Cookies.remove("userName", {  domain:'.sasone.in' });
    Cookies.remove("Email", {  domain:'.sasone.in' });
    Cookies.remove("UserId", {  domain:'.sasone.in' });
    Cookies.remove("LoginType", {  domain:'.sasone.in' });
    Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });

    navigate("/login");
  };
  const SignOutFunction=()=>{
    Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
    Cookies.remove("UserToken", {  domain:'.sasone.in' });
    Cookies.remove("FirstName", {  domain:'.sasone.in' });
    Cookies.remove("LastName", {  domain:'.sasone.in' });
    Cookies.remove("Mobile", {  domain:'.sasone.in' });
    Cookies.remove("userName", {  domain:'.sasone.in' });
    Cookies.remove("Email", {  domain:'.sasone.in' });
    Cookies.remove("UserId", {  domain:'.sasone.in' });
    Cookies.remove("LoginType", {  domain:'.sasone.in' });
    Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });

    // Cookies.remove("LoggedIn");
    // Cookies.remove("UserToken");
    // Cookies.remove("FirstName");
    // Cookies.remove("LastName");
    // Cookies.remove("Mobile");
    // Cookies.remove("userName");
    // Cookies.remove("Email");
    // Cookies.remove("UserId");
    // Cookies.remove("LoginType");
    // Cookies.remove("ProfilePicture");
    Cookies.set("googleLogin", "googleLogin", {  domain:'.sasone.in' })
    window.open("https://www.sasone.in/sasone-login","_self")
  }
  const redirectionUri = () => {
    if (pathname.includes("facebook")) {
      return "facebook";
    }
    if (pathname.includes("linkedin")) {
      return "linkedin";
    }
    if (pathname.includes("instagram")) {
      return "instagram";
    }
    if (pathname.includes("twitter")) {
      return "twitter";
    }
    if (pathname.includes("youtube")) {
      return "youtube";
    }
    if (pathname.includes("gmb")) {
      return "gmb";
    }
  };

  const saveCredential = () => {
    setModal("");
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      RedirectEndPoint: redirectionUri(),
      LinkedinCode: params.state === "LinkedIn" ? params.code : "",
      FacebookCode: params.state === "facebook" ? params.code : "",
      AuthCode:(params.state === "gmb"||params.state === "youtube") ? window.location.href: (params.state === "twitter")? params.code : "",
      UserToken: loginToken,
      redirect_url:"https://"+DomainName
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/onepost/SaveSocialCredentialsforpartner",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status_code === 200 &&
          result.status === "Success" &&params.state==="gmb"
          ){
            setSuccesMessage("Details Submitted!")
          }
      else if (
          result.status_code === 200 &&
          result.status === "Success" &&
          !result?.message?.includes(
            "User Credentials Saved Sucessfully And Updated Credentials"
          )
        ) {
          setModal("save");
          setSuccesMessage(
            "Hurray , Your account has been linked successfully."
          );
          // GetCreadentials();
        } else if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType==="Email"?LogOut():SignOutFunction()
        } else if (
          result?.message?.includes(
            "User Credentials Saved Sucessfully And Updated Credentials"
          )
        ) {
          setModal("save");
          setSuccesMessage(
            "Hurray , Your linked account has been updated successfully."
          );
        } else {
          setModal("save");
          setSuccesMessage(
            " Oops, A problem has occurred in linking your account. Please try again "
          );
        }
      })
      .catch((error) => console.log("error", error))
      .finally(()=>setLoading(false))
  };

  const people = [
    {
      name: "Facebook",
      type: "Page or Group",
      imageUrl: (
        <div className="bg-fb rounded-full flex w-12 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaFacebookF size={30} color={"white"} />
        </div>
      ),
    },
    {
      name: "LinkedIn",
      type: "Page or Profile",
      imageUrl: (
        <div className="bg-linkedIn rounded-full flex w-12 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaLinkedinIn size={30} color={"#fff"} />
        </div>
      ),
    },
    {
      name: "Instagram",
      type: "Business Account",
      imageUrl: (
        <div className="bg-insta rounded-full flex w-12 justify-center  items-center p-1 py-2 ">
          {" "}
          <BsInstagram size={30} color={"#fff"} />
        </div>
      ),
    },
    {
      name: "Twitter",
      type: "Profile",
      imageUrl: (
        <div className="bg-twitter rounded-full flex w-12 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaTwitter size={30} color={"#fff"} />
        </div>
      ),
    },
    {
      name: "Google My Business",
      type: "Verified page",
      social: "google",
      imageUrl: (
        <div className=" rounded-full flex w-12 bg-gray-100 justify-center  items-center p-1 py-2 ">
          {" "}
         <img src="../assets/gmbLogo.png" className="w-8" />
        </div>
      ),
    },
    {
      name: "YouTube",
      type: "Account",
      imageUrl: (

          
          <img src="../assets/YouTube-Logo.svg" className="w-28 -mt-4 -mb-4" />

      ),
    },

  ];
  const BreadCrumb = [
    { name: `Linked Accounts`, href: '', current: true },
]
  return (
    <DashboardComp BreadCrumb={BreadCrumb}>
      {modal === "save" && (
        <TailwindModal
          modalCase={"linkAccount"}
          Text={succesMessage}
          setModal={setModal}
          onClick={()=>navigate("/")}
          modal={modal}
        />
      )}
             {succesMessage==="Details Submitted!" &&
        <ConfirmationMessage Alert={"success"}   onClick={()=>navigate("/")} contextValue={contextValue} success={succesMessage} text={"Thank you for providing the information. As the next step, your details will be reviewed and made live soon."} />}
    <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">  <div className="overflow-hidden mb-5 rounded-lg bg-white shadow">
        <div className={"p-6"}>
          {" "}
          <h1 className="text-xl font-semibold text-gray-900">
            Connect a new channel
          </h1>
          <div className="text-gray-400">
          To allow SAS One access to your social accounts, click on connect and follow the steps
          </div>
        { loading ? (
       <LoadingComp />
      ) :  <ul
            role="list"
            className="my-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
          >
            {people.map((item, key) => {
              return <SocialCard item={item} />;
            })}
          </ul>}
        </div>
      </div></div>
    </DashboardComp>
  );
};

export default LinkedAccountsNew;
