import React from "react";
import classes from "./LinkedinCard.module.css";
import { HiDotsVertical } from "react-icons/hi";
import { IoIosSend } from "react-icons/io";
import { HiUserCircle } from "react-icons/hi";
import { AiOutlineHeart } from "react-icons/ai";
import { FaRegThumbsUp, FaRegCommentAlt } from "react-icons/fa";
import { TbArrowForwardUp } from "react-icons/tb";
const LinkedinCard = (props) => {
  const lkdCardtype = (type) => {
    const imagePath = () => {
      if (props.data.ImageUrl?.includes(",")) {
        let path = props.data.ImageUrl.split(",");
        return path[0];
      } else {
        return props.data.ImageUrl;
      }
    };
    const dateHandler = (date) => {
      const dateObj = new Date(date);
      const day = dateObj.getDate();
      const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
      const year = dateObj.getFullYear();
      return (
        monthNameLong + " " + (day <= 9 ? "0" : "") + day + " , " + " " + year
      );
    };
    switch (type) {
      case "linkdFirst":
        return (
          <>
            <div className={classes.heading}>{props.text}</div>
            <div className={classes.cardBox}>
              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.data.ProfileImage ? (
                    <img
                      src={props.data.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div>
                    <div className={classes.userTitle}>
                      {props.data?.PageName}
                    </div>
                    <div className={classes.date}>
                      {" "}
                      {dateHandler(props?.data.UpdatedAt)}
                    </div>
                  </div>
                </div>
                <HiDotsVertical size={24} color={"gray"} />
              </div>
              <p className={classes.caption}>
                {(props.data.LinkedinDescription
                  ? props.data.LinkedinDescription
                  : props.data.description
                )?.slice(0, 32)}
                {(props.data.LinkedinDescription
                  ? props.data.LinkedinDescription
                  : props.data.description
                )?.length > 32 && <>...</>}
              </p>

              {/* <img
                src={imagePath() ? imagePath() : "./assets/onepost-logo-gray.png"}
                className={classes.postImgFirst}
              /> */}
              {props.data?.ImageUrl ? (
                props.data.ImageUrl?.includes("video") ||
                props.data.ImageUrl?.includes("mp4") ? (
                  <video
                    // width="250" height="250"
                    width="100%"
                    height="100%"
                    controls
                    // autoPlay
                    className={classes.postImgFirst}
                  >
                    <source
                      src={props.data?.ImageUrl}
                      type={"video"}
                      className={classes.postImgFirst}
                    />
                  </video>
                ) : (
                  <img
                    src={props.data?.ImageUrl}
                    width="100%"
                    height="100%"
                    className={classes.postImgFirst}
                  />
                )
              ) : (
                <img
                  src={"../assets/onepost-logo-gray.png"}
                  width="100%"
                  height="100%"
                  className={classes.postImgFirst}
                />
              )}
              <div className={classes.likesCountBox}>
                {/*   <div className={classes.iconLikesBox}>
                  <FaRegThumbsUp color="#1877F2" className={classes.like} />
                  <AiOutlineHeart color={"red"} className={classes.heart} />
                  91
                </div>
                <div>18 comments</div> */}
              </div>
              <div className={classes.line}></div>
              <div className={classes.bottomTextBox}>
                <div className={classes.bottomIconBox}>
                  <FaRegThumbsUp size={18} className={classes.iconBottom} />
                  <div>Like</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <FaRegCommentAlt size={18} className={classes.iconBottom} />
                  <div>comment</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <TbArrowForwardUp size={19} className={classes.iconBottom} />
                  <div>share</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <IoIosSend size={19} className={classes.iconBottom} />
                  <div>send</div>
                </div>
              </div>
            </div>
          </>
        );
      case "socialFeed":
        return (
          <>
            <div className={classes.heading}>{props.text}</div>
            <a
              className={classes.cardBoxFeed}
              href={props.val.permalink}
              target={"_blank"}
            >
              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.profilePicture ? (
                    <img
                      src={props.profilePicture}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div>
                    {/* {props.credentials.map((item) => {
                        return (
                          <div className={classes.userTitle}>{item.SocialMediaName==='linkedin'&&item.SocailUserName}</div>
                        );
                      })} */}
                    <div className={classes.userTitle}>{props.pageName}</div>
                  </div>
                </div>
                <HiDotsVertical size={24} color={"gray"} />
              </div>
              <p className={classes.caption}>
                {props.val.commentary?.slice(0, 32)}
                {props.val.commentary?.length > 32 && <>...</>}
              </p>
              <img
                src={"./assets/onepost-logo-gray.png"}
                className={classes.postImgFirst}
              />

              <div className={classes.likesCountBox}></div>
              <div className={classes.line}></div>
              <div className={classes.bottomTextBox}>
                <div className={classes.bottomIconBox}>
                  <FaRegThumbsUp size={18} className={classes.iconBottom} />
                  <div>{props.val.likes}</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <FaRegCommentAlt size={18} className={classes.iconBottom} />
                  <div>{props.val.comments}</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <TbArrowForwardUp size={19} className={classes.iconBottom} />
                  <div>N/A</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <IoIosSend size={19} className={classes.iconBottom} />
                  <div>N/A</div>
                </div>
              </div>
            </a>
          </>
        );
        case 'schedulePost':
          return (
            <>
              <div className={classes.heading}>{props.text}</div>
              <div className={classes.cardBox}>
                <div className={classes.headerOuterBox}>
                  <div className={classes.headerFirstBox}>
                    {props.ProfileImage ? (
                      <img
                        src={props.ProfileImage}
                        className={classes.userIcon}
                      />
                    ) : (
                      <HiUserCircle className={classes.userIcon} />
                    )}
                    <div className={classes.userTitle}>{props.userName}</div>
                  </div>
                  <HiDotsVertical size={22} color={"gray"} />
                </div>
                <p className={" whitespace-normal text-sm m-[10px] "}>
                  { props.FbDescription|| props.description
                }
                </p>
  
                <div className={classes.postImg}>
                  {props.selectedFiles? (
                    props.selectedFiles?.split(",")[0]?.includes("mp4")||props.selectedFiles?.split(",")[0]?.includes("video")||props.selectedFiles?.split(",")[0]?.includes("gif")||props.selectedFiles?.split(",")[0]?.includes("MP4") ? (
                      <video
                        // width="250" height="250"
                        width="100%"
                        height="100%"
                        controls
                        // autoPlay
                        className={classes.postImginner}
                      >
                        <source
                          src={props.selectedFiles.split(",")[0]}
                          type={props.selectedFiles.type}
                          className={classes.postImginner}
                        />
                      </video>
                    ) : (
                      <img
                        src={props.selectedFiles.split(",")[0]}
                        width="100%"
                        height="100%"
                        className={classes.postImginner}
                      />
                    )
                  ) : (
                    <img
                      src={"../assets/onepost-logo-gray.png"}
                      width="100%"
                      height="100%"
                      className={classes.postImginner}
                    />
                  )}
                </div>
  
                <div className={classes.likesCountBox}>
                
                </div>
                <div className={classes.line}></div>
                <div className={classes.bottomTextBox}>
                  <div className={classes.bottomIconBox}>
                    <FaRegThumbsUp size={18} className={classes.iconBottom} />
                    <div>Like</div>
                  </div>
                  <div className={classes.bottomIconBox}>
                    <FaRegCommentAlt size={18} className={classes.iconBottom} />
                    <div>comment</div>
                  </div>
                  <div className={classes.bottomIconBox}>
                    <TbArrowForwardUp size={19} className={classes.iconBottom} />
                    <div>share</div>
                  </div>
                  <div className={classes.bottomIconBox}>
                    <IoIosSend size={19} className={classes.iconBottom} />
                    <div>send</div>
                  </div>
                </div>
              </div>
            </>
          );
      default:
        return (
          <>
            <div className={classes.heading}>{props.text}</div>
            <div className={classes.cardBox}>
              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.ProfileImage ? (
                    <img
                      src={props.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}

                  {/* {props.credentials.map((item) => {
                      return (
                        <div className={classes.userTitle}>{item.SocialMediaName==='linkedin'&&item.SocailUserName}</div>
                      );
                    })} */}
                  <div className={classes.userTitle}>{props.userName}</div>
                </div>
                <HiDotsVertical size={22} color={"gray"} />
              </div>
              <p className={" whitespace-normal text-sm m-[10px] "}>
                { props.LinkedinDescription
                  || props.description
                }
              </p>

              <div className={classes.postImg}>
                {props.selectedFiles.length ? (
                  props.selectedFiles[0].type.includes("video") ? (
                    <video
                      // width="250" height="250"
                      width="100%"
                      height="100%"
                      controls
                      // autoPlay
                      className={classes.postImginner}
                    >
                      <source
                        src={URL.createObjectURL(props.selectedFiles[0])}
                        type={props.selectedFiles.type}
                        className={classes.postImginner}
                      />
                    </video>
                  ) : (
                    <img
                      src={URL.createObjectURL(props.selectedFiles[0])}
                      width="100%"
                      height="100%"
                      className={classes.postImginner}
                    />
                  )
                ) : (
                  <img
                    src={"../assets/onepost-logo-gray.png"}
                    width="100%"
                    height="100%"
                    className={classes.postImginner}
                  />
                )}
              </div>

              <div className={classes.likesCountBox}>
                {/*   <div className={classes.iconLikesBox}>
                  <FaRegThumbsUp color="#1877F2" className={classes.like} />
                  <AiOutlineHeart color={"red"} className={classes.heart} />
                  91
                </div>
                <div>18 comments</div> */}
              </div>
              <div className={classes.line}></div>
              <div className={classes.bottomTextBox}>
                <div className={classes.bottomIconBox}>
                  <FaRegThumbsUp size={18} className={classes.iconBottom} />
                  <div>Like</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <FaRegCommentAlt size={18} className={classes.iconBottom} />
                  <div>comment</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <TbArrowForwardUp size={19} className={classes.iconBottom} />
                  <div>share</div>
                </div>
                <div className={classes.bottomIconBox}>
                  <IoIosSend size={19} className={classes.iconBottom} />
                  <div>send</div>
                </div>
              </div>
            </div>
          </>
        );
    }
  };
  return lkdCardtype(props.cardCase);
};

export default LinkedinCard;
