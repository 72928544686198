import { PencilSquareIcon, TrashIcon, XCircleIcon } from '@heroicons/react/20/solid'
import Cookies from 'js-cookie';
import React from 'react'

const PostCard = (props) => {

  const contextValue = Cookies.get("UserType")
  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };
  const timeHandler = (date) => {
    const dateObj = new Date(date);
    var t = dateObj.getHours() + ":" + dateObj.getMinutes();
    let time = t.split(":");
    let temp = " AM";
    if (parseInt(time[0]) > 11) {
      time[0] = time[0] - 12 < 1 ? 12 : (time[0] -= 12);
      temp = " PM";
    } else {
      time[0] = time[0] < 1 ? 12 : time[0];
    }
    return time.join(":") + temp;
  };
  const data = props.dataType === "All" ? props.allData : props.data
  return (
    <div className="grid xl:grid-cols-2 mt-4  gap-4">
      {data.map((product) => (
        <div
          key={product.id}
          className="border-t border-b border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
        >
          <div className=" p-5  ">
            <div className="flex flex-col sm:flex-row items-start gap-2 sm:gap-4">
              <div className="aspect-w-1 h-full w-full sm:h-32 sm:w-32 aspect-h-1 flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none ">
                <img onClick={() => { props.setModal("save"); props.setSelectedImage(product.ImageUrl) }}
                  src={product.ImageUrl}
                  alt={product.OccasionName}
                  className="w-full h-full object-cover cursor-pointer object-center "
                />
              </div>
              <div className=''>
                <div className='flex justify-end'>
                </div>

                <div className='sm:line-clamp-4'>{product.Description}</div>
                <div className={`text-sm font-semibold text-${contextValue}-500`}>{dateHandler(product.ScheduleDate)} | {timeHandler(product.ScheduleDate)}</div>
                <div className='flex justify-end mt-2  gap-2'>
                  {/* <button type="button" className="text-gray-600 bg-white hover:bg-gray-100 border  border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-md text-sm px-5 py-1 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700  ">
 
 Edit
 <PencilSquareIcon className='h-4 text-gray-400 ml-1 w-4' />
</button> */}
                  <button type="button" onClick={() => { props.setModal("remove"); props.setPostId(product.SocialId) }} className="text-gray-600  bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-md text-sm px-5 py-1 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700  ">

                    Delete
                    <TrashIcon className='h-4 text-gray-400 ml-1 w-4' />
                  </button>
                </div>
              </div>

            </div>
          </div>


        </div>
      ))}
    </div>
  )
}

export default PostCard