import React, { useRef, useState } from "react";
import Picker from 'emoji-picker-react';
import {BsEmojiSmile} from 'react-icons/bs'
import useOnClickOutside from "../useOnClickOutside";
import { GrammarlyButton, GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
const Input = (props) => {
const [showPicker,setShowPicker]=useState(false)
const inputRef = useRef(null);
const onButtonClick = () => {
  inputRef.current.click();
};
const EmojiRef = useRef(null);
// useOnClickOutside(EmojiRef, () => {
//   setShowPicker(false);
  
// });
  const inputType = (type) => {
    switch (type) {
      case "textarea":
        return (
          <div >
            <label
              htmlFor={props.id}
              className="block text-sm font-medium text-gray-700"
            >
              {props.label}
            </label>
            <div     className={`block w-full border-gray-300  border  border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm 
                ${
                  props.error
                    ? "border-red-500 focus:ring-orange-500"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                }`}>
                     <GrammarlyEditorPlugin clientId="client_9whpu7FRksRZsVptcV1QKZ">
              <textarea
                onClick={()=>setShowPicker(false)}
                rows={props.rows ? props.rows : 4}
                name="comment"
                id="comment"
            className={"w-full resize-none p-2  outline-none"}
                defaultValue={""}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
              /></GrammarlyEditorPlugin>
            
              <div  className="w-full flex justify-end bg-gray-50 p-1 " ref={EmojiRef} >  <div className="mr-2">  <GrammarlyButton></GrammarlyButton> </div> <BsEmojiSmile onClick={()=>setShowPicker(!showPicker)} size={26} className=" text-right cursor-pointer hover:text-yellow-300  text-yellow-500 " /></div>
              {showPicker&&<div className="text-right w-full flex justify-end" ref={EmojiRef}> <Picker  onEmojiClick={props.addEmoji} /></div>}
            </div>
          </div>
        );
        case "file":
            return (
                <form id="file-upload" className={props.dragActive?"bg-slate-100":""} onDragEnter={props.handleDrag} onSubmit={(e) => e.preventDefault()}>
                  
                    <label htmlFor="file-upload" onClick={onButtonClick} onDragEnter={props.handleDrag} onDragLeave={props.handleDrag} onDragOver={props.handleDrag} onDrop={props.handleDrop} className={`block w-full py-4 mt-2 border-gray-300 p-2 border outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm 
                ${
                  props.error
                    ? "border-red-500 focus:ring-orange-500"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                }`}>
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm justify-center text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span className="text-center">Upload a file</span>
                            <input id="file-upload" multiple={props.multiple} ref={inputRef}  onChange={props.onChange} name="file-upload" type="file" className="sr-only" />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, MP4, GIF, MOV up to 10MB</p>
                      </div>
                    </label>
                  </form>
            );

      case "input with label":
        return (
          <div>
            <label
              htmlFor={props.label}
              className="block text-sm font-medium text-gray-700 "
            >
              {props.label}
            </label>
            <div className="mt-1">
              <input
                type={props.inputType ? props.inputType : "email"}
                id={props.label}
                accept={props.accept}
                readOnly={props.readOnly}
                defaultValue={props.defaultValue}
                className={`block w-full rounded-md border-gray-300 p-2 border 
                outline-none 
                  sm:text-sm ${
                    props.error
                      ? "border-red-500 focus:ring-orange-500"
                      : "focus:border-indigo-500 focus:ring-indigo-500"
                  }`}
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                onInput={props.onInput}
                maxLength={props.maxLength}
                min={props.min}
                onKeyDown={props.onKeyDown}
              />
            </div>
          </div>
        );
        case "radio group":
            return (
              <div>
      
                <fieldset className="mt-2">
                  {/* <legend className="sr-only">Notification method</legend> */}
                 <div          className={`block w-full border-gray-300 p-2 border outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm 
                ${
                  props.error
                    ? "border-red-500 focus:ring-orange-500"
                    : "focus:border-indigo-500 focus:ring-indigo-500"
                }`}>
                 <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                    {props.data?.map((item) => (
                      <div key={item.id} className="flex items-center">
                        <input
                          id={item.title}
                          onChange={props.onChange}
                          name={item.title}
                          type="radio"
                          value={item.title}
                          checked={props.value === item.title}
                          onClick={() => props.setState(item.title)}
                          className="h-4 w-4 border-gray-300 text-indigo-600 cursor-pointer focus:ring-indigo-500"
                        />
                        <label
                          htmlFor={item.title}
                          className="ml-3 block text-sm cursor-pointer font-medium text-gray-700"
                        >
                          {item.title}
                        </label>
                      </div>
                    ))}
                  </div>
                 </div>
                </fieldset>
              </div>
            );
      default:
        console.log("Invalid type");
        break;
    }
  };
  return <div>{inputType(props.type)}</div>;
};

export default Input;
