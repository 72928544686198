import React,{useState} from 'react';
import classes from './PasswordScreen.module.css';
import {useNavigate} from "react-router-dom";
import OPInput from '../../../Components/OPInput';
import ActionButton from '../../../Components/ActionButton';
import Modal from "../../../Components/Modal";

const PasswordScreen = (props) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = React.useState({});
  const [modal, setModal] = useState("");
  const [loginFailed, setLoginFailed] = useState("");
  const email=localStorage.getItem('Email');
  const navigate = useNavigate()
  const formValidation = () => {
    let errors = {};
    let isValid = true;
    if (!password || password === "") {
      errors.password = "Please enter password";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };
  const login =  () => {
    setLoginFailed("")
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Password: password,
      Email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

     fetch("https://promote.onecorp.co.in/api/v1/SASLogin", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status === "SUCCESS" &&
          result.status_code === 200
        ) {
          localStorage.setItem("FirstName", result.data.FirstName);
          localStorage.setItem("LastName", result.data.LastName);
          localStorage.setItem("Mobile", result.data.Mobile);
          localStorage.setItem("userName", result.data.UserName);
          localStorage.setItem("Email", result.data.Email);
          localStorage.setItem("ProfilePicture", result.data.ProfilePicture);
          localStorage.setItem("UserId", result.data.UserId);
          localStorage.setItem("LoggedIn", true);
          sessionStorage.setItem("UserToken", result.data.UserToken);
          localStorage.setItem("UserToken", result.data.UserToken);
          sessionStorage.setItem("FirstName", result.data.FirstName);
          sessionStorage.setItem("LastName", result.data.LastName);
          sessionStorage.setItem("Mobile", result.data.Mobile);
          sessionStorage.setItem("userName", result.data.UserName);
          sessionStorage.setItem("Email", result.data.Email);
          sessionStorage.setItem("UserId", result.data.UserId);
          navigate("/signup-step-two");
        } else if (
          result.status === "FAILED" &&
          result.message === "Email or Password does not match"
        ) {
          setLoginFailed("Please enter correct password ");
        }
        else{
          setModal("apiFailed")
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  return (
    <div className={classes.mainContainer}>
              {modal === "apiFailed" && (
        <Modal
        modalCase={"confirmation"}
        Text={"There is some issue , please contact support!"}
        setShowModal={setModal}
        iscancel={false}
        onClose={() => setModal("")}
        onOk={() => setModal("")}
      />
      )}
      <div className={classes.logoContainer}>
        <img src={"/assets/One_post.svg"} width={props.device === "Mobile" ? "50%" : "15%"} />
      </div>
      <div className={classes.inputSectionContainer}>
        <div className={classes.headImage}>
          <img src={"/assets/UserRegistrationImages/Password.svg"} className="m-auto" width={"40px"} />
          <div className={classes.headingTextFirst}>Start your free one post trial</div>
        
          <div className={classes.inputContainer}>
          
            <OPInput
              input={"passwordLoginType"}
              type={"tel"}
              label={"Password"}
              onInput={() => {
                error.password = ""; setLoginFailed("")
              }}
              onChange={(e)=>setPassword(e.target.value)}
              value={password}
              placeholder={"Enter your password"}
            />
                  <div className={classes.errors}> {error.password} <span>{loginFailed}</span></div>
                  {/* <div className={classes.errors}>{loginFailed}</div> */}
             <div className={classes.loginWithOtp} onClick={()=>{navigate("/login-with-otp-email");localStorage.removeItem('redirectToDashboard')}}>Login with OTP</div>
          </div>
         
          <div className={classes.btnNext}>
            <ActionButton loading={loading}   text={"Next"} btnType={"circular"} onClick={()=>formValidation()? login(): console.log("something went wrong")}/>
            <div className={classes.forgetPassword} onClick={()=> {navigate("/forgot-password-email"); localStorage.removeItem('forgotPassword')}}>Forgot Password</div>
          </div>
          <div className={classes.sasOneText}>SAS ONE</div>
        </div>
      </div>
    </div>
  )
}

export default PasswordScreen