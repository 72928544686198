import React, { useState } from 'react';
import classes from './ForgetPasswordScreen.module.css';
import { useNavigate } from "react-router-dom";
import ActionButton from '../../../Components/ActionButton';
import OPInput from '../../../Components/OPInput';
import { TailSpin } from "react-loader-spinner";
import Modal from "../../../Components/Modal";
import Cookies from 'js-cookie';
const ForgetPasswordScreen = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [password, setPassword] = useState("");
  const [incorrectOTP, setIncorectOTP] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [modal, setModal] = useState("");
  const [resendOTPMesaage, setResendOTPMesaage] = useState("");
  const email = localStorage.getItem('Email');
  const forgotPassword = localStorage.getItem('forgotPassword');


  const navigate = useNavigate()
  const resetPassword = async () => {
    setIncorectOTP("")
    setLoading("reset")
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "Email": email,
      "Otp": otp,
      "Password": password,
      "ConfirmPassword": confirmPassword
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://promote.onecorp.co.in/api/v1/Ai/SASForgotPassword",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message === "Password change successfully" &&
          result.status === "SUCCESS"
        ) {
          // localStorage.setItem("FirstName", result.data.FirstName);
          // localStorage.setItem("LastName", result.data.LastName);
          // localStorage.setItem("Mobile", result.data.Mobile);
          // localStorage.setItem("userName", result.data.UserName);
          // localStorage.setItem("Email", result.data.Email);
          // localStorage.setItem("ProfilePicture", result.data.ProfilePicture);
          // localStorage.setItem("UserId", result.data.UserId);
          // localStorage.setItem("LoggedIn", true);
          // sessionStorage.setItem("UserToken", result.data.UserToken);
          // localStorage.setItem("UserToken", result.data.UserToken);
          // sessionStorage.setItem("FirstName", result.data.FirstName);
          // sessionStorage.setItem("LastName", result.data.LastName);
          // sessionStorage.setItem("Mobile", result.data.Mobile);
          // sessionStorage.setItem("userName", result.data.UserName);
          // sessionStorage.setItem("Email", result.data.Email);
          // sessionStorage.setItem("UserId", result.data.UserId);

          Cookies.set("LoggedIn", { domain: '.sasone.in' });
          Cookies.set("UserToken", result.data.UserToken, { domain: '.sasone.in' });
          Cookies.set("FirstName", result.data.FirstName, { domain: '.sasone.in' });
          Cookies.set("LastName", result.data.LastName, { domain: '.sasone.in' });
          Cookies.set("Mobile", result.data.Mobile, { domain: '.sasone.in' });
          Cookies.set("userName", result.data.UserName, { domain: '.sasone.in' });
          Cookies.set("Email", result.data.Email, { domain: '.sasone.in' });
          Cookies.set("UserId", result.data.UserId, { domain: '.sasone.in' });
          Cookies.set("ProfilePicture", result.data.ProfilePicture, { domain: '.sasone.in' });
          Cookies.set("LoginType", "Email", { domain: '.sasone.in' });

          // Cookies.set("LoggedIn");
          // Cookies.set("UserToken", result.data.UserToken);
          // Cookies.set("FirstName", result.data.FirstName);
          // Cookies.set("LastName", result.data.LastName);
          // Cookies.set("Mobile", result.data.Mobile);
          // Cookies.set("userName", result.data.UserName);
          // Cookies.set("Email", result.data.Email);
          // Cookies.set("UserId", result.data.UserId);
          // Cookies.set("ProfilePicture", result.data.ProfilePicture);
          // Cookies.set("LoginType", "Email");
          navigate(forgotPassword ? "/" : "/signup-step-two");
          localStorage.removeItem('forgotPassword')
        }
        if (
          result.status === "FAILED" &&
          result.message === "Please Enter Correct OTP"
        ) {
          setIncorectOTP("Please enter correct OTP");
        }
        else {
          setModal("apiFailed")
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };
  const resentOTP = async () => {
    setIncorectOTP("")
    setLoading("resend");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://promote.onecorp.co.in/api/v1/Ai/SASResendOtp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status === "SUCCESS" &&
          result.message === "OTP sent successfully"
        ) {
          setResendOTPMesaage(result.message);
          window.scrollTo(0, 0)
        }
        else {
          setModal("apiFailed")
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };
  const successMessage = () => {

    setTimeout(() => {
      setResendOTPMesaage("");
    }, 2000);
    return resendOTPMesaage;
  };
  const formValidation = () => {
    let errors = {};
    let isValid = true;


    if (!password || password === "") {
      errors.password = "Please enter password";
      isValid = false;
    }
    if (!confirmPassword || confirmPassword === "" || confirmPassword !== password) {
      errors.confirmPassword = "password and confirm password should be matched";
      isValid = false;
    }
    if (password.length < 6 && password.length > 0) {
      errors.password = "Password must be of 6 characters atleast";
      isValid = false;
    }
    if (!otp || otp === "") {
      errors.otp = "Please enter otp";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  return (
    <div className={classes.mainContainer}>
      {resendOTPMesaage ?
        <div className={classes.successMessage}>{successMessage()}</div>
        :
        <></>
      }
      <div className={classes.logoContainer}>

        {modal === "apiFailed" && (
          <Modal
            modalCase={"confirmation"}
            Text={"There is some issue , please contact support!"}
            setShowModal={setModal}
            iscancel={false}
            onClose={() => setModal("")}
            onOk={() => setModal("")}
          />
        )}
        <img src={"/assets/One_post.svg"} width={props.device === "Mobile" ? "50%" : "15%"} />
      </div>
      <div className={classes.inputSectionContainer}>
        <div className={classes.headImage}>
          <img src={"/assets/UserRegistrationImages/Forget.svg"} className="m-auto" width={"35px"} />
          <div className={classes.headingTextFirst}>Forgot password</div>

          <div className={classes.otpsent}>OTP has been sent to  {email}</div>
          <div className={classes.newpasswordContainer}><div className={classes.outer_otp_div}>
            <div className={classes.otp_div}>
              <input
                type="number"
                className={classes.otp_input}
                required
                maxLength="4"
                onInput={() => {
                  error.otp = ""; setIncorectOTP("")
                }}
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
              />
              <div className={classes.errors}> {error.otp}<span>{incorrectOTP}</span></div>
            </div>

          </div>
            <OPInput
              input={"passwordLoginTypeConfirm"}
              type={"text"}
              autoComplete="current-password"
              label={"New password"}
              placeholder={"Enter your new password"}
              onInput={() => {
                error.password = "";
              }}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <div className={classes.errors}> {error.password}</div>
            <OPInput
              input={"passwordLoginTypeConfirm"}
              type={"text"}
              label={"Confirm password"}
              placeholder={"Enter your confirm password"}
              onInput={() => {
                error.confirmPassword = "";
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
            <div className={classes.errors}> {error.confirmPassword}</div>
            <div className={classes.btnNext}>
              <ActionButton text={"Next"} btnType={"circular"} loading={loading === "reset" ? true : false} onClick={() => formValidation() ? resetPassword() : console.log("something went wrong")} />
              <div className={classes.resendCodeLoader}>
                {" "}
                {loading === "resend" ? (
                  <TailSpin color="#ef5122" width={20} height={18} />
                ) : (
                  <div className={classes.resendCode} onClick={() => resentOTP()}>
                    Resend OTP
                  </div>
                )}
              </div>
            </div></div>
          <div className={classes.sasOneText}>SAS ONE</div>
        </div>


      </div>

    </div>
  )
}

export default ForgetPasswordScreen