import { BsInstagram } from "react-icons/bs";
import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import classes from '../../Screens/custom.module.css'
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
import Cookies from "js-cookie";
const tabs = [
  { name: "common", type: "Content", social: "all", current: false },
  {
    name: "Instagram preview",
    type: "Instagram",
    social: "instagram",
    current: true,
  },
  {
    name: "Facebook preview",
    type: "Facebook",
    social: "facebook",
    current: false,
  },
  {
    name: "Linkedin preview",
    type: "Linkedin",
    social: "linkedin",
    current: false,
  },
  {
    name: "Twitter preview",
    type: "Twitter",
    social: "twitter",
    current: false,
  },
  {
    name: "Youtube preview",
    type: "Youtube",
    social: "youtube",
    current: false,
  },
  {
    name: "Google My Bussiness preview",
    type: "Google My Bussiness",
    social: "google",
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = (props) => {
  const contextValue = Cookies.get("UserType")
  const IconSmall = (type) => {
    if (type === "facebook") {
      return (
        <div className="bg-fb rounded-full flex w-4 h-4 my-1  justify-center mr-1  items-center p-1 ">
          {" "}
          <FaFacebookF size={8} color={"white"} />
        </div>
      );
    }
    if (type === "instagram") {
      return (
        <div className={classes.instgramMedium}>
          {" "}
          <BsInstagram size={8} color={"#fff"} />
        </div>
      );
    }
    if (type === "twitter") {
      return (
        <div className="bg-twitter rounded-full flex w-4 h-4  my-1  justify-center mr-1   items-center p-1">
          {" "}
          <FaTwitter size={8} color={"#fff"} />
        </div>
      );
    }
    if (type === "youtube") {
      return (

        <img src="../assets/ytb.webp" className="w-24 my-1" />

      );
    }
    if (type.includes("linkedin")) {
      return (
        <div className="bg-linkedIn rounded-full flex w-4 h-4  my-1  justify-center mr-1   items-center p-1">
          {" "}
          <FaLinkedinIn size={8} color={"#fff"} />
        </div>
      );
    }
    if (type.includes("google")) {
      return (
        <img src="./assets/gmbLogo.png" className="w-4 mr-1" />
      );
    }
  };

  const Icon = (type) => {
    if (type === "facebook") {
      return (
        <div className="bg-fb rounded-full flex w-5 h-5 my-1  justify-center mr-1  items-center p-1 ">
          {" "}
          <FaFacebookF size={12} color={"white"} />
        </div>
      );
    }
    if (type === "instagram") {
      return (
        <div className={classes.instgramIcon}>
          {" "}
          <BsInstagram size={12} color={"#fff"} />
        </div>
      );
    }
    if (type === "twitter") {
      return (
        <div className="bg-twitter rounded-full flex w-5 h-5  my-1  justify-center mr-1   items-center p-1">
          {" "}
          <FaTwitter size={12} color={"#fff"} />
        </div>
      );
    }
    if (type === "youtube") {
      return (
        <img src="../assets/ytb.webp" className="w-28 py-1.5 " />
      );
    }
    if (type.includes("linkedin")) {
      return (
        <div className="bg-linkedIn rounded-full flex w-5 h-5  my-1  justify-center mr-1   items-center p-1">
          {" "}
          <FaLinkedinIn size={12} color={"#fff"} />
        </div>
      );
    }
  };
  const tabType = (type) => {
    switch (type) {
      case "preview":
        return (
          <div className="mt-2">
            <div >
              <nav
                className="isolate flex divide-x border border-b-0 divide-gray-200  shadow"
                aria-label="Tabs"
              >
                {tabs.map((tab, tabIdx) => {
                  return (
                    (props.selectedId.findIndex((item) =>
                      item.value.includes(tab.social)
                    ) !== -1 || (props.checked?.toString()?.includes(tab.social))) && (
                      <a
                        key={tab.name}
                        onClick={() => { props.setContentType(tab.name); props.getData(tab.name) }}
                        href={tab.href}
                        className={classNames(
                          props.contentType === tab.name,

                          "group relative min-w-0 flex-1 cursor-pointer  overflow-hidden bg-white py-2 px-2 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        <span className="flex items-center justify-center">
                          {" "}
                          <div> {Icon(tab.social)} </div>
                        </span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            props.contentType === tab.name
                              ? `bg-${contextValue}-500`
                              : "bg-transparent",
                            "absolute inset-x-0 bottom-0 h-0.5"
                          )}
                        />
                      </a>
                    )
                  );
                })}
              </nav>
            </div>
          </div>
        )
      case "analytics":
        return (
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>


              <select
                id="tabs"
                onChange={(e) => props.setType(e.target.value)}
                name="tabs"
                className={`block w-full border-gray-300 p-2 border focus:border-${contextValue}-500 focus:ring-${contextValue}-500`}
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => {
                  return (
                    tab.name !== "common" && <option key={tab.name} value={tab.social}>
                      {tab.type}
                    </option>)
                }
                )}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex" aria-label="Tabs">
                  {tabs.map((tab) => {
                    return (
                      (tab.name !== "common" ) &&
                      <a
                        key={tab.name}
                        onClick={() => props.setType(tab.social)}
                        href={tab.href}
                        className={classNames(
                          props.type === tab.social
                            ? `border-${contextValue}-500 text-${contextValue}-600`
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'w-1/4 py-4 px-1 text-center border-b-2 cursor-pointer font-medium text-sm'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                      >
                        <span className="flex items-center justify-center">
                          {" "}
                          <div> {IconSmall(tab.social)} </div> <div>{tab.type === "Youtube" ? "" : tab.type}</div>
                        </span>
                      </a>
                    )


                  })}
                </nav>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div className="mt-2">
            <div className="sm:hidden ">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>

              <select
                id="tabs"
                onChange={(e) => props.setContentType(e.target.value)}
                name="tabs"
                className={`block w-full border-gray-300 p-2 border focus:border-${contextValue}-500 focus:ring-${contextValue}-500`}
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.name}>
                    {tab.type}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav
                className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                aria-label="Tabs"
              >
                {tabs.slice(0, 1).map((tab, tabIdx) => {
                  return (
                    <a
                      key={tab.name}
                      onClick={() => props.setContentType(tab.name)}
                      href={tab.href}
                      className={classNames(
                        props.contentType === tab.name,

                        "group relative min-w-0 flex-1 border border-b-0 cursor-pointer overflow-hidden bg-white py-2 px-2 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      <span>{tab.type}</span>
                      <span
                        aria-hidden="true"
                        className={classNames(
                          props.contentType === tab.name
                            ? `bg-{contextValue}-500`
                            : "bg-transparent",
                          "absolute inset-x-0 bottom-0 h-0.5"
                        )}
                      />
                    </a>
                  );
                })}
                {tabs.map((tab, tabIdx) => {
                  return (
                    (props.selectedId.findIndex((item) =>
                      item.value.includes(tab.social)
                    ) !== -1 || (props.checked?.toString()?.includes(tab.social))) && (
                      <a
                        key={tab.name}
                        onClick={() => props.setContentType(tab.name)}
                        href={tab.href}
                        className={classNames(
                          props.contentType === tab.name,

                          "group relative min-w-0 flex-1 cursor-pointer border border-b-0 overflow-hidden bg-white py-2 px-2 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        <span className="flex items-center justify-center">
                          {" "}
                          <div> {IconSmall(tab.social)} </div> <div>{tab.type === "Youtube" ? "" : tab.type}</div>
                        </span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            props.contentType === tab.name
                              ? `bg-${contextValue}-500`
                              : "bg-transparent",
                            "absolute inset-x-0 bottom-0 h-0.5"
                          )}
                        />
                      </a>
                    )
                  );
                })}
              </nav>
            </div>
          </div>
        );
    }
  };
  return <div>{tabType(props.tab)}</div>;
};
export default Tabs;
