import React from "react";
import classes from "./ActionButton.module.css";
import { TailSpin } from "react-loader-spinner";
const ActionButton = (props) => {
  const buttonType = (type) => {
    switch (type) {
      case "smallButton":
        return (
          <div>
            <button
              type="submit"
              className={classes.smallButton}
              onClick={props.onClick}
            >
              {props.loading ? (
                <TailSpin color="#5a9cb6" width={20} height={18} />
              ) : (
                props.text
              )}
            </button>
          </div>
        );
      case "whiteButton":
        return (
          <div>
            <button
              type="submit"
              className={classes.whiteButton}
              onClick={props.onClick}
            >
              {props.loading ? (
                <TailSpin color="#5a9cb6" width={20} height={18} />
              ) : (
                props.text
              )}
            </button>
          </div>
        );
      case "circular":
        return (
          <div>
            <button
              type="submit"
              className={classes.smallButtonOrange}
              onClick={props.onClick}
            >
              {props.loading ? (
                <TailSpin color="#5a9cb6" width={20} height={18} />
              ) : (
                props.text
              )}
            </button>
          </div>
        );
      case "OrangeFilled":
        return (
          <div>
            <button
              type="submit"
              className={props.IsEmailVerified==="no"||props.trialexpire==="yes"?classes.orangeFilledD:classes.orangeFilled}
              onClick={props.onClick}
            >
              {props.loading ? (
                <TailSpin color="#5a9cb6" width={20} height={18} />
              ) : (
                props.text
              )}
            </button>
          </div>
        );
        case "GreenFilled":
          return (
            <div>
              <button
                type="submit"
                className={classes.greenFilled}
                onClick={props.onClick}
              >
                {props.loading ? (
                  <TailSpin color="#5a9cb6" width={20} height={18} />
                ) : (
                  props.text
                )}
              </button>
            </div>
          );
        case "OrangeOutlined":
          return (
            <div>
              <button
                type="submit"
                className={props.IsEmailVerified==="no"||props.trialexpire==="yes"?classes.orangeOutlinedD:classes.orangeOutlined}
                onClick={props.onClick}
              >
                {props.loading ? (
                  <TailSpin color="#5a9cb6" width={20} height={18} />
                ) : (
                  props.text
                )}
              </button>
            </div>
          );
        
        default:
        return (
          <div>
            <button
              type="submit"
              className={classes.button}
              onClick={props.onClick}
            >
              {props.loading ? (
                <TailSpin color="#5a9cb6" width={20} height={18} />
              ) : (
                props.text
              )}
            </button>
          </div>
        );
    }
  };
  return <div>{buttonType(props.btnType)}</div>;
};

export default ActionButton;
