import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'

const items = [
  { name: 'Publish Now', href: '#' },
  { name: 'Schedule Post', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CreatePostButton = (props) => {
  const [type,setType]=useState("Publish Now");
  const navigate=useNavigate()
  return (
    <div className="inline-flex rounded-md shadow-sm mt-4">
      <button onClick={()=>navigate(props.data.length>0?"/create-post-new":"/linked-accounts/instagram")}
        type="button"
        className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-green-500 text-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-green-400  "
      >
       {type}
      </button>
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button className="relative inline-flex items-center rounded-r-md border  bg-green-500 text-white border-gray-300 hover:bg-green-400 px-2 py-2 text-sm ">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute  -mx-28 z-10 mt-2  w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1 ">
              {items.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <a href={props.data.length>0?"/create-post-new":"/linked-accounts/instagram/"}
                    onClick={()=>setType(item.name)}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                    >
                      {item.name}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
export default CreatePostButton