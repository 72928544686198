
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

const TwitterTestingModal = (props) => {
    return (
        <Transition.Root show={props.modal} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={props.setModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <form className='p-4' onSubmit={props.onSubmit}>
                                    <div className='text-lg font-semibold border-b mb-3 pb-1'>Enter code</div>
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter your valid code</label>
                                    <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter code" required
                                        value={props.value} onChange={props.onChange} />
                                       {/* {props.msg&& <div className='text-red-500 text-sm'>{props.error},please <span className='text-indigo-500 cursor-pointer' onClick={props.onClick}>Retry</span></div>} */}
                                       <div>{props.erroe&&props.error}</div>
                                    <button type='submit' className='bg-indigo-500 w-[20%] text-center text-white rounded mt-5 py-1.5 px-2 hover:bg-indigo-700' >Submit</button>
                                </form>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default TwitterTestingModal
