import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { TrashIcon, ExclamationIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Cookies from 'js-cookie';


const CreativeDelieveryModal = (props) => {


  const cancelButtonRef = useRef(null)

  const Email = localStorage.getItem("email")
  const Name = localStorage.getItem("name")
  const ProfilePicture = localStorage.getItem("imageUrl")
  const contextValue = Cookies.get("UserType")
        return (
          <Transition.Root
            show={props.open}
            as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef}
              onClose={props.setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex h-3/4 items-end justify-center p-4 text-center sm:items-center sm:p-0 sm:h-full">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                      <h2 className='pt-4 pb-2 px-6 text-2xl border-b-2'>Generate Creative Request</h2>
                      <form onSubmit={(e)=>{e.preventDefault();props.onClick();props.setOpen()}}>
                      <div className="flex items-start flex-col gap-3 bg-white px-6 pb-6 pt-4 w-full">
                        <div className="">
                          Creative Request
                        </div>
                        
                          <textarea required className='border w-full p-3' type="text" value={props.value} onChange={props.onChange}  rows={4}/>
                        
                      </div>
                      <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button
                          type="submit"
                          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-${contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                          
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className={`mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm`}
                          onClick={props.onClose}
                          ref={cancelButtonRef}
                        >
                          Cancel
                        </button>
                        
                      </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        )
     
        // return (
        //   <>
        //     <Transition.Root show={props.open} as={Fragment}>
        //       <Dialog
        //         as="div"
        //         className="relative z-10"
        //         onClose={props.setOpen}
        //       >
        //         <Transition.Child
        //           as={Fragment}
        //           enter="ease-out duration-300"
        //           enterFrom="opacity-0"
        //           enterTo="opacity-100"
        //           leave="ease-in duration-200"
        //           leaveFrom="opacity-100"
        //           leaveTo="opacity-0"
        //         >
        //           <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        //         </Transition.Child>

        //         <div className="fixed inset-0 z-10 overflow-y-auto">
        //           <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        //             <Transition.Child
        //               as={Fragment}
        //               enter="ease-out duration-300"
        //               enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        //               enterTo="opacity-100 translate-y-0 sm:scale-100"
        //               leave="ease-in duration-200"
        //               leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        //               leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        //             >
        //               <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        //                 <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        //                   <div className="sm:flex sm:items-start">
        //                     <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        //                       <ExclamationTriangleIcon
        //                         className="h-6 w-6 text-red-600"
        //                         aria-hidden="true"
        //                       />
        //                     </div>
        //                     <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        //                       <Dialog.Title
        //                         as="h3"
        //                         className="text-lg font-medium leading-6 text-gray-900"
        //                       >
        //                         {props.title}
        //                       </Dialog.Title>
        //                       <div className="mt-2">
        //                         <p className="text-sm text-gray-500">
        //                           {props.message}
        //                         </p>
        //                       </div>
        //                     </div>
        //                   </div>
        //                 </div>
        //                 <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        //                   <button
        //                     type="button"
        //                     className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        //                     onClick={() => {
        //                       props.deleteFunc();
        //                       props.setOpen(false);
        //                     }}
        //                   >
        //                     Delete
        //                   </button>
        //                   <button
        //                     type="button"
        //                     className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        //                     onClick={() => props.setOpen(false)}
        //                   >
        //                     Cancel
        //                   </button>
        //                 </div>
        //               </Dialog.Panel>
        //             </Transition.Child>
        //           </div>
        //         </div>
        //       </Dialog>
        //     </Transition.Root>
        //   </>
        // );

}
export default CreativeDelieveryModal;