import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import classes from "./App.module.css";
import LoginNew from "./Screens/Registration/LoginNew";
import LoginStep from "./Screens/Registration/RegeitsrationProcessNew/LoginStep";
import SignupStep from "./Screens/Registration/RegeitsrationProcessNew/SignupStep";
import FinalStep from "./Screens/Registration/RegeitsrationProcessNew/FinalStep";
import PasswordScreen from "./Screens/Registration/RegeitsrationProcessNew/PasswordScreen";
import LoginWithOtpScreen from "./Screens/Registration/RegeitsrationProcessNew/LoginWithOtpScreen";
import ForgetPasswordScreen from "./Screens/Registration/RegeitsrationProcessNew/ForgetPasswordScreen";
import ForgetPasswordEmailScreen from "./Screens/Registration/RegeitsrationProcessNew/ForgetPasswordEmailScreen";
import LoginWithOtpEmailScreen from "./Screens/Registration/RegeitsrationProcessNew/LoginWithOtpEmailScreen";
import Dashboard from "./Screens/NewScreens/Dashboard";
import CreatePostNew from "./Screens/NewScreens/CreatePostNew";
import ScheduledPostsNew from "./Screens/NewScreens/ScheduledPostsNew";
import AnalyticsNew from "./Screens/NewScreens/AnalyticsNew";
import LinkedAccountsNew from "./Screens/NewScreens/LinkedAccountsNew";
import ContactSupport from "./Screens/NewScreens/ContactSupport";
import ScheulePost from "./Screens/NewScreens/ScheulePost";
import GoogleMyBussiness from "./Screens/NewScreens/GoogleMyBussiness";
import GMBTesting from "./Screens/NewScreens/GMBTesting";
import CreativeCalender from "./Screens/NewScreens/CreativeCalender";
import Cookies from "js-cookie";
import GMBCreatePost from "./Screens/NewScreens/GMBCreatePost";
import MediaTestingTwitter from "./Screens/NewScreens/MediaTestingTwitter";
import EditSchedulePost from "./Screens/NewScreens/EditSchedulePost";
import  CreativeRequestList  from "./Screens/NewScreens/CreativeRequestList";
import CreativeDelivereyList from "./Screens/NewScreens/CreativeDelivereyList";
import FacebookData from "./Screens/NewScreens/FacebookData";




const App = () => {
  
  
  const [width, setWidth] = useState(window.innerWidth);
  const { pathname } = useLocation();


  
  useEffect(() => {
    const windowWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", windowWidth);
    return () => {
      window.removeEventListener("resize", windowWidth);
    };
  }, []);
  const isLoggedIn =Cookies.get("LoggedIn");

  const PublicRedirect = () => {
    const navigate = useNavigate();
    useEffect(() => {
      if (!isLoggedIn) {
        navigate("/login");
      }
    }, []);
    return <></>;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return width > 1023 ? (
    <div className={classes.mainContainer}>
      <div className={classes.innerContainer}>
        <Routes>
        <Route path="/create-post-new"element={ <> <PublicRedirect /> <CreatePostNew /> </> } />
        <Route path="/update-schedule-post/:id"element={ <> <PublicRedirect /> <EditSchedulePost /> </> } />
        <Route path="/schedule-post-new"element={ <> <PublicRedirect /> <ScheduledPostsNew /> </> } />
        <Route path="/live-post-new"element={ <> <PublicRedirect /> <ScheduledPostsNew /> </> } />
        <Route path="/analytics-new"element={ <> <PublicRedirect /> <AnalyticsNew /> </> } />
        <Route path="/creative-calender"element={ <> <PublicRedirect /> <CreativeCalender /> </> } />
        <Route path="/gmb-create-post/:pageId/:locationId" element={<><GMBCreatePost /></>} />
          <Route path="/linked-accounts/:path" element={<> <PublicRedirect /><LinkedAccountsNew /></>} />
          <Route path="/contact-support" element={<> <PublicRedirect /><ContactSupport /></>} />
          <Route path="/calender" element={<><ScheulePost /></>} />
          <Route path="/google-my-bussiness" element={<><GoogleMyBussiness /></>} />
          <Route path="/gmb-testing" element={<><GMBTesting /></>} />
          <Route path="/" element={<Dashboard /> } />
          <Route path="/:id" element={<Dashboard /> } />
          <Route path="/login" element={<LoginNew />} />
          <Route path="/forgot-password" element={<ForgetPasswordScreen />} />
          <Route path="/login-with-otp-email" element={<LoginWithOtpEmailScreen />} />
          <Route path="/verify-email-otp" element={<LoginWithOtpEmailScreen />} />
          <Route path="/forgot-password-email" element={<ForgetPasswordEmailScreen />} />
        <Route path="/signup-step-one" element={<LoginStep />} />
        <Route path="/signup-step-two" element={<SignupStep  />} />
          <Route path="/signup-step-three/:trialId" element={<FinalStep  />} />
          <Route path="/password" element={<PasswordScreen  />} />
          <Route path="/login-with-otp" element={<LoginWithOtpScreen  />} />
          <Route path="/verify-email" element={<LoginWithOtpScreen  />} />
          <Route path="/twitterposting"  element={ <> <PublicRedirect /> <MediaTestingTwitter /> </> }/>
          <Route path="/creativerequestlist"  element={ <> <PublicRedirect /> <CreativeRequestList /> </> }/>
          <Route path="/creativedelivereylist"  element={ <> <PublicRedirect /> <CreativeDelivereyList /> </> }/>
           
 

              
        </Routes>
      </div>
    </div>
  ) : (
    <div className={classes.mbileWrapper}>
      <Routes>
        <Route path="/create-post-new"element={ <> <PublicRedirect /> <CreatePostNew /> </> } />
        <Route path="/update-schedule-post/:id"element={ <> <PublicRedirect /> <EditSchedulePost /> </> } />
        <Route path="/schedule-post-new"element={ <> <PublicRedirect /> <ScheduledPostsNew device={"Mobile"} /> </> } />
        <Route path="/live-post-new"element={ <> <PublicRedirect /> <ScheduledPostsNew  device={"Mobile"}/> </> } />
        <Route path="/analytics-new"element={ <> <PublicRedirect /> <AnalyticsNew /> </> } />
         <Route path="/" element={<> <PublicRedirect /><Dashboard /></>} />
         <Route path="/creative-calender"element={ <> <PublicRedirect /> <CreativeCalender /> </> } />
         <Route path="/gmb-testing" element={<><GMBTesting /></>} />
         <Route path="/calender" element={<><ScheulePost  /></>} />
         <Route path="/google-my-bussiness" element={<><GoogleMyBussiness /></>} />
         <Route path="/gmb-create-post/:pageId/:locationId" element={<><GMBCreatePost /></>} />

         <Route path="/contact-support" element={<> <PublicRedirect /><ContactSupport /></>} />
         <Route path="/:id" element={<> <PublicRedirect /><Dashboard /></>} />
        <Route path="/linked-accounts/:path" element={<> <PublicRedirect /><LinkedAccountsNew /></>} />
        <Route path="/login" element={<LoginNew device={"Mobile"} />} />
        <Route path="/forgot-password" element={<ForgetPasswordScreen  device={"Mobile"}/>} />
        <Route path="/login-with-otp-email" element={<LoginWithOtpEmailScreen  device={"Mobile"}/>} />
          <Route path="/forgot-password-email" element={<ForgetPasswordEmailScreen  device={"Mobile"} />} />
          <Route path="/signup-step-one" element={<LoginStep device={"Mobile"} />} />
          <Route path="/signup-step-two" element={<SignupStep device={"Mobile"} />} />
          <Route path="/signup-step-three/:trialId" element={<FinalStep device={"Mobile"} />} />
          <Route path="/password" element={<PasswordScreen   device={"Mobile"}/>} />
          <Route path="/login-with-otp" element={<LoginWithOtpScreen  device={"Mobile"}/>} />
          <Route path="/verify-email-otp" element={<LoginWithOtpEmailScreen />} />
          <Route path="/verify-email" element={<LoginWithOtpScreen  />} />
          <Route path="/creativerequestlist"  element={ <> <PublicRedirect /> <CreativeRequestList /> </> }/>
          <Route path="/creativedelivereylist"  element={ <> <PublicRedirect /> <CreativeDelivereyList /> </> }/>

      </Routes>
    </div>
  );
};

export default App;
