

import react, { Fragment, useState } from "react";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  ExclamationCircleIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import classes from '../Screens/custom.module.css'
import { BsInstagram} from "react-icons/bs";
import {  HiUserCircle } from "react-icons/hi";
import {  FcGoogle } from "react-icons/fc";


import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import NoDataComp from "./TailwindCompnents/NoDataComp";
import { AiFillYoutube } from "react-icons/ai";
import { useContext } from "react";
import ThemeContext from "../Context/ThemeContext"
import Cookies from "js-cookie";

const items = [
  {
    id: 1,
    name: "Text",
    description: "Add freeform text with basic formatting options.",
    url: "#",
    color: "bg-indigo-500",
    icon: PencilSquareIcon,
  },
  // More items...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SelectCredentials = (props) => {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(true);
  const contextValue = Cookies.get("UserType")
  const handleCheck = (value, pageId) => {

    if (pageId) {
      if (!props.pagesId.includes(pageId)) {
        props.setChecked([...props.checked, value]);
        props.setPagesId([...props.pagesId, pageId]);
        props.setSelectedId([
          ...props.selectedId,
          {
            id: pageId,
            value: value,
          },
        ]);
        if(value==="youtube"){
          props.setYtSocial([...props.ytSocial, value]);
          props.setYTPagesId([...props.ytPagesId, pageId]);
        }
     
      } else {
        props.setSelectedId(
          props.selectedId.filter((item) => item.id !== pageId)
        );
        props.setPagesId(props.pagesId.filter((item) => item !== pageId));
        props.setChecked(props.checked.filter((item) => item !== value));
        props.setYTPagesId(props.ytPagesId.filter((item) => item !== pageId));
        props.setYtSocial(props.ytSocial.filter((item) => item !== value));
     
      }
    } else {
      if (!props.checked.includes(value)) {
        props.setChecked([...props.checked, value]);
      } else {
        props.setChecked(props.checked.filter((item) => item !== value));
      }
    }

  };
  const Icon = (type) => {
    if (type === "facebook") {
      return (
        <div className="bg-fb rounded-full flex w-4 h-4   justify-center  items-center p-1 ">
          {" "}
          <FaFacebookF size={8} color={"white"} />
        </div>
      );
    }
    if (type === "instagram") {
      return (
        <div className={classes.instgramMedium}>
          {" "}
          <BsInstagram size={8} color={"#fff"} />
        </div>
      );
    }
    if (type === "twitter") {
      return (
        <div className="bg-twitter rounded-full flex w-4 h-4  justify-center  items-center p-1">
          {" "}
          <FaTwitter size={8} color={"#fff"} />
        </div>
      );
    }
    if (type === "youtube") {
      return (
        <div className="  flex bg-white rounded-full p-0.5  justify-center  items-center">
          {" "}
          <AiFillYoutube size={12} color={"white"} fill={"red"} />
        </div>
      );
    }
    if (type.includes("linkedin")) {
      return (
        <div className="bg-linkedIn rounded-full flex w-4 h-4  justify-center  items-center p-1">
          {" "}
          <FaLinkedinIn size={8} color={"#fff"} />
        </div>
      );
    }

  if (type==="gmb") {
    return (
      <div className="bg-white rounded-full flex w-5 h-5  justify-center  items-center p-1">
        {" "}
        <FcGoogle size={18} color={"#fff"} />
      </div>
    );
  }
};
  const IconSmall = (type) => {
    if (type === "facebook") {
      return (
        <div className="bg-fb rounded-full flex w-5 h-5 my-1  justify-center mr-1  items-center p-1 ">
          {" "}
          <FaFacebookF size={10} color={"white"} />
        </div>
      );
    }
    if (type === "instagram") {
      return (
        <div className={classes.instgramIcon}>
          {" "}
          <BsInstagram size={10} color={"#fff"} />
        </div>
      );
    }
    if (type === "twitter") {
      return (
        <div className="bg-twitter rounded-full flex w-5 h-5  my-1  justify-center mr-1   items-center p-1">
          {" "}
          <FaTwitter size={10} color={"#fff"} />
        </div>
      );
    }
    if (type === "youtube") {
      return (
        <div className="bg-white rounded-full flex w-5 h-5  my-1  justify-center mr-1   items-center p-1">
          {" "}
          <FaYoutube size={10} color={"red"} />
        </div>
      );
    }
    if (type.includes("linkedin")) {
      return (
        <div className="bg-linkedIn rounded-full flex w-5 h-5  my-1  justify-center mr-1   items-center p-1">
          {" "}
          <FaLinkedinIn size={10} color={"#fff"} />
        </div>
      );
    }
  };
  const removeAll = () => {
    props.setSelected(false);
    props.setChecked([]);
    props.setPagesId([]);
    props.setSelectedId([]);
    props.setYTPagesId([])
    props.setYtSocial([])
  };
  const HandleSelectAll = () => {
    props.setSelected(true);
    let pageName = [];
    let pageId = [];
    let selectedId = [];
    let ytSocial = [];
    let ytPagesId = [];

    props.credentials?.map((item) => {
      pageName.push(item.SocialMediaName);
      pageId.push(item.PageId);
      selectedId.push({
        id: item.PageId,
        value: item.SocialMediaName,
      });
      if(item.SocialMediaName==="youtube"){
        ytSocial.push(item.SocialMediaName);
        ytPagesId.push(item.PageId)
      }
    });
    props.setChecked(pageName);
    props.setPagesId(pageId);
    props.setSelectedId(selectedId);
    props.setYTPagesId(ytPagesId)
    props.setYtSocial(ytSocial)
  };
  const selectedHandler = (name, id) => {
    if (
      (name === "facebook" ||
        name === "instagram" ||
        name === "linkedin" ||
        name === "twitter" ||
        name === "linkedinorg" ||
        name === "youtube") &&
      props.pagesId.includes(id)
    ) {
      return true;
    }
    else {
      return false;
    }
  };
  const filteredItems =
    query === ""
      ? props.credentials
      : props.credentials?.filter((item) => {
          return item?.PageName?.toLowerCase()?.includes(query?.toLowerCase()) ||item?.SocialMediaName?.toLowerCase()?.includes(query?.toLowerCase());
        });
  return (
    <>
      {" "}
      <div show={open} as={Fragment} afterLeave={() => setQuery("")} appear id={props.id}>
        <div className={`block w-full mt-2 border-gray-300  p-2 border outline-none border-gray-400shadow-sm focus:border-${contextValue}-500 focus:ring-${contextValue}-500 sm:text-sm`}>
         {  props.credentials.length>0?   <Combobox>
            <div className="relative">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Combobox.Input
                className="h-12 w-full border-0 bg-transparent outline-none pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>

            <Combobox.Options
              static
              className="max-h-96 scroll-py-3 overflow-y-auto p-3 pb-0"
            >
            {filteredItems.length>0&&  <div>
                <Combobox.Option
                  className={() =>
                    classNames(
                      " select-none rounded-xl p-3 hover:bg-gray-100  cursor-pointer"
                    )
                  }
                >
                  <label
                    htmlFor="selectAll"
                    className="flex cursor-pointer  items-center "
                  >
                    <input
                      type="checkbox"
                      id="selectAll"
                      checked={props.selected}
                      onChange={() =>
                        props.selected ? removeAll() : HandleSelectAll()
                      }
                    />    <span
                    className={classNames(
                      "text-sm font-medium ml-3 text-gray-700"
                    )}
                  >
                    Select All
                  </span>
                  </label>
                </Combobox.Option>
              </div>}
            </Combobox.Options>
            {filteredItems?.length > 0 && (
              <Combobox.Options
                static
                className="max-h-96 scroll-py-3 overflow-y-auto p-3"
              >
                {filteredItems.sort((a,b)=>a.SocialMediaName?.localeCompare(b.SocialMediaName)).map((item, index) =>{
                  if(item.SocialMediaName!=="gmb"){
                    return <div
                    onClick={() =>
                      handleCheck(item.SocialMediaName, item.PageId)
                    }
                  >
                    <Combobox.Option
                      key={index}
                      className={() =>
                        classNames(
                          " select-none rounded-xl p-3 hover:bg-gray-100  cursor-pointer"
                        )
                      }
                    >
                      <label
                        htmlFor="select"
                        className="flex cursor-pointer  items-center "
                      >
                        <input
                          className="mr-3"
                          id="slecet"
                          type="checkbox"
                          
                          onChange={() =>
                            {handleCheck(item.SocialMediaName, item.PageId)}
                          }
                          checked={selectedHandler(
                            item.SocialMediaName,
                            item.PageId
                          )}
                          value={item.SocialMediaName}
                        />

                        <>
                          <div
                            className={classNames(
                              "flex h-10  items-center justify-center rounded-lg"
                            )}
                          >
                         {item.ProfileImage?  <> <img
                              className="rounded-full"
                              src={item.ProfileImage}
                            />       <div className="-ml-3 mt-5">
                            {" "}
                            {Icon(item.SocialMediaName)}
                          </div></>:<><HiUserCircle size={46} className=" rounded-full "/>       <div className="-ml-4 mt-5">
                              {" "}
                              {Icon(item.SocialMediaName)}
                            </div></>}
                     
                          </div>
                          <div className="ml-4  flex-auto">
                            <p
                              className={classNames(
                                "text-sm font-medium",
                                "text-gray-700"
                              )}
                            >
                              {item.PageName}
                            </p>
                          </div>
                        </>
                      </label>
                    </Combobox.Option>
                  </div>
                  }
                })}
                  <div
          
                  >
                    {/* <Combobox.Option
                  
                      className={() =>
                        classNames(
                          " select-none rounded-xl p-3 hover:bg-gray-100  cursor-pointer"
                        )
                      }
                    >
                      <label
                        htmlFor="select"
                        className="flex cursor-pointer  items-center "
                      >
                        <input
                          className="mr-3"
                          id="slecet"
                          type="checkbox"
            checked={props.showGMB}
            onChange={()=>props.setShowGMB(!props.showGMB)}
                          value={"@TestGoogleMyBussiness"}
                        />

                        <>
                          <div
                            className={classNames(
                              "flex h-10  items-center justify-center rounded-lg"
                            )}
                          >
                         {<><HiUserCircle size={46} className=" rounded-full "/>       <div className="-ml-4 mt-5">
                              {" "}
                              {Icon("gmb")}
                            </div></>}
                     
                          </div>
                          <div className="ml-4  flex-auto">
                            <p
                              className={classNames(
                                "text-sm font-medium",
                                "text-gray-700"
                              )}
                            >
                              {"@TestGoogleMyBussiness"}
                            </p>
                          </div>
                        </>
                      </label>
                    </Combobox.Option> */}
                  </div>
              </Combobox.Options>
            )}

            {query !== "" && filteredItems.length === 0 && (
              <div className="py-14 px-6 text-center text-sm sm:px-14">
                <ExclamationCircleIcon
                  type="outline"
                  name="exclamation-circle"
                  className="mx-auto h-6 w-6 text-gray-400"
                />
                <p className="mt-4 font-semibold text-gray-900">
                  No results found
                </p>
              </div>
            )}
          </Combobox>:<NoDataComp contextValue={contextValue} link={"/linked-accounts/instagram"} Text={"Please add your social media Accounts"}  ButtonText={"Connect"} subText={"Click on connect to add social accounts"} />}
        </div>
      </div>
      {props.credentials?.map((item) => {
        if (props.pagesId.includes(item.PageId)&&item.SocialMediaName!=="gmb") {
          return (
            <span className={`inline-flex mt-4 mr-3 items-center rounded-full bg-${contextValue}-50 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-900`}>
              {IconSmall(item.SocialMediaName)} {item.PageName}
              <button    onClick={() => handleCheck(item.SocialMediaName, item.PageId)}
                type="button"
                className={`ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-${contextValue}-400 hover:bg-${contextValue}-200 hover:text-${contextValue}-500 focus:bg-${contextValue}-500 focus:text-white focus:outline-none`}
              >
                <span className="sr-only">Remove large option</span>
                <svg
               
                  className="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    strokeLinecap="round"
                    strokeWidth="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          );
        }
      })}
    </>
  );
};

export default SelectCredentials;
