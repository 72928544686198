import React,{Fragment} from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Menu, Transition } from "@headlessui/react";
const MonthFilter = (props) => {
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }
    const month=  [
      {
        "shortName": "Jan",
        "name": "All"
    },
        {
            "shortName": "Jan",
            "name": "January"
        },
        {
            "shortName": "Feb",
            "name": "February"
        },
        {
            "shortName": "Mar",
            "name": "March"
        },
        {
            "shortName": "Apr",
            "name": "April"
        },
        {
            "shortName": "May",
            "name": "May"
        },
        {
            "shortName": "Jun",
            "name": "June"
        },
        {
            "shortName": "Jul",
            "name": "July"
        },
        {
            "shortName": "Aug",
            "name": "August"
        },
        {
            "shortName": "Sep",
            "name": "September"
        },
        {
            "shortName": "Oct",
            "name": "October"
        },
        {
            "shortName": "Nov",
            "name": "November"
        },
        {
            "shortName": "Dec",
            "name": "December"
        }
    ]
  return (
    <Menu as="div" className="relative mt-4">
    <Menu.Button
      type="button"
      className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
    >
      <div className="  ">{props.monthName}</div>
  
      <MdOutlineKeyboardArrowDown
        className="ml-2 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
    </Menu.Button>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute  z-10 mt-3 w-36 origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1 z-30 ">
       {  month.map((item,keyIndex)=>{
          return(
<Menu.Item>
            {({ active }) => (
              <div key={keyIndex}
                onClick={() =>{ props.setMothName(item.name) }}
                className={classNames(
                  active
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-700",
                  "block pl-5 py-2 text-sm cursor-pointer"
                )}
              >
              {item.name}
              </div>
            )}
          </Menu.Item>
          )
       }) 
}
   
     
      
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
  )
}

export default MonthFilter