import React,{useState} from 'react';
import classes from './ForgetPasswordScreen.module.css';
import { useNavigate } from "react-router-dom";
import ActionButton from '../../../Components/ActionButton';
import OPInput from '../../../Components/OPInput';
import Modal from "../../../Components/Modal";
import Cookies from 'js-cookie';

const ForgetPasswordEmailScreen = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [email, setEmail] = useState("");
    const [modal, setModal] = useState("");
    const [invalidEmail, setInvalidEmail] = useState("");

    const navigate = useNavigate()
    const resentOTP =  () => {
        setInvalidEmail("")
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
        );
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({
          Email: email,
        });
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
    
         fetch(
          "https://promote.onecorp.co.in/api/v1/Ai/SASResendOtp",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (
              result.status === "SUCCESS" &&
              result.message === "OTP sent successfully"
            ) {
                localStorage.setItem("Email",email)
                Cookies.set("Email",email, {  domain:'.sasone.in' })
                // Cookies.set("Email",email)
                navigate("/forgot-password" )
            }  if(result.status === "fail" &&result.Remark==="Email Invalid"){
                setInvalidEmail("Please enter valid email address")
            }
            else{
              setModal("apiFailed")
            }
          })
          .catch((error) => console.log("error", error))
          .finally(() => setLoading(false));
      };
    const formValidation = () => {
        let errors = {};
        let isValid = true;
        if (!email || email === "") {
          errors.email = "Please enter email address";
          isValid = false;
        }
        else if (!emailRegex.test(email)) {
          isValid = false;
          errors.email = "Please enter valid email address";
        }

        setError(errors);
        return isValid;
      };
      const emailRegex =
      /^(([^<>()/.,;:\s@"]+(\.[^<>()/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    return (
        <div className={classes.mainContainer}>
                  {modal === "apiFailed" && (
        <Modal
        modalCase={"confirmation"}
        Text={"There is some issue , please contact support!"}
        setShowModal={setModal}
        iscancel={false}
        onClose={() => setModal("")}
        onOk={() => setModal("")}
      />
      )}
            <div className={classes.logoContainer}>
            <img src={"/assets/One_post.svg"} width={props.device === "Mobile" ? "50%" : "15%"} />
            </div>
            <div className={classes.inputSectionContainer}>
                <div className={classes.headImage}>
                    <img src={"/assets/UserRegistrationImages/Forget.svg"} className="m-auto" width={"35px"} />
                    <div className={classes.headingTextFirst}>Forgot password</div>

                    <div className={classes.inputContainer}>
                        <OPInput
                            input={"SingleLineInputSecType"}
                            autoComplete="off"
                            type={"text"}
                            label={"Email"}
                            placeholder={"Enter your email"}
                            onInput={() => {
                                error.email = "";setInvalidEmail("")
                              }}
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                        />
                           <div className={classes.errors}> {error.email}<span>{invalidEmail}</span></div>
                        <div className={classes.btnNext}><ActionButton  text={"Next"} btnType={"circular"} loading={loading} buttonText={"Send OTP"} onClick={()=>formValidation()?resentOTP():console.log("something went wrong")}/></div>
                    </div>

           
                    <div className={classes.sasOneText}>SAS ONE</div>
                </div>


            </div>

        </div>
    )
}

export default ForgetPasswordEmailScreen