import { MegaphoneIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Cookies from 'js-cookie';
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
const SuccessMessage = (props) => {
  const loginToken = Cookies.get("UserToken");
  const contextValue = Cookies.get("UserType")
  return (

    <div className=" mx-auto max-w-7xl mb-2">
      <div className={props.color==="red"?"bg-red-400 py-3 mx-3 rounded sm:mx-6 lg:mx-8":`bg-${contextValue}-600 py-3 mx-3 rounded sm:mx-6 lg:mx-8`}>
        <div className="sm:flex  sm:flex-wrap  sm:items-center justify-between">
              <div className="flex sm:w-0 flex-1 items-center  mr-3 sm:mr-6 lg:mr-8 ">
                <span className="flex rounded-lg  ml-3 sm:ml-6 lg:ml-8  bg-white p-2">
                  <MegaphoneIcon className={`h-6 w-6 text-${contextValue}-500`} aria-hidden="true" />
                </span>
                <p className="ml-3 hidden sm:block truncate font-medium text-white">
                <span className="">{props.heading}</span> { " "}
              <span className="">{props.subHeading}</span>
                </p>
                <p className="ml-3 sm:hidden  font-medium text-white">
                <span className="">{props.heading}</span> { " "}
              <span className="">{props.subHeading}</span>
                </p>
              </div>
              <div className="order-3 mt-2 mx-3 sm:mx-6 lg:mx-8  flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <a
            target={"_blank"}
           href={"https://www.sasone.in/pricing/onepost"}
              className={`flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-${contextValue}-600 shadow-sm hover:bg-indigo-50`}
            >
            {props.buttonText}  
            </a>
          </div>
            </div>
          </div>
        </div>
      )
    }
export default SuccessMessage