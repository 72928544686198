import React from 'react'
import classes from './InstagramCard.module.css'
import { HiDotsHorizontal, HiUserCircle } from "react-icons/hi";
import { IoChatbubbleOutline } from "react-icons/io5";
import { FiSend } from "react-icons/fi";
import { MdFavoriteBorder } from "react-icons/md";
import { GrFavorite } from "react-icons/gr";
const DummySocialCard = () => {
  return (
    <>
    <div className={classes.heading}>Preview</div>
      <div className={classes.postContainer}>
        <div className={classes.postHeader}>
          <div className={classes.postHeaderUsername}>
            <HiUserCircle className={classes.userIcon} />
           {/*  {props.credentials.map((item) => {
              return <div>{item.SocialMediaName==='instagram'&&item.SocailUserName}</div>;
            })} */}
            <div className={classes.userTitle}>User name </div>
          </div>
          <HiDotsHorizontal />
        </div>
        <img
        //   src={props.imageUrl ? URL.createObjectURL(props.imageUrl) : ""}
        src='/assets/onepost-logo-gray.png'
          alt=""
          width={'100%'}
          height={'100%'}
          className={classes.postImginnernew}
        />

        
<div className='flex mt-4'>
          <GrFavorite className={classes.favIcon} />
          <IoChatbubbleOutline className={classes.favIcon} />
          <FiSend className={classes.favIcon} />
        </div>
        {/* <div className={classes.likesContainer}>
          <MdFavoriteBorder className={classes.favIconSmall} /> 0 likes
        </div> */}
        <div className={classes.caption}>Your preview will show here</div>
        {/* <div className={classes.hashTags}>#ai #technology #artificial</div> */}
      </div>
    </>
  )
}

export default DummySocialCard