import React from 'react'

const ReplyCommentBox = (props) => {
  return (
    <div>   <textarea
    required
   onChange={(e)=>props.setComment(e.target.value)}
     id="about"
     name="about"
     rows={3}
     value={props.comment}
     className="block w-full p-2 rounded-md border outline-none border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
     defaultValue={''}
   /></div>
  )
}

export default ReplyCommentBox