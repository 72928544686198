
import { useState } from "react";
import {
  AiFillFacebook,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BsYoutube, BsLinkedin } from "react-icons/bs";
// import SuccessMessage from "../Components/SuccessMessage";
import { countryCode } from "../../Json/ContryCodes";
import { useEffect } from "react";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import TailwindModal from "../../Components/TailwindCompnents/TailwindModal";
import ConfirmationMessage from "../../Components/TailwindCompnents/ConfirmationMessage";
import { RiAttachment2 } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"


const ContactSupport = () => {

  const [fName, setFname] = useState(Cookies.get("FirstName"));
  const [lName, setLname] = useState(Cookies.get("LastName"));
  const [businessEmail, setBusinessEmail] = useState(Cookies.get("Email"));
  const [phoneNumber, setPhoneNumber] = useState(Cookies.get("Mobile").split("-")[1]);
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState("");
  const [successMsg, setSuccessMsg] = useState("")
  const [CountryCode, setCountryCode] = useState(countryCode[0].dial_code);
  const loginToken = Cookies.get("UserToken");
  const ProductName = Cookies.get("ProductName");

  const product=`${ProductName?ProductName:"One Post"} Support Ticket`
  const navigate=useNavigate()
  const checkLoginType=Cookies.get("LoginType")
  const contextValue = Cookies.get("UserType")
  
  useEffect(() => {
   GetUserDetails()
  }, [loginToken])
  const GetUserDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "UserToken": loginToken
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://promote.onecorp.co.in" + "/api/v1/Ai/GetUserInfo", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "Success") {
          setBusinessEmail(result.data.Email)
          setFname(result.data.FirstName)
          setLname(result.data.LastName)
          setPhoneNumber(result.data.Mobile.slice(-10))
          setCompanyName(result.data.CompanyName)
        
      } else if (
        result.message ===
        "Invalid User Token, The token may have expired, You may need to re-login"
      ) {
       checkLoginType==="Email"?LogOut():SignOutFunction()
      }
      })
      .catch(error => console.log('error', error));
  }
  const formSubmitHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    // myHeaders.append("Content-Type", "application/json");


    var formdata = new FormData();
formdata.append("Images", file);
formdata.append("abc", "abc");
formdata.append("FirstName", fName);
formdata.append("LastName", lName);
formdata.append("Email", businessEmail);
formdata.append("Phone", phoneNumber);
formdata.append("DialCode",CountryCode);
formdata.append("CompanyName", companyName);
formdata.append("Product", product);
formdata.append("UserToken", loginToken);
formdata.append("Message",message);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/saveOnePostSupport",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setSuccessMsg("Our team will connect with you shortly")
          setMessage("")
          setFile("")
        
      } else if (
        result.message ===
        "Invalid User Token, The token may have expired, You may need to re-login"
      ) {
        checkLoginType==="Email"?LogOut():SignOutFunction()
      } else {
          setModal("save")
          setSuccessMsg("Something went wrong, please contact support")
        }
      })
      .catch((error) => {
        setModal("save")
        setSuccessMsg("Something went wrong, please contact support")
      })
      .finally(() => setIsLoading(false));
  };
  const LogOut = () => {
    Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
    Cookies.remove("UserToken", {  domain:'.sasone.in' });
    Cookies.remove("FirstName", {  domain:'.sasone.in' });
    Cookies.remove("LastName", {  domain:'.sasone.in' });
    Cookies.remove("Mobile", {  domain:'.sasone.in' });
    Cookies.remove("userName", {  domain:'.sasone.in' });
    Cookies.remove("Email", {  domain:'.sasone.in' });
    Cookies.remove("UserId", {  domain:'.sasone.in' });
    Cookies.remove("LoginType", {  domain:'.sasone.in' });
    Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });

    navigate("/login");
  };
  const SignOutFunction=()=>{
    Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
    Cookies.remove("UserToken", {  domain:'.sasone.in' });
    Cookies.remove("FirstName", {  domain:'.sasone.in' });
    Cookies.remove("LastName", {  domain:'.sasone.in' });
    Cookies.remove("Mobile", {  domain:'.sasone.in' });
    Cookies.remove("userName", {  domain:'.sasone.in' });
    Cookies.remove("Email", {  domain:'.sasone.in' });
    Cookies.remove("UserId", {  domain:'.sasone.in' });
    Cookies.remove("LoginType", {  domain:'.sasone.in' });
    Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });

    // Cookies.remove("LoggedIn");
    // Cookies.remove("UserToken");
    // Cookies.remove("FirstName");
    // Cookies.remove("LastName");
    // Cookies.remove("Mobile");
    // Cookies.remove("userName");
    // Cookies.remove("Email");
    // Cookies.remove("UserId");
    // Cookies.remove("LoginType");
    // Cookies.remove("ProfilePicture");
    Cookies.set("googleLogin", "googleLogin", {  domain:'.sasone.in' })
    window.open("https://www.sasone.in/sasone-login","_self")
  }
  const pages = [
    { name: 'Contact support', href: '/contact-support', current: true },
  ]
  return (
    <>
       {successMsg==="Our team will connect with you shortly" &&
        <ConfirmationMessage Alert={"success"} onClick={() => setSuccessMsg(false)} success={"Thank-you !!"} text={successMsg} contextValue={contextValue} />}
      
   {modal==="save" && <TailwindModal
      modalCase={"linkAccount"}
      Text={successMsg}
      setModal={setModal}
      onClick={()=>setModal(false)}
      modal={modal}
    />}
      <DashboardComp BreadCrumb={pages} > 
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="overflow-hidden mb-5 rounded-lg bg-white shadow">
        <div className="mx-auto max-w-7xl ">
          <div className="relative ">
            <div className="sr-only">Contact us</div>

            <div className="grid grid-cols-1 lg:grid-cols-3">

          
   
              <div className="py-10 px-6 sm:px-10 lg:col-span-3 xl:p-12">
                <h2 className="text-xl font-semibold text-gray-900">
                  Get in touch with our team
                </h2>
                <form
                  onSubmit={formSubmitHandler}
                  className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                >
                  <div>
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      First name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        onChange={(e) => setFname(e.target.value.trim())}
                        value={fName}
                        pattern="[A-Za-z]{1,32}"
                        className="block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Last name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        onChange={(e) => setLname(e.target.value.trim())}
                        value={lName}
                        pattern="[A-Za-z]{1,32}"
                        required
                        className="block w-full rounded-md border-gray-300 py-3 px-4 border text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Email
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        onChange={(e) =>
                          setBusinessEmail(e.target.value.trim())
                        }
                        value={businessEmail}
                        pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
                        required
                        className="block w-full rounded-md border-gray-300 py-3 px-4 border text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Phone
                      </label>
                    </div>
                    <div className="flex items-center mt-1">
                      <div className="w-2/5 sm:w-2/6">
                        <select onChange={(e) => setCountryCode(e.target.value)} className="block w-24 border rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500">
                          {countryCode.map((item) => <option value={item.dial_code}>{item.dial_code}</option>)}
                        </select>
                      </div>

                      <input
                        type="tel"
                        name="phone"
                        id="phone"
                        autoComplete="phone"
                        onChange={(e) => setPhoneNumber(e.target.value.trim())}
                        value={phoneNumber}
                        className="ml-3 block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        pattern="^[6789][0-9]{9}$"
                        required
                      />
                    </div>
                  </div>
                  <div className="">
                    <label
                      htmlFor="companyName"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Company Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        onChange={(e) => setCompanyName(e.target.value)}
                        value={companyName}
                        required
                        className="block w-full rounded-md border border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                  <div className="">
                    <label
                      // htmlFor="companySite"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Product
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="companySite"
                        id="companySite"
                        value={product}
                        className="block w-full rounded-md border pointer-events-none border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="comment"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Message
                    </label>
                    <div className="mt-1 block w-full rounded-md border border-gray-300  ">
                    <textarea
                rows={ 4}
                required
                name="comment"
                id="comment"
            className={"  w-full py-3 px-4 outline-none rounded-md "}
                defaultValue={""}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                placeholder={"Write your message here!"}
              />
               <div className="w-full flex items-center bg-gray-100 p-1 "  > <label htmlFor="file"><RiAttachment2   size={22} className=" mr-2 text-right cursor-pointer  text-gray-700 " /> </label> {file&&<div className="bg-indigo-100 rounded-full px-2 flex items-center  cursor-pointer"><div className="w-40 truncate"> {file.name}</div> <AiOutlineClose className="mt-1 ml-1" size={14} onClick={()=>setFile("")}  /></div>}</div>
                  <input type={"file"} onChange={(e)=>setFile(e.target.files[0])}  accept="audio/*,video/*,image/*" hidden id="file" />
                    </div>
                  </div>
                  <div className="sm:col-span-2 sm:flex sm:justify-center">
       {isLoading?
                    <button type="button" class={`flex w-full sm:w-1/2 justify-center rounded-md border border-transparent bg-${contextValue}-600 py-2 px-4  shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2`} disabled>
                    <div role="status">
                        <svg aria-hidden="true" class="mr-2 w-6  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                    </div>
                    <div class="text-white"> Processing...</div>
    
    
                </button>:<button
                        type="submit"
                        className={`flex w-full sm:w-1/2 justify-center rounded-md border border-transparent bg-${contextValue}-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2`}
                      >
                        Submit
                      </button>}

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div></div></div>
  
    </DashboardComp>
    </>
  );
};

export default ContactSupport;
