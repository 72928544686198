import React, { useEffect, useState } from "react";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../custom.module.css";
import StatsCard from "../../Components/TailwindCompnents/StatsCard";
import CreatePostButton from "../../Components/TailwindCompnents/CreatePostButton";
import SocialCard from "../../Components/TailwindCompnents/SocialCard";
import RecentPost from "../../Components/TailwindCompnents/RecentPost";
import Table from "../../Components/TailwindCompnents/Table";
import AnalyticsCard from "../../Components/TailwindCompnents/AnalyticsCard";
import { BsInstagram } from "react-icons/bs";
import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import LoadingComp from "../../Components/TailwindCompnents/LoadingComp";
import TailwindModal from "../../Components/TailwindCompnents/TailwindModal";
import SubscriptionCard from "../../Components/TailwindCompnents/SubscriptionCard";
import SuccessMessage from "../../Components/TailwindCompnents/SuccessMessage";
import SocialPageModal from "../../Components/TailwindCompnents/SocialPageModal";
import Tabs from "../../Components/TailwindCompnents/Tabs";
import PricingSection from "../../Components/TailwindCompnents/PricingSection";
import Cookies from "js-cookie";
import TwitterTestingModal from "../../Components/TailwindCompnents/TwitterTestingModal";
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
import 'intro.js/introjs.css';
import { Steps, Hints } from 'intro.js-react';
import introJs from "intro.js";
import Undermaintenance from "../../Components/TailwindCompnents/Undermaintenance";
import AnalyticsModal from "../../Components/TailwindCompnents/AnalyticsModal";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const [posts, setPosts] = useState([]);
  const [activity, setActivity] = useState([]);
  const [trialData, setTrialData] = useState([]);
  const [type, setType] = useState("instagram");
  const [modal, setModal] = useState(false);
  const [succesMessage, setSuccesMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [credentialId, setCredentialId] = useState("");
  const [socialData, setSocialData] = useState("");
  const [socialModal, setSocialModal] = useState(false);
  const [modalTwitter, setModalTwitter] = useState(false);
  const [undermaintenance, setUndermaintenance] = useState(true)
  const [resourceownerKey, setResourceownerKey] = useState("")
  const [resourceownerSecret, setResourceownerSecret] = useState("")
  const [deviceType, setDeviceType] = useState("");
  const [code, setCode] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const [error, setError] = useState("")
  const DomainName = window.location.hostname;
  const contextValue = Cookies.get("UserType")

  const loginToken = Cookies.get("UserToken");
  const isLoggedIn = Cookies.get("LoggedIn");
  const checkLoginType = Cookies.get("LoginType")
  const SignupUser = Cookies.get("SignupUser")
  const navigate = useNavigate();
  const { id } = useParams();
  const onExit = () => { };
  const CompanyName = Cookies.get("CompanyName")
  const CompanyLogo = Cookies.get("CompanyLogo")
  const ProductName = Cookies.get("ProductName");

  const [fbAccessToken, setFbAccessToken] = useState("");
  const [instaAccessToken, setInstaAccessToken] = useState("");
  const [userId, setUserId] = useState("");
  const [analyticsModal, setAnalyticsModal] = useState(false)
  const [analyticsData, setAnalyticsData] = useState("")

  const [likesCount, setLikesCount] = useState(0)
  const [commentsCount, setCommentsCount] = useState(0)
console.log(type);
  const options = {
    steps: [
      {
        title: ' Welcome',
        intro: 'Welcome to react INTRO JS app',
      },
      {
        element: '#step1',
        intro: 'step 2',
      },
      {
        element: '#step2',
        intro: 'step 3',
      },
      {
        element: '#step3',
        intro: 'step 4',
      },
      {
        element: '#step4',
        intro: 'step 5',
      },
      {
        element: '#step5',
        intro: 'step 6',
      },
      {
        element: '#step6',
        intro: 'step 7',
      },
      {
        element: '#step7',
        intro: 'step 8',
      },

    ],
    onBeforeChange: false,
    showBullets: false,
  };



  useEffect(() => {
    WebsiteUrl()
    CheckSourceDevice()
  }, [])


  useEffect(() => {

    if (DomainName !== "localhost" && DomainName !== "onepost.sasone.in") {

      CompanyDetail();
    }
    if (id) {
      checkLogin();
    }
    else {
      if (!isLoggedIn) {
        navigate("/login");
      }

    }
  }, [DomainName]);
  useEffect(() => {
    checkTrial();
    GetCreadentials();
    getSocialPageDetails();
    getPost();
    Activity();
    GetAccessToken();
  }, []);

  const WebsiteUrl = () => {

    if (DomainName === "localhost") {

      Cookies.set("UserType", "indigo", {
        domain: ".sasone.in",
      })

    }
    else if (DomainName === "onepost.sasone.in") {

      Cookies.set("UserType", "indigo", {
        domain: ".sasone.in",
      })

    }
    else if (DomainName === "testonepost.sasone.in") {

      Cookies.set("UserType", "red", {
        domain: ".sasone.in",
      })

    }
    else {
      Cookies.set("UserType", "red", {
        domain: ".sasone.in",
      })
    }

  }
  // const WebsiteUrl = () => {

  //   if (DomainName === "localhost") {

  //     Cookies.set("UserType", "indigo")

  //   }
  //   else if (DomainName === "onepost.sasone.in") {

  //     Cookies.set("UserType", "indigo")

  //   }
  //   else if (DomainName === "testonepost.sasone.in") {

  //     Cookies.set("UserType", "red")

  //   }
  //   else {
  //     Cookies.set("UserType", "red")
  //   }

  // }
  const GetAccessToken = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "UserToken": loginToken
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.sasone.in/apiadmin/v2/onepost/GetAccessTokensbyUserId", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code == 200 && result.status === "Success") {
          result.data.map((item) => {
            if (item.SocialMediaName == "instagram") {
              GetUserId(item.SocialMediaAccessToken)
              setInstaAccessToken(item.SocialMediaAccessToken)
              // console.log(item.SocialMediaAccessToken)
            } else if (item.SocialMediaName == "facebook") {
              setFbAccessToken(item.SocialMediaAccessToken)
              // console.log(item.SocialMediaAccessToken)
            }
          })


        } else {
          console.log("error")
        }
      })
      .catch(error => console.log('error', error));
  }

  const GetUserId = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

    var formdata = new FormData();
    formdata.append("access_token", token);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`https://graph.facebook.com/me?fields=id,name`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setUserId(result.id)
        // if(result.status_code == 200 && result.status === "Success"){
        //       setUserId(result.id)

        // }else{
        //   console.log(result)
        // }
      })
      .catch(error => console.log('error', error));
  }

  const GetAnalyticsData = (type, id, data) => {
    setAnalyticsData(data)
    //  console.log(type,userId, id,fbAccessToken)
    setAnalyticsModal(true)
    if (type == "facebook") {
      GetFacebookData(id)
    } else if (type == "instagram") {
      GetInstagramData(id)
    }
  }

  const GetFacebookData = (id) => {

    fetch(`https://graph.facebook.com/v12.0/${userId}_${id}?fields=reactions.limit(0).summary(true),comments.limit(0).summary(true)
  &access_token=${fbAccessToken}`)
      .then(response => response.json())
      .then(data => {
        setLikesCount(data.reactions.summary.total_count)
        setCommentsCount(data.comments.summary.total_count)
        console.log(data)
      })
      .catch(error => {
        console.error(error);
      });
  }

  const GetInstagramData = (id) => {
    fetch(`https://graph.facebook.com/v12.0/${id}?fields=media_url,caption,timestamp,permalink,like_count,comments
    &access_token=${instaAccessToken}`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setLikesCount(data.like_count)
        setCommentsCount(data?.comments?.data?.length)
      })
      .catch(error => {
        console.error(error);
      });
  }




 
  const people = [
    {
      name: "Facebook",
      type: "Page or Group",
      social: "facebook",
      imageUrl: (
        <div className="bg-fb rounded-full flex w-12 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaFacebookF size={30} color={"white"} />
        </div>
      ),
    },
    {
      name: "LinkedIn",
      type: "Page or Profile",
      social: "linkedin",
      imageUrl: (
        <div className="bg-linkedIn rounded-full flex w-12 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaLinkedinIn size={30} color={"#fff"} />
        </div>
      ),
    },
    {
      name: "Instagram",
      type: "Business Account",
      social: "instagram",
      imageUrl: (
        <div className={classes.instagramSmall}>
          {" "}
          <BsInstagram size={30} color={"#fff"} />
        </div>
      ),
    },
    {
      name: "Twitter",
      type: "Profile",
      social: "twitter",
      imageUrl: (
        <div className="bg-twitter rounded-full flex w-12 justify-center  items-center p-1 py-2 ">
          {" "}
          <FaTwitter size={30} color={"#fff"} />
        </div>
      ),
    },
    {
      name: "Google My Business",
      type: "Verified page",
      social: "google",
      imageUrl: (
        <div className=" rounded-full flex  bg-gray-100 justify-center  items-center p-2 ">
          {" "}
          <img src="./assets/gmbLogo.png" className="w-10" />
        </div>
      ),
    },
    {
      name: "YouTube",
      type: "Channel",
      social: "youtube",
      imageUrl: (
        <img src="../assets/YouTube-Logo.svg" className="w-28 h-20 -mt-4 -mb-4" />
      ),
    },
    // {
    //   name: "Twitter Testing",
    //   type: "Profile",
    //   social: "twitter",
    //   imageUrl: (
    //     <div className="bg-twitter rounded-full flex w-12 justify-center  items-center p-1 py-2 ">
    //       {" "}
    //       <FaTwitter size={30} color={"#fff"} />
    //     </div>
    //   ),
    // },
  ];


  const CompanyDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      DomainName: DomainName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/onepost/getcompanyDetailsbycompanyweb",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {

          Cookies.set("CompanyLogo", result.data.CompanyLogo, {
            domain: ".sasone.in",
          });
          Cookies.set("CompanyName", result.data.CompanyName, {
            domain: ".sasone.in",
          });
          Cookies.set("ProductName", result.data.ProductName, {
            domain: ".sasone.in",
          });

          // Cookies.set("CompanyLogo", result.data.CompanyLogo);
          // Cookies.set("CompanyName", result.data.CompanyName);
          // Cookies.set("ProductName", result.data.ProductName)
        }
      })
      .catch((error) => console.log("error", error));
  };


  const Activity = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: id || loginToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1-1/onepost/GetPostCountByStatus",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setActivity(result.scheduled);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const RemoveCredentials = (credId) => {
    setModal("");
    // setLoading("remove");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      CredentialsId: credId,
      UserToken: id || loginToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/onepost/RemoveCredentials",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setModal("saved");
          window.scrollTo(0, 0);
          navigate("/");
          setSuccesMessage("Your accounts is now removed.");
          GetCreadentials();
        } else {
          setModal("saved");
          window.scrollTo(0, 0);
          navigate("/");
          setSuccesMessage(
            " Oops, A problem has occurred in removing your account. Please try again "
          );
        }
      })
      .catch((error) => console.log("error", error));
    // .finally(() => setLoading(" "));
  };
  const LogOut = () => {
    Cookies.remove("LoggedIn", { domain: '.sasone.in' });
    Cookies.remove("UserToken", { domain: '.sasone.in' });
    Cookies.remove("FirstName", { domain: '.sasone.in' });
    Cookies.remove("LastName", { domain: '.sasone.in' });
    Cookies.remove("Mobile", { domain: '.sasone.in' });
    Cookies.remove("userName", { domain: '.sasone.in' });
    Cookies.remove("Email", { domain: '.sasone.in' });
    Cookies.remove("UserId", { domain: '.sasone.in' });
    Cookies.remove("LoginType", { domain: '.sasone.in' });
    Cookies.remove("ProfilePicture", { domain: '.sasone.in' });
    Cookies.remove("UserType", { domain: '.sasone.in' });
    Cookies.remove("SignupUser", { domain: '.sasone.in' });


    navigate("/login");
  };
  const SignOutFunction = () => {
    Cookies.remove("LoggedIn", { domain: '.sasone.in' });
    Cookies.remove("UserToken", { domain: '.sasone.in' });
    Cookies.remove("FirstName", { domain: '.sasone.in' });
    Cookies.remove("LastName", { domain: '.sasone.in' });
    Cookies.remove("Mobile", { domain: '.sasone.in' });
    Cookies.remove("userName", { domain: '.sasone.in' });
    Cookies.remove("Email", { domain: '.sasone.in' });
    Cookies.remove("UserId", { domain: '.sasone.in' });
    Cookies.remove("LoginType", { domain: '.sasone.in' });
    Cookies.remove("ProfilePicture", { domain: '.sasone.in' });
    Cookies.remove("UserType", { domain: '.sasone.in' });
    Cookies.remove("SignupUser", { domain: '.sasone.in' });


    // Cookies.remove("LoggedIn");
    // Cookies.remove("UserToken");
    // Cookies.remove("FirstName");
    // Cookies.remove("LastName");
    // Cookies.remove("Mobile");
    // Cookies.remove("userName");
    // Cookies.remove("Email");
    // Cookies.remove("UserId");
    // Cookies.remove("LoginType");
    // Cookies.remove("ProfilePicture");
    Cookies.set("googleLogin", "googleLogin", { domain: '.sasone.in' })
    window.open("https://www.sasone.in/sasone-login", "_self")
  }
  const getPost = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      UserToken: id || loginToken,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://promote.onecorp.co.in/api/v1/social/GetPosts",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setPosts(result.live);
        } else if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType === "Email" ? LogOut() : SignOutFunction()
        }
      })
      .catch((error) => console.log("error", error));
    // .finally(() => setLoading(""));
  };
  const GetCreadentials = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: id || loginToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/social/GetCredentials",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setData(result.data);
          if (result.data.length < 1) {
            setSocialModal(true);
          }
        }
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType === "Email" ? LogOut() : SignOutFunction()
        }
      })
      .catch((error) => console.log("error", error));
    // .finally(() => setLoading(""));
  };
  const getSocialPageDetails = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: id || loginToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/social/GetSocialPageDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setSocialData(result.pageDetails);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const checkLogin = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: id || loginToken,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://promote.onecorp.co.in/api/v1/Ai/CheckLogin", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          Cookies.set("LoggedIn", true, { domain: '.sasone.in' });
          Cookies.set("UserToken", result.data.UserToken, { domain: '.sasone.in' });
          Cookies.set("FirstName", result.data.FirstName, { domain: '.sasone.in' });
          Cookies.set("LastName", result.data.LastName, { domain: '.sasone.in' });
          Cookies.set("Mobile", result.data.Mobile, { domain: '.sasone.in' });
          Cookies.set("userName", result.data.UserName, { domain: '.sasone.in' });
          Cookies.set("Email", result.data.Email, { domain: '.sasone.in' });
          Cookies.set("UserId", result.data.UserId, { domain: '.sasone.in' });
          Cookies.set("ProfilePicture", result.data.ProfilePicture, { domain: '.sasone.in' });
          // Cookies.set("LoginType", "Email", {  domain: '.sasone.in' });
          navigate("/");
        } else {
          navigate("/login");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };

  const checkTrial = () => {
    // setLoading("checkTrial");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: id || loginToken,
      ProductName: "onePost",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/CheckSubscription",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code === 200) {
          setTrialData(result);

          if (
            result.Trail === "Trial Expire" ||
            result.Trail === "Trial Available"
          ) {
            // navigate("/");
            localStorage.setItem("trialAvailable", "no");
            localStorage.setItem("trialexpire", "yes");
          } else if (result.Trail === "Trial On Goning") {
            // navigate("/");
            localStorage.setItem("trialAvailable", "no");
            localStorage.setItem("trialexpire", "no");
          } else {
            localStorage.setItem("trialAvailable", "no");
            localStorage.setItem("trialexpire", "no");
          }
        }
      })
      .catch((error) => console.log("error", error));
    // .finally(() => setLoading(""));
  };

  const subscription = () => {
    if (trialData.Trail === "Trial Expire") {
      return (
        <SuccessMessage
          heading={`Your ${ProductName ? ProductName : "One Post"} subscription has been expired.`}
          color={"red"}
          buttonText="Renew now"
          subHeading={
            "Renew your subscription today to continue enjoying hassle free usage."
          }
        />
      );
    } else if (trialData.Trail === "Trial Available") {
      return (
        <SuccessMessage
          heading={`Your ${ProductName ? ProductName : "One Post"} subscription is not active.`}
          buttonText="Buy plan"
          subHeading={`Start using ${ProductName ? ProductName : "One Post"} to manage your social media.`}
        />
      );
    }
  };
  const hrefLink = (path) => {
    if (DomainName === "onepost.sasone.in") {
      if (path === "Instagram") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://onepost.sasone.in/linked-accounts/instagram/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "LinkedIn") {
        return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=LinkedIn&scope=r_emailaddress,r_liteprofile,w_member_social,r_organization_admin,rw_organization_admin,r_organization_social,w_organization_social&client_id=775gacntogd1of&redirect_uri=https://onepost.sasone.in/linked-accounts/linkedin/";
      }
      if (path === "Facebook") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://onepost.sasone.in/linked-accounts/facebook/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "Twitter") {
        return "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=cXFQbG1nQWkybmhrRkFzM25MTzg6MTpjaQ&redirect_uri=https://onepost.sasone.in/linked-accounts/twitter/&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=twitter&code_challenge=challenge&code_challenge_method=plain";
      }
      if (path === "Google My Business") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://onepost.sasone.in/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true";
      }
      if (path === "YouTube") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://onepost.sasone.in/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=youtube&access_type=offline&include_granted_scopes=true";
      }
    }
    else if (DomainName === "testonepost.sasone.in") {
      if (path === "Instagram") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://testonepost.sasone.in/linked-accounts/instagram/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "LinkedIn") {
        return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=LinkedIn&scope=r_emailaddress,r_liteprofile,w_member_social,r_organization_admin,rw_organization_admin,r_organization_social,w_organization_social&client_id=775gacntogd1of&redirect_uri=https://testonepost.sasone.in/linked-accounts/linkedin/";
      }
      if (path === "Facebook") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://testonepost.sasone.in/linked-accounts/facebook/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "Twitter") {
        return "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=cXFQbG1nQWkybmhrRkFzM25MTzg6MTpjaQ&redirect_uri=https://testonepost.sasone.in/linked-accounts/twitter/&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=twitter&code_challenge=challenge&code_challenge_method=plain";
      }
      if (path === "Google My Business") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://testonepost.sasone.in/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true";
      }
      if (path === "YouTube") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://testonepost.sasone.in/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=youtube&access_type=offline&include_granted_scopes=true";
      }
    }
    else if (DomainName === "localhost") {
      if (path === "Instagram") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=http://localhost:3000/linked-accounts/instagram/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "LinkedIn") {
        return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=LinkedIn&scope=r_emailaddress,r_liteprofile,w_member_social,r_organization_admin,rw_organization_admin,r_organization_social,w_organization_social&client_id=775gacntogd1of&redirect_uri=http://localhost:3000/linked-accounts/linkedin/";
      }
      if (path === "Facebook") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=http://localhost:3000/linked-accounts/facebook/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "Twitter") {
        return "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=cXFQbG1nQWkybmhrRkFzM25MTzg6MTpjaQ&redirect_uri=http://localhost:3000/linked-accounts/twitter/&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=twitter&code_challenge=challenge&code_challenge_method=plain";
      }
      if (path === "Google My Business") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=http://localhost:3000/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true";
      }
      if (path === "YouTube") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=http://localhost:3000/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=youtube&access_type=offline&include_granted_scopes=true";
      }
    }
    else if (DomainName === "infoseedream.sasone.in") {
      if (path === "Instagram") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=http://infoseedream.sasone.in/linked-accounts/instagram/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "LinkedIn") {
        return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=LinkedIn&scope=r_emailaddress,r_liteprofile,w_member_social,r_organization_admin,rw_organization_admin,r_organization_social,w_organization_social&client_id=775gacntogd1of&redirect_uri=http://infoseedream.sasone.in/linked-accounts/linkedin/";
      }
      if (path === "Facebook") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=http://infoseedream.sasone.in/linked-accounts/facebook/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "Twitter") {
        return "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=cXFQbG1nQWkybmhrRkFzM25MTzg6MTpjaQ&redirect_uri=http://infoseedream.sasone.in/linked-accounts/twitter/&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=twitter&code_challenge=challenge&code_challenge_method=plain";
      }
      if (path === "Google My Business") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=http://infoseedream.sasone.in/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true";
      }
      if (path === "YouTube") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=http://infoseedream.sasone.in/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=youtube&access_type=offline&include_granted_scopes=true";
      }
    }
  };
  // const hrefLinkTest = (path) => {
  //   if (path === "Instagram") {
  //     return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://testonepost.sasone.in/linked-accounts/instagram/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
  //   }
  //   if (path === "LinkedIn") {
  //     return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=LinkedIn&scope=r_emailaddress,r_liteprofile,w_member_social,r_organization_admin,rw_organization_admin,r_organization_social,w_organization_social&client_id=775gacntogd1of&redirect_uri=https://testonepost.sasone.in/linked-accounts/linkedin/";
  //   }
  //   if (path === "Facebook") {
  //     return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://testonepost.sasone.in/linked-accounts/facebook/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
  //   }
  //   if (path === "Twitter") {
  //     return "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=cXFQbG1nQWkybmhrRkFzM25MTzg6MTpjaQ&redirect_uri=https://testonepost.sasone.in/linked-accounts/twitter/&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=twitter&code_challenge=challenge&code_challenge_method=plain";
  //   }
  //   if (path === "Google My Business") {
  //     return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://testonepost.sasone.in/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true";
  //   }
  //   if (path === "YouTube") {
  //     // return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://testonepost.sasone.in/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl+https://www.googleapis.com/auth/youtubepartner+https://www.googleapis.com/auth/youtube.readonly&state=youtube&access_type=offline&include_granted_scopes=true";
  //     return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://testonepost.sasone.in/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=youtube&access_type=offline&include_granted_scopes=true";
  //   }
  // };

  const CheckAuthTwitter = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://api.sasone.in/apiadmin/test/onepost/GetTwitterOauthUrl", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "success") {
          setResourceownerKey(result.data.ResourceOwnerKey)
          setResourceownerSecret(result.data.ResourceOwnerSecret)
          setModalTwitter(true)
          window.open(result.data.authURL, "_blank")
        }
      })
      .catch(error => console.log('error', error));
  }

  const FinalTwitterSubmit = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 88927950e8858bb239c62d8dcb81292630996841");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "Code": code,
      "ResourceOwnerKey": resourceownerKey,
      "ResourceOwnerSecret": resourceownerSecret,
      "UserToken": loginToken
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("https://api.sasone.in/apiadmin/test/onepost/GetTwitterOauth", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "success") {
          setModalTwitter(false)
          alert("Success")
        }
        else if (result.status_code === 300 && result.status === "fail") {
          setError(result.Reason)
          setShowMsg(true)
          setModalTwitter(false)
          // alert("Success")
        }
      })
      .catch(error => console.log('error', error));
  }

  const CheckSourceDevice = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType("Mobile");
    } else {
      setDeviceType("Desktop");
    }
  };


  return (
    <DashboardComp
      RemoveCredentials={RemoveCredentials}
      livePosts={posts}
      linkedAccounts={data}
      id1={"step2"}
      id2={"step3"}
      id3={"step4"}
      id4={"step5"}
      id5={"step6"}
      id6={"step7"}
      id7={"step8"}
    // contextValue={contextValue}
    >
      {modal === "saved" && (
        <TailwindModal
          modalCase={"confirmation"}
          Text={succesMessage}
          setModal={setModal}
          isCancel={false}
          modal={modal}
        />
      )}
      {modalTwitter && <TwitterTestingModal


        setModal={setModalTwitter}

        modal={modalTwitter}
        value={code}
        onChange={(e) => setCode(e.target.value)}
        onSubmit={FinalTwitterSubmit}
        error={"The Code has been Expired"}
        onClick={() => setModalTwitter(false)}
      />}
      {/* {undermaintenance&&<Undermaintenance      Text={"succesMessage"}
          setShowModal={setModal}
          setModal={setUndermaintenance}
          modal={undermaintenance} />} */}


      {socialModal && (

        <SocialPageModal
          Text={succesMessage}
          setShowModal={setModal}
          setModal={setSocialModal}
          modal={socialModal}
          id={'step1'}
        >
          <div className="hidden lg:block" >
            <Tabs type={type} setType={setType} tab={"analytics"} />
            <ul
              role="list"
              className="my-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4"

            >
              {people.map((item, key) => {
                return (
                  item.social === type && (
                    <div className="w-64 sm-w-0 m-auto">
                      <SocialCard item={item} />
                    </div>
                  )
                );
              })}
            </ul>
          </div>
          <ul
            role="list"
            className=" grid lg:hidden gap-x-4 grid-cols-3"
          >
            {people.map((item, key) => {
              return (

                <div className=" sm-w-0 m-auto">
                  <div
                    // key={key}
                    className="bg-white flex flex-col lg:hidden  p-4 justify-between"
                  >
                    <div className="relative flex items-center flex-col w-full ">
                      <a href={hrefLink(item.name)} > <div>{item.imageUrl}</div> <div className="text-xs pt-1 text-gray-900 font-medium">Connect</div> </a>


                    </div>

                  </div>
                </div>
              )

            })}
          </ul>
          {(DomainName === "onepost.sasone.in"||DomainName === "localhost") && <div className="mt-2">
            {trialData.SubscriptionData?.SubscriptionType !== "Paid" ? (
              <PricingSection contextValue={contextValue} />
            ) : (
              <></>
            )}
          </div>}
        </SocialPageModal>

      )}
      {analyticsModal && <AnalyticsModal setAnalyticsData={setAnalyticsModal} data={analyticsData} likesCount={likesCount} commentsCount={commentsCount} />}
      {subscription()}
      {modal === "remove" && (
        <TailwindModal
          modalCase={"ConfirmMessage"}
          Heading={"Remove Account"}
          Text={"Are you sure you want to remove this account?"}
          setModal={setModal}
          onClick={() => RemoveCredentials(credentialId)}
          modal={modal}
        />
      )}
      {/* {(SignupUser&&deviceType!=="Mobile")&& <Steps enabled={true} steps={options.steps} initialStep={0} onExit={onExit} onBeforeChange={options.onBeforeChange} showBullets={options.showBullets} />} */}
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">

        <h1 className="sr-only">Page title</h1>
        <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
          <div className="grid grid-cols-1 gap-4 lg:col-span-2">
            <section aria-labelledby="section-1-title">
              <h2 className="sr-only" id="section-1-title">
                Section title
              </h2>
              <div>
                <div>
                  <div
                    className={
                      " overflow-hidden mb-5 rounded-lg bg-white shadow"
                    }
                  >
                    <div
                      className={
                        trialData.Trail === "Trial Expire" ||
                          trialData.Trail === "Trial Available"
                          ? "pointer-events-none opacity-50 p-6"
                          : "p-6"
                      }
                    >
                      {" "}
                      {loading ? (
                        <LoadingComp />
                      ) : (
                        <AnalyticsCard data={socialData} />
                      )}
                    </div>
                  </div>
                  <div className={"sm:hidden mb-5 rounded-lg bg-white shadow"}>
                    <div
                      className={
                        trialData.Trail === "Trial Expire" ||
                          trialData.Trail === "Trial Available"
                          ? "pointer-events-none opacity-50 p-6"
                          : "p-6"
                      }
                    >
                      {" "}
                      <h1 className="text-xl font-semibold text-gray-900">
                        Create a post
                      </h1>
                      <div className="text-gray-400 text-base">
                        Quickly create a social post and publish it to the multiple
                        social platforms within minutes
                      </div>
                      {/* trialData.Trail==="Trial Expire"?"pointer-events-none opacity-50": */}
                      <div className={""}>
                        {" "}
                        <CreatePostButton data={data} />
                      </div>
                    </div>
                  </div>

                  {data?.length > 0 ? (
                    <div
                      className={
                        trialData.Trail === "Trial Expire" ||
                          trialData.Trail === "Trial Available"
                          ? "overflow-hidden mb-5  rounded-lg bg-white shadow"
                          : " overflow-hidden mb-5 rounded-lg bg-white shadow"
                      }
                    >
                      <div className={"p-6"}>
                        <Table
                          setModal={setModal}
                          setCredentialId={setCredentialId}
                          linkedAccounts={data}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {posts?.length > 0 ? (
                    <div
                      className={
                        trialData.Trail === "Trial Expire" ||
                          trialData.Trail === "Trial Available"
                          ? "overflow-hidden mb-5 pointer-events-none opacity-50 rounded-lg bg-white shadow"
                          : " overflow-hidden mb-5 rounded-lg bg-white shadow"
                      }
                    >
                      <div className="p-6 ">
                        {" "}
                        <h1 className="text-xl font-semibold text-gray-900">
                          Recent Posts
                        </h1>
                        <div className="text-gray-400">
                          A quick look on your recent posts via {ProductName ? ProductName : "One Post"}
                        </div>
                        <div className="bg-white ">
                          <div className="mx-auto max-w-2xl  px-4  sm:px-6 lg:max-w-7xl lg:px-8">
                            <div className="mt-6 flex flex-wrap">
                              {posts?.slice(0, 10)?.map((item, index) => {
                                return <RecentPost data={item} GetAnalyticsData={GetAnalyticsData} />;
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div
                    className={
                      trialData.Trail === "Trial Expire" ||
                        trialData.Trail === "Trial Available"
                        ? "overflow-hidden mb-5  rounded-lg bg-white shadow"
                        : " overflow-hidden mb-5 rounded-lg bg-white shadow"
                    }
                  >
                    <div className="p-6 ">
                      {" "}
                      <h1 className="text-xl font-semibold text-gray-900">
                        Connect a new channel
                      </h1>
                      <div className="text-gray-400">
                        {`To allow ${CompanyName ? CompanyName : "SAS One"} access to your social accounts, click
                        on connect and follow the steps`}
                      </div>
                      <ul
                        role="list"
                        className="my-8 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
                      >
                        {people.map((item, key) => {
                          return <SocialCard item={item} onClickBtn={() => CheckAuthTwitter()} />;
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {/* {/ Right column /} */}
          <div className="grid grid-cols-1 gap-4">
            <section aria-labelledby="section-2-title">
              <h2 className="sr-only" id="section-2-title">
                Section title
              </h2>
              <div className={" hidden sm:block mb-5 rounded-lg bg-white shadow"}>
                <div
                  className={
                    trialData.Trail === "Trial Expire" ||
                      trialData.Trail === "Trial Available"
                      ? "pointer-events-none opacity-50 p-6"
                      : "p-6"
                  }
                >
                  {" "}
                  <h1 className="text-xl font-semibold text-gray-900">
                    Create a post
                  </h1>
                  <div className="text-gray-400 text-base">
                    Quickly create a social post and publish it to the multiple
                    social platforms within minutes
                  </div>
                  {/* trialData.Trail==="Trial Expire"?"pointer-events-none opacity-50": */}
                  <div className={""}>
                    {" "}
                    <CreatePostButton data={data} />
                  </div>
                </div>
              </div>
              {/* trialData.Trail==="Trial Expire"? "overflow-hidden mb-5 pointer-events-none opacity-50 rounded-lg bg-white shadow":  */}
              {trialData.Calendar === "0" ? (
                <></>
              ) : (
                <div className={"mb-5 rounded-lg bg-white shadow"}>
                  <div
                    className={
                      trialData.Trail === "Trial Expire" ||
                        trialData.Trail === "Trial Available"
                        ? "pointer-events-none opacity-50 p-6"
                        : "p-6"
                    }
                  >
                    {" "}
                    <div className={"flex gap-4"}> <div className="text-xl font-semibold text-gray-900">
                      AI Creative Calendar
                    </div> <div className="flex items-center rounded-md border border-gray-300  bg-white py-1 pl-2 pr-2 text-sm font-medium text-green-500  shadow-sm "> Active</div></div>
                    <div className="text-gray-400 text-base">
                      Without lifting a finger, automatically scheduled your
                      social media posts for all important dates.
                    </div>
                    {/* trialData.Trail==="Trial Expire"?"pointer-events-none opacity-50": */}
                    <div className={""}>
                      {" "}
                      <div className=" flex justify-between mt-2 ">
                        <div>
                          {activity && activity.TotalAutoScheduled} Auto Scheduled Posts
                        </div>
                        <a
                          href="/creative-calender"
                          className={`underline hover:text-${contextValue}-400 text-${contextValue}-600`}
                        >
                          View Posts
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  trialData.Trail === "Trial Expire" ||
                    trialData.Trail === "Trial Available"
                    ? " mb-5  "
                    : " overflow-hidden mb-5  rounded-lg "
                }
              >
                <div className="bg-gradient-to-r shadow  from-indigo-500 via-purple-500 to-pink-500 w-full p-4  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg   text-center items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  {trialData && trialData.SubscriptionData?.TotalAmount === "14999" ? <div
                  >
                    <div className="text-white text-lg  flex justify-between ">
                      <div> Custom Creative Credit </div> <div>{trialData && trialData.SubscriptionData?.GoldCredits}</div>
                    </div>

                    <div className="text-xs text-left text-white">
                      Get Customized Creatives for your Brand
                    </div>
                  </div> : <div
                  >
                    <div className="text-white text-left items-center  text-lg flex justify-between ">
                      <div>
                        <div> Custom Creative Credit </div>


                        <div className="text-xs text-left text-white">
                          Get Customized Creatives for your Brand
                        </div></div>
                      <a target={"_blank"} href="https://www.sasone.in/pricing/onepost" className="flex items-center rounded-md border border-gray-300  bg-white py-1 pl-3 pr-2 text-sm font-medium text-gray-500  shadow-sm ">Buy now</a>
                    </div>
                  </div>}
                </div>
              </div>
              <div
                className={
                  trialData.Trail === "Trial Expire" ||
                    trialData.Trail === "Trial Available"
                    ? "overflow-hidden mb-5 pointer-events-none opacity-50 rounded-lg bg-white shadow"
                    : " overflow-hidden mb-5 rounded-lg bg-white shadow"
                }
              >
                {activity && <div className="p-6 ">
                  {" "}
                  <h1 className="text-xl font-semibold text-gray-900">
                    Activity
                  </h1>
                  <div className="text-gray-400">
                    Focus on regular posting to increase your business presence
                  </div>
                  <div className="text-gray-500 font-semibold mt-2">Today</div>
                  <StatsCard
                    livePosts={activity?.CurrentDateAutoLive}
                    schedulPosts={activity?.CurrentDateAutoScheduled}
                    autoLivePosts={activity?.CurrentDateManualLive}
                    autoSchedulPosts={activity?.CurrentDateManualScheduled}
                  />
                  <div className="text-gray-500 font-semibold mt-3">
                    This Week
                  </div>
                  <StatsCard
                    livePosts={activity?.CurrentWeekManualLive}
                    schedulPosts={activity?.CurrentWeekManualScheduled}
                    autoLivePosts={activity?.CurrentWeekAutoLive}
                    autoSchedulPosts={activity?.CurrentWeekAutoScheduled}
                  />
                  <div className="text-gray-500 font-semibold mt-3">
                    This Month
                  </div>
                  <StatsCard
                    livePosts={activity?.CurrentMonthManualLive}
                    schedulPosts={activity?.CurrentMonthManualScheduled}
                    autoLivePosts={activity?.CurrentMonthAutoLive}
                    autoSchedulPosts={activity?.CurrentMonthAutoScheduled}
                  />
                </div>}
              </div>
              {(DomainName === "localhost" || DomainName === "onepost.sasone.in") && <SubscriptionCard trialData={trialData} />}
            </section>
          </div>
        </div>
      </div>
    </DashboardComp>
  );
};

export default Dashboard;
