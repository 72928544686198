import { Fragment } from "react";
import {  HiUserCircle } from "react-icons/hi";
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
import Cookies from "js-cookie";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Table = (props) => {
  const contextValue = Cookies.get("UserType")
  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
          Connected accounts overview 
          </h1>
          <div className="text-gray-400">A quick overview of all your connected social media accounts at one place</div>
        </div>
        <div className=" sm:mt-0 sm:ml-16 sm:flex-none">
          {/* <div className="text-xs text-gray-400">
            Updated daily compared with previous day
          </div> */}
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-white">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Profiles
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Followers
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Impressions
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Engagement
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                     
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {props.linkedAccounts?.filter(
                    (type) => type.SocialMediaName === "facebook"
                  )?.length ? (
                    <Fragment>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-indigo-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                          <img src="/assets/Facebook.svg"  width={100}/>
                        </th>
                      </tr>
                      {props.linkedAccounts
                        .filter((type) => type.SocialMediaName === "facebook")
                        .map((item, index) => (
                          <tr
                            key={index}
                            className={classNames(
                              index === 0
                                ? "border-gray-300"
                                : "border-gray-200",
                              "border-t"
                            )}
                          >
                            <td className="whitespace-nowrap relative py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div>
                                <div className="flex items-center">
                                  {" "}
                                { item.ProfileImage? <img
                                    className="h-8 rounded-full mr-3"
                                    src={item.ProfileImage}
                                  /> : <HiUserCircle size={30} className="h-8 rounded-full mr-3"/>}
                                  <span className="w-24 truncate">{item.PageName}</span>
                                </div>
                                {/* <div className="bg-fb mr-4 rounded-full absolute flex w-4 -mt-4 ml-5 mr-10 z-50 justify-center  items-center p-1 ">
                                  <FaFacebookF size={10} color={"#fff"} />
                                </div> */}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td onClick={()=>{props.setCredentialId(item.CredentialId);props.setModal("remove")}} className={`whitespace-nowrap cursor-pointer px-3 py-4 text-sm text-${contextValue}-600 hover:text-${contextValue}-500`}>
                              Remove
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  ) : (
                    <></>
                  )}
                  {props.linkedAccounts?.filter(
                    (type) => type.SocialMediaName === "instagram"
                  )?.length ? (
                    <Fragment>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-indigo-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                           <img src="/assets/Instagram.svg"  width={100}/>
                        </th>
                      </tr>
                      {props.linkedAccounts
                        .filter((type) => type.SocialMediaName === "instagram")
                        .map((item, index) => (
                          <tr
                            key={index}
                            className={classNames(
                              index === 0
                                ? "border-gray-300"
                                : "border-gray-200",
                              "border-t"
                            )}
                          >
                            <td className="whitespace-nowrap relative py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div>
                                <div className="flex items-center">
                                  {" "}
                                  { item.ProfileImage? <img
                                    className="h-8 rounded-full mr-3"
                                    src={item.ProfileImage}
                                  /> : <HiUserCircle size={30} className="h-8 rounded-full mr-3"/>}
                                  <span className="w-24 truncate">{item.PageName}</span>
                                </div>
                                {/* <div className="bg-insta mr-4 rounded-full absolute flex w-4 -mt-4 ml-5 z-50 justify-center  items-center p-1 ">
                                  <BsInstagram size={10} color={"#fff"} />
                                </div> */}
                                {/* <div className="ml-8">{item.PageName}</div> */}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td onClick={()=>{props.setCredentialId(item.CredentialId);props.setModal("remove")}} className={`whitespace-nowrap cursor-pointer px-3 py-4 text-sm text-${contextValue}-600 hover:text-${contextValue}-500`}>
                              Remove
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  ) : (
                    <></>
                  )}
                  {props.linkedAccounts?.filter(
                    (type) => type.SocialMediaName === "twitter"
                  )?.length ? (
                    <Fragment>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-indigo-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                           <img src="/assets/Twitter.svg"  width={100}/>
                        </th>
                      </tr>
                      {props.linkedAccounts
                        .filter((type) => type.SocialMediaName === "twitter")
                        .map((item, index) => (
                          <tr
                            key={index}
                            className={classNames(
                              index === 0
                                ? "border-gray-300"
                                : "border-gray-200",
                              "border-t"
                            )}
                          >
                            <td className="whitespace-nowrap relative py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div>
                                <div className="flex items-center">
                                  {" "}
                                  { item.ProfileImage? <img
                                    className="h-8 rounded-full mr-3"
                                    src={item.ProfileImage}
                                  /> : <HiUserCircle size={30} className="h-8 rounded-full mr-3"/>}
                                  <span className="w-24 truncate">{item.PageName}</span>
                                </div>
                                {/* <div className="bg-twitter mr-4 rounded-full absolute flex w-4 -mt-4 ml-5 z-50 justify-center  items-center p-1 ">
                                  <FaTwitter size={10} color={"#fff"} />
                                </div> */}
                                {/* <div className="ml-8">{item.PageName}</div> */}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td onClick={()=>{props.setCredentialId(item.CredentialId);props.setModal("remove")}} className={`whitespace-nowrap cursor-pointer px-3 py-4 text-sm text-${contextValue}-600 hover:text-${contextValue}-500`}>
                              Remove
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  ) : (
                    <></>
                  )}
                  {props.linkedAccounts?.filter((type) =>
                    type.SocialMediaName?.includes("linkedin")
                  )?.length ? (
                    <Fragment>
                      <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-indigo-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                           <img src="/assets/Linkedin.svg"  width={80}/>
                        </th>
                      </tr>
                      {props.linkedAccounts
                        .filter((type) =>
                          type.SocialMediaName.includes("linkedin")
                        )
                        .map((item, index) => (
                          <tr
                            key={index}
                            className={classNames(
                              index === 0
                                ? "border-gray-300"
                                : "border-gray-200",
                              "border-t"
                            )}
                          >
                            <td className="whitespace-nowrap relative py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div>
                                <div className="flex items-center">
                                  {" "}
                                  { item.ProfileImage? <img
                                    className="h-8 rounded-full mr-3"
                                    src={item.ProfileImage}
                                  /> : <HiUserCircle size={30} className="h-8 rounded-full mr-3"/>}
                                  <span className="w-24 truncate">{item.PageName}</span>
                                </div>
                                {/* <div className="bg-linkedIn mr-4 rounded-full absolute flex w-4 -mt-4 ml-5 z-50 justify-center  items-center p-1 ">
                                  <FaLinkedinIn size={10} color={"#fff"} />
                                </div> */}
                                {/* <div className="ml-8">{item.PageName}</div> */}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td onClick={()=>{props.setCredentialId(item.CredentialId);props.setModal("remove")}} className={`whitespace-nowrap cursor-pointer px-3 py-4 text-sm text-${contextValue}-600 hover:text-${contextValue}-500`}>
                              Remove
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  ) : (
                    <></>
                  )}
                  {props.linkedAccounts?.filter((type) =>
                    type.SocialMediaName?.includes("youtube")
                  )?.length ? (
                    <Fragment>
                        <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-indigo-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                           <img src="/assets/YouTube-Logo.svg" className="-mx-3 -my-5"  width={100}/>
                        </th>
                      </tr>
                      {props.linkedAccounts
                        .filter((type) =>
                          type.SocialMediaName.includes("youtube")
                        )
                        .map((item, index) => (
                          <tr
                            key={index}
                            className={classNames(
                              index === 0
                                ? "border-gray-300"
                                : "border-gray-200",
                              "border-t"
                            )}
                          >
                            <td className="whitespace-nowrap relative py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div>
                                <div className="flex items-center">
                                  {" "}
                                  { item.ProfileImage? <img
                                    className="h-8 rounded-full mr-3"
                                    src={item.ProfileImage}
                                  /> : <HiUserCircle size={30} className="h-8 rounded-full mr-3"/>}
                                  <span className="">{item.PageName}</span>
                                </div>
                                {/* <div className="bg-red-500 mr-4 rounded-full absolute flex w-4 -mt-4 ml-5 z-50 justify-center  items-center p-1 ">
                                  <FaYoutube size={10} color={"#fff"} />
                                </div> */}
                                {/* <div className="ml-8">{item.PageName}</div> */}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td onClick={()=>{props.setCredentialId(item.CredentialId);props.setModal("remove")}}  className={`whitespace-nowrap text-${contextValue}-600 hover:text-${contextValue}-500 cursor-pointer px-3 py-4 text-sm`}>
                              Remove
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  ) : (
                    <></>
                  )}
                   {props.linkedAccounts?.filter((type) =>
                    type.SocialMediaName?.includes("gmb")
                  )?.length ? (
                    <Fragment>
                        <tr className="border-t border-gray-200">
                        <th
                          colSpan={5}
                          scope="colgroup"
                          className="bg-indigo-50 px-4 py-1 text-left text-sm font-semibold text-gray-900 sm:px-6"
                        >
                           <img src="/assets/google-my-business-logo.svg" className="w-30 h-10"/>
                   
                        </th>
                      </tr>
                      {props.linkedAccounts
                        .filter((type) =>
                          type.SocialMediaName.includes("gmb")
                        )
                        .map((item, index) => (
                          <tr
                            key={index}
                            className={classNames(
                              index === 0
                                ? "border-gray-300"
                                : "border-gray-200",
                              "border-t"
                            )}
                          >
                            <td className="whitespace-nowrap relative py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <div>
                                <div className="flex items-center">
                                  {" "}
                                  { item.ProfileImage? <img
                                    className="h-8 rounded-full mr-3"
                                    src={item.ProfileImage}
                                  /> : <HiUserCircle size={30} className="h-8 rounded-full mr-3"/>}
                                  <span className="">{item.PageName}</span>
                                </div>
                                {/* <div className="bg-red-500 mr-4 rounded-full absolute flex w-4 -mt-4 ml-5 z-50 justify-center  items-center p-1 ">
                                  <FaYoutube size={10} color={"#fff"} />
                                </div> */}
                                {/* <div className="ml-8">{item.PageName}</div> */}
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {"-"}
                            </td>
                            <td onClick={()=>{props.setCredentialId(item.CredentialId);props.setModal("remove")}}  className={`whitespace-nowrap text-${contextValue}-600 hover:text-${contextValue}-500 cursor-pointer px-3 py-4 text-sm`}>
                              Remove
                            </td>
                          </tr>
                        ))}
                    </Fragment>
                  ) : (
                    <></>
                  )}
                 
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Table;
