import React,{useState,useEffect} from 'react';
import ProgressBar from '../../../Components/UserRegistrationComponent/ProgressBar';
import classes from './SignupStep.module.css'
import {useNavigate} from "react-router-dom";
import ActionButton from '../../../Components/ActionButton';
import OPInput from '../../../Components/OPInput';
import Modal from "../../../Components/Modal";
const SignupStep = (props) => {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [modal, setModal] = useState("");
  const navigate = useNavigate()
  const loginToken=localStorage.getItem('UserToken');
  const email=localStorage.getItem('Email');
  useEffect(() => {
    GetUserDetails();
  }, []);
  const formValidation = () => {
    const regex=/[a-zA-Z]/;
    let errors = {};
    let isValid = true;
    if (!firstName || firstName === ""|| !firstName.match(regex)) {
      errors.firstName = "Please enter first name";
      isValid = false;
    }
    if (!lastName || lastName === ""|| !lastName.match(regex)) {
      errors.lastName = "Please enter last name";
      isValid = false;
    }
    if (!mobile || mobile === ""||mobile.length<10) {
      errors.mobile = "Please enter a valid mobile number";
      isValid = false;
    }
    if (!jobTitle || jobTitle === ""|| !jobTitle.match(regex)) {
      errors.jobTitle = "Please enter job title";
      isValid = false;
    }
    setError(errors);
    return isValid;
  }
  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobile(e.target.value);
    }
  };
  const GetUserDetails=()=>{
    var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "UserToken": loginToken
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://promote.onecorp.co.in/api/v1/Ai/GetUserInfo", requestOptions)
  .then(response => response.json())
  .then(result =>{
    if (result.status_code === 200 && result.status === "Success") {
      setFirstName(result.data.FirstName)
      setLastName(result.data.LastName)
      setMobile(result.data.Mobile)
      setJobTitle(result.data.JobTitle)
    }

  })
  .catch(error => console.log('error', error));
  }
  const checkUserTrial=()=>{
    setLoading(true)
    var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "Email": email,
  "FirstName":firstName,
  "LastName":lastName,
  "PhoneNumber": mobile,
  "JobTitle": jobTitle,
  "ProductName": "onepost"
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://promote.onecorp.co.in/api/v1/SASSignup", requestOptions)
.then((response) => response.json())
.then((result) => {
  if (result.status_code === 200 && result.status === "Success") {
    localStorage.setItem("FirstName", result.data.FirstName);
    localStorage.setItem("LastName", result.data.LastName);
    localStorage.setItem("Mobile", result.data.Mobile);
    localStorage.setItem("userName", result.data.UserName);
    localStorage.setItem("Email", result.data.Email);
    localStorage.setItem("ProfilePicture", result.data.ProfilePicture);
    localStorage.setItem("UserId", result.data.UserId);
    // localStorage.setItem("LoggedIn", true);
    sessionStorage.setItem("UserToken", result.data.UserToken);
    localStorage.setItem("UserToken", result.data.UserToken);
    sessionStorage.setItem("FirstName", result.data.FirstName);
    sessionStorage.setItem("LastName", result.data.LastName);
    sessionStorage.setItem("Mobile", result.data.Mobile);
    sessionStorage.setItem("userName", result.data.UserName);
    sessionStorage.setItem("Email", result.data.Email);
    sessionStorage.setItem("UserId", result.data.UserId);
    navigate("/signup-step-three/"+result.data.TrialFormID);
  }
  else{
    setModal("apiFailed")
  }
})
.catch((error) => console.log("error", error))
.finally(() => setLoading(false));
  }
  return (
    <div className={classes.mainContainer}>
              {modal === "apiFailed" && (
        <Modal
        modalCase={"confirmation"}
        Text={"There is some issue , please contact support!"}
        setShowModal={setModal}
        iscancel={false}
        onClose={() => setModal("")}
        onOk={() => setModal("")}
      />
      )}
      <div className={classes.logoContainer}>
        <img src={"/assets/One_post.svg"} width={props.device === "Mobile" ? "50%" : "15%"} />
      </div>
      <div className={classes.inputSectionContainer}>
        <div className={classes.headImage}>
          <img src={"/assets/UserRegistrationImages/Almost.svg"} className="m-auto" width={"40px"} />
          <div className={classes.headingTextFirst}>Almost done!</div>
          <div className={classes.headingTextThird}>Step 2 of 3</div>
          <div className={classes.progressBar}> <ProgressBar screen={"secondScreen"} /> </div>
          <div className={classes.inputSectionFlex}>
          <div className={classes.inputContainer}>
            <OPInput
              input={"SingleLineInputSecType"}
              type={"text"}
              onChange={(e)=>setFirstName(e.target.value)}
              value={firstName}
              onInput={()=>error.firstName=""}
              label={"First Name"}
              placeholder={"First Name"}
            />
             <div className={classes.errors}> {error.firstName}</div>
          </div>
          <div className={classes.inputContainer}>
            <OPInput
              input={"SingleLineInputSecType"}
              type={"text"}
              label={"Last Name"}
              onInput={()=>error.lastName=""}
              onChange={(e)=>setLastName(e.target.value)}
              value={lastName}
              placeholder={"Last Name"}
            />
             <div className={classes.errors}> {error.lastName}</div>
          </div>
          </div>
          <div className={classes.inputContainerSection}>
            <OPInput
              input={"SingleLineInputSecType"}
              type={"tel"}
              onInput={()=>error.mobile=""}
              onChange={(e) => {
                handleChange(e);
              }}
              maxLength={10}
              value={mobile}
              label={"Phone Number"}
              placeholder={"Phone Number"}
            />
             <div className={classes.errors}> {error.mobile}</div>

          </div>
          <div className={classes.inputContainerSection}>
            <OPInput
              input={"SingleLineInputSecType"}
              type={"text"}
              onInput={()=>error.jobTitle=""}
              onChange={(e)=>setJobTitle(e.target.value)}
              value={jobTitle}
              label={"Job Title"}
              placeholder={"Job Title"}
            />
             <div className={classes.errors}> {error.jobTitle}</div>
          </div>
          <div className={classes.btnNext}>
          <ActionButton text={"Next"} loading={loading} btnType={"circular"} onClick={()=>formValidation()? checkUserTrial():console.log("something went wrong")}/>
          </div>
         
          <div className={classes.sasOneText}>SAS ONE</div>
        </div>


      </div>

    </div>
  )
}

export default SignupStep