import React, { useState,useEffect } from "react";
import OPInput from "../../../Components/OPInput";
import ProgressBar from "../../../Components/UserRegistrationComponent/ProgressBar";
import classes from "./LoginStep.module.css";
import { Link, useNavigate } from "react-router-dom";
import ActionButton from "../../../Components/ActionButton";
import Modal from "../../../Components/Modal";
const LoginStep = (props) => {
  const [email,setEmail]=useState();
  const [error, setError] = useState({});
  const [modal, setModal] = useState("");
  const [loading, setLoading] = useState(false);
  const loginToken = localStorage.getItem("UserToken");
  useEffect(() => {
    GetUserDetails();
  }, []);
  const formValidation = () => {
    let errors = {};
    let isValid = true;
    if (!email || email === "") {
      errors.email = "Please enter email address";
      isValid = false;
    } else if (!emailRegex.test(email)) {
      isValid = false;
      errors.email = "Please enter valid email address";
    }
    setError(errors);
    return isValid;
  };

  const emailRegex =
    /^(([^<>()/.,;:\s@"]+(\.[^<>()/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const navigate = useNavigate();
  const CheckUser = () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Email: email,
      ProductName: "onePost",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/Ai/CheckUserTrial",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success"&& !result.Smuserdata) {
          localStorage.setItem("Email",email)
          localStorage.removeItem('UserToken')
          navigate("/signup-step-two");
        }
       else if (result.status_code === 200 && result.status === "Success"&& result.Smuserdata) {
          localStorage.setItem("Email",result.Smuserdata.Email)
          navigate("/password");
        }
        else{
          setModal("apiFailed")
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const GetUserDetails=()=>{
    var myHeaders = new Headers();
myHeaders.append("Authorization", "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa");
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "UserToken": loginToken
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://promote.onecorp.co.in/api/v1/Ai/GetUserInfo", requestOptions)
  .then(response => response.json())
  .then(result =>{
    if (result.status_code === 200 && result.status === "Success") {
      setEmail(result.data.Email)
    }
  })
  .catch(error => console.log('error', error));
  }
  return (
    <div className={classes.mainContainer}>
              {modal === "apiFailed" && (
        <Modal
        modalCase={"confirmation"}
        Text={"There is some issue , please contact support!"}
        setShowModal={setModal}
        iscancel={false}
        onClose={() => setModal("")}
        onOk={() => setModal("")}
      />
      )}
      <div className={classes.logoContainer}>
        <img
          src={"/assets/One_post.svg"}
          width={props.device === "Mobile" ? "50%" : "15%"}
        />
      </div>
      <div className={classes.inputSectionContainer}>
        <div className={classes.headImage}>
          <img className="m-auto"
            src={"/assets/UserRegistrationImages/Bolt_icon.svg"}
            width={"35px"}
          />
          <div className={classes.headingTextFirst}>
            Start your free one post trial
          </div>
          <div className={classes.headingTextSecond}>
            Already have an account ?{" "}
            <Link to="/login" className={classes.loginButton}>
              {" "}
              Login{" "}
            </Link>
          </div>
          <div className={classes.headingTextThird}>Step 1 of 3</div>
          <div className={classes.progressBar}>
            {" "}
            <ProgressBar screen={"firstScreen"} />{" "}
          </div>
          <div className={classes.inputContainer}>
            <OPInput
              input={"SingleLineInputSecType"}
              type={"email"}
              onChange={(e)=>setEmail(e.target.value)}
              value={email}
              onInput={()=>error.email=""}
              label={"Work Email"}
              placeholder={"Enter your email"}
            />
             <div className={classes.errors}> {error.email}</div>
          </div>
          <div className={classes.btnNext}>
            <ActionButton
              text={"Next"}
              btnType={"circular"}
              loading={loading}
              onClick={() =>formValidation()? CheckUser():console.log("something went wrong")}
            />
          </div>
          <div className={classes.sasOneText}>SAS ONE</div>
        </div>
      </div>
    </div>
  );
};

export default LoginStep;
