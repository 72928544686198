import Cookies from 'js-cookie';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import TailwindModal from './TailwindCompnents/TailwindModal';

const Logout = (props) => {
    const navigate=useNavigate()
  

    const checkLoginType=Cookies.get("LoginType")
    const clearStorage=()=>{
      Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
      Cookies.remove("UserToken", {  domain:'.sasone.in' });
      Cookies.remove("FirstName", {  domain:'.sasone.in' });
      Cookies.remove("LastName", {  domain:'.sasone.in' });
      Cookies.remove("Mobile", {  domain:'.sasone.in' });
      Cookies.remove("userName", {  domain:'.sasone.in' });
      Cookies.remove("Email", {  domain:'.sasone.in' });
      Cookies.remove("UserId", {  domain:'.sasone.in' });
      Cookies.remove("LoginType", {  domain:'.sasone.in' });
      Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
      Cookies.remove("UserType", {  domain:'.sasone.in' });
      Cookies.remove("SignupUser", {  domain:'.sasone.in' });

      // Cookies.remove("LoggedIn");
      // Cookies.remove("UserToken");
      // Cookies.remove("FirstName");
      // Cookies.remove("LastName");
      // Cookies.remove("Mobile");
      // Cookies.remove("userName");
      // Cookies.remove("Email");
      // Cookies.remove("UserId");
      // Cookies.remove("LoginType");
      // Cookies.remove("ProfilePicture");
      navigate("/login")
    }
    const SignOutFunction=()=>{
      Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
      Cookies.remove("UserToken", {  domain:'.sasone.in' });
      Cookies.remove("FirstName", {  domain:'.sasone.in' });
      Cookies.remove("LastName", {  domain:'.sasone.in' });
      Cookies.remove("Mobile", {  domain:'.sasone.in' });
      Cookies.remove("userName", {  domain:'.sasone.in' });
      Cookies.remove("Email", {  domain:'.sasone.in' });
      Cookies.remove("UserId", {  domain:'.sasone.in' });
      Cookies.remove("LoginType", {  domain:'.sasone.in' });
      Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
      Cookies.remove("UserType", {  domain:'.sasone.in' });
      Cookies.remove("SignupUser", {  domain:'.sasone.in' });

      Cookies.set("googleLogin", "googleLogin", {  domain:'.sasone.in' })
      // Cookies.remove("LoggedIn");
      // Cookies.remove("UserToken");
      // Cookies.remove("FirstName");
      // Cookies.remove("LastName");
      // Cookies.remove("Mobile");
      // Cookies.remove("userName");
      // Cookies.remove("Email");
      // Cookies.remove("UserId");
      // Cookies.remove("LoginType");
      // Cookies.remove("ProfilePicture");
      // Cookies.set("googleLogin", "googleLogin")
      window.open("https://www.sasone.in/sasone-login","_self")
    }
  return (
  <>
  <TailwindModal
  modalCase={"Logout"}
  Text={"Are you sure you want to logout?"}
  setModal={props.setModal}
  onClick={() =>{checkLoginType==="Email"?clearStorage():SignOutFunction()}}
  modal={props.modal}
 /></>
  )
}

export default Logout

