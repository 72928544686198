import React, { Children, useEffect, useState } from "react";
import classes from "./Carousel.module.css";

const widthSpan = 100.1;

function Carousel(props) {
  const [sliderPosition, setSliderPosition] = useState(0);
  const [touchStartPosition, setTouchStartPosition] = useState(0);
  const [touchEndPosition, setTouchEndPosition] = useState(0);
  const [touched, setTouched] = useState(false);
  const [swiped, setSwiped] = useState(false);

  const [mouseStartPosition, setMouseStartPosition] = useState(0);
  const [mouseEndPosition, setMouseEndPosition] = useState(0);
  const [mouseClicked, setMouseClicked] = useState(false);
  const [mouseSwiped, setMouseSwiped] = useState(false);

  const { children, infinite } = props;
  var Childrens=props.prevPage==="createPost"?children.filter((item)=>item!==undefined):children.filter((item)=>item!==false)
  useEffect(() => {
    cardPosition();
  }, [Childrens[0].props.cardPosition]);
  const prevSlideHandler = () => {
    let newPosition = sliderPosition;
    if (newPosition > 0) {
      newPosition = newPosition - 1;
    } else if (infinite) {
      newPosition = Childrens.length - 1 || 0;
    }
    translateFullSlides(newPosition);
    setSliderPosition(newPosition);
  };

  const nextSlideHandler = () => {
    let newPosition = sliderPosition;
    if (newPosition < Childrens.length - 1) {
      newPosition = newPosition + 1;
    } else if (infinite) {
      newPosition = 0;
    }
    translateFullSlides(newPosition);
    setSliderPosition(newPosition);
  };

  const jumpToSlideHandler = (id) => {
    translateFullSlides(id);
    setSliderPosition(id);
  };
  const cardPosition = () => {
    Childrens?.map((item, index) => {
      if (item?.props?.cardPosition === item?.props?.text) {
        jumpToSlideHandler(index);
      }
    });
  };
  const speedUpAnimation = () => {
    for (
      let i = Math.max(0, sliderPosition - 2);
      i < (Math.min(Childrens.length, sliderPosition + 3) || 1);
      i++
    ) {
      let elem = document.getElementById(`carouselitem` + i);
      elem.classList.add(classes.fastAnimation);
    }
  };
  const slowDownAnimation = () => {
    for (
      let i = Math.max(0, sliderPosition - 2);
      i < (Math.min(Childrens.length, sliderPosition + 3) || 1);
      i++
    ) {
      let elem = document.getElementById(`carouselitem` + i);
      elem.classList.remove(classes.fastAnimation);
    }
  };

  const touchStartHandler = (e) => {
    speedUpAnimation();
    setTouchStartPosition(e.targetTouches[0].clientX);
    setTouchEndPosition(e.targetTouches[0].clientX);
    setTouched(true);
  };

  const touchMoveHandler = (e) => {
    setTouchEndPosition(e.targetTouches[0].clientX);
    const frameWidth = document.getElementById("DisplayFrame").offsetWidth;
    const translateDist =
      ((touchEndPosition - touchStartPosition) / frameWidth) * 100;
    translatePartialSlides(translateDist);
    if (touched === true) {
      setSwiped(true);
    }
  };

  const touchEndHandler = (e) => {
    if (swiped) {
      slowDownAnimation();
      if (touchStartPosition - touchEndPosition > 75) {
        nextSlideHandler();
      } else if (touchStartPosition - touchEndPosition < -75) {
        prevSlideHandler();
      } else {
        jumpToSlideHandler(sliderPosition);
      }
    }
    setTouched(false);
    setSwiped(false);
  };

  const mouseStartHandler = (e) => {
    e.preventDefault();
    speedUpAnimation();
    setMouseStartPosition(e.clientX);
    setMouseEndPosition(e.clientX);
    setMouseClicked(true);
  };

  const mouseMoveHandler = (e) => {
    e.preventDefault();
    var frameWidth = document.getElementById("DisplayFrame").offsetWidth;
    if (mouseClicked === true) {
      setMouseEndPosition(e.clientX);
      let translateDist =
        ((mouseEndPosition - mouseStartPosition) / frameWidth) * 100;
      translatePartialSlides(translateDist);
      setMouseSwiped(true);
    }
  };

  const mouseEndHandler = (e) => {
    slowDownAnimation();
    if (mouseSwiped === true) {
      if (mouseStartPosition - mouseEndPosition > 100) {
        nextSlideHandler();
      } else if (mouseStartPosition - mouseEndPosition < -100) {
        prevSlideHandler();
      } else {
        jumpToSlideHandler(sliderPosition);
      }
    }
    setMouseClicked(false);
    setMouseSwiped(false);
  };

  const wheelHandler = () => {
    document.getElementById("DisplayFrame").scrollLeft = 0;
  };

  const translatePartialSlides = (toTranslate) => {
    let currentTranslation = -sliderPosition * widthSpan;
    let totalTranslation = currentTranslation + toTranslate;
    for (var i = 0; i < (Childrens.length || 1); i++) {
      let elem = document.getElementById(`carouselitem` + i);
      elem.style.transform = `translateX(` + totalTranslation + `%)`;
    }
  };

  const translateFullSlides = (newPosition) => {
    let toTranslate = -widthSpan * newPosition;
    for (var i = 0; i < (Childrens.length || 1); i++) {
      let elem = document.getElementById(`carouselitem` + i);
      elem.style.transform = `translateX(` + toTranslate + `%)`;
    }
  };
  const displayItems = Children?.map(Childrens, (child, index) => (
    <div className={classes.carouselItem} id={`carouselitem` + index}>
      {child}
    </div>
  ));
  const positionIndicators = Childrens?.map((child, index) => (
    <div
      className={
        sliderPosition === index
          ? classes.positionIndicator.concat(" " + classes.currentPosition)
          : classes.positionIndicator
      }
      onClick={() => jumpToSlideHandler(index)}
    ></div>
  ));

  return (
    <div>
      <div className={classes.alignBox}>
        <div className={classes.container}>
          <div
            className={classes.displayFrame}
            id="DisplayFrame"
            onTouchStart={(e) => touchStartHandler(e)}
            onTouchMove={(e) => touchMoveHandler(e)}
            onTouchEnd={(e) => touchEndHandler(e)}
            onMouseDown={(e) => mouseStartHandler(e)}
            onMouseMove={(e) => mouseMoveHandler(e)}
            onMouseUp={(e) => mouseEndHandler(e)}
            onMouseLeave={(e) => mouseEndHandler(e)}
            onWheel={() => wheelHandler()}
          >
            {displayItems}
          </div>
        </div>

        <div className={classes.navigation}>{positionIndicators}</div>
      </div>
    </div>
  );
}

export default Carousel;
