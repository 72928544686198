import React from 'react'
import CalendarMonthView from '../../Components/TailwindCompnents/CalendarMonthView'
import DashboardComp from '../../Components/TailwindCompnents/DashoardComp'

const ScheulePost = () => {
  return (
    <DashboardComp>
                <div className=" overflow-hidden mb-5 sm:mx-16 mx-6 rounded-lg bg-white shadow">
                          <div className={ localStorage.getItem("trialexpire")==="yes"?"p-6 pointer-events-none opacity-50":"p-6"}>
                          <h1 className="text-xl font-semibold text-gray-900">
                          Your Scheduled Posts
                        </h1>
                        <div className="text-gray-400">
                        All your Scheduled posts across social media published by One Post 
                        </div>
        <CalendarMonthView /></div></div>
    </DashboardComp>
  )
}

export default ScheulePost