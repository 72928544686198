import Cookies from 'js-cookie';
import {SiSubstack} from 'react-icons/si'
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
const SubscriptionCard = (props) => {
  const loginToken = Cookies.get("UserToken");
  const contextValue = Cookies.get("UserType")
  const days=(date)=>{

    const dateObj = new Date(date);
    const day = dateObj?.getDate();
    const month = dateObj?.getMonth();
    const year = dateObj?.getFullYear();
    const dateObjCurrent = new Date();
    var Difference_In_Time = dateObj.getTime() - dateObjCurrent.getTime();
    var Difference_In_Days = Math.ceil(Difference_In_Time / (1000 *3600 *24));
      return Difference_In_Days>30? "Expiring on " +  ((day <= 9 ? "0" : "")+ day + "-" + (month + 1)  + "-" + year):`Expiring in ${Difference_In_Days} days`
  }
  return (
    <div className={"mb-5 rounded-lg bg-white shadow"}>
    <div className="p-6 ">
            <div className="flex flex-col   lg:items-center ">
              <div className="lg:min-w-0 lg:flex-1">
          <div className='flex items-center'> 
           <div className={`rounded-md bg-${contextValue}-600 p-3 mr-2`}>
                <SiSubstack className="h-4 w-4  text-white" aria-hidden="true" />
              </div>     <h3 className={`text-lg font-semibold leading-8 tracking-tight text-${contextValue}-500`}> SAS One Post Subscription</h3>
              </div>  
              <div className="mt-2 text-base leading-7 text-gray-600">
             {props.trialData.Trail==="Trial Expire"? <div className='font-medium text-red-600'>Your One Post subscription has been expired</div>:props.trialData.Trail==="Trial Available"? <div className='font-medium text-red-600'>Your One Post subscription is not active  </div> : <div className='font-medium text-red-500'> {days(props.trialData?.SubscriptionData?.ValidTo)}  </div>}
                 {props.trialData.Trail==="Trial Expire"?"Renew your subscription  to continue hassle free usage":props.trialData.Trail==="Trial Available"?"Start using One Post to manage your social media ": "Renew your subscription before expiry to continue hassle free usage" }
                </div>
              </div>
              <div>
                <a target={'_blank'}
                  href={`https://www.sasone.in/pricing/onepost`}
                  className={`inline-block mt-5 rounded-lg bg-${contextValue}-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-${contextValue}-600 hover:bg-${contextValue}-100`}
                >
                 {props.trialData.Trail==="Trial Available"? "Get started":"Renew now"}<span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
  )
}
export default SubscriptionCard