import React from "react";
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext";
import Cookies from "js-cookie";
const SocialCard = (props) => {
  const contextValue = Cookies.get("UserType");
  const DomainName = window.location.hostname;
  const hrefLink = (path) => {
    if (DomainName === "onepost.sasone.in") {
      if (path === "Instagram") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://onepost.sasone.in/linked-accounts/instagram/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "LinkedIn") {
        return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=LinkedIn&scope=r_emailaddress,r_liteprofile,w_member_social,r_organization_admin,rw_organization_admin,r_organization_social,w_organization_social&client_id=775gacntogd1of&redirect_uri=https://onepost.sasone.in/linked-accounts/linkedin/";
      }
      if (path === "Facebook") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://onepost.sasone.in/linked-accounts/facebook/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "Twitter") {
        return "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=cXFQbG1nQWkybmhrRkFzM25MTzg6MTpjaQ&redirect_uri=https://onepost.sasone.in/linked-accounts/twitter/&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=twitter&code_challenge=challenge&code_challenge_method=plain";
      }
      if (path === "Google My Business") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://onepost.sasone.in/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true";
      }
      if (path === "YouTube") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://onepost.sasone.in/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=youtube&access_type=offline&include_granted_scopes=true";
      }
    } else if (DomainName === "testonepost.sasone.in") {
      if (path === "Instagram") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://testonepost.sasone.in/linked-accounts/instagram/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "LinkedIn") {
        return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=LinkedIn&scope=r_emailaddress,r_liteprofile,w_member_social,r_organization_admin,rw_organization_admin,r_organization_social,w_organization_social&client_id=775gacntogd1of&redirect_uri=https://testonepost.sasone.in/linked-accounts/linkedin/";
      }
      if (path === "Facebook") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=https://testonepost.sasone.in/linked-accounts/facebook/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "Twitter") {
        return "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=cXFQbG1nQWkybmhrRkFzM25MTzg6MTpjaQ&redirect_uri=https://testonepost.sasone.in/linked-accounts/twitter/&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=twitter&code_challenge=challenge&code_challenge_method=plain";
      }
      if (path === "Google My Business") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://testonepost.sasone.in/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true";
      }
      if (path === "YouTube") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://testonepost.sasone.in/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=youtube&access_type=offline&include_granted_scopes=true";
      }
    } else if (DomainName === "localhost") {
      if (path === "Instagram") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=http://localhost:3000/linked-accounts/instagram/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "LinkedIn") {
        return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=LinkedIn&scope=r_emailaddress,r_liteprofile,w_member_social,r_organization_admin,rw_organization_admin,r_organization_social,w_organization_social&client_id=775gacntogd1of&redirect_uri=http://localhost:3000/linked-accounts/linkedin/";
      }
      if (path === "Facebook") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=http://localhost:3000/linked-accounts/facebook/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "Twitter") {
        return "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=cXFQbG1nQWkybmhrRkFzM25MTzg6MTpjaQ&redirect_uri=http://localhost:3000/linked-accounts/twitter/&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=twitter&code_challenge=challenge&code_challenge_method=plain";
      }
      if (path === "Google My Business") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=http://localhost:3000/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true";
      }
      if (path === "YouTube") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=http://localhost:3000/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=youtube&access_type=offline&include_granted_scopes=true";
      }
    } else if (DomainName === "infoseedream.sasone.in") {
      if (path === "Instagram") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=http://infoseedream.sasone.in/linked-accounts/instagram/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "LinkedIn") {
        return "https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=LinkedIn&scope=r_emailaddress,r_liteprofile,w_member_social,r_organization_admin,rw_organization_admin,r_organization_social,w_organization_social&client_id=775gacntogd1of&redirect_uri=http://infoseedream.sasone.in/linked-accounts/linkedin/";
      }
      if (path === "Facebook") {
        return "https://www.facebook.com/v14.0/dialog/oauth?response_type&client_id=807577897280884&redirect_uri=http://infoseedream.sasone.in/linked-accounts/facebook/&scope=public_profile,email,read_insights,pages_show_list,instagram_basic,instagram_manage_comments,instagram_manage_insights,instagram_content_publish,pages_read_engagement,pages_manage_posts,pages_read_user_content&response_type=code&state=facebook";
      }
      if (path === "Twitter") {
        return "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=cXFQbG1nQWkybmhrRkFzM25MTzg6MTpjaQ&redirect_uri=http://infoseedream.sasone.in/linked-accounts/twitter/&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=twitter&code_challenge=challenge&code_challenge_method=plain";
      }
      if (path === "Google My Business") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=http://infoseedream.sasone.in/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true";
      }
      if (path === "YouTube") {
        return "https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=http://infoseedream.sasone.in/linked-accounts/youtube/&scope=https://www.googleapis.com/auth/youtube.force-ssl&state=youtube&access_type=offline&include_granted_scopes=true";
      }
    }
  };
  return (
    <div
      // key={key}
      className="bg-white flex flex-col border-2 p-4 justify-between"
    >
      <div className="relative flex items-center flex-col w-full  ">
        <a href={hrefLink(props.item.name)}> {props.item.imageUrl} </a>

        <div className="relative mt-4 px-4">
          <a href={hrefLink(props.item.name)}>
            <h3 className="text-base text-center font-bold  text-gray-900">
              {props.item.name}
            </h3>{" "}
          </a>
        </div>
        <div className="relative mt-1 px-4">
          <h3 className="text-sm font-normal text-slate-400">
            {props.item.type}
          </h3>
        </div>
      </div>
      <div className="mt-6">
        {props.item.name === "Twitter Testing" ? (
          <div
            onClick={props.onClickBtn}
            className={`relative flex items-center justify-center  py-2 px-8 text-sm font-bold  cursor-pointer text-${contextValue}-600 hover:tex-${contextValue}-500`}
          >
            Connect
            <span className="sr-only">, {props.item.social}</span>
          </div>
        ) : (
          <a
            href={hrefLink(props.item.name)}
            className={`relative flex items-center justify-center  py-2 px-8 text-sm font-bold  cursor-pointer text-${contextValue}-600 hover:text-${contextValue}-500`}
          >
            Connect
            <span className="sr-only">, {props.item.social}</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default SocialCard;
