import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useState } from 'react'

const YoutubeModal = (props) => {
  const [error, setError] = useState({})
  const cancelButtonRef = useRef(null)
  const categories = [
    {
      "kind": "youtube#videoCategory",
      "etag": "grPOPYEUUZN3ltuDUGEWlrTR90U",
      "id": "1",
      "snippet": {
        "title": "Film & Animation",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "Q0xgUf8BFM8rW3W0R9wNq809xyA",
      "id": "2",
      "snippet": {
        "title": "Autos & Vehicles",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "qnpwjh5QlWM5hrnZCvHisquztC4",
      "id": "10",
      "snippet": {
        "title": "Music",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "HyFIixS5BZaoBdkQdLzPdoXWipg",
      "id": "15",
      "snippet": {
        "title": "Pets & Animals",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "PNU8SwXhjsF90fmkilVohofOi4I",
      "id": "17",
      "snippet": {
        "title": "Sports",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "5kFljz9YJ4lEgSfVwHWi5kTAwAs",
      "id": "18",
      "snippet": {
        "title": "Short Movies",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "ANnLQyzEA_9m3bMyJXMhKTCOiyg",
      "id": "19",
      "snippet": {
        "title": "Travel & Events",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "0Hh6gbZ9zWjnV3sfdZjKB5LQr6E",
      "id": "20",
      "snippet": {
        "title": "Gaming",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "q8Cp4pUfCD8Fuh8VJ_yl5cBCVNw",
      "id": "21",
      "snippet": {
        "title": "Videoblogging",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "cHDaaqPDZsJT1FPr1-MwtyIhR28",
      "id": "22",
      "snippet": {
        "title": "People & Blogs",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "3Uz364xBbKY50a2s0XQlv-gXJds",
      "id": "23",
      "snippet": {
        "title": "Comedy",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "0srcLUqQzO7-NGLF7QnhdVzJQmY",
      "id": "24",
      "snippet": {
        "title": "Entertainment",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "bQlQMjmYX7DyFkX4w3kT0osJyIc",
      "id": "25",
      "snippet": {
        "title": "News & Politics",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "Y06N41HP_WlZmeREZvkGF0HW5pg",
      "id": "26",
      "snippet": {
        "title": "Howto & Style",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "yBaNkLx4sX9NcDmFgAmxQcV4Y30",
      "id": "27",
      "snippet": {
        "title": "Education",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "Mxy3A-SkmnR7MhJDZRS4DuAIbQA",
      "id": "28",
      "snippet": {
        "title": "Science & Technology",
        "assignable": true,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "4pIHL_AdN2kO7btAGAP1TvPucNk",
      "id": "30",
      "snippet": {
        "title": "Movies",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "Iqol1myDwh2AuOnxjtn2AfYwJTU",
      "id": "31",
      "snippet": {
        "title": "Anime/Animation",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "tzhBKCBcYWZLPai5INY4id91ss8",
      "id": "32",
      "snippet": {
        "title": "Action/Adventure",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "ii8nBGYpKyl6FyzP3cmBCevdrbs",
      "id": "33",
      "snippet": {
        "title": "Classics",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "Y0u9UAQCCGp60G11Arac5Mp46z4",
      "id": "34",
      "snippet": {
        "title": "Comedy",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "_YDnyT205AMuX8etu8loOiQjbD4",
      "id": "35",
      "snippet": {
        "title": "Documentary",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "eAl2b-uqIGRDgnlMa0EsGZjXmWg",
      "id": "36",
      "snippet": {
        "title": "Drama",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "HDAW2HFOt3SqeDI00X-eL7OELfY",
      "id": "37",
      "snippet": {
        "title": "Family",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "QHiWh3niw5hjDrim85M8IGF45eE",
      "id": "38",
      "snippet": {
        "title": "Foreign",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "ztKcSS7GpH9uEyZk9nQCdNujvGg",
      "id": "39",
      "snippet": {
        "title": "Horror",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "Ids1sm8QFeSo_cDlpcUNrnEBYWA",
      "id": "40",
      "snippet": {
        "title": "Sci-Fi/Fantasy",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "qhfgS7MzzZHIy_UZ1dlawl1GbnY",
      "id": "41",
      "snippet": {
        "title": "Thriller",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "TxVSfGoUyT7CJ7h7ebjg4vhIt6g",
      "id": "42",
      "snippet": {
        "title": "Shorts",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },
    {
      "kind": "youtube#videoCategory",
      "etag": "o9w6eNqzjHPnNbKDujnQd8pklXM",
      "id": "43",
      "snippet": {
        "title": "Shows",
        "assignable": false,
        "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
      }
    },

  ]
  const formValidation = () => {
    let errors = {};
    let isValid = true;

    if (props.title === "") {
      errors.title = "Please enter Title for post";
      isValid = false;
    }
    // if (props.youtubeContent === "") {
    //   errors.description = "Please enter Title for post";
    //   isValid = false;
    // }
    if (props.tags === "") {
      errors.tags = "Please enter tags  for post";
      isValid = false;
    }
    if (props.category === "") {
      errors.category = "Please select category";
      isValid = false;
    }
    if (props.visibility === "") {
      errors.visibility = "Please select post visibilty";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  return (
    <Transition.Root show={(props.modal) ? true : false} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.setModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:w-96">
                <div as={Fragment} appear>
                  <div className="block w-full mt-2   p-2 px-4  outline-none border-gray-400shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ">
                    <div className='text-base font-semibold '>Youtube Video Options</div>
                    <div>
                      <div className="mt-4">
                        <label htmlFor="event-name" className="block text-sm font-medium text-gray-700">
                          Title <span className='text-red-500'>*</span>
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="event-name"
                            onChange={(e) => { props.setTitle(e.target.value); error.title = "" }}
                            value={props.title}
                            id="event-name"
                            autoComplete="given-name"
                            className="block w-full p-2 outline-none rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          <div className={"text-red-500  text-sm"}>
                            {" "}
                            {error.title}
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-4">
                        <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                          Description <span className='text-red-500'>*</span>
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="about"
                            name="about"
                            rows={3}
                            onChange={(e) => { props.setYoutubeContent(e.target.value); error.description = "" }}
                            value={props.youtubeContent}
                            className="block w-full p-2 rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                          <div className={"text-red-500  text-sm"}>
                            {" "}
                            {error.description}
                          </div>
                        </div>

                      </div> */}
                      <div className="pt-4">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                          Visibility <span className='text-red-500'>*</span>
                        </label>
                        <div className="mt-1">
                          <select
                            id="type"
                            onChange={(e) => { props.setVisbility(e.target.value) }}
                            value={props.visibility}
                            name="type"
                            autoComplete="country-name"
                            className="block w-full p-2 outline-none rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          >
                            <option value={"Public"}>Public (Every one can see)</option>
                            <option value={"Private"}>Private</option>
                          </select>

                        </div>
                      </div>
                      <div className="pt-4">
                        <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                          Category <span className='text-red-500'>*</span>
                        </label>
                        <div className="mt-1">
                          <select
                            id="category"
                            onChange={(e) => { props.setCategory(e.target.value); error.category = "" }}
                            value={props.category}
                            name="category"
                            autoComplete="country-name"
                            className="block w-full p-2 outline-none rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          >
                            <option className='text-gray-500' hidden>Select Category</option>
                            {categories.map((item) => {
                              return item.snippet.assignable && <option className='text-gray-500' value={item.id}>{item.snippet.title}</option>
                            })}
                          </select>
                          <div className={"text-red-500  text-sm"}>
                            {" "}
                            {error.category}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                          Tags <span className='text-red-500'>*</span>
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="tags"
                            name="tags"
                            onChange={(e) => { props.setTags(e.target.value); error.tags = "" }}
                            value={props.tags}
                            placeholder='Add Tags '
                            rows={3}
                            className="block w-full p-2 outline-none rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            defaultValue={''}
                          />
                          <div className={"text-red-500  text-sm"}>
                            {" "}
                            {error.tags}
                          </div>
                        </div>
                        <div className='text-sm text-gray-500'>Separate your tags with , or ;</div>

                      </div>
                    </div>


                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 flex justify-end  sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent text-indigo-600 px-4 py-1 text-base font-medium  shadow-sm hover:bg-indigo-200 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => props.setModal("")}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => { formValidation() ? props.setModal("") : console.log("Please fill all required fields"); }}
                  >
                    Done
                  </button>

                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default YoutubeModal