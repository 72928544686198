import React, { useState, useEffect } from "react";
import classes from "./LoginNew.module.css";
import OPInput from "../../Components/OPInput";
import ActionButton from "../../Components/ActionButton";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Modal from "../../Components/Modal";
import Cookies from "js-cookie";
const LoginNew = (props) => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState({});
  const [modal, setModal] = useState("");
  const [loginFailed, setLoginFailed] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const DomainName = window.location.hostname;
  const CompanyLogo = Cookies.get("CompanyLogo");
  const CompanyName = Cookies.get("CompanyName");
  const ProductName = Cookies.get("ProductName");


  useEffect(() => {
    if (Cookies.get("LoggedIn")) {
      window.open("/","_self");
    }
    if (DomainName !== "localhost" && DomainName !== "onepost.sasone.in") {
      CompanyDetail();
    }
  }, []);

  

  const login = async () => {
    setLoginFailed("");
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Password: password,
      Email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://api.sasone.in/apiadmin/v3/onepost/SASLogin",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          Cookies.set("LoggedIn", true, { domain: ".sasone.in" });
          Cookies.set("UserToken", result.data.UserToken, {
            domain: ".sasone.in",
          });
          Cookies.set("FirstName", result.data.FirstName, {
            domain: ".sasone.in",
          });
          Cookies.set("LastName", result.data.LastName, {
            domain: ".sasone.in",
          });
          Cookies.set("Mobile", result.data.Mobile, { domain: ".sasone.in" });
          Cookies.set("userName", result.data.UserName, {
            domain: ".sasone.in",
          });
          Cookies.set("Email", result.data.Email, { domain: ".sasone.in" });
          Cookies.set("UserId", result.data.UserId, { domain: ".sasone.in" });
          Cookies.set("ProfilePicture", result.data.ProfilePicture, {
            domain: ".sasone.in",
          });
          Cookies.set("LoginType", "Email", { domain: ".sasone.in" });
          

          // Cookies.set("LoggedIn",true);
          // Cookies.set("UserToken", result.data.UserToken);
          // Cookies.set("FirstName", result.data.FirstName);
          // Cookies.set("LastName", result.data.LastName);
          // Cookies.set("Mobile", result.data.Mobile);
          // Cookies.set("userName", result.data.UserName);
          // Cookies.set("Email", result.data.Email);
          // Cookies.set("UserId", result.data.UserId);
          // Cookies.set("ProfilePicture", result.data.ProfilePicture);
          // Cookies.set("LoginType", "Email");
        
          window.open("/", "_self");
        } else if (
          result.status === "FAILED" &&
          result.message === "Email or Password does not match"
        ) {
          setLoginFailed(
            "The email or password that you've entered is incorrect"
          );
        } else if (
          result.status === "FAILED" &&
          result.message === "User Email does not exist"
        ) {
          setLoginFailed(
            "The email or password that you've entered is incorrect"
          );
        } else {
          setModal("apiFailed");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const formValidation = () => {
    let errors = {};
    let isValid = true;
    if (!email || email === "") {
      errors.email = "Please enter email address";
      isValid = false;
    } else if (!emailRegex.test(email)) {
      isValid = false;
      errors.email = "Please enter valid email address";
    }
    if (!password || password === "") {
      errors.password = "Please enter password";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };
  const emailRegex =
    /^(([^<>()/.,;:\s@"]+(\.[^<>()/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const CompanyDetail = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      DomainName: DomainName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/onepost/getcompanyDetailsbycompanyweb",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
         
          Cookies.set("CompanyLogo", result.data.CompanyLogo, {
            domain: ".sasone.in",
          });
          Cookies.set("CompanyName", result.data.CompanyName, {
            domain: ".sasone.in",
          });
          Cookies.set("ProductName", result.data.ProductName, {
            domain: ".sasone.in",
          });
          
          // Cookies.set("CompanyLogo", result.data.CompanyLogo);
          // Cookies.set("CompanyName", result.data.CompanyName);
          // Cookies.set("ProductName", result.data.ProductName);

        }
      })
      .catch((error) => console.log("error", error));
  };

  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <>
            <div className={classes.mainBoxLoginOuter}>
              {modal === "apiFailed" && (
                <Modal
                  modalCase={"confirmation"}
                  Text={"There is some issue , please contact support!"}
                  setShowModal={setModal}
                  iscancel={false}
                  onClose={() => setModal("")}
                  onOk={() => setModal("")}
                />
              )}
              <div className={classes.inBoxSpace}>
                <div className={classes.topBoxOnePost}>
                  {(DomainName === "localhost"||DomainName === "onepost.sasone.in") ? (
                    <div className={classes.onepostTextBox}>
                      <img
                        src="/assets/onepost.png"
                        alt="one_post_logo"
                        width={40}
                        height={40}
                        className={classes.logo}
                      />
                      <div className={classes.onePostText}>ONE POST</div>
                    </div>
                  ) : (
                    <div className={classes.onepostTextBox}>
                      <img
                        src={CompanyLogo}
                        alt={CompanyName}
                        width={40}
                        height={40}
                        className={classes.logo}
                      />
                      <div className={classes.onePostText}>{CompanyName}</div>
                    </div>
                  )}
                  <p className={classes.onePostFirstSubtitle}>
                    Manage your social media from one place
                  </p>
                  {/*  <p className={classes.onePostSecSubtitle}>
                    Our automated tool helps you to post your media to all
                    platforms at the same time with just a click
                  </p> */}
                </div>
                <div className={classes.centerBox}>
                  <img src="/assets/Bolt_icon.svg" height={65} width={46} />

                  <div className={classes.loginTitle}>Login</div>
                 {(DomainName === "localhost"||DomainName === "onepost.sasone.in")&& <div className={classes.loginSubtitle}>
                    Need an account?{" "}
                    <a
                      target={"_blank"}
                      className={classes.signupLink}
                      href="https://www.sasone.in/user-registration/newsignup"
                    >
                      {" "}
                      Signup
                    </a>
                  </div>}

                  <div className={classes.inputsBox}>
                    <OPInput
                      input="SingleLineInputSecType"
                      placeholder={"Enter your email address"}
                      type="text"
                      onInput={() => {
                        error.email = "";
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <div className={classes.errors}> {error.email}</div>

                    <OPInput
                      input="passwordLoginType"
                      placeholder={"Enter your password"}
                      type={passwordType}
                      onInput={() => {
                        error.password = "";
                        setLoginFailed("");
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      onClick={togglePassword}
                    />
                    <div className={classes.errors}> {error.password}</div>
                    <div className={classes.errors}>{loginFailed}</div>
                  </div>

                  <div
                    className={classes.loginButtonMob}
                    onClick={() => {
                      formValidation()
                        ? login()
                        : console.log("something went wrong");
                    }}
                  >
                    <ActionButton
                      text="Login"
                      btnType={"circular"}
                      loading={loading}
                    />
                  </div>
                  <div
                    className={classes.forgotPass}
                    onClick={() => {
                      navigate("/forgot-password-email");
                      localStorage.setItem("forgotPassword", true);
                    }}
                  >
                    Forgot Password?
                  </div>
                </div>
                <div className={classes.sasoneText}>{CompanyName?CompanyName:"SAS ONE"}</div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <div className={classes.flexBox}>
            {modal === "apiFailed" && (
              <Modal
                modalCase={"confirmation"}
                Text={"There is some issue , please contact support!"}
                setShowModal={setModal}
                iscancel={false}
                onClose={() => setModal("")}
                onOk={() => setModal("")}
              />
            )}
            {(DomainName === "localhost"||DomainName === "onepost.sasone.in") ? (
              <div className={classes.webBoxLoginOuter}>
                <div className={classes.inBoxSpaceweb}>
                  <div className={classes.centerBoxweb}>
                    <img src="/assets/Bolt_icon.svg" height={35} width={50} />

                    <div className={classes.loginTitle}>Login</div>
                   {(DomainName === "localhost"||DomainName === "onepost.sasone.in")&& <div className={classes.loginSubtitle}>
                      Need an account?{" "}
                      <a
                        className={classes.signupLink}
                        href="https://www.sasone.in/user-registration/newsignup"
                      >
                        {" "}
                        Signup
                      </a>
                    </div>}

                    <div className={classes.inputsBoxweb}>
                      <OPInput
                        input="SingleLineInputSecType"
                        placeholder={"Enter your email address"}
                        type="text"
                        onInput={() => {
                          error.email = "";
                        }}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                      <div className={classes.errors}> {error.email}</div>

                      <OPInput
                        input="passwordLoginType"
                        placeholder={"Enter your password"}
                        type={passwordType}
                        onInput={() => {
                          error.password = "";
                          setLoginFailed("");
                        }}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        onClick={togglePassword}
                      />
                      <div className={classes.errors}> {error.password}</div>
                      <div className={classes.errors}>{loginFailed}</div>
                    </div>

                    <div
                      className={classes.loginButton}
                      onClick={() => {
                        formValidation()
                          ? login()
                          : console.log("something went wrong");
                      }}
                    >
                      <ActionButton
                        text="Login"
                        btnType={"circular"}
                        loading={loading}
                      />
                    </div>
                    <div
                      className={classes.forgotPass}
                      onClick={() => {
                        navigate("/forgot-password-email");
                        localStorage.setItem("forgotPassword", true);
                      }}
                    >
                      Forgot Password?
                    </div>
                  </div>
                  <div className={classes.sasoneTextweb}>{CompanyName?CompanyName:"SAS ONE"}</div>
                </div>
                <div className={classes.blueBoxOuterweb}>
                  <div className={classes.onepostTextBoxweb}>
                    <img
                      src="/assets/onepost.png"
                      alt="one_post_logo"
                      width={40}
                      height={40}
                      className={classes.logo}
                    />
                    <div className={classes.onePostText}>ONE POST</div>
                  </div>
                  <img
                    src="/assets/One_Post.webp"
                    height={"auto"}
                    width={"50%"}
                    className={classes.imgBox}
                  />
                  <div className={classes.onePostSubtitle}>
                    <div className={classes.onePostFirstSubtitleweb}>
                      Manage your social media from one place
                    </div>
                    <div className={classes.onePostSecSubtitleweb}>
                      Our automated tool helps you to post your media to all
                      platforms at the same time with just a click.
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.inBoxSpaceweb}>
                <div className={classes.centerBoxweb}>
                  <img src="/assets/Bolt_icon.svg" height={35} width={50} />

                  <div className={classes.loginTitle}>Login</div>
                 {(DomainName === "localhost"||DomainName === "onepost.sasone.in")&& <div className={classes.loginSubtitle}>
                    Need an account?{" "}
                    <a
                      className={classes.signupLink}
                      href="https://www.sasone.in/user-registration/newsignup"
                    >
                      {" "}
                      Signup
                    </a>
                  </div>}

                  <div className={classes.inputsBoxweb}>
                    <OPInput
                      input="SingleLineInputSecType"
                      placeholder={"Enter your email address"}
                      type="text"
                      onInput={() => {
                        error.email = "";
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <div className={classes.errors}> {error.email}</div>

                    <OPInput
                      input="passwordLoginType"
                      placeholder={"Enter your password"}
                      type={passwordType}
                      onInput={() => {
                        error.password = "";
                        setLoginFailed("");
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      onClick={togglePassword}
                    />
                    <div className={classes.errors}> {error.password}</div>
                    <div className={classes.errors}>{loginFailed}</div>
                  </div>

                  <div
                    className={classes.loginButton}
                    onClick={() => {
                      formValidation()
                        ? login()
                        : console.log("something went wrong");
                    }}
                  >
                    <ActionButton
                      text="Login"
                      btnType={"circular"}
                      loading={loading}
                    />
                  </div>
                  <div
                    className={classes.forgotPass}
                    onClick={() => {
                      navigate("/forgot-password-email");
                      localStorage.setItem("forgotPassword", true);
                    }}
                  >
                    Forgot Password?
                  </div>
                </div>
                <div className={classes.sasoneTextweb}>{CompanyName?CompanyName:"SAS ONE"}</div>
              </div>
            )}
          </div>
        );
    }
  };
  return MobileWebHandlerSwitch(props.device);
};

export default LoginNew;
