import React, { useRef } from "react";
import classes from "./Modal.module.css";
import { AiOutlineClose } from "react-icons/ai";
import { TailSpin } from "react-loader-spinner";
import useOnClickOutside from "../Components/useOnClickOutside";
import { useNavigate } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import tailwindClasses from '../Screens/custom.module.css'
import { AiOutlineSchedule } from "react-icons/ai";
import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useContext } from "react";
import ThemeContext from "../Context/ThemeContext"
import Cookies from "js-cookie";
const socialImagesUrl = [
  {
    id: 1,
    image: "./assets/socialIcons/Facebook.svg",
    url: "/linked-accounts/facebook",
  },
  {
    id: 2,
    image: "./assets/socialIcons/Insta.svg",
    url: "/linked-accounts/instagram",
  },
  {
    id: 3,
    image: "./assets/socialIcons/Linkdin.svg",
    url: "/linked-accounts/linkedin",
  },
  {
    id: 4,
    image: "./assets/socialIcons/Twitter.svg",
    url: "/linked-accounts/twitter",
  },
  {
    id: 5,
    image: "./assets/socialIcons/youtube-icon.svg",
    url: "#",
  },
];
const pageName=(pagename,toPost)=>{
  if(toPost?.includes('twitter')){
    return pagename.twitter
  }
  if(toPost?.includes('instagram')){
    return pagename.instagram
  }
  if(toPost==='linkedin'){
    return pagename.linkedin
  }
  if(toPost?.includes('linkedinorg')){
    return pagename.linkedinorg
  }
  if(toPost?.includes('facebook')){
    return pagename.facebook
  }
  }
const Modal = (props) => {
  const dropdownRef = useRef(null);

  const cancelButtonRef = useRef(null);
  const contextValue = Cookies.get("UserType")

  useOnClickOutside(dropdownRef, () => {
    props.setModal(false);
  });
  const IconTextSelected = (value) => {
    if (value === "instagram") {
      return (
        <>
          <BsInstagram size={15} color={"#fff"} />
        </>
      );
    }
    if (value === "facebook") {
      return (
        <>
          <FaFacebookF size={15} color={"white"} />
        </>
      );
    }
    if (value === "linkedin" || value === "linkedinorg") {
      return (
        <>
          <FaLinkedinIn size={15} color={"#fff"} />
        </>
      );
    }
    if (value === "twitter") {
      return (
        <>
          <FaTwitter size={15} color={"#fff"} />
        </>
      );
    }
    // if (value === "youtube") {
    //   return (
    //     <>
    //       <FaYoutube size={20} color={"#fff"} />
    //     </>
    //   );
    // }
  };
  const Description=(post,toPost)=>{
    if(toPost?.includes('twitter')){
      return post.TwitterDescription||post.Description
    }
    if(toPost?.includes('instagram')){
      return post.InstaDescription||post.Description
    }
    if(toPost?.includes('facebook')){
      return post.FBDescription||post.Description
    }
    if(toPost?.includes('linkedin')){
      return post.LinkedinDescription||post.Description
    }
  
  }
  const dateWithTime = (date) => {
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dateObj = new Date(date);
    const day = dateObj?.getDate();
    const monthNameLong = dateObj?.toLocaleString("en-US", { month: "short" });
    const dayNameLong = days[dateObj.getDay()];
    const year = dateObj?.getFullYear();
    return (
      dayNameLong + " , " + (day <= 9 ? "0" : "") + day + " " + monthNameLong
    );
  };
  const time24to12Converter = (t) => {
    let time = t?.split(':');
    let temp = ' AM';
    if(parseInt(time?.length&&time[0]) > 11){
      time[0]=(time[0]-12<1)?12:time[0] -= 12
      temp = " PM";
    }
    else{
      time[0]= time[0]<1?12:time[0]
    }
    return time?.join(":") + temp;
  };
  const TextColor = (value) => {
    if (value === "instagram") {
      return tailwindClasses.schedulInsta;
    }
    if (value === "facebook") {
      return "bg-fb rounded-full flex items-center px-1";
    }
    if (value === "linkedin" || value === "linkedinorg") {
      return "bg-linkedIn rounded-full flex items-center p-1";
    }
    if (value === "twitter") {
      return "bg-twitter rounded-full flex items-center p-1";
    }
    if (value === "youtube") {
      return "bg-red-500 rounded-full flex items-center p-1";
    }
  };
  const navigate = useNavigate();
  const modalType = (type) => {
    switch (type) {
      case "save-cred":
        return (
          <>
            <div className={classes.modalContainer}>
              <div className={classes.modal} ref={dropdownRef} >
                <div className={classes.closeIcon}>
                  {" "}
                  <AiOutlineClose
                    color={"black"}
                    size={24}
                    onClick={props.onClick}
                  />
                </div>
                <div>
                  <div className={classes.imagesDiv}>
                    {socialImagesUrl.map((item) => {
                      return (
                        <div
                          className={
                            item.url === "#"
                              ? classes.imageWrapperDisable
                              : classes.imageWrapper
                          }
                          onClick={() => navigate(item.url)}
                        >
                          <img src={item.image} alt="" />
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.heading}>Select account to link</div>
                </div>
              </div>{" "}
            </div>
          </>
        );
      case "Container":
        return (
          <>
            <div className={classes.overlay} onClick={props.onIconClick}></div>
            <div className={`${classes.containerPopBox} ${props.className}`}>
              <div className={classes.closeheadingWrapper}>
                <div className={classes.modalHeading}>{"ONE POST"}</div>
                <div className={`${classes.closeIcon} `}>
                  <AiOutlineClose
                    onClick={props.onIconClick}
                    className={`${classes.closeIconModal} ${props.closeClassname}`}
                  />
                </div>
              </div>
              <div className={classes.middleSection}>{props.children}</div>
            </div>
          </>
        );
      case "confirmation":
        return (
          <>
            <div
              className={classes.overlay}
              // onClick={() => props.setShowModal(false)}
            ></div>
            <div
              className={`${classes.containerPopBox} ${props.className} ${classes.modalIn}`}
            >
              <div className={classes.closeheadingWrapper}>
                <div className={classes.modalHeading}>{"ONE POST"}</div>

                <div className={classes.closeIcon}>
                  <AiOutlineClose
                    onClick={props.onClose}
                    className={classes.closeIconModal}
                  />
                </div>
              </div>
              <div className={classes.middleSection}>
                <div className={classes.RemoveContactText}>{props.Text}</div>
                <section className={classes.modalFooter}>
                  <div className={classes.footerLeftSide}></div>
                  <div className={classes.rightSideBtnWrapper}>
                    {props.iscancel !== false && (
                      <button
                        className={classes.textButton}
                        onClick={props.onClose}
                      >
                        Cancel
                      </button>
                    )}
                    <button className={classes.okbutton} onClick={props.onOk}>
                      {props.loading ? (
                        <TailSpin color="black" width={20} height={18} />
                      ) : (
                        "ok"
                      )}
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </>
        );
      case "CreatePost":
        return (
          <>
            <div
              className={classes.overlay}
              // onClick={() => props.setShowModal(false)}
            ></div>
            <div
              className={`${classes.containerPopBox} ${props.className} ${classes.modalIn}`}
            >
              <div className={classes.closeheadingWrapper}>
                <div className={classes.modalHeading}>{"ONE POST"}</div>

                <div className={classes.closeIcon}>
                  <AiOutlineClose
                    onClick={props.onClose}
                    className={classes.closeIconModal}
                  />
                </div>
              </div>
              <div className={classes.middleSection}>
                <div className={classes.RemoveContactText}>
                  {props.createPostData.facebook?.length ? (
                    <div>
                      Facebook :
                      {props.createPostData.facebook.map((item, index) => {
                        if (item.status === "Sucess") {
                          return (
                            <div className={classes.successtext}>
                              {item.pageName} : Post is live on {item.pageName}
                            </div>
                          );
                        } else {
                          return (
                            <div className={classes.failedtext}>
                              {item.pageName} :
                              <span> Failed to post on {item.pageName}</span>{" "}
                              <br />
                              Reason : {item.message}
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.createPostData.instagram?.length ? (
                    <div>
                      Instagram :
                      {props.createPostData.instagram.map((item, index) => {
                        if (item.status === "Sucess") {
                          return (
                            <div className={classes.successtext}>
                              {item.pageName} : Post is live on {item.pageName}
                            </div>
                          );
                        } else {
                          return (
                            <div className={classes.failedtext}>
                              {item.pageName} :
                              <span> Failed to post on {item.pageName}</span>{" "}
                              <br />
                              Reason : {item.message}
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.createPostData.twitter?.length ? (
                    <div>
                      Twitter :
                      {props.createPostData.twitter.map((item, index) => {
                        if (item.status === "Sucess") {
                          return (
                            <div className={classes.successtext}>
                              {item.pageName} : Post is live on {item.pageName}
                            </div>
                          );
                        } else {
                          return (
                            <div className={classes.failedtext}>
                              {item.pageName} :
                              <span> Failed to post on {item.pageName}</span>{" "}
                              <br />
                              Reason : {item.message}
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                  {props.createPostData.linkedin?.length ? (
                    <div>
                      LinkedIn :
                      {props.createPostData.linkedin.map((item, index) => {
                        if (item.status === "Sucess") {
                          return (
                            <div className={classes.successtext}>
                              {item.pageName} : Post is live on {item.pageName}
                            </div>
                          );
                        } else {
                          return (
                            <div className={classes.failedtext}>
                              {item.pageName} :
                              <span> Failed to post on {item.pageName}</span>{" "}
                              <br />
                              Reason : {item.message}
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <div >Instagram : {props?.createPostData.linkedin[0].status==="Sucess"?<span className={classes.successtext}>Post is live on instagram</span>:
                    <span className={classes.failedtext}>Failed to post on instagram <br />Reason : {props.createPostData?.linkedin[0].message}</span>}</div>
                    <div >LinkedIn : {props?.createPostData.instagram[0].status==="Sucess"?<span className={classes.successtext}>Post is live on linkedin</span>:
                    <span className={classes.failedtext}>Failed to post on linkedin <br />Reason : {props.createPostData?.instagram[0].message}</span>}</div> */}
                </div>
                <section className={classes.modalFooter}>
                  <div className={classes.footerLeftSide}></div>
                  <div className={classes.rightSideBtnWrapper}>
                    {props.iscancel !== false && (
                      <button
                        className={classes.textButton}
                        onClick={props.onClose}
                      >
                        Cancel
                      </button>
                    )}
                    <button className={classes.okbutton} onClick={props.onOk}>
                      {props.loading ? (
                        <TailSpin color="black" width={20} height={18} />
                      ) : (
                        "ok"
                      )}
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </>
        );
      case "Post Details":
        return (
          <>
            <div className={classes.overlay} onClick={props.onIconClick}></div>
            <div className={`${classes.containerPopBox} ${props.className}`}>
              <div className="p-4 pb-2 ">
                <div className="flex items-center justify-between ">
                  <div className="flex items-center">
                    <AiOutlineSchedule
                      size={30}
                      className="border mr-2 rounded-full p-1"
                    />{" "}
                    {props.status} Posts
                  </div>
                  <IoCloseSharp
                    onClick={props.onIconClick}
                    size={30}
                    className="border cursor-pointer  rounded-full p-1"
                  />
                </div>
              </div>
              <div className={"p-2 pt-3 "}>
                {props.Text.map((item, idx) => {
                  return (
                    <div
                      id={idx}
                      className=" border border-red-100 mb-1 p-1 hover:bg-red-50 rounded px-1 mx-1 cursor-pointer "
                      onClick={() => {props.setModalShow(true);props.setScheduleData(item)}}
                    >
                      {/* <div> */}
                      <div className="flex truncate">
                        {" "}
                        <div className={TextColor(item.Posts[0]?.SocialMediaName)}>
                          {IconTextSelected(item.Posts[0]?.SocialMediaName)}
                        </div>
                        <div className=" ml-1 ">
                        {item.Posts[0]?.PageName}
                          {/* {item.PageName?.split(",")[0]} */}
                        </div>
                        <div className={`text-${contextValue}-500 ml-4 hover:text-${contextValue}-800 font-semibold`}>{item.Posts?.length>1? " +"+(item.Posts?.length-1):""}</div>
                      </div>
                     
                      {/* </div> */}
                      <p className=" font-semibold truncate text-gray-700 ">
                      {Description(item,item.Posts[0]?.SocialMediaName)}
                      </p>
                      <p className="text-gray-700 flex   group-hover:text-blue-700 min-w-fit">
                        <div className="block  mr-1">
                          {dateWithTime(item.ScheduleDate)}
                        </div>
                        <div>{time24to12Converter(item.ScheduleTime)}</div>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );
      case "schedulePost":
        return (
          <>
            <div
              className={classes.overlay}
              onClick={() => props.setShowModal(false)}
            ></div>
            <div
              className={`${classes.containerPopBox} ${props.className} ${classes.modalIn}`}
            >
              <div className={classes.middleSection}>
                <div className={classes.RemoveContactTextOrange}>
                  {props.children}
                </div>
                <section className={classes.modalFooterOrange}>
                  <div className={classes.rightSideBtnWrapper}>
                    <button
                      className={classes.textButton}
                      onClick={props.onClose}
                    >
                      Cancel
                    </button>
                    <button className={classes.okbutton} onClick={props.onOk}>
                      {props.loading ? (
                        <TailSpin color="black" width={20} height={18} />
                      ) : (
                        "ok"
                      )}
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </>
        );
      case "rightSideMOdal":
        return (
          <Transition.Root show={props.open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={props.onClose}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex h-full items-end justify-end p-4 text-center sm:items-center sm:p-0 ">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform   bg-white text-left shadow-xl transition-all  w-80  h-full  overflow-auto">
                      {props.children}
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        );
      default:
        console.log("Wrong Input");
    }
  };
  return modalType(props.modalCase);
};

export default Modal;
