import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const StatsCard = (props) => {
  const projects = [
    { name: 'Manual Live Posts', initials: 'T', href: '/live-post-new', members: props.livePosts||0, bgColor: 'bg-green-500' },
    { name: 'Auto Live Posts', initials: 'T', href: '/live-post-new', members: props.autoLivePosts||0, bgColor: 'bg-green-500' },
    { name: 'Manual Scheduled Posts', initials: 'RC', href: '/schedule-post-new', members: props.schedulPosts||0, bgColor: 'bg-yellow-500' },
    { name: 'Auto Scheduled Posts', initials: 'RC', href: '/schedule-post-new', members: props.autoSchedulPosts||0, bgColor: 'bg-yellow-500' },
  ]
  return (
    <div>
      <ul role="list" className="mt-3 grid grid-cols-1 gap-5   ">
        {projects.map((project) => (
          <li key={project.name} className="col-span-1 flex rounded-md shadow-sm">
            <div
              className={classNames(
                project.bgColor,
                'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
              )}
            >
              {/* {project.initials} */}
            </div>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <a href={project.href} className="font-medium text-gray-900 hover:text-gray-600">
                  {project.name}
                </a>
                <p className="text-gray-500">{project.members} </p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
       
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default StatsCard