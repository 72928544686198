import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Combobox } from "@headlessui/react";
import { MdVerified } from 'react-icons/md';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import Loader from './GMBComp/Loader';

const GMBPageList = (props) => {
    const cancelButtonRef = useRef(null)
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }
      console.log(props.locations,"ss");
  return (
    <Transition.Root show={props.modal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden  bg-white text-left shadow-xl transition-all sm:my-8 w-full sm:w-96">
                <div as={Fragment} appear>
                  <div className={`block w-full mt-2   p-2  outline-none border-gray-400 shadow-sm focus:border-${props.contextValue}-500 focus:ring-${props.contextValue}-500 sm:text-sm `}>
                    <div className='text-base text-center'>Choose <span className='font-semibold '>Locations or Listings</span></div>
                    <div className='text-sm text-center'>That you want to show in this stream</div>
                    {props.accounts?.length ? <div className="w-full px-3 pt-3">
                      <select onChange={(e) => props.getLocation(e.target.value)} className={`block w-full border rounded-md border-gray-300 py-2 px-2 outline-none text-gray-900 shadow-sm focus:border-${props.contextValue}-500 focus:ring-${props.contextValue}-500`}>
                        <option value={""} disabled>Select Account</option>
                        {props.accounts.map((item) => <option value={item.PageId}>{item.PageName}</option>)}
                      </select>
                    </div> : <></>}

                    <Combobox>


                      {/* <Combobox.Options
              static
              className="max-h-96 scroll-py-3 overflow-y-auto p-3 pb-0"
            >
           <div>
                <Combobox.Option
                  className={() =>
                    classNames(
                      " select-none rounded-xl p-3 pb-0  cursor-pointer"
                    )
                  }
                >
                  <label
              
                    className="flex text-indigo-500 font-semibold cursor-pointer  items-center "
                  >
           
                    Select All
              
                  </label>
                </Combobox.Option>
              </div>
            </Combobox.Options> */}
           
      {!props.locationIdLoading?  <div >
        {props.locations?.length > 0 ?(
              <Combobox.Options
                static
                className="max-h-96 scroll-py-3 overflow-y-auto p-3"
              >
                {props.locations.map((item, index) => (
                  <div
                  >
                    <Combobox.Option
                      key={index}
                      className={() =>
                        classNames(
                          " select-none hover:rounded-xl p-3 hover:bg-gray-100 border-b cursor-pointer"
                        )
                      }
                    >
                      <label
                        htmlFor={item.name}
                        className="flex cursor-pointer  items-center "
                      >
                        <input
                          className="mr-3"
                          id={item.name}
                          name={item.name}
                          type="checkbox"
                          onChange={() =>
                            props.setLocationId(item.name.split("/")[1])
                          }
                          checked={props.locationId===item.name.split("/")[1]}
                          value={item.name}
                        />
                        {console.log(props.locationId)}
                        <>
                          <div
                            className={classNames(
                              "flex h-10  items-center justify-center "
                            )}
                          >
                    
                          </div>
                          <div className="ml-2  flex-auto">
                      <div className=' flex justify-between'>      <p
                              className={classNames(
                                "text-sm font-medium",
                                "text-gray-700"
                              )}
                            >
                              {item.title}
                            </p> 
                            {/* <div className=' flex justify-between items-center text-sky-400'><MdVerified className='mr-1'/> <div>Verified</div>
                             </div> */}
                             </div>
                            <div className='line-clamp-2'>{item.profile?.description}</div>
                          </div>
                        </>
                      </label>
                                </Combobox.Option>
                              </div>
                            ))}
                          </Combobox.Options>
                        ) : (
                          <div className="py-2 px-6 text-center text-sm sm:px-14">

                            <p className="mt-4 font-semibold text-gray-900">
                              No Google My Business Listing Found
                            </p>
                          </div>
                        )}
                      </div> : <Loader />}
                    </Combobox>
                  </div>
                </div>

                {props.loading ? <div className="bg-gray-50 px-4 py-3 flex justify-center  sm:px-6">

                  <button
                    type="button"
                    className={props.locationId ? `inline-flex w-full justify-center rounded-md border border-transparent bg-${props.contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover` : `bg-${props.contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${props.contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm":"inline-flex w-full justify-center rounded-md border border-transparent bg-${props.contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${props.contextValue}-700 focus:outline-none focus:ring-2  opacity-50 pointer-events-none focus:ring-${props.contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={props.onClick}
                  >
                    <svg aria-hidden="true" className="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    Confirm
                  </button>
                </div> : <div className="bg-gray-50 px-4 py-3 flex justify-center  sm:px-6">

                  <button
                    type="button"
                    className={props.locationId ? `inline-flex w-full justify-center rounded-md border border-transparent bg-${props.contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${props.contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${props.contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm` : `inline-flex w-full justify-center rounded-md border border-transparent bg-${props.contextValue}-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-${props.contextValue}-700 focus:outline-none focus:ring-2  opacity-50 pointer-events-none focus:ring-${props.contextValue}-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={props.onClick}
                  >

                    Confirm
                  </button>
                </div>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default GMBPageList