import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi'

const GMBCard = (props) => {
  return (
    <div className=' shadow '>
        <div className='flex items-center border-b justify-between gap-2 p-1'>
           <div className='flex gap-2'>
           <img src='./assets/gmbLogo.png' alt='gmb-logo' className='h-7 w-7'/>
            <div className='font-semibold'>{props.heading} <span className='text-sm text-gray-400 font-normal'>Google My Bussiness</span></div>
            </div>
            <BsThreeDotsVertical className='cursor-pointer' onClick={()=>props.setIsAccount(true)} />
        </div>
 <div className=' '>
 { props.isPost&& <a
            href={"/gmb-create-post/" + props.pageId + "/" + props.locationId}
            className="flex justify-end p-2 text-gray-500 cursor-pointer pb-1"
          >
            <FiEdit className={(props.locationId&&props.pageId)?"h-5 w-5 hover:text-gray-700":"h-5 w-5 opacity-0 hover:text-gray-700"} />
          </a>}
 <div className='flex flex-col h-96 items-center justify-center'>
  <div className={props.isPost?'text-lg -mt-16 font-semibold':"text-lg  font-semibold"}>{props.headingText}</div>
  <div className='text-sm '>{props.text}</div>
        {/* <img src='./assets/gmbLogo.png' alt='gmb-logo' className='h-20  mb-2 w-20'/>
        <a href='https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=834036704623-n2nvtr8ajaujaif0skfrihocv4h654ju.apps.googleusercontent.com&redirect_uri=https://testonepost.sasone.in/linked-accounts/gmb/&scope=https://www.googleapis.com/auth/business.manage&state=gmb&access_type=offline&include_granted_scopes=true' className='bg-blue-400 text-sm text-white px-5 py-2 hover:bg-blue-500 cursor-pointer rounded-md ' >Login to Google My Business</a>
         */}
        </div>
 </div>
    </div>
  )
}

export default GMBCard