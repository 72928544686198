import React from "react";
import PostCard from "../../Components/TailwindCompnents/CreativeCalender/PostCard";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import { useState } from "react";
import MonthFilter from "../../Components/TailwindCompnents/CreativeCalender/MonthFilter";
import { useEffect } from "react";
import LoadingComp from "../../Components/TailwindCompnents/LoadingComp";
import TailwindModal from "../../Components/TailwindCompnents/TailwindModal";
import { useNavigate } from "react-router-dom";
import ImagePreview from "../../Components/TailwindCompnents/CreativeCalender/ImagePreview";
import Cookies from "js-cookie";

const CreativeCalender = () => {
  const [modal, setModal] = useState("");
  const [succesMessage, setSuccesMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const contextValue = Cookies.get("UserType")

  const navigate = useNavigate();
  const currentDate = () => {
    const dateObj = new Date();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "long" });
    return monthNameLong;
  };
  var currentMonth = currentDate();
  const [monthName, setMothName] = useState(currentMonth);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postId, setPostId] = useState("");

  const loginToken = Cookies.get("UserToken");
  const checkLoginType = Cookies.get("LoginType");
  useEffect(() => {
    getAllPost();
  }, []);
  const getAllPost = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: loginToken,
      StartDate: "",
      Duration: "All",
      EndDate: "",
      Posts: "1",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1-1/onepost/GetAutoPosts",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setData(result.scheduled);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const removePost = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      UserToken: loginToken,
      PostID: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/social/DeletePost",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setModal("saved");
          setSuccesMessage("Your post is now deleted!");
          getAllPost();
        } else if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
          checkLoginType === "Email" ? LogOut() : SignOutFunction();
        } else {
          setModal("saved");
          setSuccesMessage(" Oops, A problem has occurred. Please try again ");
        }
      })
      .catch((error) => console.log("error", error));
  };
  const LogOut = () => {
    Cookies.remove("LoggedIn", { domain: ".sasone.in" });
    Cookies.remove("UserToken", { domain: ".sasone.in" });
    Cookies.remove("FirstName", { domain: ".sasone.in" });
    Cookies.remove("LastName", { domain: ".sasone.in" });
    Cookies.remove("Mobile", { domain: ".sasone.in" });
    Cookies.remove("userName", { domain: ".sasone.in" });
    Cookies.remove("Email", { domain: ".sasone.in" });
    Cookies.remove("UserId", { domain: ".sasone.in" });
    Cookies.remove("LoginType", { domain: ".sasone.in" });
    Cookies.remove("ProfilePicture", { domain: ".sasone.in" });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });
    navigate("/login");
  };
  const SignOutFunction = () => {
    Cookies.remove("LoggedIn", { domain: ".sasone.in" });
    Cookies.remove("UserToken", { domain: ".sasone.in" });
    Cookies.remove("FirstName", { domain: ".sasone.in" });
    Cookies.remove("LastName", { domain: ".sasone.in" });
    Cookies.remove("Mobile", { domain: ".sasone.in" });
    Cookies.remove("userName", { domain: ".sasone.in" });
    Cookies.remove("Email", { domain: ".sasone.in" });
    Cookies.remove("UserId", { domain: ".sasone.in" });
    Cookies.remove("LoginType", { domain: ".sasone.in" });
    Cookies.remove("ProfilePicture", { domain: ".sasone.in" });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });
    // Cookies.remove("LoggedIn");
    // Cookies.remove("UserToken");
    // Cookies.remove("FirstName");
    // Cookies.remove("LastName");
    // Cookies.remove("Mobile");
    // Cookies.remove("userName");
    // Cookies.remove("Email");
    // Cookies.remove("UserId");
    // Cookies.remove("LoginType");
    // Cookies.remove("ProfilePicture");
    Cookies.set("googleLogin", "googleLogin", { domain: ".sasone.in" });
    window.open("https://www.sasone.in/sasone-login", "_self");
  };
  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "long" });
    return monthNameLong;
  };

  const pages = [
    { name: "Creative Calender", href: "/creative-calender", current: true },
  ];

  const filteredData = data?.filter(
    (item) =>
      monthName === dateHandler(item.ScheduleDate) &&
      item.PostStatus === "Scheduled"
  );
  return (
    <DashboardComp BreadCrumb={pages}>
      {modal === "saved" && (
        <TailwindModal
          modalCase={"confirmation"}
          Text={succesMessage}
          setModal={setModal}
          isCancel={false}
          modal={modal}
        />
      )}
      {modal === "save" && (
        <ImagePreview
          setModal={setModal}
          onClick={() => setModal("")}
          selectedImage={selectedImage}
          modal={modal}
        />
      )}
      {modal === "remove" && (
        <TailwindModal
          modalCase={"ConfirmMessage"}
          Heading={"Delete Post"}
          Text={"Are you sure you want to delete this post?"}
          setModal={setModal}
          RemoveText={"Delete"}
          onClick={() => removePost(postId)}
          modal={modal}
        />
      )}
      <div className="  mb-5 sm:mx-16 mx-6 rounded-lg bg-white shadow">
        <div
          className={
            localStorage.getItem("trialexpire") === "yes"
              ? "p-6 pointer-events-none opacity-50"
              : "p-6"
          }
        >
          <h1 className="text-xl flex gap-4 items-center font-semibold text-gray-900">
           
            <div className=""> AI Creative Calendar </div>{" "}
            <span className="flex items-center rounded-md border border-gray-300  bg-white py-1 pl-3 pr-2 text-sm font-medium text-green-500  shadow-sm ">
              {" "}
              {data?.length} posts{" "}
            </span>
          </h1>
          <div className="text-gray-400">
            Browse through our automated social media posts on major festivals.
            You can alter them as per your requirements.
          </div>
          <div className="flex gap-2 items-center ">
            {" "}
            <MonthFilter setMothName={setMothName} monthName={monthName} />{" "}
          {data?.length&&  <a
              className={`flex items-center rounded-md  border-gray-300 mt-4 bg-white py-2 pl-3 pr-2 text-sm font-medium  hover:underline text-${contextValue}-500 hover:bg-gray-50`}
              target={"_blank"}
              // href="https://onepost.sasone.in/schedule-post-new"
              href="/schedule-post-new"
            >
              View in Calendar
            </a>}
          </div>
          {loading ? (
            <LoadingComp />
          ) : (
            <>
              {(monthName==="All"?data?.length:filteredData?.length) ? (
                <PostCard
                  setPostId={setPostId}
                  setModal={setModal}
                  allData={data}
                  setSelectedImage={setSelectedImage}
                  dataType={monthName}
                  data={filteredData}
                />
              ) : (
                <div className="border-t border-b mt-5 border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
                  <div className=" p-5  ">
                    <div className="flex justify-center gap-2 sm:gap-4">
                      <div>No more social creatives aligned for this month. Please check next month.</div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </DashboardComp>
  );
};

export default CreativeCalender;
