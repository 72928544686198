import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnalyticsGrid from "../../Components/TailwindCompnents/AnalyticsGrid";
import AnalyticsListCard from "../../Components/TailwindCompnents/AnalyticsListCard";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import LoadingComp from "../../Components/TailwindCompnents/LoadingComp";
import Tabs from "../../Components/TailwindCompnents/Tabs";
import DetailedAnalyticsCard from "../../Components/TailwindCompnents/DetailedAnalyticsCard";

const AnalyticsNew = () => {
    const [type,setType]=useState("instagram")
    const [socialData, setSocialData] = useState("");
    const [loading, setLoading] = useState(false);
    const loginToken = Cookies.get("UserToken");
    const [fbData,setFbData] = useState("")
    const [instaData,setInstaData] = useState("")
    const [linkedinData,setLinkedinData] = useState("")
    const [twitterData,setTwitterData] = useState("")
    const [fbPage,setFbPage] =useState([])
    const [instaPage,setInstaPage] =useState([])
    const [linkedinPage,setLinkedinPage] =useState([])
    const [twitterPage,setTwitterPage] =useState([])


  const navigate=useNavigate()
    useEffect(() => {
      getSocialPageDetails();
    }, []);
    const LogOut = () => {
      Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
      Cookies.remove("UserToken", {  domain:'.sasone.in' });
      Cookies.remove("FirstName", {  domain:'.sasone.in' });
      Cookies.remove("LastName", {  domain:'.sasone.in' });
      Cookies.remove("Mobile", {  domain:'.sasone.in' });
      Cookies.remove("userName", {  domain:'.sasone.in' });
      Cookies.remove("Email", {  domain:'.sasone.in' });
      Cookies.remove("UserId", {  domain:'.sasone.in' });
      Cookies.remove("LoginType", {  domain:'.sasone.in' });
      Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
      Cookies.remove("UserType", {  domain:'.sasone.in' });
      Cookies.remove("SignupUser", {  domain:'.sasone.in' });

      navigate("/login");
    };
    const getSocialPageDetails = () => {
      setLoading(true)
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
      );
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        UserToken: loginToken,
      });
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      fetch(
        "https://promote.onecorp.co.in/api/v1/social/GetSocialPageDetails",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status_code === 200 && result.status === "Success") {
            setSocialData(result.pageDetails);
            setFbData(result.pageDetails.facebook)
            setFbPage(result.pageDetails.facebook.pages)
            setInstaPage(result.pageDetails.instagram.pages)
            setInstaData(result.pageDetails.instagram)
            setLinkedinData(result.pageDetails.linkedin)
            setTwitterData(result.pageDetails.twitter)
            setLinkedinPage(result.pageDetails.linkedin.pages)
            setTwitterPage(result.pageDetails.twitter.pages)

          }
          else if (
            result.message ===
            "Invalid User Token, The token may have expired, You may need to re-login"
          ) {
            LogOut();
          }
        })
        .catch((error) => console.log("error", error))
        .finally(() => setLoading(false));
    };

   
    const socialType=()=>{
      switch (type) {
        case "facebook":
          return(
            <>

<DetailedAnalyticsCard data={fbData} type="facebook" Pagedata={fbPage}/>
</>
          )
          case "instagram":
          return(
<DetailedAnalyticsCard data={instaData} type="instagram" Pagedata={instaPage}/>
          )
          case "linkedin":
          return(
<DetailedAnalyticsCard data={linkedinData} type="linkedin" Pagedata={linkedinPage}/>
          )
          case "twitter":
          return(
<DetailedAnalyticsCard data={twitterData} type="twitter" Pagedata={twitterPage}/>
          )
          case "youtube":
          return(
<DetailedAnalyticsCard data={""} type="youtube"/>
          )
//           case "youtube":
//             return(
//  <AnalyticsGrid />
//             )
          default:
            console.log('invalid case');
    }}
    const pages = [

      { name: 'Analytics', href: '', current: true },
  
  ]
  return (
    <DashboardComp BreadCrumb={pages} >  <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="overflow-hidden mb-5 rounded-lg bg-white shadow">
        <div className={ localStorage.getItem("trialexpire")==="yes"?"p-6 pointer-events-none opacity-50":"p-6"}>
          {" "}
          <h1 className="text-xl font-semibold text-gray-900">Analytics</h1>
          <div className="text-gray-400">
            Get insights on your social posts performance in real time
          </div>
        </div>

  <div className="px-6">
  <Tabs type={type} setType={setType} tab={"analytics"}/>
  {loading?<LoadingComp />:
  socialType()}
  
  </div>
      </div></div>
    </DashboardComp>
  );
};

export default AnalyticsNew;
