import React from 'react';
import classes from './ProgressBar.module.css'

const ProgressBar = (props) => {


    const ProgressCount = (screen) => {
        switch (screen) {
            case "firstScreen":
                return (
                    <div className={classes.mainContainer}>
                        <div className={classes.bgColorFirst}>30%</div>
                    </div>
                );
            case "secondScreen":
                return (
                    <div className={classes.mainContainer}>
                        <div className={classes.bgColorSecond}>60%</div>
                    </div>
                );
            case "lastScreen":
                return (
                    <div className={classes.mainContainer}>
                    <div className={classes.bgColorThird}>100%</div>
                </div>
                );
            default:
                console.log("wrong input");

        }

    }
    return <>{ProgressCount(props.screen)}</>;
}
export default ProgressBar;