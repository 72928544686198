import { useState } from "react";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import Badges from "../../Components/TailwindCompnents/Badges";
import { type } from "@testing-library/user-event/dist/type";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import ConfirmationMessage from "../../Components/TailwindCompnents/ConfirmationMessage";
import TailwindModal from "../../Components/TailwindCompnents/TailwindModal";
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext"
const GMBCreatePost = () => {
  const [topicType, setTopicType] = useState("STANDARD");
  const [summary, setSummary] = useState("");
  const [title, seTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [type, setType] = useState("callToAction");
  const [offerURL, setOfferURL] = useState("");
  const [actionType, setActionType] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");
  const { pageId, locationId } = useParams();
  const [modal, setModal] = useState("");
  const [succesMessage, setSuccesMessage] = useState("");
  const [createPostData, setCreatePostData] = useState([]);
  const BreadCrumb = [
    {
      name: `Events`,
      href: "/events",
      current: true,
      name: `Post an Event`,
      href: "",
      current: true,
    },
  ];
  const navigate = useNavigate();
  const checkLoginType=Cookies.get("LoginType")
  const LogOut = () => {
    Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
    Cookies.remove("UserToken", {  domain:'.sasone.in' });
    Cookies.remove("FirstName", {  domain:'.sasone.in' });
    Cookies.remove("LastName", {  domain:'.sasone.in' });
    Cookies.remove("Mobile", {  domain:'.sasone.in' });
    Cookies.remove("userName", {  domain:'.sasone.in' });
    Cookies.remove("Email", {  domain:'.sasone.in' });
    Cookies.remove("UserId", {  domain:'.sasone.in' });
    Cookies.remove("LoginType", {  domain:'.sasone.in' });
    Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });

    navigate("/login");
  };
  const SignOutFunction=()=>{
    Cookies.remove("LoggedIn", {  domain:'.sasone.in' });
    Cookies.remove("UserToken", {  domain:'.sasone.in' });
    Cookies.remove("FirstName", {  domain:'.sasone.in' });
    Cookies.remove("LastName", {  domain:'.sasone.in' });
    Cookies.remove("Mobile", {  domain:'.sasone.in' });
    Cookies.remove("userName", {  domain:'.sasone.in' });
    Cookies.remove("Email", {  domain:'.sasone.in' });
    Cookies.remove("UserId", {  domain:'.sasone.in' });
    Cookies.remove("LoginType", {  domain:'.sasone.in' });
    Cookies.remove("ProfilePicture", {  domain:'.sasone.in' });
    Cookies.remove("UserType", {  domain:'.sasone.in' });
    Cookies.remove("SignupUser", {  domain:'.sasone.in' });

    // Cookies.remove("LoggedIn");
    // Cookies.remove("UserToken");
    // Cookies.remove("FirstName");
    // Cookies.remove("LastName");
    // Cookies.remove("Mobile");
    // Cookies.remove("userName");
    // Cookies.remove("Email");
    // Cookies.remove("UserId");
    // Cookies.remove("LoginType");
    // Cookies.remove("ProfilePicture");
    Cookies.set("googleLogin", "googleLogin", {  domain:'.sasone.in' })
    window.open("https://www.sasone.in/sasone-login","_self")
  }

  const loginToken = Cookies.get("UserToken");
  const contextValue = Cookies.get("UserType")
  const createPost = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );
    var formdata = new FormData();
    formdata.append("UserToken", loginToken);
    formdata.append("PageId", pageId);
    formdata.append("LocationId", locationId);
    formdata.append("TopicType", topicType);
    formdata.append("Summary", summary);
    formdata.append("Images", selectedFiles);

    formdata.append("Title", title);
    formdata.append("StartDate", startDate);
    formdata.append("StartTime", startTime + ":00");
    formdata.append("EndDate", endDate);
    formdata.append("EndTime", endTime + ":00");
    formdata.append("Type", type);
    formdata.append("OfferURL", offerURL);
    formdata.append("ActionType", actionType);
    formdata.append("CouponCode", couponCode);
    formdata.append("TermsConditions", termsAndConditions);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v2/onepost/CreateGMBPostByLocationId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.message ===
          "Invalid User Token, The token may have expired, You may need to re-login"
        ) {
         checkLoginType==="Email"?LogOut():SignOutFunction()
        } else if (result.status_code === 200 && result.status === "Success") {
          setSuccessMsg("Post is live now.");
          setModal("CreatePost")
          setCreatePostData(result.data);
          setActionType("")
          setCouponCode("")
          setEndDate("")
          setEndTime("")
          setOfferURL("")
          setSelectedFiles("")
          setStartDate("")
          setStartTime("")
          setSummary("")
          setTermsAndConditions("")
          setTopicType("STANDARD")
          setType("callToAction")
        }
        else if (result.status_code === 200 && result.status === "Failed") {
          setModal("CreatePost")
          setCreatePostData(result.data);} else {
          setModal("save");
          setSuccesMessage(
            "Oops, A problem has occurred while posting.Please try after sometime"
          );
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  return (
    <DashboardComp BreadCrumb={BreadCrumb}>
      {modal === "save" && (
        <TailwindModal
          modalCase={"confirmation"}
          Text={succesMessage}
          setModal={setModal}
          isCancel={false}
          modal={modal}
        />
      )}
      {/* {successMsg === "Post is live now." && (
        <ConfirmationMessage
          Alert={"success"}
          onClick={() => setSuccessMsg(false)}
          success={""}
          text={successMsg}
        />
      )} */}
        {modal === "CreatePost" && (
        <TailwindModal
          modalCase={"CreatePost"}
          createPostData={createPostData}
          setModal={setModal}
          modal={modal}
        />
      )}
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        {" "}
        <div className="overflow-hidden mb-5 rounded-lg bg-white shadow">
          <div className={"p-6"}>
            {" "}
            <h1 className="text-xl font-semibold text-gray-900">
              Create a new post
            </h1>
            <div className="text-gray-400">
              Quickly create a social post and publish it to the multiple social
              platforms within minutes
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                createPost();
              }}
              className="space-y-4 my-4"
            >
              <div className="grid sm:grid-cols-2 gap-x-8  gap-y-4">
                <div className="">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Topic Type
                  </label>
                  <div className="mt-1">
                    <select
                      required
                      id="category"
                      onChange={(e) => setTopicType(e.target.value)}
                      value={topicType}
                      name="category"
                      autoComplete="country-name"
                      className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                    >
                      <option value={"STANDARD"}>STANDARD</option>
                      <option value={"EVENT"}>EVENT</option>
                      <option value={"OFFER"}>OFFER</option>
                    </select>
                  </div>
                </div>

                {(topicType === "EVENT" || topicType === "OFFER") && (
                  <div className="">
                    <label
                      htmlFor="event-location"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Title
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        required={
                          topicType === "EVENT" || topicType === "OFFER"
                            ? true
                            : false
                        }
                        onChange={(e) => seTitle(e.target.value)}
                        value={title}
                        name="event-location"
                        id="event-location"
                        autoComplete="given-name"
                        className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                      />
                    </div>
                  </div>
                )}
              </div>

              {(topicType === "EVENT" || topicType === "OFFER") && (
                <>
                  {" "}
                  <div className="grid sm:grid-cols-2 gap-x-8 gap-y-4">
                    {" "}
                    <div className="">
                      <label
                        htmlFor="event-start-time"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Start Time
                      </label>
                      <div className="mt-1">
                        <input
                          required={
                            topicType === "EVENT" || topicType === "OFFER"
                              ? true
                              : false
                          }
                          type="time"
                          onChange={(e) => setStartTime(e.target.value)}
                          value={startTime}
                          name="event-start-time"
                          id="event-start-time"
                          autoComplete="given-name"
                          className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="event-end-time"
                        className="block text-sm font-medium text-gray-700"
                      >
                        End Time
                      </label>
                      <div className="mt-1">
                        <input
                          required={
                            topicType === "EVENT" || topicType === "OFFER"
                              ? true
                              : false
                          }
                          type="time"
                          onChange={(e) => setEndTime(e.target.value)}
                          value={endTime}
                          name="event-end-time"
                          id="event-end-time"
                          autoComplete="given-name"
                          className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-2 gap-x-8 gap-y-4">
                    <div className="">
                      <label
                        htmlFor="event-start-date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Start Date
                      </label>
                      <div className="mt-1">
                        <input
                          required={
                            topicType === "EVENT" || topicType === "OFFER"
                              ? true
                              : false
                          }
                          onChange={(e) => setStartDate(e.target.value)}
                          value={startDate}
                          type="date"
                          name="event-start-date"
                          id="event-start-date"
                          autoComplete="given-name"
                          className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="">
                      <label
                        htmlFor="event-end-date"
                        className="block text-sm font-medium text-gray-700"
                      >
                        End Date
                      </label>
                      <div className="mt-1">
                        <input
                          required={
                            topicType === "EVENT" || topicType === "OFFER"
                              ? true
                              : false
                          }
                          type="date"
                          onChange={(e) => setEndDate(e.target.value)}
                          value={endDate}
                          name="event-end-date"
                          id="event-end-date"
                          autoComplete="given-name"
                          className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {topicType === "OFFER" && (
                <>
                  {" "}
                  <div className="grid sm:grid-cols-2 gap-x-8 gap-y-4">
                    {" "}
                    <div className="">
                      <label
                        htmlFor="Type "
                        className="block text-sm font-medium text-gray-700"
                      >
                        Type
                      </label>
                      <div className="mt-1">
                        <select
                          required={topicType === "OFFER" ? true : false}
                          id="Type "
                          onChange={(e) => setType(e.target.value)}
                          value={type}
                          name="Type "
                          autoComplete="country-name"
                          className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                        >
                          <option value={"callToAction"}>callToAction</option>
                          <option value={"Offer"}>offer</option>
                        </select>
                      </div>
                    </div>
                    <div className="">
                      <>
                        {" "}
                        <label
                          htmlFor="redirection-url"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Offer URL
                        </label>
                        <div className="mt-1">
                          <input
                            required={topicType === "OFFER" ? true : false}
                            type="text"
                            onChange={(e) => setOfferURL(e.target.value)}
                            value={offerURL}
                            name="redirection-url"
                            id="redirection-url"
                            autoComplete="given-name"
                            className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                          />
                        </div>
                      </>
                    </div>
                  </div>
                </>
              )}
              {type === "callToAction" && (
                <>
                  <label
                    htmlFor="ActionType "
                    className="block text-sm font-medium text-gray-700"
                  >
                    ActionType
                  </label>
                  <div className="mt-1">
                    <select
                      required={type === "callToAction" ? true : false}
                      id="ActionType "
                      name="ActionType "
                      onChange={(e) => setActionType(e.target.value)}
                      value={actionType}
                      autoComplete="country-name"
                      className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                    >
                      <option value={"UNSPECIFIED"}>UNSPECIFIED</option>
                      <option value={"BOOK"}>BOOK</option>
                      <option value={"ORDER"}>ORDER</option>
                      <option value={"SHOP"}>SHOP</option>
                      <option value={"LEARN_MORE"}>LEARN_MORE</option>
                      <option value={"SIGN_UP "}>SIGN_UP</option>
                      <option value={"CALL"}>CALL</option>
                    </select>
                  </div>
                </>
              )}
              <div className="grid sm:grid-cols-2 gap-x-8 gap-y-4">
                <div className="">
                  <label
                    htmlFor="speacker-image"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Image
                  </label>
                  <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-8 w-8 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex  justify-center text-sm text-gray-600">
                        <label
                          htmlFor="speakerImage"
                          className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload a file</span>
                          <input
                            id="speakerImage"
                            // multiple={true}
                            // onChange={handleImageChange}
                            onChange={(e) =>
                              setSelectedFiles(e.target.files[0])
                            }
                            name="speakerImage"
                            type="file"
                            className="sr-only"
                          />
                        </label>
                      </div>
                      <p className="text-xs text-gray-500">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </div>
                  </div>
                  <div className={"classes.flexBox"}>
                    {/* <Badges item={selectedFiles} onClick={() => setSelectedFiles("")} /> */}
                    {selectedFiles && (
                      <span className="inline-flex items-center h-15 w-15 mr-2 mt-2  py-0.5 pl-2.5 pr-1 text-sm font-medium text-indigo-700">
                        <div className=" border-2  border-indigo-500 rounded-md">
                          {selectedFiles.type?.includes("video") ? (
                            <div className="h-16 w-16">
                              {" "}
                              <video
                                // width="250" height="250"
                                controls
                                // autoPlay
                              >
                                <source
                                  src={URL.createObjectURL(selectedFiles)}
                                  type={selectedFiles.type}
                                  className=" object-cover  rounded-md"
                                />
                              </video>
                            </div>
                          ) : (
                            <div className="h-16 w-16">
                              {" "}
                              <img
                                src={URL.createObjectURL(selectedFiles)}
                                alt=""
                                className=" object-cover w-full  rounded-md"
                              />
                            </div>
                          )}
                        </div>
                        <button
                          onClick={() => setSelectedFiles("")}
                          type="button"
                          className="ml-0.5 -ml-2.5 -mt-16 bg-red-500 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-white hover:bg-indigo-400 hover:text-white focus:bg-indigo-500 focus:text-white focus:outline-none"
                        >
                          <svg
                            className="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M1 1l6 6m0-6L1 7"
                            />
                          </svg>
                        </button>
                      </span>
                    )}
                  </div>
                </div>

                <div className="">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Summary
                  </label>
                  <div className="mt-1">
                    <textarea
                      required
                      id="about"
                      name="about"
                      onChange={(e) => setSummary(e.target.value)}
                      value={summary}
                      rows={5}
                      className="block w-full border outline-none p-2 rounded-md border-gray-300 shadow-sm  sm:text-sm"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>

              {type === "Offer" && (
                <div className="">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Terms and Conditions
                  </label>
                  <div className="mt-1">
                    <textarea
                      required={type === "Offer" ? true : false}
                      id="about"
                      name="about"
                      onChange={(e) => setTermsAndConditions(e.target.value)}
                      value={termsAndConditions}
                      rows={5}
                      className="block w-full border outline-none p-2 rounded-md border-gray-300 shadow-sm  sm:text-sm"
                      defaultValue={""}
                    />
                  </div>
                </div>
              )}
              {type === "Offer" && (
                <div className="">
                  <label
                    htmlFor="event-location"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Coupon Code
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      required={type === "Offer" ? true : false}
                      name="event-location"
                      onChange={(e) => setCouponCode(e.target.value)}
                      value={couponCode}
                      id="event-location"
                      autoComplete="given-name"
                      className="block w-full rounded-md border outline-none p-2 border-gray-300 shadow-sm  sm:text-sm"
                    />
                  </div>
                </div>
              )}
              <div className="w-full flex justify-center">
              { loading?  

<button
  type="submit"
  className={`inline-flex  items-center justify-center rounded-md border border-transparent bg-${contextValue}-600 px-6 py-2 mx-auto text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2`}
>
<svg  aria-hidden="true" className="mr-2 w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
</svg>
 Submit
</button>
:       

<button
type="submit"
className={`inline-flex  items-center justify-center rounded-md border border-transparent bg-${contextValue}-600 px-6 py-2 mx-auto text-base font-medium text-white shadow-sm hover:bg-${contextValue}-700 focus:outline-none focus:ring-2 focus:ring-${contextValue}-500 focus:ring-offset-2`}
>

Submit
</button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </DashboardComp>
  );
};
export default GMBCreatePost;
