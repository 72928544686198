import React from "react";
import { FaRegThumbsUp, FaRegCommentAlt } from "react-icons/fa";
import { HiUserCircle } from "react-icons/hi";
import { RiShareForwardLine } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";
import classes from "./YoutubeCard.module.css";
const YoutubeCard = (props) => {
  const cardType = (type) => {
    const imagePath = () => {
      if (props.data.ImageUrl?.includes(",")) {
        let path = props.data.ImageUrl.split(",");
        return path[0];
      } else {
        return props.data.ImageUrl;
      }
    };
    switch (type) {
      case "youtubeTypeFirst":
        return (
          <>
            <div className={classes.cardBox}>
              <div>
                {imagePath()?.includes("video") ||
                imagePath()?.includes("mp4") ? (
                  <video controls className={classes.postImgFirst}>
                    <source src={imagePath()} />
                  </video>
                ) : (
                  <img
                    src={
                      imagePath()
                        ? imagePath()
                        : "./assets/onepost-logo-gray.png"
                    }
                    className={classes.postImgFirst}
                  />
                )}
              </div>
              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.ProfileImage ? (
                    <img
                      src={props.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div>
                    <div className={classes.userTitle}>{props.userName}</div>
                  </div>
                </div>
              </div>
              <div className={classes.bottomTextBox}>
                <div>T-Series</div>
                <div>
                  200M views <span>.</span> <span> 1 year ago</span>
                </div>
              </div>
            </div>
          </>
        );
      case "socialFeed":
        return (
          <>
            <a
              className={classes.cardBoxFeed}
              href={props.val.permalink_url}
              target={"_blank"}
            >
              <p className={classes.caption}>
                {props.val.message?.slice(0, 32)}
                {props.val.message?.length > 32 && <>...</>}
              </p>

              <div>
                {props.val.media_url?.includes("video") ? (
                  <video controls>
                    <source
                      className={classes.postImgFirst}
                      src={props.val.full_picture}
                    />
                  </video>
                ) : (
                  <img
                    src={props.val.full_picture}
                    alt=""
                    className={classes.postImgFirst}
                  />
                )}
              </div>

              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.ProfileImage ? (
                    <img
                      src={props.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div>
                    <div className={classes.userTitle}>{props.userName}</div>
                  </div>
                </div>
              </div>
              <div className={classes.bottomTextBox}>
                <div>T-Series</div>
                <div>
                  200M views <span>.</span> <span> 1 year ago</span>
                </div>
              </div>
            </a>
          </>
        );
      default:
        return (
          <>
            <div className={classes.heading}>{props.text}</div>
            <div className={classes.cardBox}>
              <div className={classes.postImg}>
                {props.selectedFiles.length ? (
                  props.selectedFiles[0].type.includes("video") ? (
                    <video
                      // width="250" height="250"
                      width="100%"
                      height="100%"
                      controls
                      autoPlay
                      className={classes.postImginner}
                    >
                      <source
                        src={URL.createObjectURL(props.selectedFiles[0])}
                        type={props.selectedFiles.type}
                        className={classes.postImginner}
                      />
                    </video>
                  ) : (
                    <img
                      src={URL.createObjectURL(props.selectedFiles[0])}
                      width="100%"
                      height="100%"
                      className={classes.postImginner}
                    />
                  )
                ) : (
                  <img
                    src={"../assets/onepost-logo-gray.png"}
                    width="100%"
                    height="100%"
                    className={classes.postImginner}
                  />
                )}
              </div>
              <div className={classes.headerOuterBox}>
                <div className={classes.headerFirstBox}>
                  {props.ProfileImage ? (
                    <img
                      src={props.ProfileImage}
                      className={classes.userIcon}
                    />
                  ) : (
                    <HiUserCircle className={classes.userIcon} />
                  )}
                  <div>
                    <div className={classes.userTitle}>{props.userName}</div>
                  </div>
                </div>
              </div>
              <div className={" mb-[20px] mx-2 mt-2 "}>
                <div className="text-sm whitespace-normal ">{props.youtubeContent}</div>
                <div className="text-sm">
                  0 views <span>.</span> <span> 0 minutes ago</span>
                </div>
              </div>
            </div>
          </>
        );
    }
  };
  return cardType(props.cardCase);
};

export default YoutubeCard;
