import React, { useState } from "react";

import classes from "./LoginWithOtpScreen.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import ActionButton from "../../../Components/ActionButton";
import { TailSpin } from "react-loader-spinner";
import { MdMarkEmailRead } from 'react-icons/md';
import Modal from "../../../Components/Modal";

const LoginWithOtpScreen = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [otp, setOtp] = useState("");
  const [modal, setModal] = useState("");
  const [wrongOTp, setWrongOTp] = useState("");
  const [resendOTPMesaage, setResendOTPMesaage] = useState("");
  const email=localStorage.getItem('Email');
  const {pathname}=useLocation();
  const navigate = useNavigate();
  const redirectToDashboard=localStorage.getItem('redirectToDashboard');

  const formValidation = () => {
    let errors = {};
    let isValid = true;
    if (!otp || otp === "") {
      errors.otp = "Please enter otp";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };
  const verifyOTP = () => {
    setWrongOTp("")
    setLoading("verify");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Email: email,
      Otp: otp,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://promote.onecorp.co.in/api/v1/Ai/SASVerifyOtp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status === "FAILED" &&
          result.message === "Please Enter Correct OTP"
        ) {
          setWrongOTp("Please enter correct OTP");
        } else if (
          result.status === "SUCCESS" &&
          result.message === "Data has been saved successfully!"
        ) {
          localStorage.setItem("FirstName", result.data.FirstName);
          localStorage.setItem("LastName", result.data.LastName);
          localStorage.setItem("Mobile", result.data.Mobile);
          localStorage.setItem("userName", result.data.UserName);
          localStorage.setItem("ProfilePicture", result.data.ProfilePicture);
          localStorage.setItem("Email", result.data.Email);
          localStorage.setItem("UserId", result.data.UserId);
          sessionStorage.setItem("UserToken", result.data.UserToken);
          localStorage.setItem("UserToken", result.data.UserToken);
          localStorage.setItem("LoggedIn", true);
          sessionStorage.setItem("FirstName", result.data.FirstName);
          sessionStorage.setItem("LastName", result.data.LastName);
          sessionStorage.setItem("Mobile", result.data.Mobile);
          sessionStorage.setItem("userName", result.data.UserName);
          sessionStorage.setItem("Email", result.data.Email);
          sessionStorage.setItem("UserId", result.data.UserId);
          navigate(redirectToDashboard?"/":"/signup-step-two");
          localStorage.removeItem('redirectToDashboard')
        }
        else{
          setModal("apiFailed")
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };

  const resentOTP = async () => {
    setWrongOTp("")
    setLoading("resend");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 8cb23c1e5efb27dbadefabe601226a4264f59daa"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Email: email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      "https://promote.onecorp.co.in/api/v1/Ai/SASResendOtp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (
          result.status === "SUCCESS" &&
          result.message === "OTP sent successfully"
        ) {
          setResendOTPMesaage(result.message);
        }
        else{
          setModal("apiFailed")
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };
  const successMessage = () => {
    setTimeout(() => {
      setResendOTPMesaage("");
    }, 2000);
    return resendOTPMesaage;
  };
  return (
    <div className={classes.mainContainer}>
              {modal === "apiFailed" && (
        <Modal
        modalCase={"confirmation"}
        Text={"There is some issue , please contact support!"}
        setShowModal={setModal}
        iscancel={false}
        onClose={() => setModal("")}
        onOk={() => setModal("")}
      />
      )}
             {resendOTPMesaage ? 
        <div className={classes.successMessage}>{successMessage()}</div>
       : 
        <></>
      }
      <div className={classes.logoContainer}>

        <img
          src={"/assets/One_post.svg"}
          width={props.device === "Mobile" ? "50%" : "15%"}
        />
      </div>
      <div className={classes.inputSectionContainer}>

        <div className={classes.headImage}>
        { pathname==="/verify-email"?<MdMarkEmailRead size={40} className="m-auto"  color={"#ef5122"}/> : <img className="m-auto"
            src={"/assets/UserRegistrationImages/Login.svg"}
            width={"35px"}
          />}
          <div className={classes.headingTextFirst}>{pathname==="/verify-email"?"Verify email":"Login with OTP"}</div>
        <div className={classes.otpsent}>OTP has been sent to  {email}</div>
          <>
            <div className={classes.outer_otp_div}>
              <div className={classes.otp_div}>
                <input
                  type="text"
                  className={classes.otp_input}
                  required
                  maxLength="4"
                  onInput={() => {
                    error.otp = "";setWrongOTp("")
                  }}
                  onChange={(e) => setOtp(e.target.value)}
                  value={otp}
                />
                <div className={classes.errors}> {error.otp}<span>{wrongOTp}</span></div>

              </div>
            </div>

            <div className={classes.btnNext}>
              <ActionButton
                text={"Next"}
                btnType={"circular"}
                loading={loading === "verify" ? true : false}
                onClick={() =>
                  formValidation()
                    ? verifyOTP()
                    : console.log("something went wrong")
                }
              />
            </div>
            <div className={classes.resendCodeLoader}>
              {" "}
              {loading === "resend" ? (
                <TailSpin color="#ef5122" width={20} height={18} />
              ) : (
                <div className={classes.resendCode} onClick={() => resentOTP()}>
                  Resend OTP
                </div>
              )}
            </div>
          </>
          <div className={classes.sasOneText}>SAS ONE</div>
        </div>
      </div>
    </div>
  );
};

export default LoginWithOtpScreen;
