import React, { useState } from "react";
import classes from "./OPTheme.module.css";
import { AiOutlineEye,AiOutlineCloudUpload } from "react-icons/ai";
import { BsEyeSlash } from "react-icons/bs";


const OPInput = (props) => {
  const [passwordFocus, setPasswordFocus] = useState();
  const [passwordShow, setPasswordShow] = useState(true);



  const inputType = (type) => {
    switch (type) {
      case "SingleLineInput":
        return (
          <div className={classes.formContainer}>
            <input
              type={props.type}
              name={props.label}
              onInput={props.onInput}
              pattern={props.pattern}
              autoComplete="off"
              className={classes.inputArea}
              placeholder={props.placeholder}
              maxLength={props.maxLength}
              minLength={props.minLength}
              value={props.value}
              onChange={props.onChange}
              onFocus={props.onFocus}
            />
          </div>
        );
      case "MultiLineInput":
        return (
          <div className={classes.formContainer}>
            <textarea
              onInput={props.onInput}
              className={classes.inputAreaMulti}
              placeholder={props.placeholder}
              maxLength={props.maxLength}
              value={props.value}
              onClick={props.onClick}
              onChange={props.onChange}
              onFocus={props.onFocus}
              rows={5}
              cols={5}
            />
          </div>
        );
      case "DropDown":
        return (
          <div className={classes.formContainer}>
          <label className={classes.label}>{props.label}</label>
            <select
              name=" "
              id="1"
              className={classes.inputAreaLoginType}
              onChange={props.onChange}
              onInput={props.onInput}
              value={props.value}
            >
{props.optionData.map((item, index) => {
                return (
                  <option
                    key={index}
                    value={item.name}
                  >
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        );
      case "RadioButton":
        return (
          <div >
    
<div className={classes.inputArea}>
  <div><input className={classes.redioButton} type="radio" id="Yes" onChange={props.onChange} checked={props.isReel==="yes"}  name="reels" value="yes" />
          <label className={classes.redioButton} for="Yes">Yes</label></div>
         <div > <input className={classes.redioButton} type="radio" id="No" onChange={props.clickOnNo} checked={props.isReel==="no"} name="reels" value="no" />
          <label className={classes.redioButton} for="No">No</label></div>
</div>
          </div>
        );
      case "DateInput":
        return (
          <div className={classes.formContainer}>
            <input
              type={"date"}
              id={props.id}
              min={props.min}
              placeholder={props.placeholder}
              name={props.name}
              onChange={props.onChange}
              value={props.value}
              className={classes.inputAreaDate}
            />
          </div>
        );
        case "TimeInput":
          return (
            <div className={classes.formContainer}>
              <input
                // type={"datetime-local"}
                type={"time"}
                id={props.id}
                min={props.min}
                placeholder={props.placeholder}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                className={classes.inputAreaDate}
              />
            </div>
          );
      // case "password":
      //   return (
      //     <div className={classes.formContainer}>
      //       <div
      //         className={
      //           passwordFocus
      //             ? `${classes.input_fields_mobile} ${classes.input_fields}`
      //             : classes.input_fields
      //         }
      //       >
      //         <input
      //           type={passwordShow ? "password" : "text"}
      //           onFocus={() => {
      //             setPasswordFocus(true);
      //           }}
      //           onInput={props.onInput}
      //           onBlur={() => {
      //             setPasswordFocus(false);
      //           }}
      //           name={props.label}
      //           className={
      //             passwordFocus
      //               ? `${classes.inputArea_withIconFocus} ${classes.inputArea_withIcon}`
      //               : classes.inputArea_withIcon
      //           }
      //           placeholder={props.placeholder}
      //           value={props.value}
      //           onChange={props.onChange}
      //         />
      //         {passwordShow ? (
      //           <AiOutlineEye
      //             onClick={() => setPasswordShow(!passwordShow)}
      //             className={classes.eyeIcon}
      //             color="black"
      //           />
      //         ) : (
      //           <BsEyeSlash
      //             onClick={() => setPasswordShow(!passwordShow)}
      //             className={classes.eyeIcon}
      //             color="black"
      //           />
      //         )}
      //       </div>
      //     </div>
      //   );
      case "Checkbox":
        return (
          <div className={classes.checkboxContainer}>
            <input
              type="checkbox"
              id={props.id}
              name={props.name}
              value={props.value}
              className={classes.checkboxInput}
              onChange={props.onChange}
              checked={props.checked}
            />
            <label htmlFor={props.for}>
              {props.text} {props.icon}
              {props.subtext}
            </label>
          </div>
        );
        case "passwordLoginType":
        return (
          <div className={classes.formContainer}>
               <label className={classes.label}>{props.label}</label>
            <div
              className={
                passwordFocus
                  ? `${classes.input_fields_mobile} ${classes.input_fieldsLogin}`
                  : classes.input_fieldsLogin
              }
            >
              <input
                type={props.type}
                autocomplete={props.autoComplete}
                onFocus={() => {
                  setPasswordFocus(true);
                }}
                onInput={props.onInput}
                onBlur={() => {
                  setPasswordFocus(false);
                }}
                name={props.label}
                className={
                  passwordFocus
                    ? `${classes.inputArea_withIconFocusLogin} ${classes.inputArea_withIconLogin}`
                    : classes.inputArea_withIconLogin
                }
                placeholder={props.placeholder}
                value={props.value}
     
                onChange={props.onChange}
              />
              <span onClick={props.onClick}>
              {props.type==="password" ? (
                <AiOutlineEye
                  // onClick={() => setPasswordShow(!passwordShow)}
                  className={classes.eyeIcon}
                  color="black"
                />
              ) : (
                <BsEyeSlash
                  // onClick={() => setPasswordShow(!passwordShow)}
                  className={classes.eyeIcon}
                  color="black"
                />
              )}
              </span>
            </div>
          </div>
        );
        case "passwordLoginTypeConfirm":
        return (
          <div className={classes.formContainer}>
               <label className={classes.label}>{props.label}</label>
            <div
              className={
                passwordFocus
                  ? `${classes.input_fields_mobile} ${classes.input_fieldsLogin}`
                  : classes.input_fieldsLogin
              }
            >
              <input
                type={props.type}
                autocomplete={props.autoComplete}
                onFocus={() => {
                  setPasswordFocus(true);
                }}
                onInput={props.onInput}
                onBlur={() => {
                  setPasswordFocus(false);
                }}
                name={props.label}
                className={
                  passwordFocus
                    ? `${classes.inputArea_withIconFocusLogin} ${classes.inputArea_withIconLogin}`
                    : classes.inputArea_withIconLogin
                }
                placeholder={props.placeholder}
                value={props.value}
     
                onChange={props.onChange}
              />
            
            </div>
          </div>
        );
        case "SingleLineInputSecType":
          return (
            <div className={classes.formContainer}>
              <label className={classes.label}>{props.label}</label>
              <input
                type={props.type}
                name={props.label}
                autoComplete={props.autoComplete}
                maxLength={props.maxLength}
                onInput={props.onInput}
                pattern={props.pattern}
                className={classes.inputAreaLoginType}
                placeholder={props.placeholder}
                minLength={props.minLength}
                value={props.value}
                onChange={props.onChange}
                onFocus={props.onFocus}
              />
            </div>
          );
        case "file":
          return (
            <div className={classes.formContainer}>
            <label className={classes.uploadInput} htmlFor="uploadM">
              <input
                type="file"
                multiple={props.multiple}
                id="uploadM"
                hidden
                
                accept="video/*,image/*,.gif,"
                onChange={props.onChange}
                placeholder={props.placeholder}
              />
            <div className={classes.postImg}>
            {props.PreUrl && !props.placeholder ? (
                <img
                  src={props.PreUrl}
                  alt=""
                  height='100%'
                  width='100%'
                  className={classes.postImginner}
                  // className={classes.uploadImage}
                />
              ) : props.placeholder ? (
                props.placeholder.type.includes("video") ? (
                  <video height='100%'
                  width='100%' controls autoPlay  className={classes.postImginner}>
                    <source
                      src={URL.createObjectURL(props.placeholder)}
                      type={props.placeholder.type}
                      className={classes.postImginner}
                    />
                  </video>
                ) : (
                  <img
                    src={URL.createObjectURL(props.placeholder)}
                    alt=""
                    height='100%'
                  width='100%'
                  className={classes.postImginner}
                  />
                )
              ) : (
                <AiOutlineCloudUpload
                  id="uploadM"
                  color="#ef5122"
                  className={classes.uploadIcon}
                />
              )}
            </div>
              <div className={classes.uploadPlaceholder}>
                {props.PreUrl && !props.placeholder ? (
                  props.PreUrl.slice(-40)
                ) : props.placeholder ? (
                  <div>Change</div>
                ) : (
                  <div>
                    Select <br /> file to upload
                  </div>
                )}
              </div>
            </label>
          </div>
          );
  
      default:
        console.log("Invalid Input");
        break;
    }
  };
  return <div>{inputType(props.input)}</div>;
};

export default OPInput;
