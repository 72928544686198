import React, { useState, useEffect } from "react";
import DashboardComp from "../../Components/TailwindCompnents/DashoardComp";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../custom.module.css";
import StatsCard from "../../Components/TailwindCompnents/StatsCard";
import CreatePostButton from "../../Components/TailwindCompnents/CreatePostButton";
import SocialCard from "../../Components/TailwindCompnents/SocialCard";
import RecentPost from "../../Components/TailwindCompnents/RecentPost";
import Table from "../../Components/TailwindCompnents/Table";
import AnalyticsCard from "../../Components/TailwindCompnents/AnalyticsCard";
import { BsInstagram } from "react-icons/bs";
import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import LoadingComp from "../../Components/TailwindCompnents/LoadingComp";
import TailwindModal from "../../Components/TailwindCompnents/TailwindModal";
import SubscriptionCard from "../../Components/TailwindCompnents/SubscriptionCard";
import SuccessMessage from "../../Components/TailwindCompnents/SuccessMessage";
import SocialPageModal from "../../Components/TailwindCompnents/SocialPageModal";
import Tabs from "../../Components/TailwindCompnents/Tabs";
import PricingSection from "../../Components/TailwindCompnents/PricingSection";
import Cookies from "js-cookie";
import TwitterTestingModal from "../../Components/TailwindCompnents/TwitterTestingModal";
import { useContext } from "react";
import ThemeContext from "../../Context/ThemeContext";
import "intro.js/introjs.css";
import { Steps, Hints } from "intro.js-react";
import introJs from "intro.js";
import Undermaintenance from "../../Components/TailwindCompnents/Undermaintenance";
import NewPagination from "../../Components/TailwindCompnents/NewPagination";

const CreativeDelivereyList = () => {
  const [creativeDeliverData, setCreativeDeliverData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [count, setCount] = useState("");
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const dateHandler = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthNameLong = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return (day <= 9 ? "0" : "") + day + "-" + monthNameLong + "-" + year;
  };
  useEffect(() => {
    GetCreativeDeliverData();
  }, [searchPage]);

  const GetCreativeDeliverData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 88927950e8858bb239c62d8dcb81292630996841"
    );

    var formdata = new FormData();
    formdata.append("UserID", Cookies.get("UserId"));
    formdata.append("page", searchPage);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://api.sasone.in/apiadmin/v1/DeliveredCreativeList",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCreativeDeliverData(result.data);
          setCount(result.count);
          setMaxPage(Math.ceil(result.count / 20));
        }
      })
      .catch((error) => console.log("error", error));
  };
  const pageHandler = (value) => {
    setSearchPage(value);
};
  const pages = [{ name: "Creative Delivery List", href: "", current: true }];
  return (
    <>
      <DashboardComp BreadCrumb={pages}>
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <table
            className="min-w-full border-separate py-2 overflow-scroll lg:overflow-hidden rounded-sm shadow bg-white"
            style={{ borderSpacing: 0 }}
          >
            <thead className="">
              <tr>
                <th
                  scope="col"
                  className="sticky top-0  border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                >
                  Email
                </th>

                <th
                  scope="col"
                  className="sticky top-0  border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Creative Description
                </th>
                <th
                  scope="col"
                  className="sticky top-0  border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Image
                </th>
                <th
                  scope="col"
                  className="sticky top-0  border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  RequestDate
                </th>
                <th
                  scope="col"
                  className="sticky top-0  border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                  Delivery Date
                </th>

                {/* <th
                  scope="col"
                  className="sticky top-0  border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                >
                Update
                </th> */}
              </tr>
            </thead>
            <tbody className="bg-white">
              {creativeDeliverData.length>0?creativeDeliverData?.map((person, personIdx) => (
                <tr key={person.email}>
                  <td
                    className={classNames(
                      personIdx !== creativeDeliverData.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    {person.UserEmail}
                  </td>

                  <td
                    className={classNames(
                      personIdx !== creativeDeliverData.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.CreativeDescription}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== creativeDeliverData.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    <img src={person.CreativeImageURL} className="h-8 w-8" />
                  </td>
                  <td
                    className={classNames(
                      personIdx !== creativeDeliverData.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.RequestDate && dateHandler(person.RequestDate)}
                  </td>
                  <td
                    className={classNames(
                      personIdx !== creativeDeliverData.length
                        ? "border-b border-gray-200"
                        : "",
                      "whitespace-nowrap px-3 py-3 text-sm text-gray-500  lg:table-cell"
                    )}
                  >
                    {person.DeliveryDate && dateHandler(person.DeliveryDate)}
                  </td>
                </tr>
              )):<div className="m-5 text-base font-semibold">No Data found !!</div>}
            </tbody>
          </table>
          {creativeDeliverData.length>0&&<NewPagination
          maxPages={maxPage}
          dataCount={count}
          datalength={creativeDeliverData.length}
          hitPage={pageHandler}
          pageNum={searchPage}
        />}
        </div>
       
      </DashboardComp>
    </>
  );
};

export default CreativeDelivereyList;
