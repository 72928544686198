import { ClockIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";
import { Fragment, useRef, useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Menu, Transition } from "@headlessui/react";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineSchedule } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import {
  FaLinkedinIn,
  FaYoutube,
  FaFacebookF,
  FaTwitter,
} from "react-icons/fa";
import classes from "../../Screens/custom.module.css";
import Modal from "../Modal";
import Carousel from "../CreatePost/Carousel";
import FBCard from "../CreatePost/FBCard";
import InstagramCard from "../CreatePost/InstagramCard";
import LinkedinCard from "../CreatePost/LinkedinCard";
import TwitterCard from "../CreatePost/TwitterCard";
import LoadingComp from "./LoadingComp";
import { useNavigate } from "react-router-dom";

const days = [
  { date: "2021-12-27", events: [] },
  { date: "2021-12-28", events: [] },
  { date: "2021-12-29", events: [] },
  { date: "2021-12-30", events: [] },
  { date: "2021-12-31", events: [] },
  { date: "2021-12-30", events: [] },
  { date: "2021-12-31", events: [] },
  {
    date: "2022-01-03",
    isCurrentMonth: true,
    events: [
      {
        id: 1,
        name: "Design review",
        time: "10AM",
        datetime: "2022-01-03T10:00",
        href: "#",
      },
      {
        id: 2,
        name: "Sales meeting",
        time: "2PM",
        datetime: "2022-01-03T14:00",
        href: "#",
      },
    ],
  },
  { date: "2022-01-04", isCurrentMonth: true, events: [] },
  { date: "2022-01-05", isCurrentMonth: true, events: [] },
  { date: "2022-01-06", isCurrentMonth: true, events: [] },
  {
    date: "2022-01-07",
    isCurrentMonth: true,
    events: [
      {
        id: 3,
        name: "Date night",
        time: "6PM",
        datetime: "2022-01-08T18:00",
        href: "#",
      },
    ],
  },
  { date: "2022-01-08", isCurrentMonth: true, events: [] },
  { date: "2022-01-09", isCurrentMonth: true, events: [] },
  { date: "2022-01-10", isCurrentMonth: true, events: [] },
  { date: "2022-01-11", isCurrentMonth: true, events: [] },
  {
    date: "2022-01-12",
    isCurrentMonth: true,
    isToday: true,
    events: [
      {
        id: 6,
        name: "Sam's birthday party",
        time: "2PM",
        datetime: "2022-01-25T14:00",
        href: "#",
      },
    ],
  },
  { date: "2022-01-13", isCurrentMonth: true, events: [] },
  { date: "2022-01-14", isCurrentMonth: true, events: [] },
  { date: "2022-01-15", isCurrentMonth: true, events: [] },
  { date: "2022-01-16", isCurrentMonth: true, events: [] },
  { date: "2022-01-17", isCurrentMonth: true, events: [] },
  { date: "2022-01-18", isCurrentMonth: true, events: [] },
  { date: "2022-01-19", isCurrentMonth: true, events: [] },
  { date: "2022-01-20", isCurrentMonth: true, events: [] },
  { date: "2022-01-21", isCurrentMonth: true, events: [] },
  {
    date: "2022-01-22",
    isCurrentMonth: true,
    isSelected: true,
    events: [
      {
        id: 4,
        name: "Maple syrup museum",
        time: "3PM",
        datetime: "2022-01-22T15:00",
        href: "#",
      },
      {
        id: 5,
        name: "Hockey game",
        time: "7PM",
        datetime: "2022-01-22T19:00",
        href: "#",
      },
    ],
  },
  { date: "2022-01-23", isCurrentMonth: true, events: [] },
  { date: "2022-01-24", isCurrentMonth: true, events: [] },
  { date: "2022-01-25", isCurrentMonth: true, events: [] },
  { date: "2022-01-26", isCurrentMonth: true, events: [] },
  { date: "2022-01-27", isCurrentMonth: true, events: [] },
  { date: "2022-01-28", isCurrentMonth: true, events: [] },
  { date: "2022-01-29", isCurrentMonth: true, events: [] },
  { date: "2022-01-30", isCurrentMonth: true, events: [] },
  { date: "2022-01-31", isCurrentMonth: true, events: [] },
  { date: "2022-02-01", events: [] },
  { date: "2022-02-02", events: [] },
  {
    date: "2022-02-03",
    events: [
      {
        id: 7,
        name: "Cinema with friends",
        time: "9PM",
        datetime: "2022-02-04T21:00",
        href: "#",
      },
    ],
  },
  { date: "2022-02-04", events: [] },
  { date: "2022-02-05", events: [] },
  { date: "2022-02-06", events: [] },
];
const selectedDay = days.find((day) => day.isSelected);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CalendarMonthView = (props) => {
  const [scheduleData, setScheduleData] = useState([]);
  const [scheduleFullData, setScheduleFullData] = useState([]);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState("Pages");
  const [socialAccount, setSocialAccount] = useState("All");
  const weekDuration = 7 * 60 * 60 * 24 * 1000;
  console.log(scheduleData, "djg");
  const navigate = useNavigate()

  const [editable ,setEditable ] = useState(true);

  const curDay = () => {
    var weekdays = new Array(7);
    weekdays[0] = "Sunday";
    weekdays[1] = "Monday";
    weekdays[2] = "Tuesday";
    weekdays[3] = "Wednesday";
    weekdays[4] = "Thursday";
    weekdays[5] = "Friday";
    weekdays[6] = "Saturday";
    var r = weekdays[props.monthNum.getDay()];
    var data = [];
    if (r === "Tuesday") {
      data = days.slice(0, 1);
    }
    if (r === "Wednesday") {
      data = days.slice(0, 2);
    }
    if (r === "Thursday") {
      data = days.slice(0, 3);
    }
    if (r === "Friday") {
      data = days.slice(0, 4);
    }
    if (r === "Saturday") {
      data = days.slice(0, 5);
    }
    if (r === "Sunday") {
      data = days.slice(0, 6);
    }
    return data;
  };

  const IconTextSelected = (value) => {
    if (value === "instagram") {
      return (
        <>
          <BsInstagram size={15} color={"#fff"} />
        </>
      );
    }
    if (value === "facebook") {
      return (
        <>
          <FaFacebookF size={15} color={"white"} />
        </>
      );
    }
    if (value === "linkedin" || value === "linkedinorg") {
      return (
        <>
          <FaLinkedinIn size={15} color={"#fff"} />
        </>
      );
    }
    if (value === "twitter") {
      return (
        <>
          <FaTwitter size={15} color={"#fff"} />
        </>
      );
    }
    if (value === "youtube") {
      return (
        <>
          <FaYoutube size={15} color={"#fff"} />
        </>
      );
    }
  };
  const time24to12Converter = (t) => {
    let time = t?.split(":");
    let temp = " AM";
    if (parseInt(time?.length && time[0]) > 11) {
      time[0] = time[0] - 12 < 1 ? 12 : (time[0] -= 12);
      temp = " PM";
    } else {
      time[0] = time[0] < 1 ? 12 : time[0];
    }
    return time?.join(":") + temp;
  };

  const TextColor = (value) => {
    if (value === "instagram") {
      return classes.schedulInsta;
    }
    if (value === "facebook") {
      return "bg-fb rounded-full  flex items-center p-1";
    }
    if (value === "linkedin" || value === "linkedinorg") {
      return "bg-linkedIn rounded-full flex items-center p-1";
    }
    if (value === "twitter") {
      return "bg-twitter rounded-full flex items-center p-1";
    }
    if (value === "youtube") {
      return "bg-red-500 rounded-full flex items-center p-1";
    }
  };
  const nextMonth = () => {
    props.monthNum.setMonth(props.monthNum.getMonth() + 1, 1);
    var dt = new Date(props.monthNum);
    props.setMonthNum(dt);
  };
  const prevMonth = () => {
    props.monthNum.setMonth(props.monthNum.getMonth() - 1, 1);
    var dt = new Date(props.monthNum);
    props.setMonthNum(dt);
  };
  const currentMonth = () => {
    const date1 = new Date(
      props.device === "Mobile" ? props.startDate : props.monthNum
    );
    const month = date1.toLocaleString("default", { month: "long" });
    return month;
  };

  const dateWithTime = (date) => {
    var days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const dateObj = new Date(date);
    const day = dateObj?.getDate();
    const monthNameLong = dateObj?.toLocaleString("en-US", { month: "short" });
    const dayNameLong = days[dateObj.getDay()];
    const year = dateObj?.getFullYear();
    return (
      dayNameLong + " , " + (day <= 9 ? "0" : "") + day + " " + monthNameLong
    );
  };

  const socialAccounts = () => {
    if (socialAccount === "All") {
      return "All Posts";
    }
    if (socialAccount === "facebook") {
      return "Facebook Posts";
    }
    if (socialAccount === "instagram") {
      return "Instagram Posts";
    }
    if (socialAccount === "linkedin") {
      return "LinkedIn Posts";
    }
    if (socialAccount === "twitter") {
      return "Twitter Posts";
    }
  };
  const pageName = (pagename, toPost) => {
    if (toPost?.includes("twitter")) {
      return pagename.twitter;
    }
    if (toPost?.includes("instagram")) {
      return pagename.instagram;
    }
    if (toPost === "linkedin") {
      return pagename.linkedin;
    }
    if (toPost?.includes("linkedinorg")) {
      return pagename.linkedinorg;
    }
    if (toPost?.includes("facebook")) {
      return pagename.facebook;
    }
  };
  const filteredData = (data) => {
    var filterData = [];
    if (socialAccount === "facebook") {
      filterData = data
        ?.filter((item) => item.ToPost?.includes(socialAccount))
        ?.filter((post) =>
          page === "Pages"
            ? post.Posts?.findIndex((item) => item.PageName == page) === -1
            : post.Posts?.findIndex((item) => item.PageName == page) !== -1
        )
        ?.filter((val) => val.PostStatus === props.status);
    } else if (socialAccount === "instagram") {
      filterData = data
        ?.filter((item) => item.ToPost?.includes(socialAccount))
        ?.filter((post) =>
          page === "Pages"
            ? post.Posts?.findIndex((item) => item.PageName == page) === -1
            : post.Posts?.findIndex((item) => item.PageName == page) !== -1
        )
        ?.filter((val) => val.PostStatus === props.status);
    } else if (socialAccount === "twitter") {
      filterData = data
        ?.filter((item) => item.ToPost?.includes(socialAccount))
        ?.filter((post) =>
          page === "Pages"
            ? post.Posts?.findIndex((item) => item.PageName == page) === -1
            : post.Posts?.findIndex((item) => item.PageName == page) !== -1
        )
        ?.filter((val) => val.PostStatus === props.status);
    } else if (socialAccount === "linkedin") {
      filterData = data
        ?.filter((item) => item.ToPost?.includes(socialAccount))
        ?.filter((post) =>
          page === "Pages"
            ? post.Posts?.findIndex((item) => item.PageName == page) === -1
            : post.Posts?.findIndex((item) => item.PageName == page) !== -1
        )
        ?.filter((val) => val.PostStatus === props.status);
    } else if (socialAccount === "All" && page !== "Pages") {
      filterData = data
        ?.filter(
          (post) =>
            post.Posts?.findIndex((item) => item.PageName == page) !== -1
        )
        ?.filter((val) => val.PostStatus === props.status);
    } else if (socialAccount === "All" && page === "Pages") {
      filterData = data?.filter((val) => val.PostStatus === props.status);
    }
    return filterData;
  };

  const Description = (post, toPost) => {
    if (toPost?.includes("twitter")) {
      return post.TwitterDescription || post.Description;
    }
    if (toPost?.includes("instagram")) {
      return post.InstaDescription || post.Description;
    }
    if (toPost?.includes("facebook")) {
      return post.FBDescription || post.Description;
    }
    if (toPost?.includes("linkedin")) {
      return post.LinkedinDescription || post.Description;
    }
  };
  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <header className=" flex-none items-center hidden sm:flex  justify-between border-b border-gray-200 py-4 ">
        <div className="flex">
          <div className="  md:flex md:items-center  ">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                <div className="hidden sm:block ">{socialAccounts()}</div>
                <FaFilter className="block sm:hidden" />
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1 ml-5">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => {
                            setSocialAccount("All");
                            setPage("Pages");
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          All Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => {
                            setSocialAccount("facebook");
                            setPage("Pages");
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Facebook Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => {
                            setSocialAccount("instagram");
                            setPage("Pages");
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Instagram Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => {
                            setSocialAccount("linkedin");
                            setPage("Pages");
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          LinkedIn Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => {
                            setSocialAccount("twitter");
                            setPage("Pages");
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Twitter Posts
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className=" md:ml-4 md:flex md:items-center ml-4">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                {page}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {(socialAccount === "All"
                      ? props.pages
                      : props.pages?.filter((val) =>
                        val.SocialMediaName?.includes(socialAccount)
                      )
                    )?.map((item, index) => {
                      return (
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() => setPage(item.PageName)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm cursor-pointer"
                              )}
                            >
                              {item.PageName}
                            </div>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className=" md:ml-4 md:flex md:items-center ml-4">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                {props.status === "Live" ? "Live Posts" : "Scheduled Posts"}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => props.setStatus("Scheduled")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Scheduled Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => props.setStatus("Live")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Live Posts
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex items-center ">
          <div className="flex items-center rounded-md shadow-sm md:items-stretch">
            <button
              onClick={() => {
                props.device === "Mobile"
                  ? props.setStartDate(props.startDate - weekDuration)
                  : prevMonth();
              }}
              type="button"
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-2 pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous month</span>
              <MdOutlineKeyboardArrowLeft
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>

            <button
              type="button"
              className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              {currentMonth()}
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              onClick={() => {
                props.device === "Mobile"
                  ? props.setStartDate(props.startDate + weekDuration)
                  : nextMonth();
              }}
              type="button"
              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-2 pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <MdOutlineKeyboardArrowRight
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </header>
      <header className="flex  flex-col  sm:hidden   border-b border-gray-200 py-4 ">
        <div className="flex">
          <div className="  md:flex   ">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                <div className="">{socialAccounts()}</div>
                {/* <FaFilter className="block sm:hidden" /> */}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1 ml-5">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("All")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          All Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("facebook")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Facebook Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("instagram")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Instagram Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("linkedin")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          LinkedIn Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => setSocialAccount("twitter")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Twitter Posts
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="   ml-4">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                {page}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {(socialAccount === "All"
                      ? props.pages
                      : props.pages?.filter((val) =>
                        val.SocialMediaName?.includes(socialAccount)
                      )
                    )?.map((item, index) => {
                      return (
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() => setPage(item.PageName)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm cursor-pointer"
                              )}
                            >
                              {item.PageName}
                            </div>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex mt-2 items-center ">
          <div className=" ">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                {props.status === "Live" ? "Live Posts" : "Schedule Posts"}
                <MdOutlineKeyboardArrowDown
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => props.setStatus("Scheduled")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Scheduled Posts
                        </div>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={() => props.setStatus("Live")}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        >
                          Live Posts
                        </div>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="flex items-center rounded-md ml-4 shadow-sm md:items-stretch">
            <button
              onClick={() => {
                props.setStartDate(props.startDate - weekDuration);
              }}
              type="button"
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-2 pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Previous month</span>
              <MdOutlineKeyboardArrowLeft
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>

            <button
              type="button"
              className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              {currentMonth()}
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              onClick={() => props.setStartDate(props.startDate + weekDuration)}
              type="button"
              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-2 pr-2 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <MdOutlineKeyboardArrowRight
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </header>
      {props.Loading ? (
        <LoadingComp />
      ) : (
        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
            <div className="bg-white py-2">
              M<span className="sr-only sm:not-sr-only">on</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">ue</span>
            </div>
            <div className="bg-white py-2">
              W<span className="sr-only sm:not-sr-only">ed</span>
            </div>
            <div className="bg-white py-2">
              T<span className="sr-only sm:not-sr-only">hu</span>
            </div>
            <div className="bg-white py-2">
              F<span className="sr-only sm:not-sr-only">ri</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">at</span>
            </div>
            <div className="bg-white py-2">
              S<span className="sr-only sm:not-sr-only">un</span>
            </div>
          </div>
          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 lg:gap-px">
              {curDay()?.map((item) => {
                return (
                  <div key={item.id}>
                    <a href={"event.href"} className="group flex">
                      <p className={`flex-auto truncate font-medium text-gray-900 group-hover:text-${props.contextValue}-600`}>
                        {/* {item.date} */}
                      </p>
                    </a>
                  </div>
                );
              })}
              {props.newPostedData?.map((day) => {
                return (
                  <div
                    key={day.date}
                    className={classNames(
                      day.isCurrentMonth
                        ? "bg-white"
                        : "bg-gray-50 text-gray-500",
                      "relative py-3 h-32 px-3"
                    )}
                  >
                    <time
                      dateTime={day.date}
                      className={
                        day.isToday
                          ? `flex h-6 w-6 items-center justify-center rounded-full bg-${props.contextValue}-600 font-semibold text-white`
                          : undefined
                      }
                    >
                      {day.date.split("-").pop().replace(/^0/, "")}
                    </time>

                    {filteredData(day.content).length > 0 && (
                      <ol
                      // className={
                      //   props.status === "Live"
                      //     ? " rounded-lg bg-green-50  text-xs "
                      //     : " rounded-lg bg-blue-50  text-xs "
                      // }
                      >
                        {day.content.slice(0, 1).map((post, idx) => (
                          <div
                            id={idx}
                            className="   rounded   "

                          >
                            <div onClick={() => {
                              setScheduleData(post);
                              props.setModalShow(true);
                            }}>   <div className="flex  cursor-pointer justify-between mt-1 ">
                                <div className="flex truncate">
                                  {" "}
                                  <div
                                    className={TextColor(
                                      post.Posts[0]?.SocialMediaName
                                    )}
                                  >
                                    {IconTextSelected(
                                      post.Posts[0]?.SocialMediaName
                                    )}
                                  </div>
                                  <div className="font-semibold ml-1 truncate  text-gray-700 ">
                                    {post.Posts[0]?.PageName}
                                  </div>
                                </div>
                                <div className={`text-${props.contextValue}-500 cursor-pointer hover:text-${props.contextValue}-800 font-bold w-8 text-right`}>
                                  {post.Posts?.length > 1
                                    ? " +" + (post.Posts?.length - 1)
                                    : ""}
                                </div>
                              </div>
                              <p className=" cursor-pointer  font-semibold truncate text-gray-700 ">
                                {Description(
                                  post,
                                  post.Posts[0]?.SocialMediaName
                                )}
                              </p></div>
                            <div className="flex  justify-between">  <p className="text-gray-700 flex truncate   group-hover:text-blue-700 ">
                              <div className="block sm:hidden ">
                                {dateWithTime(post.ScheduleDate)}
                              </div>
                              {post.ScheduleTime && (
                                <div>
                                  {time24to12Converter(post.ScheduleTime)}
                                </div>
                              )}
                            </p>
                              {filteredData(day.content)?.length > 1 && (
                                <div
                                  className="text-right   cursor-pointer text-slate-600"
                                  onClick={() => {
                                    setScheduleFullData(filteredData(day.content));
                                    // setCardScheduleData(item.c)
                                    setModal(true);
                                  }}
                                >
                                  <div className=" flex justify-end">
                                    {" "}
                                    <div className={` hover:underline text-${props.contextValue}-500  rounded`}>
                                      {"View All"}{" "}
                                    </div>{" "}
                                  </div>
                                  {/* <div className=" flex justify-end"> + <div className="border px-1 bg-green-200 rounded-full" >{ filteredData(item.content)?.length-1} </div> </div> */}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}

                      </ol>
                    )}
                  </div>
                );
              })}
            </div>
            {modal && (
              <Modal
                modalCase={"Post Details"}
                Text={scheduleFullData}
                setShowModal={setModal}
                iscancel={false}
                status={props.status}
                setScheduleData={setScheduleData}
                setModalShow={props.setModalShow}
                onIconClick={() => setModal("")}
                onOk={() => setModal("")}
              />
            )}
            <Modal
              setOpen={props.setModalShow}
              open={props.modalShow}
              data={scheduleData}
              onClose={() => {
                props.setModalShow(false);
              }}
              modalCase={"rightSideMOdal"}
            >
              <div className=" ">
                <div className="p-4 flex items-center border border-gray-200 justify-between">
                  <div className="flex items-center">
                    <AiOutlineSchedule
                      size={40}
                      className="border mr-2 rounded-full p-2"
                    />{" "}
                    <div>
                      <div>{props.status} Post</div>
                      <div className="text-gray-700  flex  text-sm text-slate-500 group-hover:text-blue-700 min-w-fit">
                        <div className="block  mr-1">
                          {dateWithTime(scheduleData.ScheduleDate)}
                        </div>
                        {scheduleData.ScheduleTime && (
                          <div>
                            {time24to12Converter(scheduleData.ScheduleTime)}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <IoCloseSharp
                    size={40}
                    onClick={() => {
                      props.setModalShow(false);
                    }}
                    className="border cursor-pointer rounded-full p-2"
                  />
                </div>
                <div className="px-4 py-3 flex justify-between items-center ">


                  <div className="flex gap-4">
                    {scheduleData.ToPost?.includes("linkedin") && <div className="bg-linkedIn rounded-full flex w-4 h-4  my-1  justify-center mr-1   items-center p-1">
                      {" "}
                      <FaLinkedinIn size={10} color={"#fff"} />
                    </div>}
                    {scheduleData.ToPost?.includes("twitter") && <div className="bg-twitter rounded-full flex w-4 h-4  my-1  justify-center mr-1   items-center p-1">
                      {" "}
                      <FaTwitter size={10} color={"#fff"} />
                    </div>}
                    {scheduleData.ToPost?.includes("instagram") && <div className={classes.instagramSchedule}>
                      {" "}
                      <BsInstagram size={10} color={"#fff"} />
                    </div>}
                    {scheduleData.ToPost?.includes("facebook") && <div className="bg-fb rounded-full flex w-4 h-4 my-1  justify-center mr-1  items-center p-1 ">
                      {" "}
                      <FaFacebookF size={10} color={"white"} />
                    </div>}
                    {scheduleData.ToPost?.includes("youtube") && <div className="bg-youtube rounded-full flex w-5 h-4 my-1  justify-center mr-1  items-center p-1 ">
                      {" "}
                      <BsYoutube size={10} color={"white"} />
                    </div>}
                  </div>
                  {scheduleData.PostStatus === "Scheduled"  ? (
                    <div>
                      <button onClick={() => navigate('/update-schedule-post/' + scheduleData.SocialId)} className="border-none font-medium py-1 border-transparent pr-2">
                        <PencilSquareIcon className="h-7 w-7 border-transparent" />
                      </button>
                      <button
                        onClick={() => {
                          props.setPostId(scheduleData.SocialId);
                          props.setModal("remove");
                        }}
                        className="rounded-full font-medium  py-1"
                      >
                        <TrashIcon className="h-7 w-7 text-red-500"/>
                      </button>

                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="mt-2">
                  <Carousel infinite timer={2000} stopOnManual>
                    {scheduleData.Posts?.filter((item)=>item.SocialMediaName != "gmb")?.map((item) => {
                      if (item.SocialMediaName === "facebook") {
                        return (
                          <FBCard
                            text={"Facebook preview"}
                            cardCase={"schedulePost"}
                            selectedFiles={scheduleData.ImageUrl}
                            pageId={scheduleData.PageId.facebook}
                            description={scheduleData.Description}
                            FbDescription={scheduleData.FBDescription}
                            userName={item.PageName}
                            ProfileImage={item.ProfileImage}
                          />
                        );
                      }
                     

                      if (item.SocialMediaName === "instagram") {
                        return (
                          <InstagramCard
                            text={"Instagram preview"}
                            cardCase={"schedulePost"}
                            selectedFiles={scheduleData.ImageUrl}
                            description={scheduleData.Description}
                            FbDescription={scheduleData.InstaDescription}
                            userName={item.PageName}
                            ProfileImage={item.ProfileImage}
                          />
                        );
                      }

                      if (item.SocialMediaName.includes("linkedin")) {
                        return (
                          <LinkedinCard
                            text={"Linkedin preview"}
                            cardCase={"schedulePost"}
                            selectedFiles={scheduleData.ImageUrl}
                            description={scheduleData.Description}
                            FbDescription={scheduleData.LinkedinDescription}
                            userName={item.PageName}
                            ProfileImage={item.ProfileImage}
                          />
                        );
                      }
                      if (item.SocialMediaName === "twitter") {
                        return (
                          <TwitterCard
                            text={"Twitter preview"}
                            cardCase={"schedulePost"}
                            selectedFiles={scheduleData.ImageUrl}
                            description={scheduleData.Description}
                            FbDescription={scheduleData.TwitterDescription}
                            userName={item.PageName}
                            ProfileImage={item.ProfileImage}
                          />
                        );
                      }
                      if (item.SocialMediaName === "youtube") {
                        
                        return (
                          <TwitterCard
                            text={"Youtube preview"}
                            cardCase={"schedulePost"}
                            selectedFiles={scheduleData.ImageUrl}
                            description={scheduleData.Description}
                            FbDescription={scheduleData.TwitterDescription}
                            userName={item.PageName}
                            ProfileImage={item.ProfileImage}
                          />
                        );
                      }
                   
                    })}
                    
                  </Carousel>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};
export default CalendarMonthView;
